import { standarizeLatLngArray } from 'common/utils/map';
import * as map from 'components/map';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';

interface CenteringExtendProps {
  areaId: number;
  enabled: boolean;
}

export const CenteringExtent = ({ areaId, enabled }: CenteringExtendProps) => {
  const isExtentVisible = useLayerManagerStore.use.isExtentVisible();

  const { areaOfInterestDetails } = areasRepository.useFetchAreaOfInterestDetails(Number(areaId));
  const extentPolygon = areaOfInterestDetails?.geometry.coordinates[0];

  const standarizedExtentPolygon = extentPolygon && standarizeLatLngArray(extentPolygon);

  return (
    <>
      {standarizedExtentPolygon && (
        <map.Layer visible={true} name={'mapCenteringFrame'} zIndex={100}>
          <map.LeafletMapViewCenteringFrame areaId={areaId} latLngs={standarizedExtentPolygon} />
        </map.Layer>
      )}
      <map.Layer visible={isExtentVisible && enabled} name={'extentPolygon'} zIndex={611}>
        {standarizedExtentPolygon && (
          <map.PolylineView
            positions={standarizedExtentPolygon}
            pathOptions={{
              color: 'white',
              opacity: 0.75,
            }}
            pane={'extentPolygon'}
          />
        )}
      </map.Layer>
    </>
  );
};
