import { waterRepository } from 'infrastructure/water/waterRepository';
import { useTimelineService } from 'components/Timeline/timelineService';
import { useAoiNumber, useQueryParameter } from 'common/navigation/hooks';
import { WATER_INDICATOR_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { type GenericTooltipInputResult } from 'common/types/mapData';
import { getBareCogUrl } from 'services/titiler/utils';

export const useGetWaterQualityRemappedResults = () => {
  const areaId = useAoiNumber();

  const { waterBodiesList } = waterRepository.useFetchWaterbodies(areaId);

  const { timestamp } = useTimelineService();

  const waterIndicator = useQueryParameter(WATER_INDICATOR_QUERY_PARAMETER);
  const indicatorId = waterIndicator ? Number(waterIndicator) : undefined;

  const { waterQualityResults, waterQualityResultsLoading } = waterRepository.useFetchWaterQualityResults(
    areaId,
    timestamp,
    indicatorId,
  );
  const waterQualityResultIds = waterQualityResults.map(({ id }) => id);

  const { waterQualityCogsResponse, waterQualityCogsLoading } =
    waterRepository.useFetchWaterQualityCogsResponse(waterQualityResultIds);

  const waterQualityReamappedResults: GenericTooltipInputResult[] = waterQualityResults
    .map((result) => ({
      ...result,
      url: waterQualityCogsResponse.find((item) => item.data?.data?.id === result.id)?.data?.data?.url || '',
      label: waterBodiesList.find((item) => item.id === result.waterbody_id)?.name || '',
      value: result.waterbody_id,
    }))
    .map((result) => ({
      ...result,
      url: `/vsiaz${getBareCogUrl(result.url)}`,
    }));

  const waterQualityRemappedResultsLoading = waterQualityResultsLoading || waterQualityCogsLoading;

  return { waterQualityReamappedResults, waterQualityRemappedResultsLoading };
};
