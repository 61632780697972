import { useDeleteQueryParameters, useQueryParameter, useReplaceQueryParameters } from 'common/navigation/hooks';
import {
  REPORTS_INDICATOR_STATS_QUERY_PARAMETER,
  REPORTS_QUERY_PARAMETER,
  REPORTS_QUERY_PARAMETER_VALUE,
  VIEW_MODE_QUERY_PARAMETER,
} from 'common/navigation/queryParams';
import { updateSemicolonSeparatedSigns } from 'common/utils';

export const reportsQueryService = {
  useReportsActiveness: () => {
    const replaceQueryParameters = useReplaceQueryParameters();

    const activateReports = () => {
      replaceQueryParameters([
        {
          key: REPORTS_QUERY_PARAMETER,
          value: REPORTS_QUERY_PARAMETER_VALUE,
        },
        {
          key: VIEW_MODE_QUERY_PARAMETER,
          value: null,
        },
      ]);
    };

    return {
      activateReports,
    };
  },

  useReportsIndicatorStats: () => {
    const indicatorStatsQuery = useQueryParameter(REPORTS_INDICATOR_STATS_QUERY_PARAMETER);

    const deleteQueryParameters = useDeleteQueryParameters();
    const replaceQueryParameters = useReplaceQueryParameters();

    const toggleIndicatorStats = (clickedTag: string) => {
      const rejectedTags = updateSemicolonSeparatedSigns(indicatorStatsQuery || '', clickedTag);

      clickedTag === null || rejectedTags === ''
        ? deleteQueryParameters([REPORTS_INDICATOR_STATS_QUERY_PARAMETER])
        : replaceQueryParameters([
            {
              key: REPORTS_INDICATOR_STATS_QUERY_PARAMETER,
              value: rejectedTags,
            },
          ]);
    };

    return { indicatorStatsQuery, toggleIndicatorStats };
  },
};
