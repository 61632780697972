import {
  buildConstantMonoColormapArray,
  buildDiscreteColorArray,
  buildGradientColormapArray,
} from 'common/utils/color';
import { getLayerNameWithAoiId } from 'common/utils';
import { type LayerRange } from 'common/types';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { MTMF_GRADIENT_COLORMAP, TURBO_COLORMAP } from 'domain/exploration/colors';
import { ExplorationMapLayer } from '../ExplorationMapLayer';

interface MineralsPlainMapLayerProps {
  areaId: number;
  index: number;
  name: string;
  url: string;
  color: string;
  classifierId: number;
  isVisible: boolean;
}

export const MineralsPlainMapLayer = ({
  areaId,
  index,
  name,
  url,
  color,
  classifierId,
  isVisible,
}: MineralsPlainMapLayerProps) => {
  const layersTresholds = useLayerManagerStore.use.layersTresholdsValues();
  const layerName = getLayerNameWithAoiId(name, areaId);

  const currentTreshold = layersTresholds[layerName];

  if (typeof currentTreshold !== 'number') {
    return null;
  }

  const expression = `((b1>${currentTreshold})&(b1<=${classifierId === 4 ? '255.0' : '1.0'}))*b1`;

  const colormapArray = buildConstantMonoColormapArray(color, typeof classifierId === 'number' ? classifierId : null);
  const colormap = JSON.stringify(colormapArray);

  return (
    <ExplorationMapLayer
      index={index}
      name={name}
      url={url}
      builder={titilerApiAdapter.buildMonocolorCogUrl}
      params={{ expression, colormap }}
      isVisible={isVisible}
    />
  );
};

interface GradientMapLayerProps {
  areaId: number;
  index: number;
  name: string;
  url: string;
  isVisible: boolean;
  layerRange: LayerRange;
}

export const SamGradientMapLayer = ({ areaId, index, name, url, isVisible, layerRange }: GradientMapLayerProps) => {
  const layersTresholds = useLayerManagerStore.use.layersTresholdsValues();
  const layerName = getLayerNameWithAoiId(name, areaId);

  const currentTreshold = layersTresholds[layerName];

  if (typeof currentTreshold !== 'number') {
    return null;
  }

  const { max } = layerRange;
  const expression = `((b1>=${currentTreshold})&(b1<${max}))*b1`;
  const colormapArray = buildGradientColormapArray(TURBO_COLORMAP, currentTreshold, max);

  return (
    <ExplorationMapLayer
      index={index}
      name={name}
      url={url}
      isVisible={isVisible}
      builder={titilerApiAdapter.buildMonocolorCogUrl}
      params={{ expression, colormap: JSON.stringify(colormapArray) }}
      opacity={0.5}
    />
  );
};

export const MtmfGradientMapLayer = ({ areaId, index, name, url, isVisible, layerRange }: GradientMapLayerProps) => {
  const layersTresholds = useLayerManagerStore.use.layersTresholdsValues();
  const layerName = getLayerNameWithAoiId(name, areaId);

  const currentTreshold = layersTresholds[layerName];

  if (typeof currentTreshold !== 'number') {
    return null;
  }

  const { max } = layerRange;
  const expression = `((b1>=${currentTreshold})&(b1<${max}))*b1`;
  const colormapArray = buildDiscreteColorArray(MTMF_GRADIENT_COLORMAP, [currentTreshold, max]);

  return (
    <ExplorationMapLayer
      index={index}
      name={name}
      url={url}
      isVisible={isVisible}
      builder={titilerApiAdapter.buildMonocolorCogUrl}
      params={{ expression, colormap: JSON.stringify(colormapArray) }}
      opacity={0.5}
    />
  );
};
