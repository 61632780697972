import styled, { type CSSProperties } from 'styled-components';

import { waveLoading } from 'common/animations';
import { theme } from 'common/theme';
import { type Palette } from 'ui/types';

type LoadingWaveVariants = 'NARROW_TRANSPARENT' | 'CARD';

type LoadingWaveStyles = Required<Pick<CSSProperties, 'background'>>;

const styles: Palette<LoadingWaveVariants, LoadingWaveStyles> = {
  NARROW_TRANSPARENT: {
    background: `linear-gradient(70deg, transparent 35%, ${theme.color.mineShaftLight} 50%, transparent 65%)`,
  },
  CARD: {
    background: `linear-gradient(65deg, ${theme.color.mineShaftABitDarker} 32%, ${theme.color.mineShaftABitLighter} 50%, ${theme.color.mineShaftABitDarker} 68%)`,
  },
};

interface LoadingWaveProps {
  variant?: LoadingWaveVariants;
}

export const LoadingWave = ({ variant = 'NARROW_TRANSPARENT' }: LoadingWaveProps) => (
  <StyledLoadingWave variant={variant} />
);

const StyledLoadingWave = styled.div<{ variant: LoadingWaveVariants }>`
  width: 100%;
  height: 100%;
  background: ${({ variant }) => styles[variant].background};
  background-size: 250% 100%;
  animation: 2s ${waveLoading} linear infinite;
`;
