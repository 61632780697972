import { useQueryParameter } from 'common/navigation/hooks';
import { waterRepository } from 'infrastructure/water/waterRepository';
import { WATERBODY_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { useGetWaterAreaResultsUseCase } from 'views/water/surface/getWaterAreaResultsUseCase';
import { useTimelineService } from 'components/Timeline/timelineService';
import { findMatchingIdsForCommonWaterbodyId } from 'domain/water/helpers';

export const useGetWaterAreaComparatorCogsUseCase = () => {
  const { timestamp, secondTimestamp } = useTimelineService();

  const { waterAreaResults: firstDateResults } = useGetWaterAreaResultsUseCase(timestamp);
  const { waterAreaResults: secondDateResults } = useGetWaterAreaResultsUseCase(secondTimestamp);

  const selectedWaterbodyId = useQueryParameter(WATERBODY_QUERY_PARAMETER);
  const resultIds = findMatchingIdsForCommonWaterbodyId(firstDateResults, secondDateResults, selectedWaterbodyId);

  const { comparatorCogsUrlTuples, comparatorCogsLoading, setComparatorCogsFetchingEnabled } =
    waterRepository.useGetWaterAreaComparatorCog(resultIds);

  const comparatorGenerateDisabled =
    (comparatorCogsUrlTuples.length && comparatorCogsUrlTuples.length === resultIds.length) || comparatorCogsLoading;

  const updateComparatorCogs = () => {
    setComparatorCogsFetchingEnabled(true);
    setTimeout(() => {
      setComparatorCogsFetchingEnabled(false);
    });
  };

  return { comparatorCogsUrlTuples, comparatorCogsLoading, comparatorGenerateDisabled, updateComparatorCogs };
};
