import { type ReactNode } from 'react';

import { useGetAoiName } from 'common/hooks/getAoiName';
import { LayerManager } from './LayerManager';
import { LayerManagerCommonContent } from './CommonContent';

export interface LayerManagerGeometry {
  top?: number;
  right?: number;
  minWidth?: number;
  zIndex?: number;
  lowerClearance?: number;
}

interface LayerManagerCoreProps {
  disableInfrastructureLayer?: boolean;
  mainLayerLabel?: string | JSX.Element;
  mainLayerOpacityAdjustable?: boolean;
  children?: ReactNode;
  geometry?: LayerManagerGeometry;
}

export const AppLayerManager = ({
  disableInfrastructureLayer = false,
  mainLayerLabel,
  mainLayerOpacityAdjustable,
  children,
  geometry,
}: LayerManagerCoreProps) => {
  const areaName = useGetAoiName();

  if (!areaName) {
    return null;
  }

  return (
    <LayerManager
      {...{
        areaName,
        geometry,
      }}
    >
      <>
        <LayerManagerCommonContent
          {...{
            areaName,
            disableInfrastructureLayer,
            mainLayerOpacityAdjustable,
            mainLayerLabel,
          }}
        />
        {children}
      </>
    </LayerManager>
  );
};
