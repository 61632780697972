import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { type LayerObject } from 'common/types/mapData';
import { titilerInfoService } from 'services/titiler/titilerInfoService';
import { type TitilerStats } from 'services/titiler/types';

export const useAvailableLayers = (cogUrl: string, layerObjects: LayerObject[]) => {
  const { pathname } = useLocation();

  const [availableLayers, setAvailableLayers] = useState<string[] | undefined>(undefined);

  const { stats } = titilerInfoService.useGetStats(cogUrl);

  const getFilteredObjectsFromStats = (stats: TitilerStats, objects: LayerObject[]) => {
    if (!stats) {
      return;
    }

    const getActiveClassesNumbersFromCog = (stats: TitilerStats) => {
      return Object.entries(stats)
        .filter((item) => item[1].mean > 0)
        .map((item) => item[0].slice(1));
    };

    // TODO: restore when 'active' property objects will be implemented on backend
    const activeObjects = objects;

    const filterActiveObjects = (objects: LayerObject[], activeClassesNumbers: string[]) => {
      return objects.filter(({ id }) => activeClassesNumbers.includes(id.toString()));
    };

    const filteredObjects = filterActiveObjects(activeObjects, getActiveClassesNumbersFromCog(stats));

    return filteredObjects;
  };

  const layerStats = stats ? Object.keys(stats) : undefined;

  useEffect(() => {
    const filteredObjects = getFilteredObjectsFromStats(stats, layerObjects);
    const filteredObjectNames = filteredObjects?.map(({ name }) => name);

    filteredObjectNames && setAvailableLayers(filteredObjectNames);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cogUrl, layerStats?.length, layerObjects.length, pathname]);

  return { availableLayers };
};
