import { Fragment, useRef } from 'react';
import styled, { css } from 'styled-components';

import { type StateValueAndLabel, type ValueAndLabel } from 'common/types';
import { selectComponentService } from './selectComponentService';
import { SearchElement } from './SearchElement';
import { REGULAR_SELECT_ROWS, SELECT_DROPDOWN_ROW_HEIGHT, SPECIAL_SELECT_ROWS } from './constants';
import { Chevron } from '../chevron';
import { LoadingDots } from '../loadingDots';
import { ScrollbarWrapper } from '../scrollbarWrapper';
import { Backdrop } from '../backdrop';
import { Tooltip } from '../tooltip';

interface SelectProps {
  options: StateValueAndLabel[] | undefined;
  selectedOption: ValueAndLabel | undefined;
  name: string;
  isFetching?: boolean;
  initialStateLabel?: string;
  noOptionsLabel?: string;
  title?: string;
  onChange?: (option: ValueAndLabel) => void;
  reset?: () => void;
  minWidth?: number;
  maxHeight?: number;
  special?: boolean;
  searchable?: boolean;
  disabled?: boolean;
}

export const Select = ({
  options = [],
  selectedOption,
  isFetching = false,
  initialStateLabel = 'Initial state',
  noOptionsLabel = 'No Options',
  title,
  onChange,
  reset,
  name = 'generic dropdown',
  minWidth,
  maxHeight,
  special = false,
  searchable = false,
  disabled = false,
}: SelectProps) => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);

  const availableOptionsNumber = options.length;
  const haveOptions = !!availableOptionsNumber;
  const label = !haveOptions ? noOptionsLabel : initialStateLabel;

  const { isOpen, onSelectClicked, onOptionClicked, onResetOptionClicked } =
    selectComponentService.useHandleUserActions(wrapperRef, options, onChange, reset);

  const { resultOptions, isSearchNeeded, query, onQueryChange } = selectComponentService.useFilterResults(
    options,
    selectedOption,
    noOptionsLabel,
    special,
    isOpen,
  );

  return (
    <Fragment key={name}>
      <SelectWrapper ref={wrapperRef} special={special} $disabled={disabled}>
        {title && <Title>{title}</Title>}
        <SelectInnerWrapper special={special} minWidth={minWidth}>
          <SelectButton
            onClick={() => {
              !isFetching && !disabled && onSelectClicked();
            }}
            special={special}
            open={haveOptions && isOpen}
            selected={false}
            role="combobox"
            aria-label={name}
          >
            {isFetching ? (
              <LoadingDots />
            ) : (
              <>
                <SelectLabelWrapper>{selectedOption?.label || label}</SelectLabelWrapper>
                <ChevronWrapper>
                  <Chevron isFlipped={haveOptions && isOpen} width={14} marginLeft={10} />
                </ChevronWrapper>
              </>
            )}
          </SelectButton>
          {isOpen && (
            <DropdownWrapper>
              <Dropdown special={special} maxHeight={maxHeight} data-testid="dropdown">
                {searchable && (
                  <SearchElement
                    isSearchNeeded={isSearchNeeded}
                    rowHeight={SELECT_DROPDOWN_ROW_HEIGHT}
                    query={query}
                    onQueryChange={onQueryChange}
                  />
                )}
                {reset && selectedOption && (
                  <DropdownItem
                    role="option"
                    aria-label={`${initialStateLabel} option`}
                    special={false}
                    onClick={onResetOptionClicked}
                    selected={false}
                  >
                    {initialStateLabel}
                  </DropdownItem>
                )}
                {haveOptions &&
                  resultOptions.map((option, index) => (
                    <Tooltip key={index} disabled={!option.disabled} content="No data available">
                      <DropdownItem
                        role="option"
                        aria-label={`${option.label} option`}
                        onClick={option.disabled ? undefined : () => onOptionClicked(option)}
                        $disabled={option.disabled}
                        special={special}
                        selected={selectedOption ? String(option.value) === String(selectedOption.value) : false}
                      >
                        {option.label}
                      </DropdownItem>
                    </Tooltip>
                  ))}
              </Dropdown>
            </DropdownWrapper>
          )}
        </SelectInnerWrapper>
      </SelectWrapper>
      {isOpen && special && <Backdrop zIndex={1010} />}
    </Fragment>
  );
};

interface SpecialSelectProps {
  special: boolean;
  $disabled?: boolean;
}

const SelectWrapper = styled.div<SpecialSelectProps>`
  color: ${({ theme }) => theme.color.white};
  width: 100%;
  z-index: 1020;
  user-select: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  ${({ $disabled }) =>
    $disabled &&
    css`
      pointer-events: none;
      color: ${({ theme }) => theme.color.midGray};
    `};
`;

const Title = styled.div`
  font-weight: 600;
  letter-spacing: 0.15em;
  text-transform: uppercase;
  margin: 0 0.25rem 0 1rem;
`;

interface SelectInnerWrapperProps extends SpecialSelectProps {
  minWidth?: number;
}

const SelectInnerWrapper = styled.div<SelectInnerWrapperProps>`
  position: relative;
  min-width: ${({ minWidth }) => minWidth}px;
  width: 100%;
  margin: 0;
  height: ${SELECT_DROPDOWN_ROW_HEIGHT}px;

  ${({ special }) =>
    special &&
    css`
      z-index: 1040;
      margin: 0;
    `};
`;

interface DropdownItemProps extends SpecialSelectProps {
  selected: boolean;
}

const DropdownItem = styled.div<DropdownItemProps>`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  height: ${SELECT_DROPDOWN_ROW_HEIGHT}px;
  font-size: 14px;
  cursor: pointer;
  white-space: nowrap;
  transition: background-color ${({ theme }) => theme.transitionFunction.main};
  padding: 0 1rem;
  font-weight: 500;
  text-transform: capitalize;
  z-index: 1040;

  &:first-child {
    border-top: 2px solid ${({ theme }) => theme.color.mineShaftABitLighter};
  }

  &:hover {
    background-color: ${({ theme }) => theme.color.selectGray};
  }

  ${({ selected }) =>
    selected &&
    css`
      background-color: ${({ theme }) => theme.color.selectGray};
      pointer-events: none;
    `};

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.6;
      cursor: default;

      &:hover {
        background-color: inherit;
      }
    `};
`;

interface SelectButtonProps extends SpecialSelectProps {
  open: boolean;
}

const SelectButton = styled(DropdownItem)<SelectButtonProps>`
  background-color: ${({ theme }) => theme.color.mineShaftABitLighter};
  border-radius: ${SELECT_DROPDOWN_ROW_HEIGHT / 2}px;

  &:first-child {
    border-top: none;
  }

  ${({ special }) =>
    special &&
    css`
      filter: opacity(0.8);
    `};

  ${({ open }) =>
    open &&
    css`
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      background-color: ${({ theme }) => theme.color.selectGray};
      filter: opacity(1);
    `};
`;

const DropdownWrapper = styled.div<{ maxHeight?: number }>`
  overflow-y: hidden;
  max-height: ${({ maxHeight }) => maxHeight}px;
  border-bottom-left-radius: ${SELECT_DROPDOWN_ROW_HEIGHT / 2}px;
  border-bottom-right-radius: ${SELECT_DROPDOWN_ROW_HEIGHT / 2}px;
`;

const Dropdown = styled(ScrollbarWrapper)<SpecialSelectProps & { maxHeight?: number }>`
  max-height: ${({ special, maxHeight }) =>
    maxHeight
      ? maxHeight
      : special
      ? SPECIAL_SELECT_ROWS * SELECT_DROPDOWN_ROW_HEIGHT
      : REGULAR_SELECT_ROWS * SELECT_DROPDOWN_ROW_HEIGHT}px;
  background-color: ${({ theme }) => theme.color.mineShaftABitLighter};
  border-bottom-right-radius: ${SELECT_DROPDOWN_ROW_HEIGHT / 2}px;
  border-bottom-left-radius: ${SELECT_DROPDOWN_ROW_HEIGHT / 2}px;
  -webkit-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.5);
  overflow-x: hidden;

  ::-webkit-scrollbar-thumb {
    border-radius: 0;
  }
`;

const ChevronWrapper = styled.div<{ dropdown?: boolean }>`
  color: ${({ theme }) => theme.color.scropion};

  ${({ dropdown }) =>
    dropdown &&
    css`
      color: ${({ theme }) => theme.color.silver};
    `};
`;

const SelectLabelWrapper = styled.div`
  overflow: hidden;
`;
