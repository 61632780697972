import { useSnowCoverIndicators } from './useSnowCoverIndicators';
import { SnowCoverMapTimeSeriesLayer } from './SnowCoverMapTimeSeriesLayer';
import { SnowCoverMapLayer } from './SnowCoverMapLayer';

export const SnowCoverMapContent = () => {
  const { selectedIndicator } = useSnowCoverIndicators();

  if (!selectedIndicator) {
    return <SnowCoverMapTimeSeriesLayer />;
  }

  const { source_url, min_value, max_value, unit, precision } = selectedIndicator;

  return <SnowCoverMapLayer sourceUrl={source_url} min={min_value} max={max_value} unit={unit} precision={precision} />;
};
