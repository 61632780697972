import { type LayerObject } from 'common/types/mapData';
import { useAoiNumber } from 'common/navigation/hooks';
import { useGreeneryObjectsUpdateUseCase } from 'views/greenery/useCases/greeneryObjectsUpdateUseCase';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { LayerManagerLegendItem } from 'components/layerManager/legacy/LegendItem';

interface VegetationSpecialLayerManagerProps {
  layerObjects: LayerObject[];
}

export const VegetationSpecialLayerManager = ({ layerObjects }: VegetationSpecialLayerManagerProps) => {
  const areaId = useAoiNumber();

  useGreeneryObjectsUpdateUseCase(layerObjects, areaId);

  const layersVisibility = useLayerManagerStore.use.layersVisibility();
  const toggleLayerVisibility = useLayerManagerStore.use.toggleLayerVisibility();

  const onObjectsToggle = (name: string) => {
    toggleLayerVisibility(name);

    if (name === 'pleiades' && layersVisibility['planet_scope']) {
      toggleLayerVisibility('planet_scope');
    }

    if (name === 'planet_scope' && layersVisibility['pleiades']) {
      toggleLayerVisibility('pleiades');
    }
  };

  return (
    <>
      <LayerManagerLegendItem
        option={{
          id: 100,
          name: 'pleiades',
          label_name: 'Pleiades',
          color: '',
          status: 'active',
        }}
        handleObjectsVisibility={onObjectsToggle}
        objectVisibility={layersVisibility['pleiades']}
      />
      <LayerManagerLegendItem
        option={{
          id: 101,
          name: 'planet_scope',
          label_name: 'PlanetScope',
          color: '',
          status: 'active',
        }}
        handleObjectsVisibility={onObjectsToggle}
        objectVisibility={layersVisibility['planet_scope']}
      />
    </>
  );
};
