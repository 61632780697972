import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';
import { length } from '@turf/turf';

import { type LeafletLayer, type GeoJSONFeature, type MultilineGeoJson } from 'common/types/mapData';
import { useExplorationLayerManagerStore } from '../explorationLayerManagerStore';

interface GeoJSONLayerProps {
  pane: string;
  geoJson: Partial<MultilineGeoJson>;
  opacity?: number;
  withAuxiliaryFilling?: boolean;
}

export const GeoJSONLineamentLayer = ({ pane, geoJson, opacity, withAuxiliaryFilling }: GeoJSONLayerProps) => {
  const toggleSelectedLineament = useExplorationLayerManagerStore.use.toggleSelectedLineament();
  const selectedLineamentsInProgress = useExplorationLayerManagerStore.use.selectedLineamentsInProgress();
  const selectedLineamentsSaved = useExplorationLayerManagerStore.use.selectedLineamentsSaved();
  const isLineamentsSelectionMode = useExplorationLayerManagerStore.use.isLineamentsSelectionMode();

  const selected = (id: number) => selectedLineamentsInProgress.includes(id);
  const saved = (id: number) => selectedLineamentsSaved.includes(id);
  const choiceMade = !isLineamentsSelectionMode && selectedLineamentsSaved.length > 0;

  const map = useMap();
  const geoJSON = L.geoJSON(geoJson, {
    style: (feature: GeoJSONFeature) => {
      const isSelected = selected(length(feature));
      const isSaved = saved(length(feature));

      return {
        stroke: true,
        pane,
        color: isSelected ? 'crimson' : 'white',
        weight: isSelected ? 4 : 2,
        opacity: choiceMade ? (isSaved ? opacity : 0) : opacity,
        fill: withAuxiliaryFilling,
        fillColor: '#333399',
        fillOpacity: opacity !== undefined ? opacity * 0.2 : 0.2,
        smoothFactor: 0.5,
        interactive: isLineamentsSelectionMode,
      };
    },
    onEachFeature: function (feature: GeoJSONFeature, layer: LeafletLayer) {
      layer.on('click', () => {
        length(feature) && toggleSelectedLineament(length(feature));
      });
    },
  });

  useEffect(() => {
    geoJSON.addTo(map);

    return () => {
      geoJSON.remove();
    };
  }, [geoJSON, map]);

  return null;
};
