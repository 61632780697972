import styled from 'styled-components';
import { DndContext, closestCenter } from '@dnd-kit/core';
import { restrictToFirstScrollableAncestor, restrictToVerticalAxis } from '@dnd-kit/modifiers';
import { SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable';

import { type LayerManagerSliderConfig, type LayersVisibilityDictionary } from 'common/types';
import { type LayerObjectWithUrl } from 'common/types/mapData';
import { getLayerNameWithAoiId } from 'common/utils';
import { WithCogBandRange } from 'services/titiler/WithCogBandRange';
import { LayerManagerLegendItem } from './LegendItem';
import { useLayerManagerLegendOptionsService } from './LegendOptionsService';
import { LayerManagerThresholdSlider } from './ThresholdSlider';

interface Props {
  areaId: number;
  layersOptions: LayerObjectWithUrl[];
  layersVisibility: LayersVisibilityDictionary;
  sliderConfig?: LayerManagerSliderConfig;
  isSingleChoice?: boolean;
  toggleLayerVisibility: (layerName: string) => void;
  layersOrderRecord: Record<string, number[]> | null;
  setLayersOrderRecord: (key: string, layersOrder: number[]) => void;
}

export const LayerManagerLegendDndList = ({
  areaId,
  layersOptions,
  layersVisibility,
  sliderConfig,
  isSingleChoice,
  toggleLayerVisibility,
  layersOrderRecord,
  setLayersOrderRecord,
}: Props) => {
  const { dndOptions, handleDragEnd } = useLayerManagerLegendOptionsService(
    layersOptions,
    layersOrderRecord,
    setLayersOrderRecord,
    true,
  );

  return (
    <DndListWrapper>
      <DndContext
        onDragEnd={handleDragEnd}
        collisionDetection={closestCenter}
        modifiers={[restrictToVerticalAxis, restrictToFirstScrollableAncestor]}
      >
        <SortableContext items={dndOptions} strategy={verticalListSortingStrategy}>
          <LegendList>
            {dndOptions.map((option) => (
              <li key={option.id}>
                <LayerManagerLegendItem
                  option={option}
                  handleObjectsVisibility={toggleLayerVisibility}
                  objectVisibility={!!layersVisibility[option.name]}
                  isDndEnabled={dndOptions.length > 1}
                  radio={isSingleChoice}
                  noBadges={isSingleChoice}
                >
                  {sliderConfig && (
                    <>
                      {sliderConfig.staticRange ? (
                        <LayerManagerThresholdSlider
                          layerRange={{
                            min: sliderConfig.staticRange.min,
                            max: sliderConfig.staticRange.max,
                            initialValue: sliderConfig.staticRange.initialValue,
                          }}
                          layerName={getLayerNameWithAoiId(option.name, areaId)}
                          config={sliderConfig}
                        />
                      ) : (
                        <WithCogBandRange
                          Component={LayerManagerThresholdSlider}
                          props={{ layerName: getLayerNameWithAoiId(option.name, areaId), config: sliderConfig }}
                          url={option.url}
                          precision={sliderConfig.precision}
                          isFetchingEnabled={!!layersVisibility[option.name]}
                          isLoadingEnabled
                        />
                      )}
                    </>
                  )}
                </LayerManagerLegendItem>
              </li>
            ))}
          </LegendList>
        </SortableContext>
      </DndContext>
    </DndListWrapper>
  );
};

const LegendList = styled.ul`
  list-style: none;
  padding: 0 1rem;
  margin: 0;
`;

const DndListWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.mineShaftLight};
  margin: 0 -1rem;
`;
