import { ROUTES } from 'common/navigation/routes';
import {
  COMMON_QUERY_PARAMETERS,
  SAM_CLASSES_QUERY_PARAMETER,
  MINERAL_QUERY_PARAMETER,
  SPECTRAL_CLASSIFIER_QUERY_PARAMETER,
  LINEAMENT_THRESHOLD_QUERY_PARAMETER,
  MTMF_CLASSES_QUERY_PARAMETER,
  INDICES_CLASSES_QUERY_PARAMETER,
  ML_CLASSES_QUERY_PARAMETER,
} from 'common/navigation/queryParams';
import { type LayerManagerSliderConfig } from 'common/types';
import { MINERALIZATION_PATH_NAME } from 'domain/exploration/constants';
import { MineralizationActiveTab } from 'domain/exploration/enums';

export const EXPLORATION_VIEW_CONFIG = {
  name: MINERALIZATION_PATH_NAME,
  path: ROUTES.mineralization,
  params: [...COMMON_QUERY_PARAMETERS],
  tabs: {
    [MineralizationActiveTab.SURFACE]: {
      path: ROUTES.mineralsSurface,
      params: [
        MINERAL_QUERY_PARAMETER,
        SPECTRAL_CLASSIFIER_QUERY_PARAMETER,
        SAM_CLASSES_QUERY_PARAMETER,
        MTMF_CLASSES_QUERY_PARAMETER,
        INDICES_CLASSES_QUERY_PARAMETER,
        ML_CLASSES_QUERY_PARAMETER,
        LINEAMENT_THRESHOLD_QUERY_PARAMETER,
      ],
    },
  },
};

export const PROSPECTING_TAB_SWITCHER_BUTTONS = [
  {
    id: 1,
    label: 'surface mineralization',
    value: MineralizationActiveTab.SURFACE,
  },
];

export const MINERALIZATION_INFO_AREA_DESCRIPTION =
  'This view presents results generated for your area of interest. Use the panel located on the left side to activate chosen data layers. You also have the ability to adjust the base map settings located at the lower section of the control panel. For each data layer use the slider to adjust the results to ease interpretations. You can find additional information on each method and how to adjust the sliders in the info bar at each section.';

export const INDICES_INFO_AREA_DESCRIPTION =
  'This functionality presents geological spectral indices. Indices focus on specific spectral features of each mineral and look for those features in the pixel spectra of the product. Click on the checkboxes on the right side of the control panel to activate layers of your choice. Use the sliders to adjust the displayed data. The higher the index value is, the higher the relative abundance of the specific mineral phase in each pixel. Hyperspectral data (EMIT, EnMAP) allows to observe more details in the spectral response with lower spatial resolution, while multispectral data provides less detail of the spectrum with higher spatial resolution (Sentinel-2). You can change the order of the layers using the drag-and-drop tool. Use other control panel sections to overlay mapping results with different tools available in the app.';

export const CLUSTERS_INFO_POINT_DESCRIPTION =
  'Ariticial Intelligence (AI) Clusters layer was created with use of unsupervised machine learning models. Use this insight to better understand your area of interest and discover new patterns. Color assignation for clusters is random. The same clusters suggest spectrally similar lithologies. Use the tool below to decide on number of clusters and select clusters of interest.';

export const COMPOSITES_INFO_POINT_DESCRIPTION =
  'Images produced by the False Color Composite (FCC) method use a mix of colors to draw attention to a particular mineralization-related phenomenon present on the surface. Different proportions of different spectral bands assigned to RGB channels highlight different surficial characteristics of your area of interest. FCC highlights different spectral patterns of individual lithologies and/or minerals, which allows to build a site-specific interpretation of the local mineralogy.';

export const LINEAMENTS_INFO_POINT_DESCRIPTION =
  'This function allows you to extract surficial linear structures present in your area of interest. The “length range” threshold enables you to customize the amount of lineaments to be detected based on their total length. The lower the minimal value is, the bigger the number of relatively short, minor lineament will be detected. With higher threshold values, you will be able to detect only the dominant linear structures.';

export const LINEAMENT_DENSITY_INFO_POINT_DESCRIPTION =
  'Density maps provide you with insights into the density and distribution of the linear structures over your area of interest.';

export const LINEAMENT_DENSITY_ERROR_INFO_POINT_DESCRIPTION = 'No density map available for selected length range';

export const PROSPECTING_OVERVIEW_LINES_OPACITY = 0.7;

export const INDICES_RESULTS_PRECISION = 2;

export const INDICES_SLIDER_CONFIG = {
  step: 0.01,
  precision: 2,
  isDoubleHandle: true,
  variant: 'RANGE',
  restricted: {
    min: false,
    max: false,
  },
  staticRange: null,
  withInput: true,
} satisfies LayerManagerSliderConfig;

export const MINERALIZATION_SLIDER_CONFIG = {
  sam: {
    step: 0.001,
    precision: 3,
    isDoubleHandle: false,
    variant: 'RANGE_INVERTED',
    restricted: {
      min: false,
      max: true,
    },
    staticRange: null,
    withInput: true,
  },
  mtmf: {
    step: 0.2,
    precision: 1,
    isDoubleHandle: false,
    variant: 'RANGE_INVERTED',
    restricted: {
      min: true,
      max: true,
    },
    staticRange: {
      min: 0,
      max: 1,
      initialValue: 0.2,
    },
    withInput: false,
  },
} satisfies Record<string, LayerManagerSliderConfig>;

export const LINEAMENT_DENSITY_LAYER_NAME = 'lineament_density';

export const LINEAMENT_DENSITY_SLIDER_CONFIG = {
  step: 0.01,
  precision: 2,
  isDoubleHandle: false,
  variant: 'RANGE_INVERTED',
  restricted: {
    min: false,
    max: true,
  },
  staticRange: null,
  withInput: false,
} satisfies LayerManagerSliderConfig;

export const HIDE_THRESHOLD_INPUT_TIMEOUT = 10_000;

// TODO: remove after backend implementation, issue: https://github.com/FourPointSPZOO/terraeye-backend/issues/431
export const DEPOSITS_PREFERRED_MINERALS_LAYERS: Record<string, string[]> = {
  1: ['jarosite', 'alunite', 'goethite', 'hematite', 'kaolinite', 'chlorite', 'magnetite'],
  2: ['serpentine', 'olivine', 'pyroxene', 'magnetite', 'hematite'],
  3: ['lepidolite', 'kaolinite', 'goethite', 'chlorite', 'epidote', 'muscovite'],
};
