export const WATER_PATH_NAME = 'water';

export const WATERBODIES_QUERY_KEY = 'waterbodies';
export const WATER_DATES_QUERY_KEY = 'water_dates';

export const WATER_QUALITY_INDICATORS_QUERY_KEY = 'water_quality_indicators';
export const WATER_QUALITY_RESULTS_QUERY_KEY = 'water_quality_results';
export const WATER_QUALITY_COG_QUERY_KEY = 'water_quality_cog';

export const WATER_AREA_RESULTS_QUERY_KEY = 'water_area_results';
export const WATER_AREA_COG_QUERY_KEY = 'water_area_cog';
export const WATER_AREA_COMPARATOR_COG_QUERY_KEY = 'water_area_comparator_cog';

export const MOISTURE_INDICATORS_QUERY_KEY = 'moisture_indicators';
export const MOISTURE_RESULTS_QUERY_KEY = 'moisture_results';

export const SNOW_COVER_INDICATORS_QUERY_KEY = 'snow_cover_indicators';
export const SNOW_COVER_RESULTS_QUERY_KEY = 'snow_cover_results';

export const WATER_INDICATORS_DATA_RESOLUTION = 2048;
