import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

import { createSelectors } from 'common/utils/createSelectors';
import { type BandCombosData } from 'domain/composites/types';

interface CompositesState {
  areCompositesVisible: boolean;
  toggleCompositesVisibility: () => void;
  dataOpacityDictionary: { [key: string]: number };
  setDataOpacity: (name: string, value: number) => void;
  selectedBandCombos: BandCombosData | undefined;
  setSelectedBandCombos: (bandCombos: BandCombosData) => void;
  clearSelectedBandCombos: () => void;
}

const initialState = {
  areCompositesVisible: true,
  dataOpacityDictionary: {},
  selectedBandCombos: undefined,
};

const useCompositesStoreBase = create<CompositesState>()(
  devtools(
    persist(
      (set) => ({
        ...initialState,
        toggleCompositesVisibility: () =>
          set(
            (state) => ({ areCompositesVisible: !state.areCompositesVisible }),
            false,
            'composites/toggleCompositesVisibility',
          ),
        setDataOpacity: (name, value) =>
          set(
            (state) => ({ dataOpacityDictionary: { ...state.dataOpacityDictionary, [name]: value } }),
            false,
            'composites/setDataOpacity',
          ),
        setSelectedBandCombos: (bandCombos) =>
          set(() => ({ selectedBandCombos: bandCombos }), false, 'composites/setSelectedBandCombos'),
        clearSelectedBandCombos: () =>
          set(() => ({ selectedBandCombos: undefined }), false, 'composites/clearSelectedBandCombos'),
      }),
      { name: 'RSOM_compositesStore', storage: createJSONStorage(() => localStorage) },
    ),
    { name: 'compositesStore' },
  ),
);

export const useCompositesStore = createSelectors(useCompositesStoreBase);
