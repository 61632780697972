import { ColorScale } from 'ui';
import { useGetCogRange } from 'views/water/moisture/useGetCogRange';
import { type MoistureCommonProps } from 'domain/water/types';

export const MoistureColorScale = ({ indicator, layerName, min, max }: MoistureCommonProps) => {
  const range = useGetCogRange(layerName, min, max);

  return (
    <ColorScale
      colormap={indicator.colormap}
      labels={range.map(String)}
      unit={indicator.unit}
      opacity={indicator.opacity}
    />
  );
};
