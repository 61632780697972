import L from 'leaflet';

import { calculateDistance } from 'common/utils/map';
import { type PointArray, type BoundsInterface, type MapPoint } from 'common/types/mapData';

export const MINERALS = ['chlorite', 'illite', 'smectite', 'kaolinite', 'magnetite', 'jarosite'] as const;
export const GRADE_MAPPING = {
  2: '<0.30',
  3: '0.30-0.44',
  4: '0.44-0.60',
  5: '0.60<',
} as const as { [key: number]: string };
export const TONNAGE_MAPPING = {
  2: 'low',
  3: 'medium',
  4: 'high',
} as const as { [key: number]: string };

export function determineWestAndEast(...args: number[]) {
  const [min, max] = [Math.min(...args), Math.max(...args)];

  if (min <= -180 || max >= 180) {
    throw new Error('Invalid coordinates. Longitude should be between -180 and 180.');
  }

  switch (true) {
    case min < 0 && max > 0 && Math.abs(min) + max < 180:
      return { west: min, east: max };
    case max > 0 && min < 0:
      return { west: max, east: min };
    case min < max:
      return { west: min, east: max };
    default:
      throw new Error('Both longitudes are equal');
  }
}

const getRectangleCorners = (coordinates: PointArray[]): BoundsInterface => {
  const lats = coordinates.reduce((acc, item) => {
    acc.push(item[0]);
    return acc;
  }, [] as number[]);

  const lngs = coordinates.reduce((acc, item) => {
    acc.push(item[1]);
    return acc;
  }, [] as number[]);

  const nord = Math.max(...lats);
  const south = Math.min(...lats);

  const maxLng = Math.max(...lngs);
  const minLng = Math.min(...lngs);
  const { west, east } = determineWestAndEast(maxLng, minLng);

  return {
    nordWest: [nord, west],
    nordEast: [nord, east],
    southWest: [south, west],
    southEast: [south, east],
  };
};

export const getCuttedBounds = (extentPolygon: PointArray[], innerBounds: PointArray[]): PointArray[] => {
  const { nordWest: outerTopLeft, southEast: outerBottomRight } = getRectangleCorners(extentPolygon);
  const { nordWest: innerTopLeft, southEast: innerBottomRight } = getRectangleCorners(innerBounds);

  const adjustedNordWest: PointArray = [
    Math.min(outerTopLeft[0], innerTopLeft[0]),
    determineWestAndEast(outerTopLeft[1], innerTopLeft[1]).east,
  ];
  const adjustedSouthEast: PointArray = [
    Math.max(outerBottomRight[0], innerBottomRight[0]),
    determineWestAndEast(outerBottomRight[1], innerBottomRight[1]).west,
  ];

  const adjustedSouthWest: PointArray = [adjustedSouthEast[0], adjustedNordWest[1]];
  const adjustedNordEast: PointArray = [adjustedNordWest[0], adjustedSouthEast[1]];

  return [adjustedSouthWest, adjustedNordWest, adjustedNordEast, adjustedSouthEast, adjustedSouthWest];
};

export const getGrids = (extentPolygon: PointArray[], stepKM: number, startFrom: number) => {
  const { southWest, nordWest, nordEast } = getRectangleCorners(extentPolygon);
  const distanceLatKM = calculateDistance(nordWest, southWest);
  const distanceLngKM = calculateDistance(nordWest, nordEast);

  const latDif = nordWest[0] - southWest[0];
  const lngDif = nordEast[1] - nordWest[1];

  const stepLat = distanceLatKM / stepKM;
  const stepLng = distanceLngKM / stepKM;

  const grids = [];

  for (let x = startFrom; x < stepLng; x++) {
    const lng = nordWest[1] + (x * lngDif) / stepLng;

    for (let y = startFrom; y < stepLat; y++) {
      const lat = nordWest[0] - (y * latDif) / stepLat;
      grids.push({ lat, lng, x, y });
    }
  }

  return grids;
};

export const getLines = (extentPolygon: PointArray[], stepKM: number, frequency = 1) => {
  const { southWest, nordEast } = getRectangleCorners(extentPolygon);
  const grides = getGrids(extentPolygon, stepKM, 0);

  return grides.reduce((acc, point) => {
    if (point.y === grides[0].y && point.x !== 0 && (frequency === 1 || point.x % frequency === 1)) {
      acc.push([
        [point.lat, point.lng],
        [southWest[0], point.lng],
      ]);
    }

    if (point.x === grides[0].x && point.y !== 0 && (frequency === 1 || point.y % frequency === 1)) {
      acc.push([
        [point.lat, point.lng],
        [point.lat, nordEast[1]],
      ]);
    }

    return acc;
  }, [] as PointArray[][]);
};

export const checkIfPointInPolygon = (point: PointArray | MapPoint, polygon: PointArray[]): boolean => {
  const latLngBounds = L.latLngBounds(polygon);
  return latLngBounds.contains(point);
};

export const getDepositClassificationSitePointColor = (grade: number) => {
  switch (true) {
    case grade === 1:
      return '#959595';
    case grade === 2:
      return '#A49357';
    case grade === 3:
      return '#E8B500';
    case grade === 4:
      return '#E76F18';
    case grade === 5:
      return '#EC3D17';
    default:
      return '#959595';
  }
};

export const getDepositClassificationSitePointRadius = (tonnage: number) => {
  const multiplier = tonnage < 1 ? 1 : tonnage > 4 ? 4 : tonnage;

  return multiplier * 50;
};
