import { useEffect } from 'react';

import { Button } from 'ui/atoms/button';
import { Creator } from 'ui/creator';
import { ButtonsWrapper, TrialUserInformation } from 'ui/creator/styled';
import { RequestSuccessModal } from 'ui/modal';
import { useGetActiveSubscription } from 'views/subscriptions/useGetActiveSubscription';
import { AreaRequestCreatorWrapper } from 'views/areas/areaRequestNew/AreaRequestCreatorWrapper';
import { useAreaRequestStore } from 'views/areas/areaRequestNew/AreaRequestStore';
import { ErrorColumn } from 'views/areas/areaRequestNew/ErrorColumn';
import { DepositsColumn } from 'views/areas/areaRequestNew/DepositsColumn';
import { SuppliersColumn } from 'views/areas/areaRequestNew/SuppliersColumn';
import { ExplanationColumn } from 'views/areas/areaRequestNew/ExplanationColumn';
import { PropertiesColumn } from 'views/areas/areaRequestNew/PropertiesColumn';
import { SubscriptionsColumn } from 'views/areas/areaRequestNew/SubscriptionsColumn';
import { useHandleRequestSubmit } from 'views/areas/areaRequestNew/useHandleRequestSubmit';
import { useSummaryPopupState } from 'views/areas/areaRequestNew/useSummaryPopupState';
import { useIsTrialUser } from 'views/user/useIsTrialUser';
import { subscriptionRepository } from 'infrastructure/subscription/subscriptionRepository';
import { useSubscriptionService } from 'views/subscriptions/useSubscriptionService';
import { type AoiData } from 'domain/areas/types';
import { getExplanationColumnStateHelper, getRequestButtonTextHelper } from 'domain/areas/helpers';
import { RequestCancelButton } from './RequestCancelButton';

interface AreaRequestCreatorProps {
  initialAoiData: AoiData;
}

export const AreaRequestCreator = ({ initialAoiData }: AreaRequestCreatorProps) => {
  const isTrialUser = useIsTrialUser();
  const selectionStatus = useAreaRequestStore.use.selectionStatus();
  const requestStatus = useAreaRequestStore.use.requestStatus();
  const clearCreator = useAreaRequestStore.use.clearCreatorData();
  const clearData = useAreaRequestStore.use.clearAreaRequestData();
  const isPropertiesColumnValid = useAreaRequestStore.use.isPropertiesColumnValid();
  const setPropertiesColumnValidity = useAreaRequestStore.use.setPropertiesColumnValidity();
  const isDepositsColumnValid = useAreaRequestStore.use.isDepositsColumnValid();
  const setDepositsColumnValidity = useAreaRequestStore.use.setDepositsColumnValidity();
  const isSuppliersColumnValid = useAreaRequestStore.use.isSuppliersColumnValid();
  const setSuppliersColumnValidity = useAreaRequestStore.use.setSuppliersColumnValidity();
  const features = useAreaRequestStore.use.features();
  const setRequestStatus = useAreaRequestStore.use.setRequestStatus();

  const { trialDuration } = subscriptionRepository.useGetTrialDuration();

  const { isSummaryVisible, summaryType, showSummary, hideSummary, navigateToList } = useSummaryPopupState();

  const handleRequestError = (error: Error) => {
    setRequestStatus({ warning: error.message, mode: 'ERROR' });
  };

  const { onSubscriptionRequest, isSubscriptionRequesting } = useSubscriptionService(handleRequestError, clearCreator);

  const { handleRequestSubmit } = useHandleRequestSubmit(initialAoiData, onSubscriptionRequest, showSummary, clearData);

  const { subscriptionStatusGetErrorCode } = useGetActiveSubscription();

  useEffect(() => {
    hideSummary();

    return () => {
      clearData();
    };
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const isLoading = isSubscriptionRequesting;
  const isSubscriptionsColumnValid = requestStatus.mode !== 'DISABLED' && !isLoading && !!features.length;

  const explanationColumnVariant = getExplanationColumnStateHelper(
    isPropertiesColumnValid,
    isDepositsColumnValid,
    isSubscriptionsColumnValid,
  );

  return (
    <>
      <AreaRequestCreatorWrapper
        status={isLoading ? { mode: 'IDLE', message: 'Request is processing...' } : selectionStatus}
      >
        <Creator>
          {subscriptionStatusGetErrorCode ? (
            <ErrorColumn errorCode={subscriptionStatusGetErrorCode} />
          ) : (
            <>
              <PropertiesColumn {...{ setPropertiesColumnValidity }} />
              {isPropertiesColumnValid && <DepositsColumn {...{ setDepositsColumnValidity }} />}
              {isPropertiesColumnValid && isDepositsColumnValid && <SubscriptionsColumn />}
              {isPropertiesColumnValid && isDepositsColumnValid && isSubscriptionsColumnValid && (
                <SuppliersColumn {...{ setSuppliersColumnValidity }} />
              )}
              <ExplanationColumn variant={explanationColumnVariant} />
            </>
          )}
        </Creator>
        <ButtonsWrapper>
          {isTrialUser && requestStatus.mode === 'TRIAL_ENABLED' && trialDuration && (
            <TrialUserInformation>
              With your free trial you get first {trialDuration.trial_in_days} days for free!
            </TrialUserInformation>
          )}
          <RequestCancelButton />
          <Button
            name="request-confirm"
            variant="PRIMARY"
            disabled={
              !isPropertiesColumnValid ||
              !isDepositsColumnValid ||
              !isSubscriptionsColumnValid ||
              !isSuppliersColumnValid
            }
            onClick={handleRequestSubmit}
            isLoading={isLoading}
            spinnerSize={24}
          >
            {getRequestButtonTextHelper(requestStatus)}
          </Button>
        </ButtonsWrapper>
      </AreaRequestCreatorWrapper>
      <RequestSuccessModal
        variant={summaryType === 'custom' ? 'CUSTOM_REQUEST' : 'REQUEST_SUCCESS'}
        isOpen={isSummaryVisible}
        closeModal={hideSummary}
        onConfirm={navigateToList}
      />
    </>
  );
};
