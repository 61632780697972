import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { useQuery, useQueryClient } from '@tanstack/react-query';

import { sanitizeDate } from 'common/utils/datetime';
import { ROUTES } from 'common/navigation/routes';
import { restAPI } from 'services/rest/restApiService';
import { useExecuteGraphqlMutation } from 'services/graphql/executeGraphqlMutation';
import { areasAPI } from 'infrastructure/areas/areasApiAdapter';
import {
  AREAS_LIST_QUERY_KEY,
  AREA_DETAILS_QUERY_KEY,
  INFRASTRUCTURE_SHAPES_QUERY_KEY,
  SATELLITE_IMAGE_QUERY_KEY,
  AREA_INSERT_NEW_MUTATION_KEY,
  AREA_INSERT_API_PREFIX,
  AREA_REQUEST_PAYLOAD_LOCAL_STORAGE_KEY,
  TOPOGRAPHY_LAYERS_QUERY_KEY,
  INFRASTRUCTURE_SHAPE_QUERY_KEY,
} from 'infrastructure/areas/constants';
import { AreasOfInterestCreateDocument } from 'infrastructure/areas/areaOfInterestCreateMutation';
import { useHandleWarningErrorUseCase } from 'views/@errors/handleTypicalWarningErrorUseCase';
import { alterFeatureCoordinates } from 'common/utils/map';
import { type Feature, type Polygon } from 'geojson';
import { useAreaRequestStore } from 'views/areas/areaRequestNew/AreaRequestStore';
import { localStorageService } from 'services/localStorageService';
import { DEFAULT_RESULTS_PER_PAGE } from 'common/constants/services';
import { isGeoJsonResultsArrayValid, isGeoJsonResultValid } from 'common/typeguards';
import { type AreaOfInterestTopographyLayer, type AoiData } from 'domain/areas/types';
import { sortAreasByName } from 'domain/areas/helpers';
import { WarningErrorType } from 'domain/@errors/enums';
import {
  isAreaOfInterestTopographyArrayValid,
  isAreaOfInterestTopographyValid,
  isInsertedAoiResponseValid,
} from 'domain/areas/typeguards';

export const areasRepository = {
  useFetchAreasOfInterestList: (limit: number = DEFAULT_RESULTS_PER_PAGE, offset?: number) => {
    const { data, isLoading, isFetching, error, isFetchedAfterMount, isSuccess } = useQuery(
      [
        AREAS_LIST_QUERY_KEY,
        {
          limit,
          offset,
        },
      ],
      () => areasAPI.getAreasOfInterest({ limit, offset }),
      {
        staleTime: Infinity,
        cacheTime: Infinity,
        refetchOnMount: 'always',
      },
    );

    useHandleWarningErrorUseCase(WarningErrorType.AOI_LIST, data, error, isSuccess);

    const results = data?.data.results || [];
    const areasList = results
      .filter((result) => result.status && (result.type === 'P' || result.type === 'M'))
      .sort(sortAreasByName);

    return {
      areasListLoading: isLoading || !isFetchedAfterMount,
      areasListFetching: isFetching,
      areasList,
    };
  },

  useFetchAreaOfInterestDetails: (areaId: number) => {
    const { data, isLoading, error } = useQuery(
      [AREA_DETAILS_QUERY_KEY, { areaId }],
      async () => areasAPI.getAreaOfInterestById(areaId),
      { retry: false },
    );
    const navigate = useNavigate();

    useEffect(() => {
      if (error) {
        console.log(`Unable to fetch areaOfInterestDetails for AOI no. ${areaId}`);
      }

      if (error instanceof AxiosError && error.response?.status === 403) {
        navigate(ROUTES.areasList);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error]);

    return {
      areaOfInterestDetailsLoading: isLoading,
      areaOfInterestDetailsError: error,
      areaOfInterestDetails: data?.data,
    };
  },

  useFetchCurrentSatelliteImageUrl: (areaId: number, timestamp?: string) => {
    const { isLoading, error, data, isSuccess } = useQuery(
      [
        SATELLITE_IMAGE_QUERY_KEY,
        {
          areaId,
          timestamp,
        },
      ],
      () =>
        timestamp
          ? areasAPI.getAreaOfInterestSatelliteByDate(areaId, { img_date: sanitizeDate(timestamp) })
          : areasAPI.getLatestAreaOfInterestSatellite(areaId),
    );

    useHandleWarningErrorUseCase(WarningErrorType.SATELLITE, data, error, isSuccess, 'url');

    return { aoiSatelliteLoading: isLoading, aoiSatelliteError: error, aoiSatelliteUrl: data?.data.url };
  },

  useFetchInfrastructureShapes: (areaName: string) => {
    const { data, isLoading, error } = useQuery(
      [
        INFRASTRUCTURE_SHAPES_QUERY_KEY,
        // {
        //   areaId,
        // },
      ],
      // () => areasAPI.getAreaOfInterestShapes(Number(areaId)), // TODO: restore when the API is ready
      () => areasAPI.getAreaOfInterestShapes({}),
    );

    const shapesData = data?.data?.results;

    if (!isLoading && !shapesData) {
      console.log(`Unable to fetch infrastructure shapes for AOI ${areaName}`);
    }

    if (Array.isArray(shapesData) && !isGeoJsonResultsArrayValid(shapesData)) {
      console.log(`Invalid infrastructure shapes for AOI ${areaName}`);
    }

    const shapes =
      shapesData?.filter((entry) => isGeoJsonResultValid(entry)).filter(({ aoi_name }) => aoi_name === areaName) || [];

    return { shapes, shapesLoading: isLoading, shapesError: error };
  },

  useFetchTopographyLayers: (areaName: string) => {
    const { data, isLoading, error } = useQuery(
      [
        TOPOGRAPHY_LAYERS_QUERY_KEY,
        // {
        //   areaId,
        // },
      ],
      // () => areasAPI.getTopographyLayers({ area_id: areaId }), // TODO: restore when the API is ready
      () => areasAPI.getTopographyLayers({}),
    );

    const layersData = data?.data?.results;

    if (!isLoading && !layersData) {
      console.log(`Unable to fetch topography layers for AOI: ${areaName}`);
    }

    if (Array.isArray(layersData) && !isAreaOfInterestTopographyArrayValid(layersData)) {
      console.log(`Invalid topography layers for AOI: ${areaName}`);
    }

    const layersList: AreaOfInterestTopographyLayer[] =
      layersData
        ?.filter((item) => isAreaOfInterestTopographyValid(item))
        .filter(({ name }) => name === areaName)
        .map((layer) => ({
          id: layer.id,
          aoiId: layer.aoi_id,
          name: layer.name,
          labelName: layer.label_name,
          hillshadeUrl: layer.hillshade_url,
          topographyUrl: layer.topography_url,
          topographyRange: [layer.topography_min, layer.topography_max],
        })) || [];

    return { layersList, layersLoading: isLoading, layersError: error };
  },

  // TODO: switch to private endpoint when the API is ready
  useFetchGeoJsonShape: (id: number, url: string) => {
    const { data, isLoading, error } = useQuery([INFRASTRUCTURE_SHAPE_QUERY_KEY, { id }], () => restAPI.external(url));

    return { geoJsonShape: data?.data, geoJsonShapeLoading: isLoading, geoJsonShapeError: error };
  },

  usePostNewAreaRequest: () => {
    const queryClient = useQueryClient();
    const [aoiData, setAoiData] = useState<AoiData | undefined>();

    const clearCreator = useAreaRequestStore.use.clearCreatorData();

    const { data, error, isLoading, isSuccess, mutate } = useExecuteGraphqlMutation(
      AREA_INSERT_API_PREFIX,
      AREA_INSERT_NEW_MUTATION_KEY,
      {},
      AreasOfInterestCreateDocument,
      3,
      {
        rawAoi: {
          source: aoiData?.source,
          name: aoiData?.name,
          display_name: aoiData?.display_name,
          user_id: aoiData?.user_id,
          aoi_type: aoiData?.aoi_type,
          payload: aoiData ? alterFeatureCoordinates(aoiData.payload as Feature<Polygon>) : undefined,
        },
      },
    );

    useEffect(() => {
      if (aoiData) {
        mutate();
      }
    }, [aoiData, mutate]);

    useEffect(() => {
      if (data) {
        setAoiData(undefined);
        queryClient.invalidateQueries({ queryKey: [AREAS_LIST_QUERY_KEY] });
        clearCreator();
      }
    }, [clearCreator, data, queryClient]);

    const postNewAreaRequest = (feature: AoiData) => {
      setAoiData(feature);
    };

    const insertedAoiId = isInsertedAoiResponseValid(data) ? data.data.insertAoi.id : undefined;

    return { postNewAreaRequest, data, insertedAoiId, error, isRequestLoading: isLoading, isSuccess };
  },

  useAreaRequestPayload: () => {
    const areaRequestPayload = localStorageService.getValue(AREA_REQUEST_PAYLOAD_LOCAL_STORAGE_KEY);

    const setAreaRequestPayload = (payload: AoiData) => {
      localStorageService.setValue(AREA_REQUEST_PAYLOAD_LOCAL_STORAGE_KEY, payload);
    };

    const clearAreaRequestPayload = () => {
      localStorageService.removeValue(AREA_REQUEST_PAYLOAD_LOCAL_STORAGE_KEY);
    };

    return { areaRequestPayload, setAreaRequestPayload, clearAreaRequestPayload };
  },
};
