import { InfoArea } from 'ui/infoArea';
import { MultipleWaterInfoAreaContent, SingleWaterInfoAreaContent } from 'views/water/components';
import { useWaterInfoAreaService } from 'views/water/services';

interface WaterQualityInfoAreaProps {
  waterbodyId: string | number | undefined;
}

export const WaterQualityInfoArea = ({ waterbodyId }: WaterQualityInfoAreaProps) => {
  const { singleWaterInfoArea, multipleWaterInfoArea, infoAreaProps } = useWaterInfoAreaService();

  return (
    <InfoArea {...infoAreaProps}>
      {waterbodyId ? (
        <SingleWaterInfoAreaContent singleWaterInfoArea={singleWaterInfoArea} />
      ) : (
        <MultipleWaterInfoAreaContent multipleWaterInfoArea={multipleWaterInfoArea} />
      )}
    </InfoArea>
  );
};
