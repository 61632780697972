import styled from 'styled-components';

import { EnvironmentsIconButtons } from 'ui/organisms/areaInfoPanel/EnvironmentsIconButtons';
import { type AreaOfInterest } from 'domain/areas/types';

interface AreaDataIndicatorsProps {
  area: AreaOfInterest;
}

export const AreaDataIndicators = ({ area }: AreaDataIndicatorsProps) => {
  const environments = area.environments || [];
  const deposit = area.deposits[0] || undefined;
  const areaType = area.type;

  return (
    <StyledDataIndicators>
      <EnvironmentsIconButtons environments={environments} deposit={deposit} areaType={areaType} />
    </StyledDataIndicators>
  );
};

const StyledDataIndicators = styled.div`
  display: flex;
  align-items: flex-end;
`;
