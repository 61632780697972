import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { type UrlWithProps, type UrlWithNameAndLabel } from 'services/titiler/types';

export const titilerApiService = {
  // TODO: Deprecated method, migrate gradually to getInfoGeneric
  getInfo: async (url: string) => {
    const geotiffInfoUrl = titilerApiAdapter.buildGeorasterInfoUrl(url);
    const response = await fetch(geotiffInfoUrl);
    const geotiffInfo = await response.json();

    return await geotiffInfo;
  },

  getInfoGeneric: async (url: string) => {
    const geotiffInfoUrl = titilerApiAdapter.buildGenericGeorasterInfoUrl(url);
    const response = await fetch(geotiffInfoUrl);
    const geotiffInfo = await response.json();

    return await geotiffInfo;
  },

  getRichInfo: async (url: string, id: number) => {
    const geotiffInfoUrl = titilerApiAdapter.buildGeorasterInfoUrl(url);
    const response = await fetch(geotiffInfoUrl);
    const geotiffInfo = await response.json();
    const info = await geotiffInfo;

    return { geotiffInfo: info, id: id };
  },

  getExternalInfo: async (url: string) => {
    const geotiffInfoUrl = titilerApiAdapter.buildExternalGeorasterInfoUrl(url);
    const response = await fetch(geotiffInfoUrl);
    const geotiffInfo = await response.json();

    return await geotiffInfo;
  },
  // TODO: Deprecated method, migrate gradually to getStatsGeneric
  getStats: async (url: string) => {
    const geotiffStatsUrl = titilerApiAdapter.buildGeorasterStatisticsUrl(url);
    const response = await fetch(geotiffStatsUrl);
    const geotiffStats = response.json();

    return await geotiffStats;
  },

  getStatsGeneric: async (url: string) => {
    const geotiffStatsUrl = titilerApiAdapter.buildGenericGeorasterStatisticsUrl(url);
    const response = await fetch(geotiffStatsUrl);
    const geotiffStats = response.json();

    return await geotiffStats;
  },

  getCogBandStats: async (url: string, band: number) => {
    const geotiffStatsUrl = titilerApiAdapter.buildGeorasterBandStatisticsUrl(url, band);
    const response = await fetch(geotiffStatsUrl);
    const geotiffStats = response.json();

    return await geotiffStats;
  },

  getStatsWithPropsGeneric: async <T>(url: string, props: T) => {
    const geotiffStatsUrl = titilerApiAdapter.buildGenericGeorasterStatisticsUrl(url);
    const response = await fetch(geotiffStatsUrl);
    const geotiffStats = response.json();
    const stats = await geotiffStats;

    return { stats: stats, props };
  },

  getRichStats: async (url: string, name: string) => {
    const geotiffStatsUrl = titilerApiAdapter.buildGeorasterStatisticsUrl(url);
    const response = await fetch(geotiffStatsUrl);
    const geotiffStats = response.json();
    const stats = await geotiffStats;

    return { stats: stats, name };
  },

  // TODO: Deprecated method, migrate gradually to getPointInfoGeneric
  getPointInfo: async (url: string, lat: number, lng: number, layer?: number) => {
    const pointInfoUrl = titilerApiAdapter.buildGeorasterPointInfoUrl(url, lat, lng, layer);
    const response = await fetch(pointInfoUrl);
    const pointInfo = response.json();

    return await pointInfo;
  },

  getPointInfoGeneric: async (url: string, lat: number, lng: number, layer?: number) => {
    const pointInfoUrl = titilerApiAdapter.buildGenericGeorasterPointInfoUrl(url, lat, lng, layer);
    const response = await fetch(pointInfoUrl);
    const pointInfo = response.json();

    return await pointInfo;
  },

  getPointsArrayInfo: async (urlsWithProps: UrlWithProps[], lat: number, lng: number, layer?: number) => {
    const requests = urlsWithProps.map(async ({ url, ...rest }) => ({
      response: await fetch(titilerApiAdapter.buildGeorasterPointInfoUrl(url, lat, lng, layer)),
      ...rest,
    }));
    const responses = await Promise.all(requests);
    const resolvedResponses = responses.filter(({ response }) => response.ok);
    const json = resolvedResponses.map(async ({ response, ...rest }) => ({ info: await response.json(), ...rest }));
    const data = Promise.all(json);

    return await data;
  },

  getPointsArrayInfoWithLabel: async (
    urlsWithNameAndLabel: UrlWithNameAndLabel[],
    lat: number,
    lng: number,
    layer?: number,
  ) => {
    const requests = urlsWithNameAndLabel.map(async ({ url, ...rest }) => ({
      response: await fetch(titilerApiAdapter.buildGeorasterPointInfoUrl(url, lat, lng, layer)),
      ...rest,
    }));
    const responses = await Promise.all(requests);
    const resolvedResponses = responses.filter(({ response }) => response.ok);
    const json = resolvedResponses.map(async ({ response, ...rest }) => ({ info: await response.json(), ...rest }));
    const data = Promise.all(json);

    return await data;
  },
};
