import { useQueryParameter } from 'common/navigation/hooks';
import { COMPARISON_OPTION_NAME } from 'common/navigation/constants';
import { GREENERY_OBJECT_QUERY_PARAMETER, VIEW_MODE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { useGreeneryTimestamps } from 'views/greenery/useGreeneryTimestamps';
import { SegmentationSingleDateMapContent } from 'views/greenery/segmentation/SegmentationSingleDateMapContent';
import { SegmentationComparatorMapContent } from 'views/greenery/segmentation/SegmentationComparatorMapContent';

export const SegmentationMapContent = () => {
  const viewQuery = useQueryParameter(VIEW_MODE_QUERY_PARAMETER);
  const isComparisonActive = viewQuery === COMPARISON_OPTION_NAME;

  const { validTimestamp, validSecondTimestamp } = useGreeneryTimestamps();

  const { greeneryObjects } = greeneryRepository.useFetchGreeneryObjects();

  const greeneryObjectQuery = useQueryParameter(GREENERY_OBJECT_QUERY_PARAMETER);
  const validCurrentObjectId = greeneryObjects.find(({ name }) => name === greeneryObjectQuery)?.id;

  if (!validTimestamp) {
    return null;
  }

  return (
    <>
      {isComparisonActive ? (
        <>
          {validSecondTimestamp && validCurrentObjectId && (
            <SegmentationComparatorMapContent
              timestamp={validTimestamp}
              secondTimestamp={validSecondTimestamp}
              currentObjectId={validCurrentObjectId}
            />
          )}
        </>
      ) : (
        <SegmentationSingleDateMapContent timestamp={validTimestamp} />
      )}
    </>
  );
};
