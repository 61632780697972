import { type ValueAndLabel } from 'common/types';
import { useReplaceQueryParameters } from 'common/navigation/hooks';
import { GREENERY_INDICATOR_QUERY_PARAMETER } from 'common/navigation/queryParams';

export const useChangeIndicatorUseCase = () => {
  const replaceQueryParameter = useReplaceQueryParameters();

  const handleIndicatorChange = (option: ValueAndLabel): void => {
    if (option) {
      replaceQueryParameter([
        {
          key: GREENERY_INDICATOR_QUERY_PARAMETER,
          value: String(option.value),
        },
      ]);
    }
  };

  return { handleIndicatorChange };
};
