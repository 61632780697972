import { ShareModalControlButton } from 'ui/organisms/shareModal/ShareModalControlButton';
import { ReturnButton } from 'ui/molecules/returnButton/ReturnButton';
import { ColorScale } from 'ui/colorScale';
import { LayerManagerControlButton } from 'components/layerManager/LayerManagerControlButton';
import { usePointHistory } from 'components/graph/usePointHistory';
import { useGraphStore } from 'components/graph/graphStore';
import { AreaDetailsLayout } from 'views/areas/areaDetails/AreaDetailsLayout';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { DISPLACEMENTS_COLORMAP, DISPLACEMENTS_COLOR_SCALE_RANGE_NAMES } from 'domain/displacements/constants';
import { DisplacementsTimeline } from './timeline/DisplacementsTimeline';
import { DisplacementsInfoArea } from './infoArea/DisplacementsInfoArea';
import { CoherenceSlider } from './coherenceSlider';
import { DisplacementsLayerManager } from './DisplacementsLayerManager';
import { DisplacementsShareModal } from './DisplacementsShareModal';

export const Subsidence = () => {
  const isGraphVisible = useGraphStore.use.graphVisible();
  const { clearPointHistoryUseCase } = usePointHistory();

  const areReportsActive = useGetAreReportsActiveUseCase();

  return (
    <AreaDetailsLayout
      returnButton={<ReturnButton label="Back" visible={isGraphVisible} onClick={clearPointHistoryUseCase} />}
      legend={<ColorScale colormap={DISPLACEMENTS_COLORMAP} labels={DISPLACEMENTS_COLOR_SCALE_RANGE_NAMES} />}
      toolbarContent={
        <>
          <DisplacementsInfoArea />
          {!areReportsActive && <CoherenceSlider />}
        </>
      }
      toolboxContent={
        <>
          {!areReportsActive && <LayerManagerControlButton />}
          {!areReportsActive && <ShareModalControlButton />}
        </>
      }
    >
      {!areReportsActive && (
        <>
          <DisplacementsLayerManager />
          <DisplacementsTimeline />
        </>
      )}
      <DisplacementsShareModal />
    </AreaDetailsLayout>
  );
};
