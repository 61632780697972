import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { planetscopeUrls, pleiadesUrls } from 'views/greenery/vegetation/puneData';

// TODO: remove when mercedes data will be handled
export const useGetPuneVegetationTimestamps = () => {
  const layersVisibility = useLayerManagerStore.use.layersVisibility();
  const pleiadesTimestamps = pleiadesUrls.map((p) => p.date).sort();
  const planetScopeTimestamps = planetscopeUrls.map((p) => p.date).sort();
  const puneTimestamps = layersVisibility['pleiades']
    ? pleiadesTimestamps
    : layersVisibility['planet_scope']
    ? planetScopeTimestamps
    : [];

  return { puneTimestamps };
};
