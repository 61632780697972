import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { COMPARISON_OPTION_NAME, TIMELINE_OPTION_NAME } from 'common/navigation/constants';
import { GREENERY_INDICATOR_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { useDeleteQueryParameters, useQueryParameter, useReplaceQueryParameters } from 'common/navigation/hooks';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { useGreeneryTimestamps } from 'views/greenery/useGreeneryTimestamps';
import { useVegetationIndicators } from 'views/greenery/vegetation/useVegetationIndicators';
import { GreeneryActiveTab } from 'domain/greenery/enums';

export const vegetationControlsService = {
  useIndicatorsOptions: () => {
    const { indicatorOptions, areIndicatorsLoading } = useVegetationIndicators();

    const greeneryIndicator = useQueryParameter(GREENERY_INDICATOR_QUERY_PARAMETER);

    const { pathname } = useLocation();
    const isVegetationTab = pathname.includes(GreeneryActiveTab.VEGETATION);

    const replaceQueryParameter = useReplaceQueryParameters();
    const deleteQueryParameters = useDeleteQueryParameters();

    useEffect(() => {
      if (greeneryIndicator === 'undefined') {
        deleteQueryParameters([GREENERY_INDICATOR_QUERY_PARAMETER]);
      }

      if (!greeneryIndicator && isVegetationTab && indicatorOptions[0]?.value) {
        setTimeout(() => {
          replaceQueryParameter([
            {
              key: GREENERY_INDICATOR_QUERY_PARAMETER,
              value: String(indicatorOptions[0].value),
            },
          ]);
        }, 500);
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [JSON.stringify(indicatorOptions.map(({ value }) => value))]);

    // TODO: refactor when mercedes data will be handled
    const layersVisibility = useLayerManagerStore.use.layersVisibility();
    const isSpecialModeActive = layersVisibility['pleiades'] || layersVisibility['planet_scope'];
    const filteredOptions = indicatorOptions.filter(({ value }) => value === 'ndvi');
    // TODO

    return {
      indicatorsSelectData: {
        options: isSpecialModeActive ? filteredOptions : indicatorOptions,
        selectedOption: indicatorOptions.find(({ value }) => String(value) === greeneryIndicator),
        initialStateLabel: 'Select Greenery Indicator',
        noOptionsLabel: 'No Greenery Indicators',
        isFetching: areIndicatorsLoading,
        minWidth: 130,
      },
    };
  },

  useGetVegetationViewConfig: () => {
    const { timestamps } = useGreeneryTimestamps();

    const vegetationViewOptions = [
      {
        value: COMPARISON_OPTION_NAME,
        label: 'comparison',
        disabled: timestamps.length <= 1,
      },
      {
        value: 'hotspots',
        label: 'hot & cold spots',
      },
    ];

    const vegetationDefaultView = TIMELINE_OPTION_NAME;

    return {
      options: vegetationViewOptions,
      defaultOption: vegetationDefaultView,
    };
  },
};
