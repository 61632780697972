import { useEffect } from 'react';

import { useGetAoiName } from 'common/hooks/getAoiName';
import { mapIndicatorsToOptions } from 'common/utils';
import { useHandleQueryParameter } from 'common/navigation/hooks';
import { MOISTURE_INDICATOR_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { waterRepository } from 'infrastructure/water/waterRepository';

export const useMoistureIndicators = () => {
  const areaName = useGetAoiName();
  const [indicatorQueryId, handleIndicatorChange] = useHandleQueryParameter(MOISTURE_INDICATOR_QUERY_PARAMETER);

  const { indicators, isLoading } = waterRepository.useFetchMoistureIndicators();
  const { results } = waterRepository.useFetchMoistureResults(areaName);

  const indicatorsWithResults = indicators.filter(({ id }) => results.some(({ indicator_id }) => indicator_id === id));

  const selectedIndicator = indicatorsWithResults.find(({ id }) => String(id) === indicatorQueryId);

  const options = mapIndicatorsToOptions(indicatorsWithResults);
  const selectedOption = options.find(({ value }) => String(value) === indicatorQueryId);

  useEffect(() => {
    if (!selectedOption && options.length) {
      handleIndicatorChange(options[0]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedOption, options]);

  return {
    indicators: indicatorsWithResults,
    selectedIndicator,
    indicatorsSelectData: {
      options,
      selectedOption,
      initialStateLabel: 'Select Indicator',
      noOptionsLabel: 'No Indicators',
      name: 'moisture indicator selection',
      title: '',
      isFetching: isLoading,
    },
    handleIndicatorChange,
  };
};
