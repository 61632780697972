import { useAoiNumber, useHandleQueryParameterByPrimitiveValue } from 'common/navigation/hooks';
import { getDisplayableMapPoint } from 'common/utils/map';
import { DEPOSIT_CLASSIFICATION_SITE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { DetailedInfoPanel } from 'ui/organisms/areaInfoPanel/DetailedInfoPanel';
import { GeneralInfoPanel } from 'ui/organisms/areaInfoPanel/GeneralInfoPanel';
import { TargetsSection } from 'ui/organisms/areaInfoPanel/TargetsSection';
import { PinsSection } from 'ui/organisms/areaInfoPanel/PinsSection';
import { AreaInfoDescription, AreaInfoSup } from 'ui/organisms/areaInfoPanel/components';
import { CapitalizeText } from 'ui/atoms/capitalizeText/CapitalizeText';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { explorationRepository } from 'infrastructure/exploration/explorationRepository';
import { useOverviewInfoStore } from 'views/prospecting/store';
import { GRADE_MAPPING, TONNAGE_MAPPING } from 'views/prospecting/utils';

export const ProspectingPanel = () => {
  const areaId = useAoiNumber();

  const setHoveredDepositSiteName = useOverviewInfoStore.use.setHoveredDepositSiteName();

  const [selectedDepositSite, setSelectedDepositSite] = useHandleQueryParameterByPrimitiveValue(
    DEPOSIT_CLASSIFICATION_SITE_QUERY_PARAMETER,
  );

  const { areaOfInterestDetails } = areasRepository.useFetchAreaOfInterestDetails(areaId);

  const { minerals } = explorationRepository.useFetchMineralsTypes();
  const { depositClassificationSites } = explorationRepository.useFetchDepositClassificationSites(areaId, 1);

  const selectedSite = depositClassificationSites.find((site) => site.properties.index === selectedDepositSite);

  const allMinerals = Array.from(
    new Set(
      depositClassificationSites
        .map((site) =>
          site.properties.minerals
            .map(({ mineral_id }) => minerals.find((mineral) => mineral.id === mineral_id)?.label_name)
            .filter((item): item is string => !!item),
        )
        .flat(),
    ),
  );

  const selectedSiteMinerals = selectedSite
    ? selectedSite.properties.minerals
        .map(({ mineral_id }) => minerals.find((mineral) => mineral.id === mineral_id)?.label_name)
        .filter((item): item is string => !!item)
    : [];

  const { prospectingTargets } = explorationRepository.useProspectingTargets();
  const targets = prospectingTargets[areaId] || [];

  const recommendedSites = useOverviewInfoStore.use.recommendedSites();
  const recommended = recommendedSites[areaId] || [];
  const filteredRecommended = recommended.filter((recommendedSite) =>
    depositClassificationSites.some((site) => site.properties.index === recommendedSite),
  );

  return selectedSite ? (
    <DetailedInfoPanel
      siteName={selectedSite.properties.index}
      location={getDisplayableMapPoint(selectedSite.geometry.coordinates)}
      areaId={areaId}
      extraContent={
        <DetailMineralsInfoSection
          minerals={selectedSiteMinerals}
          grade={GRADE_MAPPING[selectedSite.properties.grade_id]}
          tonnage={TONNAGE_MAPPING[selectedSite.properties.tonnage_id]}
        />
      }
    >
      <PinsSection />
    </DetailedInfoPanel>
  ) : areaOfInterestDetails ? (
    <GeneralInfoPanel
      areaDetails={areaOfInterestDetails}
      extraContent={<GeneralMineralsInfoSection minerals={allMinerals} />}
    >
      <TargetsSection
        siteTargets={targets}
        recommendedSites={filteredRecommended}
        setHoveredDepositSiteName={setHoveredDepositSiteName}
        setSelectedDepositSiteName={setSelectedDepositSite}
      />
    </GeneralInfoPanel>
  ) : null;
};

interface DetailMineralsInfoSectionProps {
  minerals: string[];
  grade: string | undefined;
  tonnage: string | undefined;
}

const DetailMineralsInfoSection = ({ minerals, grade, tonnage }: DetailMineralsInfoSectionProps) => (
  <>
    <AreaInfoDescription>
      Porphyry Copper Deposit <AreaInfoSup>BETA</AreaInfoSup>
    </AreaInfoDescription>
    {grade && <div>Estimated Cu grade Class: {grade}</div>}
    {tonnage && <div>Estimated Tonnage Class: {tonnage.toUpperCase()}</div>}
    <br></br>
    {!!minerals.length && (
      <>
        <AreaInfoDescription>Present minerals:</AreaInfoDescription>
        <CapitalizeText>{minerals.join(', ')}</CapitalizeText>
      </>
    )}
  </>
);

const GeneralMineralsInfoSection = ({ minerals }: { minerals: string[] }) => (
  <>
    {!!minerals.length && (
      <>
        <AreaInfoDescription>
          Porphyry Copper Deposit <AreaInfoSup>BETA</AreaInfoSup>
        </AreaInfoDescription>
        <br />
        <AreaInfoDescription>Present minerals:</AreaInfoDescription>
        <CapitalizeText>{minerals.join(', ')}</CapitalizeText>
      </>
    )}
  </>
);
