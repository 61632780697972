import styled, { type CSSProperties } from 'styled-components';

export const AreaInfoDescription = styled.p<{ marginTop?: number; paddingLeft?: number }>`
  font-weight: 600;
  margin: 0;
  margin-top: ${({ marginTop }) => marginTop || 0}px;
  padding-left: ${({ paddingLeft }) => paddingLeft || 0}px;
  letter-spacing: 1px;
  white-space: nowrap;
`;

export const AreaInfoSup = styled.span`
  vertical-align: super;
  font-size: 0.5rem;
`;

export const AreaInfoButtonsWrapper = styled.div<{ paddingLeft?: number; paddingRight?: number }>`
  margin-top: 10px;
  display: inline-flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  box-sizing: border-box;
  min-width: 100%;
`;

export const AreaInfoButtonInnerWrapper = styled.div<Pick<CSSProperties, 'gap'>>`
  display: flex;
  flex-direction: row;
  gap: ${({ gap }) => (gap ? gap + 'px' : '10px')};
  align-items: center;
`;

export const AreaInfoTitle = styled.h1`
  font-family: 'Barlow Condensed';
  font-size: 30px;
  font-weight: 600;
  line-height: 1;
  margin: 0;
`;

export const AreaInfoTitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 15px;
  align-items: center;
`;
