import { type ComponentType } from 'react';

import { useTimelineService } from 'components/Timeline/timelineService';
import { type MoistureIndicator, type MoistureResult } from 'domain/water/types';
import { useMoistureIndicators } from './useMoistureIndicators';
import { WithMoistureMultiResults } from './WithMoistureMultiResults';

interface Props<T> {
  Component: ComponentType<T & { results: MoistureResult[]; indicator: MoistureIndicator }>;
  props?: T;
  enableLoading?: boolean;
}

export const WithMoistureMultiData = <T,>({ Component, props, enableLoading }: Props<T>) => {
  const { timestamp } = useTimelineService();
  const { selectedIndicator: indicator } = useMoistureIndicators();

  if (!timestamp || !indicator) {
    return null;
  }

  return <WithMoistureMultiResults {...{ Component, props, timestamp, indicator, enableLoading }} />;
};
