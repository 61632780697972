import styled from 'styled-components';

import { Container } from 'ui';
import { LoadingDots } from 'ui/atoms/loadingDots';
import { BaseModal, ModalHeader } from 'ui/modal';
import { AddNewAreaModalContent } from 'components/AddNewAreaModalContent';
import { DataRequestForm } from 'views/dataRequest/DataRequestForm';
import { useGetActiveSubscription } from 'views/subscriptions/useGetActiveSubscription';
import { useDataRequestStore } from 'infrastructure/dataRequest/dataRequestStore';
import { AREA_REQUEST_NO_SUBSCRIPTION_INFORMATION } from 'domain/areas/configs';

export const DataRequestModal = () => {
  const { activeSubscriptionData, isActiveSubscriptionLoading } = useGetActiveSubscription();

  const closeModal = useDataRequestStore.use.closeDataRequestModal();

  return (
    <BaseModal isOpen={true} closeModal={closeModal} fullWidth maxWidth={800}>
      <ModalWrapper>
        <ModalHeader>Request More Data</ModalHeader>
        <Container mt={40}>
          {isActiveSubscriptionLoading ? (
            <Container gap={5}>
              Subscription checking <LoadingDots />
            </Container>
          ) : (
            <>
              {!activeSubscriptionData ? (
                <AddNewAreaModalContent header={AREA_REQUEST_NO_SUBSCRIPTION_INFORMATION} handleClose={closeModal} />
              ) : (
                <DataRequestForm />
              )}
            </>
          )}
        </Container>
      </ModalWrapper>
    </BaseModal>
  );
};

const ModalWrapper = styled.div`
  min-width: 240px;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
`;
