import { PUBLIC_CONTAINER_URL } from 'common/constants/services';

export const EXPLORATION_API_URLS = {
  getDeposits: '/exploration/deposits/',
  getMinerals: '/exploration/minerals/',
  getMineralsResults: '/exploration/results/',
  // getLineamentsShapes: '/areas/area-of-interest/lineaments/',
  getLineamentsShapes: `${PUBLIC_CONTAINER_URL}/lineaments/lineaments.json`, // TODO: restore line above this line when the API is ready
  getLineamentsRangeResults: `${PUBLIC_CONTAINER_URL}/lineaments/lineaments_ranges.json`,
  getLineamentDensityResults: `${PUBLIC_CONTAINER_URL}/lineaments/density_results.json`,
  getClassifiers: '/exploration/spectral-classifiers/',
  getIndices: '/exploration/spectral-indicators/',
  getIndicesResults: '/exploration/spectral-indicators-results/',
  getIndicatorResultById: '/exploration/spectral-indicators-results/:indicatorId/',
  getDepositClassificationSites: '/exploration/deposit-classification-results/',
  getSamplingPoints: '/exploration/sampling-points/',
  getClustersDataSuppliers: `${PUBLIC_CONTAINER_URL}/clusters/data_suppliers.json`,
  getClustersResults: `${PUBLIC_CONTAINER_URL}/clusters/results.json`,
};
