import styled from 'styled-components';

export const ModalHeader = styled.h3`
  font-family: 'Barlow Condensed', sans-serif;
  text-transform: capitalize;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  margin-bottom: 14px;
`;
