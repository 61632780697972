import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useShareModalStore } from 'ui/organisms/shareModal/shareModalStore';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { getDefaultRouteByAreaType, useAoiNumber } from 'common/navigation/hooks';
import { type NavigationItemConfig } from 'common/navigation/types';

export const useChangeViewUseCase = (areaNavigationConfig: NavigationItemConfig[]) => {
  const areaId = useAoiNumber();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const hideShareModal = useShareModalStore.use.closeShareModal();

  const { areaOfInterestDetails, areaOfInterestDetailsLoading } = areasRepository.useFetchAreaOfInterestDetails(
    Number(areaId),
  );

  useEffect(() => {
    hideShareModal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  useEffect(() => {
    if (areaOfInterestDetailsLoading || !areaOfInterestDetails) return;

    const permittedRoutes =
      Array.isArray(areaNavigationConfig) &&
      areaNavigationConfig
        .map((config) => config.permittedRoutes.map((route) => route.replace(':areaId', String(areaId))))
        .flat();

    if (
      permittedRoutes &&
      !permittedRoutes.some((route) => route.includes(pathname.replace(':areaId', String(areaId))))
    ) {
      const route = getDefaultRouteByAreaType(areaOfInterestDetails?.properties.type);
      navigate(route.replace(':areaId', String(areaId)));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areaNavigationConfig, areaId, areaOfInterestDetails, areaOfInterestDetailsLoading, pathname]);
};
