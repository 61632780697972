import styled, { css } from 'styled-components';

import { ReactComponent as MonitoringIcon } from 'assets/svg/monitoring-icon.svg';
import { ReactComponent as MineralIcon } from 'assets/svg/target.svg';
import type { AreaEnvironmentsTypes, AreaType } from 'common/navigation/types';
import { IconButton } from 'ui/atoms/iconButton';
import { Tooltip } from 'ui/atoms/tooltip';
import { TitleBodyTooltip } from 'ui/atoms/tooltip/TitleBodyTooltip';
import { AreaInfoEnvironmentsIcons, AreaInfoMineralsIcons } from 'domain/areas/AreaInfoEnvironmentsIcons';
import { AreaInfoButtonInnerWrapper } from './components';

interface EnvironmentsIconButtonsProps {
  environments: AreaEnvironmentsTypes[];
  deposit?: keyof typeof AreaInfoMineralsIcons;
  areaType: AreaType;
}

export const EnvironmentsIconButtons = ({ environments, deposit, areaType }: EnvironmentsIconButtonsProps) => {
  const validEnvironments = environments.filter((env) => env in AreaInfoEnvironmentsIcons);

  const environmentsText = validEnvironments.map((env) => AreaInfoEnvironmentsIcons[env].description);

  const environmentsIcons = validEnvironments.map((env) => (
    <IconButton
      key={env}
      icon={AreaInfoEnvironmentsIcons[env]?.icon}
      size={22}
      $iconSize={AreaInfoEnvironmentsIcons[env]?.$iconSize}
      variant="OVERVIEW_NO_HOVER"
    />
  ));

  const tooltipTitle = areaType === 'M' ? 'Environment Monitoring' : 'Mineral Prospecting';
  const mineralsBody = deposit && `${AreaInfoMineralsIcons[deposit].labelName} BETA`;

  const areAnyEnvironments = validEnvironments.length > 0;

  const tooltipBody = (
    <div>
      {environmentsText.length > 0 && <div>Available data: {environmentsText.join(', ')}</div>}
      {mineralsBody && <div>Direction: {mineralsBody}</div>}
    </div>
  );

  return (
    <Tooltip
      content={<TitleBodyTooltip title={tooltipTitle} body={areAnyEnvironments && tooltipBody} />}
      interactive={true}
    >
      <StyledEnvironmentsIconButtons>
        {areaType === 'M' ? (
          <AreaTypeIconWrapper type="M" isRounded={!areAnyEnvironments}>
            <MonitoringIcon />
          </AreaTypeIconWrapper>
        ) : (
          <AreaTypeIconWrapper type="P" isRounded={!areAnyEnvironments}>
            <MineralIcon />
            {deposit && <span>{AreaInfoMineralsIcons[deposit].symbol}</span>}
          </AreaTypeIconWrapper>
        )}

        <StyledDataIndicators gap={4}>{environmentsIcons}</StyledDataIndicators>
      </StyledEnvironmentsIconButtons>
    </Tooltip>
  );
};

const StyledEnvironmentsIconButtons = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDataIndicators = styled(AreaInfoButtonInnerWrapper)`
  background-color: ${({ theme }) => theme.color.selectGray};
  padding: 0 8px 0 4px;
  border-radius: 0 20px 20px 0;

  button {
    pointer-events: none;
    border-radius: 0;
    background-color: transparent;
  }
`;

interface AreaTypeIconWrapperProps {
  type: 'P' | 'M';
  isRounded: boolean;
}

const AreaTypeIconWrapper = styled.div<AreaTypeIconWrapperProps>`
  height: 22px;
  display: flex;
  align-items: center;
  gap: 5px;
  background-color: ${({ theme }) => theme.color.midGray};
  color: ${({ theme }) => theme.color.lightGray};
  padding: 5px;
  pointer-events: none;
  border-radius: 20px 0 0 20px;

  ${({ type }) =>
    type === 'M' &&
    css`
      padding-left: 8px;
    `}

  ${({ isRounded }) =>
    isRounded &&
    css`
      border-radius: 50%;
      padding: 5px;
    `}

  svg {
    width: 12px;
    height: 12px;
  }

  span {
    font-size: 10px;
    font-weight: 700;
    letter-spacing: 1px;
    text-transform: capitalize;
    margin-top: 2px;
  }
`;
