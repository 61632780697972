import { getColorScaleValuesHelper } from 'common/utils/color';

export const withIndicatorConfig = <I extends { indicator_name: string }, C>(
  indicators: I[],
  configuration: { [name: string]: C },
) =>
  indicators.map((indicator) => ({
    ...indicator,
    ...configuration[indicator.indicator_name],
  }));

export const withIndicatorLabels = <I extends { min_value: number; max_value: number }>(
  indicators: I[],
  areBetweenValues?: boolean,
) =>
  indicators.map((indicator) => {
    return {
      ...indicator,
      labels: getColorScaleValuesHelper(indicator.min_value, indicator.max_value, areBetweenValues).map(String),
    };
  });
