import chroma from 'chroma-js';

import { type LeafletBounds } from 'common/types/mapData';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { DataLayer } from 'components/map';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { titilerInfoService } from 'services/titiler/titilerInfoService';
import { getBoundsFromCogInfo } from 'services/titiler/utils';
import { SingleLayerClickToValueTitilerTooltip } from '../../../components/map/tooltip/SingleLayerClickToValueTitilerTooltip';

export interface SnowCoverMapLayerProps {
  sourceUrl: string;
  min: number;
  max: number;
  unit: string;
  precision: number;
}

export const SnowCoverMapLayer = ({ sourceUrl, min, max, unit, precision }: SnowCoverMapLayerProps) => {
  const isVisible = useLayerManagerStore.use.isDataLayerVisible();

  const colormapArray = [
    [
      [min, max],
      [...chroma('blue').rgb(), 255],
    ],
  ];

  const titilerUrl = titilerApiAdapter.buildGenericMonocolorCogUrl(`${sourceUrl}`, {
    expression: 'b1',
    colormap: JSON.stringify(colormapArray),
  });

  const { info } = titilerInfoService.useGetInfoGeneric(sourceUrl);
  const bounds = getBoundsFromCogInfo(info) as LeafletBounds | undefined;

  if (!titilerUrl || !bounds) {
    return null;
  }

  return (
    <>
      <DataLayer
        isVisible={isVisible}
        layerName={'snowCoverLayer'}
        zIndex={431}
        cogUrl={titilerUrl}
        bounds={bounds}
        opacity={1}
      />
      {isVisible && <SingleLayerClickToValueTitilerTooltip url={sourceUrl} unit={unit} precision={precision} />}
    </>
  );
};
