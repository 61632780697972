import { type ExtendedIndicator, type ListItemStatus } from 'common/types';
import { LayerManagerLegendItem } from 'components/layerManager/legacy/LegendItem';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';

interface Props {
  indicator: ExtendedIndicator;
}

export const SnowCoverLayerManagerLegendItem = ({ indicator }: Props) => {
  const isDataLayerVisible = useLayerManagerStore.use.isDataLayerVisible();
  const toggleDataLayerVisibility = useLayerManagerStore.use.toggleDataLayerVisibility();

  const layerManagerOption = {
    id: indicator.id,
    name: indicator.indicator_name,
    label_name: indicator.indicator_name,
    color: '',
    status: 'active' as ListItemStatus,
  };

  return (
    <LayerManagerLegendItem
      option={layerManagerOption}
      objectVisibility={isDataLayerVisible}
      handleObjectsVisibility={toggleDataLayerVisibility}
      iconColormap={indicator.colormap}
    />
  );
};
