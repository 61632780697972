import { useDeleteQueryParameters } from 'common/navigation/hooks';
import { SECOND_DATE_QUERY_PARAMETER, VIEW_MODE_QUERY_PARAMETER } from 'common/navigation/queryParams';

export const useHandleAreaViewOptionResetUseCase = () => {
  const deleteQueryParameters = useDeleteQueryParameters();

  const resetViewOption = (): void => {
    deleteQueryParameters([VIEW_MODE_QUERY_PARAMETER, SECOND_DATE_QUERY_PARAMETER]);
  };

  return { resetViewOption };
};
