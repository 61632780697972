import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { type DragEndEvent } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';

export const useLayerManagerLegendOptionsService = <T extends { id: number }>(
  options: T[],
  layersOrderRecord?: Record<string, number[]> | null,
  setLayersOrderRecord?: (key: string, layersOrder: number[]) => void,
  isDndView?: boolean,
) => {
  const [dndOptions, setDndOptions] = useState<T[]>([]);

  const { pathname } = useLocation();

  useEffect(() => {
    if (!isDndView) return;

    const optionsIds = layersOrderRecord ? (layersOrderRecord[pathname] ? layersOrderRecord[pathname] : []) : [];

    if (!optionsIds.length && !!options.length && setLayersOrderRecord) {
      setLayersOrderRecord(
        pathname,
        options.map((option) => option.id),
      );
    }

    let optionsArray: T[] | undefined;

    if (optionsIds.length && options.length) {
      optionsArray = optionsIds
        .map((id) => {
          return options.find((option) => option.id === id);
        })
        .filter((option) => option !== undefined) as T[];

      const newOptions = options.filter((option) => {
        return !optionsIds.includes(option.id);
      });

      if (newOptions.length > 0) {
        optionsArray = [...newOptions, ...optionsArray];
      }
    }

    setDndOptions(optionsArray || options);
  }, [options, pathname, layersOrderRecord, setLayersOrderRecord, isDndView]);

  const handleDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;

    if (over) {
      if (active.id !== over?.id && setLayersOrderRecord) {
        let newDndOptions = [...dndOptions];

        const activeIndex = newDndOptions.findIndex((item) => item.id === active.id);
        const overIndex = newDndOptions.findIndex((item) => item.id === over.id);
        newDndOptions = arrayMove(newDndOptions, activeIndex, overIndex);

        setLayersOrderRecord(
          pathname,
          newDndOptions.map((option) => option.id),
        );

        setDndOptions(newDndOptions);
      }
    }
  };

  return {
    dndOptions,
    setDndOptions,
    handleDragEnd,
  };
};
