import { useQueries } from '@tanstack/react-query';

import { greeneryApi } from 'infrastructure/greenery/greeneryApiAdapter';
import { titilerInfoService } from 'services/titiler/titilerInfoService';
import { getBoundsFromCogInfo } from 'services/titiler/utils';
import { type GreeneryResult } from 'domain/greenery/types';

export const useGetGreeneryCogUrlsWithIdsUseCase = (greeneryResultsData: GreeneryResult[] = []) => {
  const resultIds = greeneryResultsData?.map((object) => object.id);

  const urlsResult = useQueries({
    queries: resultIds.map((id) => ({
      queryKey: ['vegetation_cog', id],
      queryFn: () => greeneryApi.getVegetationCog(id),
    })),
  });

  const urls = urlsResult
    .map((r) => r.data)
    .filter((data) => !!data)
    .map((data) => {
      const id = greeneryResultsData.find(
        (result) => result.id === Number(data?.config.url?.replace(/[^0-9]/g, '')),
      )!.greenery_object_id;

      return {
        url: data?.data.url as string,
        id,
      };
    });

  const results = titilerInfoService.useGetArrayInfo(urls || []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cogBounds = results.map((result: any) => {
    const bounds = getBoundsFromCogInfo(result.data?.geotiffInfo);
    return { bounds, id: result.data?.id };
  });

  const urlsWithBounds = urls.map((url) => ({
    ...url,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    bounds: cogBounds.find((bounds: any) => bounds.id === url.id)?.bounds,
  }));

  return { urlsWithBounds };
};
