import { useWaterStore } from 'infrastructure/water/waterStore';
import { useDeleteQueryParameters } from 'common/navigation/hooks';
import { useMapAlign } from 'components/map/hooks';
import { WATERBODY_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { usePointHistory } from 'components/graph/usePointHistory';
import { useGraphStore } from 'components/graph/graphStore';

export const useHandleWaterbodyResetUseCase = () => {
  const deleteQueryParameters = useDeleteQueryParameters();
  const { clearPointHistoryUseCase } = usePointHistory();
  const clearDefaultView = useWaterStore.use.clearWaterBodyDefaultView();
  const resetGraphStore = useGraphStore.use.resetGraphStore();
  const alignMap = useMapAlign();

  const resetWaterbody = (): void => {
    clearPointHistoryUseCase();
    clearDefaultView();

    deleteQueryParameters([WATERBODY_QUERY_PARAMETER]);
    resetGraphStore();
    alignMap();
  };

  return { resetWaterbody };
};
