import { useQueryParameter } from 'common/navigation/hooks';
import { WATERBODY_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { useReportsStore } from 'infrastructure/reports/reportsStore';
import { waterRepository } from 'infrastructure/water/waterRepository';
import { SingleDateReportsCreatorContent } from 'views/reports/creator/content/SingleDateReportsCreatorContent';
import { useGetActiveOptionReportsCreatorUseCase } from 'views/reports/creator/useCases/getActiveOptionReportsCreatorUseCase';
import { useWaterUseCases } from 'views/water/useCases';
import { useWaterTimestamps } from 'views/water/useWaterTimestamps';
import { REPORTS_ALL_WATERBODIES_LABEL } from 'domain/reports/constants';

interface WaterAreaReportsCreatorContentProps {
  areaId: number;
  timestamp: string | null;
}

export const WaterAreaReportsCreatorContent = ({ areaId, timestamp }: WaterAreaReportsCreatorContentProps) => {
  const { timestamps } = useWaterTimestamps();

  const { waterBodiesList: waterbodies, waterBodiesLoading } = waterRepository.useFetchWaterbodies(areaId);
  const { changeWaterbody, resetWaterbody } = useWaterUseCases();

  const selectedWaterbodyId = useQueryParameter(WATERBODY_QUERY_PARAMETER);

  const isReportsSecondDateSelected = useReportsStore.use.isReportsSecondDateSelected();

  const { isCompareDatesType } = useGetActiveOptionReportsCreatorUseCase();

  const data = waterbodies.map(({ id, name }) => ({
    id,
    label: name,
    checked: selectedWaterbodyId === String(id),
    radio: true,
    onChange: () => changeWaterbody({ value: id, label: '' }),
  }));

  if (waterbodies.length > 1) {
    data.unshift({
      id: -1,
      label: REPORTS_ALL_WATERBODIES_LABEL,
      checked: selectedWaterbodyId === null,
      radio: true,
      onChange: () => resetWaterbody(),
    });
  }

  if (isCompareDatesType ? !isReportsSecondDateSelected : !(timestamp && timestamps.includes(timestamp))) {
    return null;
  }

  return <SingleDateReportsCreatorContent data={data} isLoading={waterBodiesLoading} />;
};
