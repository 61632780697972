import { useQueryParameter, useAoiNumber } from 'common/navigation/hooks';
import { DATE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { DEFAULT_DATE_FORMAT_DASH } from 'common/constants/datetime';
import { formatDate } from 'common/utils/datetime';
import { ShareModalBody } from 'ui/organisms/shareModal';
import { ShareModalContent } from 'ui/organisms/shareModal/ShareModalContent';
import { displacementsRepository } from 'infrastructure/displacements/displacementsRepository';
import { useDisplacementsStore } from 'infrastructure/displacements/displacementsStore';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { DisplacementsActiveTab } from 'domain/displacements/enums';

export const DisplacementsShareModal = () => {
  const areaId = useAoiNumber();

  const date = useQueryParameter(DATE_QUERY_PARAMETER);

  const kmzId = useDisplacementsStore.use.kmzId();
  const kmzUrl = useDisplacementsStore.use.kmzUrls()?.find((kmz) => kmz.id === kmzId)?.url;

  const { areaOfInterestDetails } = areasRepository.useFetchAreaOfInterestDetails(areaId);

  const fileName = `${areaOfInterestDetails?.id}_${DisplacementsActiveTab.SUBSIDENCE}${
    date ? `_${formatDate(date, DEFAULT_DATE_FORMAT_DASH)}` : ''
  }`;

  const { getSignedKmzUrl } = displacementsRepository.useGetSignedKmzUrl();

  const getReadyLinks = async () => {
    if (kmzId) {
      const signedUrl = await getSignedKmzUrl(kmzId);
      return [signedUrl!];
    } else {
      throw new Error('No kmz id');
    }
  };

  return (
    <ShareModalBody>
      <ShareModalContent fileLinks={[kmzUrl!]} fileType="kmz" fileName={fileName} getReadyLinks={getReadyLinks} />
    </ShareModalBody>
  );
};
