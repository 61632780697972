/* eslint-disable @typescript-eslint/no-explicit-any */
import { type UrlWithProps } from 'services/titiler/types';
import { getBoundsFromCogInfo } from './utils';
import { titilerInfoService } from './titilerInfoService';

export const useGetBandRange = (url: string, band: string) => {
  const { stats } = titilerInfoService.useGetStatsGeneric(url);

  const range =
    stats && typeof stats[band]?.min === 'number' && typeof stats[band]?.max === 'number'
      ? [stats[band].min, stats[band].max]
      : [];

  return { range };
};

// TODO: Deprecated method, migrate gradually to useGetCogWithBoundsGeneric
export const useGetCogWithBounds = <T>(
  builderFunction: (url: string, params: T) => string | undefined,
  url: string,
  params: T,
): {
  cogUrl: string | undefined;
  cogInfo: any;
  cogBounds: any;
  isInfoLoading: boolean;
} => {
  const cogUrl = url && builderFunction(url, params);

  const { info: cogInfo, isInfoLoading } = titilerInfoService.useGetInfo(url);

  const cogBounds = getBoundsFromCogInfo(cogInfo);

  return { cogUrl, cogInfo, cogBounds, isInfoLoading };
};

export const useGetCogWithBoundsGeneric = <T>(
  builderFunction: (url: string, params: T) => string | undefined,
  url: string,
  params: T,
): {
  cogUrl: string | undefined;
  cogInfo: any;
  cogBounds: any;
  isInfoLoading: boolean;
} => {
  const cogUrl = url && builderFunction(url, params);

  const { info: cogInfo, isInfoLoading } = titilerInfoService.useGetInfoGeneric(url);

  const cogBounds = getBoundsFromCogInfo(cogInfo);

  return { cogUrl, cogInfo, cogBounds, isInfoLoading };
};

export const useGetCogsArrayWithBounds = <T>(
  builderFunction: (url: string, params: T) => string,
  urlsWithParams: { id: number; label: string; url: string; params: T }[],
): {
  resultsArray: UrlWithProps[];
} => {
  const originUrls = urlsWithParams.map(({ id, url }) => ({ url, id }));
  const cogUrls = urlsWithParams.map(({ url, params, ...rest }) => ({
    url,
    cogUrl: builderFunction(url, params),
    ...rest,
  }));

  const results = titilerInfoService.useGetArrayInfo(originUrls);

  const cogBoundsArray = results.map((result) => ({
    id: result.data?.id,
    info: getBoundsFromCogInfo(result.data?.geotiffInfo),
  }));

  const resultsArray = cogUrls.map((cog) => ({
    id: cog.id,
    label: cog.label,
    url: cog.url,
    cogUrl: cog.cogUrl,
    bounds: cogBoundsArray.find((bounds) => bounds.id === cog.id)?.info,
  }));

  return { resultsArray };
};
