import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { formatDate } from 'common/utils/datetime';
import { getDefaultRouteByAreaType } from 'common/navigation/hooks';
import { DEFAULT_DATE_FORMAT_DASH } from 'common/constants/datetime';
import { useCardVisibleSpaceIntersectUseCase } from 'views/areas/areasList/cardVisibleSpaceIntersectUseCase';
import { AreaCardInfo } from 'views/areas/areasList/AreaCardInfo';
import { AreaCardAvatar } from 'views/areas/areasList/AreaCardAvatar';
import { AreaDataIndicators } from 'views/areas/areasList/AreaDataIndicators';
import { AreaCardTags } from 'views/areas/areasList/AreaCardTags';
import { AreaCardTargets } from 'views/areas/areasList/AreaCardTargets';
import { Button } from 'ui/atoms/button';
import { InfoPoint } from 'ui/molecules/infoPoint';
import { StatusLabel } from 'ui/atoms/statusLabel';
import placeholder from 'assets/img/area-placeholder.png';
import { type AreaOfInterest } from 'domain/areas/types';
import { isAreaCardStatusValid } from 'domain/areas/typeguards';
import { AREA_CARD_MIN_HEIGHT } from 'domain/areas/constants';
import { AREA_CARD_REJECTED_STATUS_INFORMATION } from 'domain/areas/configs';

interface AreaCardProps {
  area: AreaOfInterest;
}

export const AreaCard = ({ area }: AreaCardProps) => {
  const navigate = useNavigate();

  const observerElement = useRef<HTMLDivElement | null>(null);

  const status = area.status;
  const areaCardStatus = isAreaCardStatusValid(status) ? status : undefined;
  const isCardRejected = status === 'R';
  const isCardDisabled = status === 'P' || isCardRejected;

  const { areaId: areaIdForAvatar } = useCardVisibleSpaceIntersectUseCase(area, observerElement);

  return (
    <StyledAreaCard ref={observerElement} data-testid={`area-card-${area.name}`}>
      {areaCardStatus && (
        <StatusLabelWrapper>
          <StatusLabel status={areaCardStatus} />
        </StatusLabelWrapper>
      )}
      <AreaCardImage dark={isCardDisabled}>
        <ImagePlaceholder>{!!areaIdForAvatar && <AreaCardAvatar areaId={areaIdForAvatar} />}</ImagePlaceholder>
      </AreaCardImage>
      <AreaCardContent>
        <TopSection>
          <AreaDataIndicators area={area} />
          <InfoPoint>
            <AreaCardInfo areaId={area.id} />
          </InfoPoint>
        </TopSection>
        <AreaName dark={isCardDisabled}>{area.display_name}</AreaName>
        {isCardDisabled ? (
          <>
            {isCardRejected && (
              <RejectedStatusInformation>{AREA_CARD_REJECTED_STATUS_INFORMATION}</RejectedStatusInformation>
            )}
          </>
        ) : (
          <AreaCardTargets areaId={area.id} />
        )}
        <BottomSection>
          <TagsSection>
            {area.tags && <AreaCardTags tags={area.tags} />}
            <CreatedDate dark={isCardDisabled}>
              Created: {formatDate(area.created_date, DEFAULT_DATE_FORMAT_DASH)}
            </CreatedDate>
          </TagsSection>
          <ButtonWrapper>
            {isCardRejected ? (
              <Button variant="SECONDARY" onClick={() => (window.location.href = 'mailto:support@terraeye.co')}>
                contact us
              </Button>
            ) : (
              <Button
                name={`open ${area.name}`
                  .replaceAll(/[-,.;'`"_()]/g, ' ')
                  .replaceAll('  ', ' ')
                  .toLowerCase()}
                onClick={() => navigate(getDefaultRouteByAreaType(area.type).replace(':areaId', String(area.id)))}
                disabled={isCardDisabled}
              >
                open
              </Button>
            )}
          </ButtonWrapper>
        </BottomSection>
      </AreaCardContent>
    </StyledAreaCard>
  );
};

const StyledAreaCard = styled.div`
  position: relative;
  display: flex;
  min-height: ${AREA_CARD_MIN_HEIGHT}px;
  background-color: ${({ theme }) => theme.color.mineShaftDark};
  border: 1px solid ${({ theme }) => theme.color.white};
`;

const StatusLabelWrapper = styled.div`
  position: absolute;
  top: 12px;
  left: 12px;
  z-index: 1;
`;

const AreaCardImage = styled.div<{ dark: boolean }>`
  width: 200px;
  flex-shrink: 0;
  opacity: ${({ dark }) => (dark ? 0.5 : 1)};
`;

const ImagePlaceholder = styled.div`
  background-image: url(${placeholder});
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-shrink: 0;
  width: 200px;
  height: 100%;
`;

const AreaCardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  width: 100%;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
  height: 36px;
  width: 100%;
`;

const AreaName = styled.h2<{ dark: boolean }>`
  font-family: 'Barlow Condensed', sans-serif;
  font-size: 28px;
  font-weight: 600;
  line-height: 30px;
  color: ${({ theme, dark }) => (dark ? theme.color.lightGray : theme.color.white)};
  margin: 5px 50px 10px 0;
`;

const RejectedStatusInformation = styled.div`
  max-width: 225px;
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  color: ${({ theme }) => theme.color.notificationDarkPink};
  margin-bottom: 10px;
`;

const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: auto;
`;

const TagsSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  gap: 6px;
`;

const CreatedDate = styled.div<{ dark: boolean }>`
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: ${({ theme, dark }) => (dark ? theme.color.lightGray : theme.color.white)};
`;

const ButtonWrapper = styled.div`
  align-self: flex-end;
`;
