import styled from 'styled-components';

import { getFullFileName } from 'common/utils';
import { Button, Container } from 'ui';

interface DownloadModalProps {
  filesList: {
    id: number;
    title: string;
    list: {
      id: number;
      url: string;
      label: string;
      name: string;
    }[];
  }[];
  fileName: string;
  fileExtension: string;
  isDownloading: boolean;
  handleDownload: () => void;
}

export const DownloadModal = ({
  filesList,
  fileName,
  fileExtension,
  isDownloading,
  handleDownload,
}: DownloadModalProps) => (
  <>
    {filesList.map((list) => (
      <div key={list.id}>
        <h4>{list.title}:</h4>
        <FilesList>
          {list.list.map((listItem) => (
            <li key={listItem.id}>{listItem.label}</li>
          ))}
        </FilesList>
      </div>
    ))}
    <FilesDownloadBlock>
      <Container variant="VERTICAL_START">
        <FileNameWrapper>{getFullFileName(fileName, fileExtension)}</FileNameWrapper>
        <Container mt={25} mb={10}>
          <Button onClick={handleDownload} squared isLoading={isDownloading} minWidth={220}>
            Download {fileExtension}
          </Button>
        </Container>
        {isDownloading && (
          <>
            You can close this window.
            <br />
            The downloaded data will be available in a minute.
          </>
        )}
      </Container>
    </FilesDownloadBlock>
  </>
);

const FilesList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0;
  margin: 0.8em 0 0.4em;
  margin-left: 1.4em;
`;

const FilesDownloadBlock = styled.div`
  font-size: 14px;
`;

const FileNameWrapper = styled.div`
  word-break: break-word;
  line-height: 16px;
`;
