import { type FiltersAreaStatusSet, type FiltersAreaTypeSet, type FiltersAreaEnvironmentSet } from 'domain/areas/types';

export const FILTERS_AREA_STATUS_SET: FiltersAreaStatusSet = {
  N: {
    id: 1,
    variant: 'NEW',
    text: 'new',
  },
  X: {
    id: 2,
    variant: 'ARCHIVED',
    text: 'archived',
  },
  P: {
    id: 3,
    variant: 'BASIC',
    text: 'pending',
  },
  A: {
    id: 4,
    variant: 'BASIC',
    text: 'active',
  },
  R: {
    id: 5,
    variant: 'REJECTED',
    text: 'rejected',
  },
};

export const FILTERS_AREA_TYPE_SET: FiltersAreaTypeSet = {
  M: {
    id: 1,
    variant: 'BASIC',
    text: 'monitoring',
  },
  P: {
    id: 2,
    variant: 'BASIC',
    text: 'prospecting',
  },
};

export const FILTERS_AREA_ENVIRONMENT_SET: FiltersAreaEnvironmentSet = {
  displacement: {
    id: 1,
    variant: 'BASIC',
    text: 'ground deformation',
  },
  mine: {
    id: 2,
    variant: 'BASIC',
    text: 'mine',
  },
  land_cover: {
    id: 3,
    variant: 'BASIC',
    text: 'land cover',
  },
  water: {
    id: 4,
    variant: 'BASIC',
    text: 'water',
  },
  surface_mineralization: {
    id: 6,
    variant: 'BASIC',
    text: 'surface mineralization',
  },
  temperature: {
    id: 7,
    variant: 'BASIC',
    text: 'temperature',
  },
};

export const AREA_CARD_REJECTED_STATUS_INFORMATION = 'There are issues with your request. For more details contact us.';

export const AREA_REQUEST_STANDARD_SUBSCRIPTION_NAME = 'Standard Exploration';
export const AREA_REQUEST_EXTENDED_SUBSCRIPTION_NAME = 'Extended Exploration';

export const AREA_REQUEST_NO_SUBSCRIPTION_INFORMATION =
  'Add a new Exploration Area and start a subscription to unlock this function.';
export const AREA_CREATOR_LOWER_SUBSCRIPTION_INFORMATION = 'downgrade to';

export const AREA_REQUEST_DATA_SUPPLIERS = [
  {
    id: 1,
    name: 'sentinel-2',
    label: 'Sentinel-2',
    extended_feature: false,
    disabled_permanently: true,
  },
  {
    id: 2,
    name: 'emit',
    label: 'EMIT',
    extended_feature: false,
    disabled_permanently: false,
  },
];
