import { useState } from 'react';

import { Button } from 'ui';
import { type SubscriptionType } from 'domain/subscription/types';
import { useUpgradeSubscription } from './useUpgradeSubscription';
import { UpgradeModal } from './UpgradeModal';

interface SubscriptionUpgradeProps {
  subscriptionType: SubscriptionType;
  disabled: boolean;
  setErrorMessage: (message: string) => void;
  setLoading?: (state: boolean) => void;
  message?: string;
  large?: boolean;
}

export const SubscriptionUpgrade = ({
  subscriptionType,
  disabled,
  setErrorMessage,
  setLoading,
  message,
  large,
}: SubscriptionUpgradeProps) => {
  const [upgradeModalVisible, setUpgradeModalVisible] = useState(false);

  const openUpgradeModal = () => setUpgradeModalVisible(true);
  const closeUpgradeModal = () => setUpgradeModalVisible(false);

  const { getUpgradeData, handleUpgrade } = useUpgradeSubscription(setErrorMessage, setLoading);

  const handleConfirm = () => {
    handleUpgrade(subscriptionType);
    closeUpgradeModal();
  };

  const upgradeData = getUpgradeData(subscriptionType);

  return (
    <>
      <Button
        minWidth={100}
        variant={large ? 'PRIMARY' : 'PRIMARY_SMALL'}
        disabled={disabled || !getUpgradeData(subscriptionType)}
        onClick={openUpgradeModal}
      >
        {message || 'add more calculation points'}
      </Button>
      {upgradeData && (
        <UpgradeModal
          type={subscriptionType}
          data={upgradeData}
          isOpen={upgradeModalVisible}
          closeModal={closeUpgradeModal}
          onConfirm={handleConfirm}
        />
      )}
    </>
  );
};
