import { type ReactNode, useState } from 'react';
import styled from 'styled-components';

import { Chevron } from 'ui/atoms';
import { FoldableSectionHeader } from './FoldableSectionHeader';

interface FoldableSectionProps {
  headerContent: ReactNode;
  children: ReactNode;
  disabled?: boolean;
}

export const FoldableSection = ({ headerContent, children, disabled }: FoldableSectionProps) => {
  const [isFolded, setIsFolded] = useState(true);

  const onFoldedStateToggle = () => setIsFolded(!isFolded);

  return (
    <>
      <FoldableSectionHeader disabled={disabled}>
        <FoldingButton onClick={onFoldedStateToggle}>
          <Chevron width={12} isFlipped={!disabled && !isFolded} />
        </FoldingButton>
        {headerContent}
      </FoldableSectionHeader>
      <FoldableSectionContent isFolded={disabled || isFolded}>{children}</FoldableSectionContent>
    </>
  );
};

const FoldingButton = styled.button`
  height: 100%;
  background-color: transparent;
  color: ${({ theme }) => theme.color.white};
  border: none;
  cursor: pointer;
`;

export const FoldableSectionContent = styled.div<{ isFolded: boolean }>`
  display: ${({ isFolded }) => (isFolded ? 'none' : 'block')};
  width: 100%;
  transition: all ${({ theme }) => theme.transitionFunction.main};
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.mineShaftLight};
`;
