import { useAoiNumber, useHandleQueryParameter, useQueryParameter } from 'common/navigation/hooks';
import {
  GREENERY_INDICATOR_QUERY_PARAMETER,
  MINERAL_INDICATOR_QUERY_PARAMETER,
  MINERAL_QUERY_PARAMETER,
  SPECTRAL_CLASSIFIER_QUERY_PARAMETER,
  WATERBODY_QUERY_PARAMETER,
} from 'common/navigation/queryParams';
import { type ValueAndLabel } from 'common/types';
import { explorationRepository } from 'infrastructure/exploration/explorationRepository';
import { waterRepository } from 'infrastructure/water/waterRepository';
import { useGetCompleteIndicesResults } from 'views/exploration/indices/useGetCompleteIndices';
import { useVegetationIndicators } from 'views/greenery/vegetation/useVegetationIndicators';
import { useFilteredSpectralClassifiers } from 'views/exploration/useFilteredSpectralClassifiers';
import { mapWaterBodiesToOptionsHelper } from 'domain/water/helpers';
import {
  mapIndicesResultObjectsToOptionsHelper,
  mapSpectralClassifiersObjectsToOptionsHelper,
} from 'domain/exploration/helpers';

const useGetSelectedIndicator = (indicatorOptions: ValueAndLabel[], queryParameter: string) => {
  const query = useQueryParameter(queryParameter);

  const selectedIndicator: ValueAndLabel | undefined = indicatorOptions.find(({ value }) => String(value) === query);
  return selectedIndicator;
};

export const promptIndicatorService = {
  useWaterIndicator: () => {
    const areaId = useAoiNumber();

    const { waterBodiesList } = waterRepository.useFetchWaterbodies(areaId);

    const waterBodyOptions = mapWaterBodiesToOptionsHelper(waterBodiesList);

    const selectedWaterbodyIndicator = useGetSelectedIndicator(waterBodyOptions, WATERBODY_QUERY_PARAMETER);

    return { selectedWaterbodyIndicator };
  },
  useGreeneryIndicator: () => {
    const { indicatorOptions } = useVegetationIndicators();

    const selectedGreeneryIndicator = useGetSelectedIndicator(indicatorOptions, GREENERY_INDICATOR_QUERY_PARAMETER);

    return { selectedGreeneryIndicator };
  },
  useSpectralClassifierIndicator: () => {
    const { spectralClassifiers } = useFilteredSpectralClassifiers();

    const indicatorOptions = mapSpectralClassifiersObjectsToOptionsHelper(spectralClassifiers);

    const selectedSpectralClassifierIndicator = useGetSelectedIndicator(
      indicatorOptions,
      SPECTRAL_CLASSIFIER_QUERY_PARAMETER,
    );

    return { selectedSpectralClassifierIndicator };
  },
  useIndicesMineralIndicator: () => {
    const { indicesResults } = useGetCompleteIndicesResults();

    const indicatorOptions = mapIndicesResultObjectsToOptionsHelper(indicesResults);

    const selectedMineralIndicator = useGetSelectedIndicator(indicatorOptions, MINERAL_INDICATOR_QUERY_PARAMETER);

    return { selectedMineralIndicator };
  },
  useMineralIndicator: () => {
    const { minerals } = explorationRepository.useFetchMineralsTypes();

    const [mineralTypeQuery] = useHandleQueryParameter(MINERAL_QUERY_PARAMETER);

    const currentMineral = minerals.find(({ id }) => String(id) === mineralTypeQuery);

    return { currentMineral };
  },
};
