import { RESEARCH_ML_STORAGE_URL } from 'common/constants/services';
import { type LeafletBounds } from 'common/types/mapData';
import { buildGradientColormapArray } from 'common/utils/color';
import { Loading } from 'ui/molecules/loading';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { DataLayer } from 'components/map';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { titilerInfoService } from 'services/titiler/titilerInfoService';
import { getAzureBlobStorageUrl, getBoundsFromCogInfo } from 'services/titiler/utils';
import { useGetCogRange } from 'views/water/moisture/useGetCogRange';
import { SingleLayerClickToValueTitilerTooltip } from 'components/map/tooltip/SingleLayerClickToValueTitilerTooltip';
import { type MoistureCommonProps } from 'domain/water/types';

export const MoistureMapDataLayer = ({ indicator, layerName, min, max, sourceUrl }: MoistureCommonProps) => {
  const isVisible = useLayerManagerStore.use.isDataLayerVisible();

  const range = useGetCogRange(layerName, min, max);
  const expression = `((b1>=${range[0]})&(b1<${range[1]}))*b1`;
  const colormapArray = buildGradientColormapArray(indicator.colormap, range[0], range[1]);

  const titilerUrl = titilerApiAdapter.buildMonocolorCogUrl(`${RESEARCH_ML_STORAGE_URL}${sourceUrl}`, {
    expression,
    colormap: JSON.stringify(colormapArray),
  });

  const { info, isInfoLoading } = titilerInfoService.useGetInfo(sourceUrl);
  const bounds = getBoundsFromCogInfo(info) as LeafletBounds | undefined;

  if (isInfoLoading) {
    return <Loading />;
  }

  if (!titilerUrl || !bounds) {
    return null;
  }

  return (
    <>
      <DataLayer
        isVisible={isVisible}
        layerName={'moistureLayer'}
        zIndex={432}
        cogUrl={titilerUrl}
        bounds={bounds}
        opacity={indicator.opacity}
      />
      {isVisible && (
        <SingleLayerClickToValueTitilerTooltip
          url={getAzureBlobStorageUrl(sourceUrl)}
          unit={indicator.unit}
          precision={indicator.precision}
        />
      )}
    </>
  );
};
