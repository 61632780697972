import { type LeafletBounds } from 'common/types/mapData';
import { Loading } from 'ui/molecules/loading';
import { DataLayer } from 'components/map';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { titilerInfoService } from 'services/titiler/titilerInfoService';
import { getBoundsFromCogInfo } from 'services/titiler/utils';

interface Props {
  url: string;
  rescaleFactors: [number, number][];
  visible: boolean;
  opacity: number;
}

export const CompositesMapLayer = ({ url, rescaleFactors, visible, opacity }: Props) => {
  const titilerUrl = titilerApiAdapter.buildGenericRGBImageUrl(`${url}`, {
    rescaleFactors,
    fileType: 'png',
  });

  const { info, isInfoLoading } = titilerInfoService.useGetInfoGeneric(url);
  const bounds = getBoundsFromCogInfo(info) as LeafletBounds | undefined;

  if (isInfoLoading) {
    return <Loading />;
  }

  if (!titilerUrl || !bounds) {
    return null;
  }

  return (
    <DataLayer
      isVisible={visible}
      layerName={'compositesLayer'}
      zIndex={430}
      cogUrl={titilerUrl}
      bounds={bounds}
      opacity={opacity}
    />
  );
};
