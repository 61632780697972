import { type ValueAndLabel } from 'common/types';
import { useReplaceQueryParameters } from 'common/navigation/hooks';
import { VIEW_MODE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { usePointHistory } from 'components/graph/usePointHistory';

export const useHandleAreaViewOptionChangeUseCase = () => {
  const replaceQueryParameter = useReplaceQueryParameters();
  const { clearPointHistoryUseCase } = usePointHistory();

  const changeViewOption = (option: ValueAndLabel): void => {
    const chosenOption = option.value;

    chosenOption &&
      replaceQueryParameter([
        {
          key: VIEW_MODE_QUERY_PARAMETER,
          value: String(chosenOption).replaceAll(' ', '-'),
        },
      ]);

    clearPointHistoryUseCase();
  };

  return { changeViewOption };
};
