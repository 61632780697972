import { useParams } from 'react-router-dom';
import styled from 'styled-components';

import { areasRepository } from 'infrastructure/areas/areasRepository';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { SIDEBAR_IMAGE_RATIO, SIDEBAR_MAX_WIDTH } from 'common/constants';

export const AreaDetailsSidebarImage = () => {
  const { areaId } = useParams();

  if (!areaId) {
    return null;
  }

  const { aoiSatelliteUrl } = areasRepository.useFetchCurrentSatelliteImageUrl(Number(areaId));

  const aoiImageUrl =
    aoiSatelliteUrl &&
    titilerApiAdapter.buildGrayscaleTitilerImageUrl(aoiSatelliteUrl, {
      layerNumber: 3,
      maxSize: 512,
      imageHeight: 300,
      imageWidth: 220,
      rescaleFactor: 0.8096,
      colorMap: 'gray',
    });

  return <StyledImgBox>{aoiImageUrl && <StyledAreaPreview src={aoiImageUrl} alt="Area preview" />}</StyledImgBox>;
};

const StyledImgBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${SIDEBAR_MAX_WIDTH}px;
  aspect-ratio: ${SIDEBAR_IMAGE_RATIO};
  background-color: ${({ theme }) => theme.color.alto};
`;

const StyledAreaPreview = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
