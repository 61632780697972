import styled from 'styled-components';

import { LoadingDots } from 'ui/atoms/loadingDots';
import { FoldableSectionHeader } from './foldableSection';

export const LoadingStatePlaceholder = () => {
  return (
    <FoldableSectionHeader>
      <LoadingWrapper>
        <LoadingDots />
      </LoadingWrapper>
    </FoldableSectionHeader>
  );
};

const LoadingWrapper = styled.div`
  padding: 12px;
`;
