import { useEffect } from 'react';
import styled from 'styled-components';

import { type ColormapDictionary, type LayersVisibilityDictionary } from 'common/types';
import { useAoiNumber } from 'common/navigation/hooks';
import { ModeSwitcher } from 'ui';
import {
  FoldableSection,
  TransientStateCheckbox,
  LayerManagerLegendDndList,
  layerManagerOptionsBuildHelper,
  SingleOptionListCheckbox,
  useFoldableSectionStatus,
  NO_DATA_MESSAGE,
} from 'components/layerManager';
import { explorationRepository } from 'infrastructure/exploration/explorationRepository';
import { useLegendOptions } from './useLegendOptions';
import { useExplorationLayerManagerStore } from '../../explorationLayerManagerStore';
import { useGetMineralizationSliderConfig } from '../../useGetSliderConfig';

interface MineralsLayerManagerProps {
  classifierId: number;
  classesVisibility: LayersVisibilityDictionary;
  updateClassesVisibility: (classesVisibility: LayersVisibilityDictionary) => void;
  updateLayerVisibility: (clickedValue: string) => void;
  title: string;
  defaultDescription: string;
  gradientModeColormap?: ColormapDictionary;
  layersOrderRecord: Record<string, number[]> | null;
  setLayersOrderRecord: (key: string, layersOrder: number[]) => void;
}

export const MineralsLayerManager = ({
  classifierId,
  classesVisibility,
  updateClassesVisibility,
  updateLayerVisibility,
  title,
  defaultDescription,
  gradientModeColormap,
  layersOrderRecord,
  setLayersOrderRecord,
}: MineralsLayerManagerProps) => {
  const areaId = useAoiNumber();

  const isGradientModeOn = useExplorationLayerManagerStore.use.gradientModeState()[classifierId];
  const toggleGradientMode = useExplorationLayerManagerStore.use.toggleGradientMode();

  const sliderConfig = useGetMineralizationSliderConfig(classifierId);

  const { minerals } = explorationRepository.useFetchMineralsTypes();
  const { legendOptions } = useLegendOptions(minerals, classifierId);

  useEffect(() => {
    if (isGradientModeOn) {
      const filteredOptions = legendOptions.filter(({ name }) => !!classesVisibility[name]);

      if (filteredOptions.length > 1) {
        updateClassesVisibility(layerManagerOptionsBuildHelper([filteredOptions[0]]));
      }
    }
  }, [isGradientModeOn]); // eslint-disable-line react-hooks/exhaustive-deps

  const { description, areResultsAvailable } = useFoldableSectionStatus({
    defaultDescription,
    noDataMessage: NO_DATA_MESSAGE,
    areResultsAvailable: !!legendOptions.length,
  });

  const firstAvailableLayer = legendOptions[0]?.name;

  return (
    <FoldableSection
      headerContent={
        <HeaderContentWrapper>
          {isGradientModeOn ? (
            <SingleOptionListCheckbox
              {...{ title, description, classesVisibility, firstAvailableLayer, updateClassesVisibility }}
            />
          ) : (
            <TransientStateCheckbox
              title={title}
              description={description || ''}
              options={legendOptions}
              objectsVisibility={classesVisibility}
              updateLayersVisibility={updateClassesVisibility}
              disabled={!areResultsAvailable}
            />
          )}
          {gradientModeColormap && areResultsAvailable && (
            <ModeSwitcherWrapper>
              <ModeSwitcher
                isModeOn={isGradientModeOn}
                label="gradient mode"
                colormap={gradientModeColormap}
                segmentedColorbar
                toggleMode={() => toggleGradientMode(classifierId)}
              />
            </ModeSwitcherWrapper>
          )}
        </HeaderContentWrapper>
      }
      disabled={!areResultsAvailable}
    >
      <LayerManagerLegendDndList
        areaId={areaId}
        layersOptions={legendOptions}
        layersVisibility={classesVisibility}
        toggleLayerVisibility={updateLayerVisibility}
        sliderConfig={sliderConfig}
        isSingleChoice={isGradientModeOn}
        layersOrderRecord={layersOrderRecord}
        setLayersOrderRecord={setLayersOrderRecord}
      />
    </FoldableSection>
  );
};

const HeaderContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const ModeSwitcherWrapper = styled.div`
  padding: 10px 0 2px;
`;
