import { Comparator } from 'components';
import { useTimeline } from 'components/Timeline/useTimeline';
import { Timeline } from 'components/Timeline/Timeline';
import { Graph } from 'components/graph';
import { useGetVegetationComparatorCogUseCase } from 'views/greenery/vegetation/getVegetationComparatorCogUseCase';
import { useGetPuneVegetationTimestamps } from 'views/greenery/vegetation/useGetPuneVegetationTimestamps';
import { useAoiNumber } from 'common/navigation/hooks';

interface VegetationTimelineProps {
  timestamps: string[];
  areTimestampsLoading: boolean;
  isComparisonActive: boolean;
  areReportsActive: boolean;
  enablePleiadesAndPlanetScope: boolean;
  lackOfSelectedOption: boolean;
}

export const VegetationTimeline = ({
  timestamps,
  areTimestampsLoading,
  isComparisonActive,
  areReportsActive,
  enablePleiadesAndPlanetScope,
  lackOfSelectedOption,
}: VegetationTimelineProps) => {
  const areaId = useAoiNumber();

  const { puneTimestamps } = useGetPuneVegetationTimestamps();
  const areSpecialTimestamps = areaId === 353 && enablePleiadesAndPlanetScope && puneTimestamps.length;

  const {
    filteredTimestamps,
    firstDate,
    lastDate,
    rangeValue,
    selectedTimestamps,
    setRangeValue,
    isTimelineShowed,
    firstTimestamp,
    lastTimestamp,
  } = useTimeline(areSpecialTimestamps ? puneTimestamps : timestamps, !isComparisonActive && !areReportsActive);

  const { isCompareCogsDisabled, updateComparatorCog } = useGetVegetationComparatorCogUseCase();

  return (
    <>
      {isComparisonActive && !areReportsActive && (
        <Comparator
          variant="NEW_TO_OLDER"
          timestamps={timestamps}
          areTimestampsLoading={areTimestampsLoading}
          isCompareDisabled={isCompareCogsDisabled || lackOfSelectedOption}
          handleCompare={updateComparatorCog}
        />
      )}
      <Timeline
        {...{
          filteredTimestamps,
          firstDate,
          lastDate,
          rangeValue,
          selectedTimestamps,
          setRangeValue,
          visible: !isComparisonActive && !areReportsActive,
        }}
      />
      {!areReportsActive && (
        <Graph
          isTimelineVisible={isTimelineShowed}
          dateRange={{ firstDate: firstTimestamp, lastDate: lastTimestamp }}
        />
      )}
    </>
  );
};
