import { type ReactNode } from 'react';
import { Polygon } from 'react-leaflet';
import { Layer } from 'components/map';
import { type PointArray } from 'common/types/mapData';

interface ProspectingOverviewBackgroundProps {
  children: ReactNode;
  bounds: PointArray[];
}

export const ProspectingOverviewBackground = ({ children, bounds }: ProspectingOverviewBackgroundProps) => {
  return (
    <Layer visible={true} name="deposit-classification-sites-layer" zIndex={403}>
      <Polygon
        positions={[
          [
            [90, 180],
            [90, -180],
            [-90, -180],
            [-90, 180],
          ],
          bounds,
        ]}
        pathOptions={{
          fillColor: '#000',
          fillOpacity: 0.3,
          weight: 0,
        }}
      >
        {children}
      </Polygon>
    </Layer>
  );
};
