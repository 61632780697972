import { TileLayer } from 'react-leaflet';

import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { useGetCogWithBounds } from 'services/titiler/hooks';

interface WaterAreaMapLayerProps {
  url: string;
  pane: string;
  color: string;
}

export const WaterAreaMapLayer = ({ url, pane, color }: WaterAreaMapLayerProps) => {
  const { cogUrl, cogBounds } = useGetCogWithBounds(titilerApiAdapter.buildMonocolorFloatCogUrl, url, {
    color,
  });

  return <>{cogBounds && <TileLayer pane={pane} url={cogUrl} bounds={cogBounds} />}</>;
};
