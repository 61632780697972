/* eslint-disable @typescript-eslint/no-unused-vars */
import { useAoiNumber } from 'common/navigation/hooks';
import { useGetAoiName } from 'common/hooks/getAoiName';
import { useShareModalStore } from 'ui/organisms/shareModal/shareModalStore';
import { buildCoordinatesOnlyTooltipStringHelper } from 'common/utils/map';
import { CoordinatesOnlyMapTooltip } from 'components/map/tooltip/CoordinatesOnlyMapTooltip';
import { useExplorationLayerManagerStore } from './explorationLayerManagerStore';
import { SamMineralsMapContent, MtmfMineralsMapContent, MlMineralsMapContent } from './minerals';
import { LineamentDensityMapContent, LineamentsCollection } from './lineaments';
import { IndicesMapContent, useIndices } from './indices';
import { ClustersMapContent } from './clusters';
import { CompositesMapContent } from './composites';
import { ExplorationDownloadModal } from './ExplorationDownloadModal';
import { useSurfaceMinerals } from './useSurfaceMinerals';

export const ExplorationMapContent = () => {
  const areaId = useAoiNumber();
  const areaName = useGetAoiName();

  const { indicesResults } = useIndices();
  const { selectedMinerals: samMinerals, urlsWithNameAndLabel: samUrls } = useSurfaceMinerals(1);
  const { selectedMinerals: mtmfMinerals, urlsWithNameAndLabel: mtmfUrls } = useSurfaceMinerals(2);
  const { selectedMinerals: mlMinerals, urlsWithNameAndLabel: mlUrls } = useSurfaceMinerals(3);

  const isClustersSelectionMode = useExplorationLayerManagerStore.use.isClustersSelectionMode();
  const isLineamentsSelectionMode = useExplorationLayerManagerStore.use.isLineamentsSelectionMode();
  const isModalOpen = useShareModalStore.use.isShareModalOpen();

  return (
    <>
      {areaName && <LineamentsCollection areaName={areaName} />}
      {!isClustersSelectionMode && !isLineamentsSelectionMode && (
        <>
          {areaName && <LineamentDensityMapContent areaName={areaName} />}
          {!!indicesResults.length && <IndicesMapContent areaId={areaId} indicesResults={indicesResults} />}
          {!!samMinerals.length && (
            <SamMineralsMapContent areaId={areaId} minerals={samMinerals} indexOffset={indicesResults.length} />
          )}
          {!!mtmfMinerals.length && (
            <MtmfMineralsMapContent
              areaId={areaId}
              minerals={mtmfMinerals}
              indexOffset={indicesResults.length + samMinerals.length}
            />
          )}
          {!!mlMinerals.length && (
            <MlMineralsMapContent
              areaId={areaId}
              minerals={mlMinerals}
              indexOffset={indicesResults.length + samMinerals.length + mtmfMinerals.length}
            />
          )}
          <CoordinatesOnlyMapTooltip tooltipStringBuilder={buildCoordinatesOnlyTooltipStringHelper} />
        </>
      )}
      <ClustersMapContent />
      <CompositesMapContent />
      {isModalOpen && <ExplorationDownloadModal />}
    </>
  );
};
