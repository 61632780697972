/* eslint-disable @typescript-eslint/no-explicit-any */
import { type AxiosResponse } from 'axios';
import { isGeoJsonPointValid } from 'common/typeguards';
import {
  type DepositsApiResponse,
  type AreaPropsectingSitesDTO,
  type DepositClassificationSitesApiResponse,
  type MineralTypesApiResponse,
  type MineralizationResultsApiResponse,
  type SamplingPointsApiResponse,
  type SpectralClassifiersApiResponse,
  type SamplingPoint,
  type ClustersDataSupplier,
  type ClustersResult,
  type IndicatorDTO,
  type ExtendedSpectralIndicator,
  type LineamentDensityResult,
  type ExtendedIndicesResult,
} from 'domain/exploration/types';

export const areDepositClassificationSitesValid = (
  data: any,
): data is AxiosResponse<DepositClassificationSitesApiResponse, any> | undefined => {
  return (
    data &&
    Array.isArray(data?.data?.results?.features) &&
    data.data.results.features.every(
      (feature: any) =>
        typeof feature.type === 'string' &&
        typeof feature.properties.aoi_id === 'number' &&
        typeof feature.properties.deposit_id === 'number' &&
        typeof feature.properties.grade_id === 'number' &&
        typeof feature.properties.tonnage_id === 'number' &&
        typeof feature.properties.index === 'string' &&
        Array.isArray(feature.properties.minerals) &&
        feature.properties.minerals.every(
          (mineral: any) => typeof mineral.value === 'number' && typeof mineral.mineral_id === 'number',
        ) &&
        typeof feature.geometry.type === 'string' &&
        Array.isArray(feature.geometry.coordinates) &&
        feature.geometry.coordinates.length === 2 &&
        feature.geometry.coordinates.every((coordinate: any) => typeof coordinate === 'number'),
    )
  );
};

export const areProspectingTargetsValid = (targets: any): targets is AreaPropsectingSitesDTO | undefined => {
  return (
    targets &&
    typeof targets === 'object' &&
    Object.keys(targets).every((key: any) => typeof key === 'string') &&
    Object.values(targets).every(
      (value: any) => value && Array.isArray(value) && value.every((item: any) => typeof item === 'string'),
    )
  );
};

export const areDepositsValid = (data: any): data is AxiosResponse<DepositsApiResponse, any> => {
  return (
    data &&
    Array.isArray(data?.data?.results) &&
    data.data.results.every(
      (result: any) =>
        typeof result.id === 'number' &&
        typeof result.name === 'string' &&
        typeof result.label_name === 'string' &&
        typeof result.description === 'string' &&
        typeof result.status === 'string' &&
        typeof result.symbol === 'string',
    )
  );
};

export const areMineralsValid = (data: any): data is AxiosResponse<MineralTypesApiResponse, any> => {
  return (
    data &&
    Array.isArray(data?.data?.results) &&
    data.data.results.every(
      (result: any) =>
        typeof result.id === 'number' &&
        typeof result.name === 'string' &&
        typeof result.label_name === 'string' &&
        typeof result.deposit_id === 'number' &&
        typeof result.color === 'string' &&
        typeof result.alt_color === 'string',
    )
  );
};

export const areMineralsResultsValid = (data: any): data is AxiosResponse<MineralizationResultsApiResponse, any> => {
  return (
    data &&
    Array.isArray(data?.data?.results) &&
    data.data.results.every(
      (result: any) =>
        typeof result.id === 'number' &&
        typeof result.aoi_id === 'number' &&
        typeof result.deposit_id === 'number' &&
        typeof result.mineral_id === 'number' &&
        typeof result.classifier_id === 'number' &&
        typeof result.file_path === 'string',
    )
  );
};

export const areSpectralClassifiersValid = (data: any): data is AxiosResponse<SpectralClassifiersApiResponse, any> => {
  return (
    data &&
    Array.isArray(data?.data?.results) &&
    data.data.results.every(
      (result: any) =>
        typeof result.id === 'number' &&
        typeof result.name === 'string' &&
        typeof result.label_name === 'string' &&
        typeof result.description === 'string',
    )
  );
};

export const isSamplingPointValid = (point: any): point is SamplingPoint => {
  return (
    point &&
    typeof point.id === 'number' &&
    typeof point.aoi_id === 'number' &&
    typeof point.deposit_id === 'number' &&
    typeof point.mineral_id === 'number' &&
    typeof point.value === 'number'
  );
};

export const isSamplingPointsCollectionValid = (data: any): data is AxiosResponse<SamplingPointsApiResponse, any> => {
  return (
    data?.data?.results &&
    typeof data.data.results.type === 'string' &&
    Array.isArray(data.data.results.features) &&
    data.data.results.features.every(
      (feature: any) => isGeoJsonPointValid(feature.geometry) && isSamplingPointValid(feature.properties),
    )
  );
};

export const areClustersDataSuppliersValid = (results: any): results is ClustersDataSupplier[] => {
  return (
    results &&
    Array.isArray(results) &&
    results.every(
      (result: any) =>
        typeof result.id === 'number' &&
        typeof result.name === 'string' &&
        typeof result.label === 'string' &&
        typeof result.opacity === 'number' &&
        typeof result.description === 'string',
    )
  );
};

export const areClustersResultsValid = (results: any): results is ClustersResult[] => {
  return (
    results &&
    Array.isArray(results) &&
    results.every(
      (result: any) =>
        typeof result.id === 'number' &&
        typeof result.aoi_id === 'number' &&
        typeof result.aoi_name === 'string' &&
        typeof result.data_supplier_id === 'number' &&
        typeof result.amount === 'number' &&
        typeof result.url === 'string',
    )
  );
};

export const isSpectralIndicatorValid = (indicator: any): indicator is IndicatorDTO => {
  return (
    indicator &&
    typeof indicator.id === 'number' &&
    typeof indicator.name === 'string' &&
    typeof indicator.description === 'string' &&
    (typeof indicator.min_value === 'number' || indicator.min_value === null) &&
    (typeof indicator.max_value === 'number' || indicator.max_value === null)
  );
};

export const isExtendedSpectralIndicatorValid = (indicator: any): indicator is ExtendedSpectralIndicator => {
  return (
    indicator &&
    typeof indicator.file_min === 'number' &&
    typeof indicator.file_max === 'number' &&
    typeof indicator.file_defaultValue === 'number' &&
    typeof indicator.file_path === 'string' &&
    typeof indicator.label_name === 'string' &&
    typeof indicator.colormap === 'object' &&
    Object.values(indicator.colormap).every(String) &&
    isSpectralIndicatorValid(indicator)
  );
};

export const isExtendedIndicesResultValid = (result: any): result is ExtendedIndicesResult => {
  return (
    result &&
    typeof result.id === 'number' &&
    typeof result.indicator_id === 'number' &&
    typeof result.name === 'string' &&
    typeof result.label_name === 'string' &&
    typeof result.url === 'string' &&
    typeof result.info === 'string' &&
    typeof result.colormap === 'object' &&
    Object.values(result.colormap).every(String) &&
    typeof result.color === 'string' &&
    (result.status === 'active' || result.status === 'inactive' || result.status === 'hidden')
  );
};

export const areLineamentsRangeResultsValid = (
  results: any,
): results is { id: number; aoi_name: string; ranges: number[] }[] => {
  return (
    results &&
    Array.isArray(results) &&
    results.every(
      (result) =>
        typeof result.id === 'number' &&
        typeof result.aoi_name === 'string' &&
        Array.isArray(result.ranges) &&
        result.ranges.every(Number),
    )
  );
};

export const areLineamentDensityResultsValid = (results: any): results is LineamentDensityResult[] => {
  return (
    results &&
    Array.isArray(results) &&
    results.every(
      (result: any) =>
        typeof result.id === 'number' &&
        typeof result.aoi_name === 'string' &&
        typeof result.length_range === 'string' &&
        typeof result.url === 'string',
    )
  );
};
