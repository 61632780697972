import { type CSSProperties, type ReactNode } from 'react';
import styled from 'styled-components';
import { type Palette } from 'ui/types';

export type ContainerVariant = 'HORIZONTAL' | 'VERTICAL' | 'VERTICAL_START' | 'VERTICAL_STRETCH' | 'CENTERING';

type ContainerStyles = Required<Pick<CSSProperties, 'display' | 'alignItems' | 'flexDirection' | 'placeContent'>>;

const styles: Palette<ContainerVariant, ContainerStyles> = {
  HORIZONTAL: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    placeContent: 'unset',
  },
  VERTICAL: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    placeContent: 'unset',
  },
  VERTICAL_START: {
    display: 'flex',
    alignItems: 'flex-start',
    flexDirection: 'column',
    placeContent: 'unset',
  },
  VERTICAL_STRETCH: {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    placeContent: 'unset',
  },
  CENTERING: {
    display: 'grid',
    alignItems: 'unset',
    flexDirection: 'unset',
    placeContent: 'center',
  },
};

interface ContainerProps {
  variant?: ContainerVariant;
  children: ReactNode;
  width?: CSSProperties['width'];
  height?: CSSProperties['height'];
  maxWidth?: CSSProperties['maxWidth'];
  gap?: CSSProperties['gap'];
  mt?: CSSProperties['marginTop'];
  mb?: CSSProperties['marginBottom'];
  ml?: CSSProperties['marginLeft'];
  mr?: CSSProperties['marginRight'];
  mv?: CSSProperties['marginTop'] | CSSProperties['marginBottom'];
  mh?: CSSProperties['marginLeft'] | CSSProperties['marginRight'];
  m?: CSSProperties['margin'];
  pt?: CSSProperties['paddingTop'];
  pb?: CSSProperties['paddingBottom'];
  pl?: CSSProperties['paddingLeft'];
  pr?: CSSProperties['paddingRight'];
  pv?: CSSProperties['paddingTop'] | CSSProperties['paddingBottom'];
  ph?: CSSProperties['paddingLeft'] | CSSProperties['paddingRight'];
  p?: CSSProperties['padding'];
  hMax?: boolean;
  wMax?: boolean;
}

export const Container = styled.div<ContainerProps>`
  width: ${({ width, wMax }) => (width ? `${width}px` : wMax ? '100%' : 'auto')};
  height: ${({ height, hMax }) => (height ? `${height}px` : hMax ? '100%' : 'auto')};
  max-width: ${({ maxWidth }) => maxWidth}px;
  display: ${({ variant }) => styles[variant!].display};
  align-items: ${({ variant }) => styles[variant!].alignItems};
  place-content: ${({ variant }) => styles[variant!].placeContent};
  gap: ${({ gap }) => gap || 0}px;
  flex-direction: ${({ variant }) => styles[variant!].flexDirection};
  margin-top: ${({ mt, mv, m }) => mt || mv || m || 0}px;
  margin-bottom: ${({ mb, mv, m }) => mb || mv || m || 0}px;
  margin-left: ${({ ml, mh, m }) => ml || mh || m || 0}px;
  margin-right: ${({ mr, mh, m }) => mr || mh || m || 0}px;
  padding-top: ${({ pt, pv, p }) => pt || pv || p || 0}px;
  padding-bottom: ${({ pb, pv, p }) => pb || pv || p || 0}px;
  padding-left: ${({ pl, ph, p }) => pl || ph || p || 0}px;
  padding-right: ${({ pr, ph, p }) => pr || ph || p || 0}px;
`;

Container.defaultProps = {
  variant: 'HORIZONTAL' as ContainerVariant,
};

Container.displayName = 'Container';
