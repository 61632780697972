import { useEffect, useState } from 'react';
import { useQueries, useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { isSuccessfulQueryUrl } from 'common/typeguards';
import { type UrlTuple } from 'common/types';
import { useHandleWarningErrorUseCase } from 'views/@errors/handleTypicalWarningErrorUseCase';
import { useMapStore } from 'components/map/mapStore';
import { useGraphStore } from 'components/graph/graphStore';
import { useWaterStore } from 'infrastructure/water/waterStore';
import { waterApi } from 'infrastructure/water/waterApiAdapter';
import { useErrorsStore } from 'infrastructure/@errors/errorsStore';
import { DEFAULT_RESULTS_PER_PAGE } from 'common/constants/services';
import { type WaterQualityResult, type WaterAreaComparatorResultIds, type WaterAreaItem } from 'domain/water/types';
import {
  WATERBODIES_QUERY_KEY,
  WATER_AREA_RESULTS_QUERY_KEY,
  WATER_DATES_QUERY_KEY,
  WATER_QUALITY_INDICATORS_QUERY_KEY,
  WATER_PATH_NAME,
  WATER_AREA_COMPARATOR_COG_QUERY_KEY,
  WATER_QUALITY_RESULTS_QUERY_KEY,
  WATER_QUALITY_COG_QUERY_KEY,
  MOISTURE_INDICATORS_QUERY_KEY,
  MOISTURE_RESULTS_QUERY_KEY,
  SNOW_COVER_INDICATORS_QUERY_KEY,
  SNOW_COVER_RESULTS_QUERY_KEY,
} from 'domain/water/constants';
import {
  areMoistureIndicatorsValid,
  areMoistureResultsValid,
  areSnowCoverIndicatorsValid,
  areSnowCoverResultsValid,
  areWaterDatesValid,
  isSuccessfulChosenPoint,
  isSuccessfulWaterbodyDefaultView,
  isWaterBodiesResponseValid,
  isWaterIndicatorsResponseValid,
} from 'domain/water/typeguards';
import { getGraphDataFromRequestHelper, transformToWaterGraphResultForm } from 'domain/water/helpers';
import { WarningErrorType } from 'domain/@errors/enums';
import { ErrorsModel } from 'domain/@errors/ErrorsModel';

export const waterRepository = {
  useFetchWaterbodies: (areaId: number | undefined) => {
    const { data, isLoading, error, isSuccess } = useQuery([WATERBODIES_QUERY_KEY, { areaId }], () =>
      areaId ? waterApi.getWaterbodies({ areaId: areaId, limit: DEFAULT_RESULTS_PER_PAGE }) : undefined,
    );

    useHandleWarningErrorUseCase(WarningErrorType.WATERBODIES, data, error, isSuccess);

    const waterBodiesList = data && isWaterBodiesResponseValid(data) ? data.data.results : [];

    return { waterBodiesList, waterBodiesLoading: isLoading };
  },

  useFetchWaterDates: (areaId: number) => {
    const addTimestampsIndex = useWaterStore.use.addTimestampsIndex();

    const { data, isLoading, error, isSuccess } = useQuery(
      [
        WATER_DATES_QUERY_KEY,
        {
          areaId,
          limit: DEFAULT_RESULTS_PER_PAGE,
        },
      ],
      () => waterApi.getWaterDates({ areaId, limit: DEFAULT_RESULTS_PER_PAGE }),
    );

    useHandleWarningErrorUseCase(WarningErrorType.WATER_DATES, data, error, isSuccess);

    useEffect(() => {
      if (data && areWaterDatesValid(data)) {
        const timestampsIndex = data.data.results.map(({ img_date }) => [img_date]);
        const uniqueTimestampsIndex = Object.fromEntries(timestampsIndex);
        addTimestampsIndex(uniqueTimestampsIndex);
      }
    }, [addTimestampsIndex, data]);

    return { data, isLoading, error };
  },

  useFetchWaterQualityIndicators: () => {
    const { data, isLoading, error, isSuccess } = useQuery(
      [WATER_QUALITY_INDICATORS_QUERY_KEY, { limit: DEFAULT_RESULTS_PER_PAGE }],
      () => waterApi.getWaterQualityIndicators({ limit: DEFAULT_RESULTS_PER_PAGE }),
    );

    useHandleWarningErrorUseCase(WarningErrorType.WATER_QUALITY_INDICATORS, data, error, isSuccess);

    const waterIndicators = isWaterIndicatorsResponseValid(data) ? data.data.results : [];

    return {
      waterIndicators,
      areWaterIndicatorsLoading: isLoading,
    };
  },

  useFetchWaterAreaResults: (date?: string, areaId?: number, waterbodyId?: number) => {
    const { data, isInitialLoading, error, isSuccess } = useQuery(
      [
        WATER_AREA_RESULTS_QUERY_KEY,
        {
          date,
          areaId,
          waterbodyId,
          limit: DEFAULT_RESULTS_PER_PAGE,
        },
      ],
      () =>
        areaId && date
          ? waterApi.getWaterAreaResults({ date, areaId, waterbodyId, limit: DEFAULT_RESULTS_PER_PAGE })
          : undefined,
    );

    useHandleWarningErrorUseCase(WarningErrorType.WATER_AREA_RESULTS, data, error, isSuccess);

    return {
      waterAreaResults: data?.data?.results || [],
      waterAreaResultsLoading: isInitialLoading,
    };
  },

  useFetchWaterQualityResults: (
    areaId: number,
    timestamp: string | null | undefined,
    indicatorId: number | undefined,
  ) => {
    const { waterBodiesList } = waterRepository.useFetchWaterbodies(areaId);

    const waterQualityResultsResponse = useQueries({
      queries: waterBodiesList.map(({ id }) => ({
        queryKey: [WATER_QUALITY_RESULTS_QUERY_KEY, { date: timestamp, indicatorId, waterbodyId: id }],
        enabled: !!indicatorId && !!id && !!timestamp,
        queryFn: () =>
          indicatorId && timestamp
            ? waterApi.getWaterQualityResults({ date: timestamp, indicatorId, waterbodyId: id })
            : undefined,
      })),
    });

    const error = waterQualityResultsResponse.every((result) => result.isError)
      ? waterQualityResultsResponse.find((result) => result.error instanceof AxiosError)?.error
      : undefined;

    const isSuccess =
      waterQualityResultsResponse.every((result) => result.data?.data.count === 0) && !!waterBodiesList.length;

    useHandleWarningErrorUseCase(WarningErrorType.WATER_QUALITY_RESULTS, undefined, error, isSuccess);

    const waterQualityResults = waterQualityResultsResponse
      .map((item) => item.data?.data?.results)
      .filter((item) => item)
      .flat() as WaterQualityResult[];

    const waterQualityResultsLoading = waterQualityResultsResponse.some((result) => result.isInitialLoading);

    return { waterQualityResults, waterQualityResultsLoading };
  },

  useFetchWaterQualityCogsResponse: (waterQualityResultIds: number[]) => {
    const waterQualityCogsResponse = useQueries({
      queries: waterQualityResultIds.map((id) => ({
        queryKey: [WATER_QUALITY_COG_QUERY_KEY, id],
        enabled: typeof id === 'number',
        queryFn: () => waterApi.getWaterQualityCog(id),
      })),
    });

    const error = waterQualityCogsResponse.every((result) => result.isError)
      ? waterQualityCogsResponse.find((result) => result.error instanceof AxiosError)?.error
      : undefined;

    const isSuccess =
      waterQualityCogsResponse.every((result) => result.isSuccess) &&
      waterQualityCogsResponse.every((result) => !result.data?.data.url) &&
      !!waterQualityResultIds.length;

    useHandleWarningErrorUseCase(WarningErrorType.WATER_QUALITY_COGS, undefined, error, isSuccess);

    const waterQualityCogsLoading = waterQualityCogsResponse.some((item) => item.isInitialLoading);

    return { waterQualityCogsResponse, waterQualityCogsLoading };
  },

  useFetchWaterBodyDefaultView: () => {
    const [isDefaultViewLoading, setIsDefaultViewLoading] = useState(false);

    const setCenteringOnGeotiffData = useMapStore.use.setCenteringOnGeotiffData();
    const setWaterBodyDefaultView = useWaterStore.use.setWaterBodyDefaultView();

    const fetchWaterBodyDefaultView = async (waterbodyId: number) => {
      try {
        setIsDefaultViewLoading(true);

        const response: unknown = await waterApi.getWaterBodyDefaultView(waterbodyId);

        if (response === undefined || !isSuccessfulWaterbodyDefaultView(response)) {
          return;
        }

        if (response?.status === 200 && response?.data) {
          setWaterBodyDefaultView(response.data);
          setCenteringOnGeotiffData();
        }
      } catch (err) {
        if (err instanceof Error) {
          console.log(err.message || `Unable to fetch default coordinates assigned to selected waterbody >>> ${err}`);
        }
      } finally {
        setIsDefaultViewLoading(false);
      }
    };

    return { isDefaultViewLoading, fetchWaterBodyDefaultView };
  },

  useFetchWaterPointHistory: () => {
    const [isHistoryLoading, setIsHistoryLoading] = useState(false);

    const updateWarningError = useErrorsStore.use.updateWarningError();
    const removeWarningErrorByKey = useErrorsStore.use.removeWarningErrorByKey();

    const fetchWaterPointHistory = async (waterbodyId: number, indicatorId: number, lat: number, lng: number) => {
      try {
        setIsHistoryLoading(true);
        removeWarningErrorByKey(WarningErrorType.WATER_POINT_HISTORY);

        const response = await waterApi.getWaterPointHistory({ indicatorId, waterbodyId, lat, lng });

        if (response?.status === 200 && isSuccessfulChosenPoint(response)) {
          return getGraphDataFromRequestHelper(response.data.results);
        } else {
          if (response === undefined || !response.data.count) {
            throw new Error(String(response?.status));
          } else {
            throw new Error('Empty water point data');
          }
        }
      } catch (err) {
        if (err instanceof Error) {
          const errorCause = ErrorsModel.getErrorCause(err.message);
          updateWarningError({
            [WarningErrorType.WATER_POINT_HISTORY]: {
              type: WarningErrorType.WATER_POINT_HISTORY,
              viewPath: WATER_PATH_NAME,
              errorCause,
            },
          });
        }
      } finally {
        setIsHistoryLoading(false);
      }
    };

    return { isHistoryLoading, fetchWaterPointHistory };
  },

  useFetchWaterAreaHistory: () => {
    const [isHistoryLoading, setIsHistoryLoading] = useState(false);

    const setPointHistory = useGraphStore.use.setGraphData();

    const fetchWaterAreaHistory = async (waterbodyId: number) => {
      try {
        setIsHistoryLoading(true);

        const response = await waterApi.getWaterAreaHistory({ waterbodyId });
        if (response?.status === 200) {
          const graphData = transformToWaterGraphResultForm<WaterAreaItem>(response.data.results, {
            date: 'img_date',
            id: 'id',
            value: 'area',
          });
          setPointHistory(getGraphDataFromRequestHelper(graphData));
        } else {
          if (response === undefined || !response.data.count) {
            throw new Error(String(response?.status));
          } else {
            throw new Error('No water area history data');
          }
        }
      } catch (err) {
        if (err instanceof Error) {
          console.log(err.message || `Unable to fetch water history assigned to selected waterbody >>> ${err}`);
        }
      } finally {
        setIsHistoryLoading(false);
      }
    };

    return { isHistoryLoading, fetchWaterAreaHistory };
  },

  useGetWaterAreaComparatorCog: (resultIds: WaterAreaComparatorResultIds[]) => {
    const [comparatorCogsFetchingEnabled, setComparatorCogsFetchingEnabled] = useState(false);

    const response = useQueries({
      queries: resultIds.map(({ firstResultId, secondResultId }) => ({
        queryKey: [WATER_AREA_COMPARATOR_COG_QUERY_KEY, { firstResultId, secondResultId }],
        queryFn: () =>
          firstResultId && secondResultId
            ? waterApi.getWaterAreaComparatorCog({ firstResultId, secondResultId })
            : undefined,
        enabled: comparatorCogsFetchingEnabled,
      })),
    });

    const error = response.every((result) => result.isError)
      ? response.find((result) => result.error instanceof AxiosError)?.error
      : undefined;

    const isSuccess =
      response.every((result) => result.isSuccess) &&
      response.every((result) => !result.data?.data.url) &&
      !!resultIds.length;

    useHandleWarningErrorUseCase(WarningErrorType.WATER_AREA_COMPARATOR_COGS, undefined, error, isSuccess);

    const comparatorCogsUrl = response
      .map((item) =>
        isSuccessfulQueryUrl(item.data) ? { [item.data.config.params.first_result_id]: item.data.data.url } : undefined,
      )
      .filter((i) => i)
      .map((i) => i!);

    const comparatorCogsUrlTuples: UrlTuple[] = Object.entries(Object.assign({}, ...comparatorCogsUrl));
    const comparatorCogsLoading = response.some((item) => item.isInitialLoading && item.fetchStatus !== 'idle');

    return { comparatorCogsUrlTuples, comparatorCogsLoading, setComparatorCogsFetchingEnabled };
  },

  useFetchMoistureIndicators: () => {
    const { data, isLoading } = useQuery([MOISTURE_INDICATORS_QUERY_KEY, { limit: DEFAULT_RESULTS_PER_PAGE }], () =>
      waterApi.getMoistureIndicators({ limit: DEFAULT_RESULTS_PER_PAGE }),
    );

    const response = data?.data?.results;
    const indicators = areMoistureIndicatorsValid(response) ? response : [];

    return {
      indicators,
      isLoading,
    };
  },

  useFetchMoistureResults: (areaName: string | undefined) => {
    const { data, isLoading } = useQuery([MOISTURE_RESULTS_QUERY_KEY, { limit: DEFAULT_RESULTS_PER_PAGE }], () =>
      waterApi.getMoistureResults({ limit: DEFAULT_RESULTS_PER_PAGE }),
    );

    const results = areMoistureResultsValid(data) ? data.data.results : [];
    const filteredResults = results.filter(({ aoi_name }) => aoi_name === areaName);

    return {
      results: filteredResults,
      isLoading,
    };
  },

  useFetchMoistureResult: (areaName: string | undefined, timestamp: string, indicatorId: number) => {
    const { data, isLoading } = useQuery([MOISTURE_RESULTS_QUERY_KEY, { limit: DEFAULT_RESULTS_PER_PAGE }], () =>
      waterApi.getMoistureResults({ limit: DEFAULT_RESULTS_PER_PAGE }),
    );

    const results = areMoistureResultsValid(data) ? data.data.results : [];
    const result = results.find(
      ({ aoi_name, img_date, indicator_id }) =>
        aoi_name === areaName && img_date === timestamp && indicator_id === indicatorId,
    );

    return {
      result,
      isLoading,
    };
  },

  useFetchSnowCoverIndicators: () => {
    const { data, isLoading } = useQuery([SNOW_COVER_INDICATORS_QUERY_KEY, { limit: DEFAULT_RESULTS_PER_PAGE }], () =>
      waterApi.getSnowCoverIndicators({ limit: DEFAULT_RESULTS_PER_PAGE }),
    );

    const response = data?.data?.results;
    const indicators = areSnowCoverIndicatorsValid(response) ? response : [];

    return {
      indicators,
      isLoading,
    };
  },

  useFetchSnowCoverResults: (areaName: string | undefined) => {
    const { data, isLoading } = useQuery([SNOW_COVER_RESULTS_QUERY_KEY, { limit: DEFAULT_RESULTS_PER_PAGE }], () =>
      waterApi.getSnowCoverResults({ limit: DEFAULT_RESULTS_PER_PAGE }),
    );

    const results = areSnowCoverResultsValid(data) ? data.data.results : [];

    const filteredResults = results.filter(({ aoi_name }) => aoi_name === areaName);

    return {
      results: filteredResults,
      isLoading,
    };
  },
};
