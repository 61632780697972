import { useQueryParameter, useAoiNumber } from 'common/navigation/hooks';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { Loading } from 'ui/molecules/loading';
import { COMPARISON_OPTION_NAME } from 'common/navigation/constants';
import { quarterlyDataService } from 'views/greenery/quarterly/quarterlyDataService';
import { GREENERY_OBJECT_QUERY_PARAMETER, VIEW_MODE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { QuarterlySegmentationDataLayer } from 'views/greenery/quarterly/QuarterlySegmentationDataLayer';
import { QuarterlyComparatorDataLayer } from 'views/greenery/quarterly/QuarterlyComparatorDataLayer';
import { useGreeneryStore } from 'infrastructure/greenery/greeneryStore';
import { useTimelineService } from 'components/Timeline/timelineService';
import { ShareModal } from 'components';
import { GreeneryActiveTab } from 'domain/greenery/enums';

export const QuarterlyMapContent = () => {
  const areaId = useAoiNumber();

  const { timestamp } = useTimelineService();

  const viewQuery = useQueryParameter(VIEW_MODE_QUERY_PARAMETER);
  const isComparisonActive: boolean = viewQuery === COMPARISON_OPTION_NAME;

  const isDataLayerVisible = useLayerManagerStore.use.isDataLayerVisible();
  const quarterlyComparatorUrl = useGreeneryStore.use.comparatorUrl();

  const { greeneryObjects } = greeneryRepository.useFetchGreeneryObjects();

  const { quarterlyResults, quarterlyResultsLoading } = quarterlyDataService.useFetchQuarterlyResults(areaId);

  const {
    quarterlyCogUrl,
    isQuarterlyCogLoading: isBaseCogLoading,
    chosenResultId,
  } = quarterlyDataService.useFetchQuarterlyCogUrl(quarterlyResults, timestamp);

  const quarterlyComparatorCogUrl = useGreeneryStore.use.comparatorUrl();

  const greeneryObjectQuery = useQueryParameter(GREENERY_OBJECT_QUERY_PARAMETER);
  const currentObjectId = greeneryObjects.filter(({ name }) => name === greeneryObjectQuery)[0]?.id;

  if (quarterlyResultsLoading || isBaseCogLoading) {
    return <Loading />;
  }

  return (
    <>
      {!isComparisonActive
        ? quarterlyCogUrl &&
          chosenResultId && (
            <QuarterlySegmentationDataLayer
              quarterlyCogUrl={quarterlyCogUrl}
              chosenResultId={chosenResultId}
              greeneryObjects={greeneryObjects}
              isDataLayerVisible={isDataLayerVisible}
            />
          )
        : quarterlyComparatorCogUrl &&
          currentObjectId && (
            <QuarterlyComparatorDataLayer
              quarterlyComparatorCogUrl={quarterlyComparatorCogUrl}
              isDataLayerVisible={isDataLayerVisible}
              currentObjectId={currentObjectId}
            />
          )}
      <ShareModal
        links={[quarterlyCogUrl, quarterlyComparatorUrl].filter((link): link is string => !!link)}
        activeTab={GreeneryActiveTab.QUARTERLY}
      />
    </>
  );
};
