import styled from 'styled-components';

import { LayerManagerCommonContent } from 'components/layerManager';
import { useGetAoiName } from 'common/hooks/getAoiName';
import { explorationRepository } from 'infrastructure/exploration/explorationRepository';
import { LineamentsLayerManager } from './lineaments';
import { IndicesLayerManager } from './indices';
import { MineralsLayerManagerMtmf, MineralsLayerManagerSam, MineralsLayerManagerMl } from './minerals';
import { CompositesLayerManager } from './composites';
import { ClustersLayerManager } from './clusters';

export const ExplorationLayerManager = () => {
  const areaName = useGetAoiName();

  const { spectralClassifiers: classifiers } = explorationRepository.useFetchSpectralClassifiers();

  if (!areaName) {
    return null;
  }

  return (
    <Wrapper>
      <LayerManagerCommonContent>
        <LineamentsLayerManager {...{ areaName }} />
        <IndicesLayerManager />
        <MineralsLayerManagerSam {...{ classifiers }} />
        <MineralsLayerManagerMtmf {...{ classifiers }} />
        <MineralsLayerManagerMl {...{ classifiers }} />
        <ClustersLayerManager />
        <CompositesLayerManager />
      </LayerManagerCommonContent>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 0 1rem;
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.mineShaftABitLighter};
  border: 1px solid ${({ theme }) => theme.color.primary};
`;
