import { type ReactNode, useCallback, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

import { type TooltipData } from 'common/types/mapData';
import 'ui/map/tooltip/style.css';

interface TooltipProps {
  tooltipData: TooltipData;
  content: ReactNode;
  isScrollable?: boolean;
}

export const MapTooltip = ({ tooltipData, content, isScrollable = false }: TooltipProps) => {
  const map = useMap();

  const renderTooltip = useCallback(() => {
    if (tooltipData) {
      if (isScrollable) {
        L.tooltip({
          content: `<div class='scrollable' style='padding: 10px; max-height: 300px'>${content}</div>`,
          className: 'map-leaflet-tooltip no-padding',
          interactive: true,
        })
          .setLatLng([tooltipData.lat, tooltipData.lng])
          .addEventListener('mouseover', () => {
            map.scrollWheelZoom.disable();
          })
          .addEventListener('mouseout', () => {
            map.scrollWheelZoom.enable();
          })
          .addTo(map);
      } else {
        map.openTooltip(content, [tooltipData.lat, tooltipData.lng], {
          className: 'map-leaflet-tooltip',
        });
      }
    }
  }, [map, tooltipData, content, isScrollable]);

  useEffect(() => {
    renderTooltip();

    return () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      map.eachLayer((layer: any) => {
        if (layer.options.pane === 'tooltipPane') layer.removeFrom(map);
      });
      map.scrollWheelZoom.enable();
    };
  }, [tooltipData, content, map, renderTooltip]);

  return null;
};
