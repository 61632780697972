import chroma from 'chroma-js';

import { useAoiNumber } from 'common/navigation/hooks';
import { type ColormapDictionary } from 'common/types';
import { explorationRepository } from 'infrastructure/exploration/explorationRepository';
import { isExtendedIndicesResultValid } from 'domain/exploration/typeguards';
import { type ExtendedIndicesResult } from 'domain/exploration/types';
import { SPECTRAL_INDICATOR_GRADIENT_START_COLOR, TURBO_COLORMAP } from 'domain/exploration/colors';

export const useIndices = () => {
  const areaId = useAoiNumber();

  const { indices } = explorationRepository.useFetchIndices();
  const { indicesResults } = explorationRepository.useFetchIndicesResults(areaId);
  const { results } = explorationRepository.useFetchIndicatorResultsByIds(indicesResults);

  const extendedResults = results
    .map(({ id, indicator_id, file_path }) => {
      const indicator = indices.find(({ id }) => indicator_id === id);

      if (!indicator) {
        return null;
      }

      return {
        id,
        indicator_id: indicator.id,
        name: `${indicator.name
          .replaceAll(/[()]/g, '')
          .replaceAll(/[ -]/g, '_')
          .replaceAll('+', 'plus')}`.toLowerCase(),
        label_name: indicator.name,
        url: file_path,
        info: indicator.description.length === 1 && indicator.description[0] === ' ' ? '' : indicator.description,
        color: '',
        status: 'active',
      };
    })
    .filter((indicator) => !!indicator);

  const turboScale = chroma.scale(Object.values(TURBO_COLORMAP)).domain([0, extendedResults.length - 1]);

  const extendedColoredResults = extendedResults
    .sort((a, b) => a!.indicator_id - b!.indicator_id)
    .map((item, index) => ({
      ...item,
      colormap: {
        0: `${SPECTRAL_INDICATOR_GRADIENT_START_COLOR}`,
        1: `${turboScale(index).hex()}`,
      } as ColormapDictionary,
    }))
    .filter((indicator): indicator is ExtendedIndicesResult => isExtendedIndicesResultValid(indicator));

  return {
    indicesResults: extendedColoredResults,
  };
};
