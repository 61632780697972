import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { useGetActiveOptionReportsCreatorUseCase } from 'views/reports/creator/useCases/getActiveOptionReportsCreatorUseCase';
import { VegetationSingleDateReportsExternalData } from 'views/reports/external/data/VegetationSingleDateReportsExternalData';
import { useVegetationIndicators } from 'views/greenery/vegetation/useVegetationIndicators';
import { VegetationDobuleDateReportsExternalInitialData } from 'views/reports/external/data/VegetationDobuleDateReportsExternalInitialData';
import { useGetGreeneryIndicatorStatsUseCase } from 'views/greenery/useCases/getGreeneryIndicatorsStatsUseCase';
import { useGreeneryLayersVisibility } from 'views/greenery/useGreeneryLayersVisibility';
import { type ExternalReportHeaderCommon } from 'domain/reports/types';

interface VegetationReportsExternalDataProps {
  areaId: number;
  timestamp: string;
  reportInfo: ExternalReportHeaderCommon;
}

export const VegetationReportsExternalData = ({
  areaId,
  timestamp,
  reportInfo,
}: VegetationReportsExternalDataProps) => {
  const { isCompareDatesType } = useGetActiveOptionReportsCreatorUseCase();

  const { classesVisibility } = useGreeneryLayersVisibility();

  const { selectedIndicator } = useVegetationIndicators();

  const { greeneryObjects } = greeneryRepository.useFetchGreeneryObjects();
  const selectedSegmentationClass = greeneryObjects.find(({ name }) => classesVisibility[name]);

  const { indicatorStats } = useGetGreeneryIndicatorStatsUseCase(timestamp);
  const selectedIndicatorStats = indicatorStats.find(
    ({ greenery_object_id }) => greenery_object_id === selectedSegmentationClass?.id,
  );

  return (
    selectedIndicator &&
    selectedSegmentationClass &&
    (isCompareDatesType ? (
      <VegetationDobuleDateReportsExternalInitialData
        areaId={areaId}
        timestamp={timestamp}
        selectedIndicator={selectedIndicator}
        selectedSegmentationClass={selectedSegmentationClass}
        reportInfo={reportInfo}
      />
    ) : (
      selectedIndicatorStats && (
        <VegetationSingleDateReportsExternalData
          areaId={areaId}
          timestamp={timestamp}
          selectedIndicator={selectedIndicator}
          selectedSegmentationClass={selectedSegmentationClass}
          selectedIndicatorStats={selectedIndicatorStats}
          reportInfo={reportInfo}
        />
      )
    ))
  );
};
