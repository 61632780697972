import { useEffect, useState } from 'react';
import { useMap } from 'react-leaflet';

import { type MapPoint } from 'common/types/mapData';
import { MapTooltip } from 'ui/map/tooltip';

interface TooltipProps {
  tooltipStringBuilder?: (tooltip: MapPoint) => string;
}

export const CoordinatesOnlyMapTooltip = ({ tooltipStringBuilder }: TooltipProps) => {
  const [tooltip, setTooltip] = useState<MapPoint | undefined>();

  const map = useMap();
  const currentZoom = map.getZoom();

  useEffect(() => {
    map.off('click');
    map.off('mousemove');

    map.on('click', async (event: { originalEvent: MouseEvent }) => {
      const latLng = await map.mouseEventToLatLng(event.originalEvent);

      setTooltip({
        lat: latLng?.lat as number,
        lng: latLng?.lng as number,
      });
    });

    return () => {
      map.off('click');
      map.off('mousemove');
    };
  }, [map, currentZoom]);

  if (tooltip && tooltipStringBuilder) {
    return <MapTooltip tooltipData={{ id: 'generic-tooltip', ...tooltip }} content={tooltipStringBuilder(tooltip)} />;
  }

  return null;
};
