import { useLocation, useParams } from 'react-router-dom';

import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { planetscopeWaterbodies, pleiadesWaterbodies } from 'views/water/puneData';
import { WaterActiveTab } from 'domain/water/enums';

// TODO: remove when mercedes data will be handled
export const useGetPuneWaterAreaTimestamps = () => {
  const { areaId } = useParams();
  const { pathname } = useLocation();

  const enablePleiadesAndPlanetScope = process.env.REACT_APP_ENABLE_PLEIADES_AND_PLANET_SCOPE;

  const layersVisibility = useLayerManagerStore.use.layersVisibility();
  const pleiadesTimestamps = pleiadesWaterbodies.map((p) => p.date);
  const uniquePleiadesTimestamps = pleiadesTimestamps && Array.from(new Set(pleiadesTimestamps));
  const planetScopeTimestamps = planetscopeWaterbodies.map((p) => p.date);
  const uniquePlanetscopeTimestamps = planetScopeTimestamps && Array.from(new Set(planetScopeTimestamps));
  const puneTimestamps = layersVisibility['pleiades']
    ? uniquePleiadesTimestamps
    : layersVisibility['planet_scope']
    ? uniquePlanetscopeTimestamps
    : [];

  const areSpecialTimestamps =
    areaId === '353' &&
    pathname.includes(WaterActiveTab.SURFACE) &&
    enablePleiadesAndPlanetScope &&
    puneTimestamps.length;

  return { puneTimestamps, areSpecialTimestamps };
};
