import { useEffect } from 'react';
import styled from 'styled-components';

import { Container, Input } from 'ui';
import { HIDE_THRESHOLD_INPUT_TIMEOUT } from 'domain/exploration/configs';

interface ThresholdInputProps {
  value: number | number[];
  min: number;
  max: number;
  step: number;
  showInput: boolean;
  setShowInput: React.Dispatch<React.SetStateAction<boolean>>;
  onChange: (value: number | number[]) => void;
}

export const LayerManagerThresholdInput = ({
  value,
  min,
  max,
  step,
  showInput,
  setShowInput,
  onChange,
}: ThresholdInputProps) => {
  useEffect(() => {
    let timeoutId: NodeJS.Timeout;

    const resetTimeout = () => {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        showInput && setShowInput(false);
      }, HIDE_THRESHOLD_INPUT_TIMEOUT);
    };

    resetTimeout();

    return () => clearTimeout(timeoutId);
  }, [showInput, value, setShowInput]);

  return (
    <DetailInputWrapper mv={showInput ? 5 : 0} wMax isOpen={showInput} isSingle={typeof value === 'number'}>
      <p>Detail Adjustment</p>
      {typeof value === 'number' ? (
        <Input
          type="number"
          value={value}
          min={min}
          max={max}
          step={step}
          onChange={({ target }) => {
            const value = target.valueAsNumber;
            if (isNaN(value)) return;
            onChange(value);
          }}
        />
      ) : Array.isArray(value) && value.length === 2 ? (
        <>
          <Input
            type="number"
            value={value[0]}
            min={min}
            max={max}
            step={step}
            onChange={({ target }) => {
              const val = target.valueAsNumber;
              if (isNaN(val) || val >= value[1]) return;
              onChange([val, value[1]]);
            }}
          />
          <Input
            type="number"
            value={value[1]}
            min={min}
            max={max}
            step={step}
            onChange={({ target }) => {
              const val = target.valueAsNumber;
              if (isNaN(val) || val <= value[0]) return;
              onChange([value[0], val]);
            }}
          />
        </>
      ) : null}
    </DetailInputWrapper>
  );
};

const DetailInputWrapper = styled(Container)<{ isOpen: boolean; isSingle: boolean }>`
  display: grid;
  grid-template-columns: ${({ isSingle }) => (isSingle ? 'repeat(2, 50px)' : 'repeat(3, 50px)')};
  align-items: end;
  justify-content: end;
  gap: 8px;
  overflow-y: hidden;
  height: ${({ isOpen }) => (isOpen ? '24px' : '0px')};
  opacity: ${({ isOpen }) => (isOpen ? '100' : '0')};
  transition: height ${({ theme }) => theme.transitionFunction.main},
    opacity ${({ theme }) => theme.transitionFunction.main}, margin ${({ theme }) => theme.transitionFunction.main};

  p {
    font-size: 10px;
    font-weight: 600;
    line-height: 11px;
    text-align: right;
    margin: 0;
    color: ${({ theme }) => theme.color.silverDark};
  }

  input {
    font-size: 14px;
    font-weight: 600;
    line-height: 11px;
    height: 24px;
  }
`;
