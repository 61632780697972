import { DataLayer } from 'components/map';
import { getBoundsFromCogInfo } from 'services/titiler/utils';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { titilerInfoService } from 'services/titiler/titilerInfoService';

interface VegetationPuneDataLayerProps {
  isSpecialModeActive: boolean;
  url: string;
}

export const VegetationPuneDataLayer = ({ isSpecialModeActive, url }: VegetationPuneDataLayerProps) => {
  const cogUrl = titilerApiAdapter.buildNamedColorScaleFloatCogUrl(url, {
    range: [-1, 1],
    colormapName: 'rdylgn',
    noDataValue: '0',
  });

  const { info } = titilerInfoService.useGetInfo(url);

  const bounds = getBoundsFromCogInfo(info);

  return (
    <DataLayer
      isVisible={isSpecialModeActive}
      layerName={'vegetationLayer-100'}
      zIndex={490}
      cogUrl={cogUrl}
      bounds={bounds}
    />
  );
};
