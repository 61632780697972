import { useState } from 'react';
import styled from 'styled-components';

import { ReactComponent as TargetIcon } from 'assets/svg/target.svg';
import { Button } from 'ui/atoms/button';
import { AreaInfoTitle } from 'ui/organisms/areaInfoPanel/components';
import { explorationRepository } from 'infrastructure/exploration/explorationRepository';

interface DetailedInfoPanelHeaderProps {
  areaId: number;
  siteName: string;
}

export const DetailedInfoPanelHeader = ({ areaId, siteName }: DetailedInfoPanelHeaderProps) => {
  const { prospectingTargets, setProspectingTargets } = explorationRepository.useProspectingTargets();
  const targets = prospectingTargets[areaId] || [];

  const [isTarget, setIsTarget] = useState(targets.includes(siteName));

  const onClickHandler = () => {
    let newSiteTargets = [];

    if (isTarget) {
      newSiteTargets = targets.filter((target) => target !== siteName);
    } else {
      newSiteTargets = [...targets, siteName];
    }

    setIsTarget((prev) => !prev);

    setProspectingTargets({ ...prospectingTargets, [areaId]: newSiteTargets });
  };

  return (
    <HeaderWrapper>
      <AreaInfoTitle>{`Prospect Site Name: ${siteName}`}</AreaInfoTitle>
      <Button
        variant={isTarget ? 'PRIMARY_SUPER_SMALL' : 'SECONDARY_SUPER_SMALL'}
        onClick={onClickHandler}
        minWidth={70}
      >
        <ButtonContentWrapper>
          <TargetIcon />
          <div>{isTarget ? 'target' : 'mark as target'}</div>
        </ButtonContentWrapper>
      </Button>
    </HeaderWrapper>
  );
};

const HeaderWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const ButtonContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  gap: 6px;

  & svg {
    width: 12px;
    height: 12px;
  }
`;
