import { useEffect, useState } from 'react';

import { type StateValueAndLabel, type ValueAndLabel } from 'common/types';
import { useOnClickOutside } from 'common/hooks';
import { REGULAR_SELECT_ROWS, SPECIAL_SELECT_ROWS } from './constants';

export const selectComponentService = {
  useFilterResults: (
    options: StateValueAndLabel[],
    selectedOption: ValueAndLabel | undefined,
    noOptionsLabel: string,
    special: boolean,
    isOpen: boolean,
  ) => {
    const [query, setQuery] = useState<string>('');

    const availableOptionsNumber = options.length;
    const haveOptions = !!availableOptionsNumber;

    const currentRowsNumber = special ? SPECIAL_SELECT_ROWS : REGULAR_SELECT_ROWS;
    const isSearchNeeded = haveOptions && availableOptionsNumber > currentRowsNumber;

    const filteredOptions = options.filter((option) => option.label.toLowerCase().includes(query.toLowerCase()));

    const getResultOptions = () => {
      switch (true) {
        case !!query?.length && !!filteredOptions?.length:
          return filteredOptions;
        case !query?.length && !!options?.length:
          return options;
        default:
          return [];
      }
    };

    const resultOptions = getResultOptions();

    const onQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setQuery(event.target.value);
    };

    useEffect(() => {
      if (!isOpen) {
        setQuery('');
      }
    }, [isOpen]);

    return { resultOptions, isSearchNeeded, query, onQueryChange };
  },

  useHandleUserActions: (
    wrapperRef: React.MutableRefObject<HTMLDivElement | null>,
    options: ValueAndLabel[] | [],
    onChange?: (option: ValueAndLabel) => void,
    reset?: () => void,
  ) => {
    const [isOpen, setOpen] = useState<boolean>(false);

    useOnClickOutside(wrapperRef, () => setOpen(false));

    const onSelectClicked = () => {
      if (options) {
        setOpen(!isOpen);
      }
    };

    const onResetOptionClicked = () => {
      reset && reset();
      setOpen(false);
    };

    const onOptionClicked = (option: ValueAndLabel) => {
      setOpen(false);
      onChange && onChange(option);
    };

    return { isOpen, onSelectClicked, onResetOptionClicked, onOptionClicked };
  },
};
