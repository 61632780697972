import { GreeneryActiveTab } from 'domain/greenery/enums';

export const GREENERY_VEGETATION_ROLE_NAME = 'view-greenery-vegetation';
export const GREENERY_SEGMENTATION_ROLE_NAME = 'view-daily-segmentation';
export const GREENERY_QUARTERLY_ROLE_NAME = 'view-quarterly-segmentation';
export const GREENERY_VHR_ROLE_NAME = 'view-vhr-segmentation';
export const GREENERY_VCI_ROLE_NAME = 'view-vegetation-condition-index';

export const GREENERY_PATH_NAME = 'greenery';
export const LAND_COVER_PATH_NAME = GreeneryActiveTab.SEGMENTATION;
export const FOLIAGE_CONDITION_PATH_NAME = GreeneryActiveTab.VEGETATION;
export const LAND_COVER_COMPARISON_PATH_NAME = 'segmentation-comparison';
