import styled from 'styled-components';

import { AreaInfoButtonInnerWrapper, AreaInfoDescription } from 'ui/organisms/areaInfoPanel/components';
import { Button } from 'ui/atoms/button';
import { useMonitoringInfoStore } from 'views/monitoring/store';
import { ROUTES } from 'common/navigation/routes';

import { ReactComponent as TreeIcon } from 'assets/svg/tree-icon.svg';
import { ReactComponent as WaterdropIcon } from 'assets/svg/water-drop-icon.svg';
import { useNavigateWithQuery } from 'common/navigation/hooks';
import { WATERBODY_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { ScrollbarWrapper } from 'ui/atoms/scrollbarWrapper';

export const WaterbodiesReclamationsSection = () => {
  const navigateWithQuery = useNavigateWithQuery();
  const waterbodies = useMonitoringInfoStore.use.waterbodies();
  const reclamationAreas = useMonitoringInfoStore.use.reclamationAreas();

  return (
    <Wrapper>
      {waterbodies && (
        <div>
          <AreaInfoDescription>MONITORED WATERBODIES</AreaInfoDescription>
          <ButtonsWrapper>
            {waterbodies.map((item, index) => (
              <Button
                minWidth={110}
                key={item.properties.id}
                onClick={() =>
                  navigateWithQuery(ROUTES.waterQuality, [
                    { key: WATERBODY_QUERY_PARAMETER, value: String(item.properties.id) },
                  ])
                }
                variant="PRIMARY_SUPER_SMALL"
              >
                <AreaInfoButtonInnerWrapper>
                  <StyledWaterIcon /> Waterbody {++index}
                </AreaInfoButtonInnerWrapper>
              </Button>
            ))}
          </ButtonsWrapper>
        </div>
      )}

      {reclamationAreas && (
        <div>
          <AreaInfoDescription>RECLAMATION AREAS</AreaInfoDescription>
          <ButtonsWrapper>
            {reclamationAreas.map((item) => (
              <Button
                minWidth={70}
                key={item.properties.id}
                onClick={() => navigateWithQuery(ROUTES.landCover, [])}
                variant="PRIMARY_SUPER_SMALL"
              >
                <AreaInfoButtonInnerWrapper>
                  <StyledTreeIcon /> {item.properties.name}
                </AreaInfoButtonInnerWrapper>
              </Button>
            ))}
          </ButtonsWrapper>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled(ScrollbarWrapper)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  position: relative;
  max-width: 300px;
  min-width: 120px;
`;

const StyledWaterIcon = styled(WaterdropIcon)`
  width: 7px;
  height: 9px;
`;

const StyledTreeIcon = styled(TreeIcon)`
  width: 7px;
  height: 9px;
`;

const ButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  row-gap: 8px;
  column-gap: 4px;
  flex-wrap: wrap;
  margin-top: 10px;
`;
