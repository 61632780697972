import { useQueries, useQuery } from '@tanstack/react-query';
import {
  INDICES_RESULTS_QUERY_KEY,
  MINERAL_DEPOSITS_QUERY_KEY,
  MINERALS_TYPES_QUERY_KEY,
  EXPLORATION_INITIAL_DATA_CACHE_TIME,
  EXPLORATION_INITIAL_DATA_STALE_TIME,
  MINERALS_RESULTS_QUERY_KEY,
  INDICATOR_DETAILS_QUERY_KEY,
  SPECTRAL_CLASSIFIERS_QUERY_KEY,
  SAMPLING_POINTS_QUERY_KEY,
  DEPOSIT_CLASSIFICATION_SITES_QUERY_KEY,
  INDICES_QUERY_KEY,
  CLUSTERS_DATA_SUPPLIERS_QUERY_PARAMETER,
  CLUSTERS_RESULTS_QUERY_PARAMETER,
  LINEAMENTS_QUERY_KEY,
  LINEAMENT_SHAPE_QUERY_KEY,
  LINEAMENT_DENSITY_RESULTS_QUERY_KEY,
  LINEAMENTS_RANGE_RESULTS_QUERY_KEY,
} from 'infrastructure/exploration/constants';
import { explorationAPI } from 'infrastructure/exploration/explorationApiAdapter';
import { localStorageService } from 'services/localStorageService';
import { DEFAULT_RESULTS_PER_PAGE } from 'common/constants/services';
import { isGeoJsonResultsArrayValid } from 'common/typeguards';
import { restAPI } from 'services/rest/restApiService';
import {
  areDepositsValid,
  areMineralsResultsValid,
  areMineralsValid,
  areDepositClassificationSitesValid,
  areProspectingTargetsValid,
  areSpectralClassifiersValid,
  isSamplingPointsCollectionValid,
  areClustersDataSuppliersValid,
  areClustersResultsValid,
  areLineamentDensityResultsValid,
  areLineamentsRangeResultsValid,
} from 'domain/exploration/typeguards';
import { withSwappedCoordinatesOrder } from 'domain/exploration/decorators';
import { PROSPECTING_TARGETS_LOCAL_STORAGE_KEY } from 'domain/exploration/constants';
import { type IndicesResultDTO, type AreaPropsectingSitesDTO, type IndicatorDetails } from 'domain/exploration/types';

export const explorationRepository = {
  useFetchDeposits: (limit: number = DEFAULT_RESULTS_PER_PAGE, offset?: number) => {
    const { data, isLoading } = useQuery(
      [
        MINERAL_DEPOSITS_QUERY_KEY,
        {
          limit,
          offset,
        },
      ],
      () => explorationAPI.getDeposits({ limit, offset }),
      {
        staleTime: EXPLORATION_INITIAL_DATA_STALE_TIME,
        cacheTime: EXPLORATION_INITIAL_DATA_CACHE_TIME,
      },
    );

    const deposits = areDepositsValid(data) ? data.data.results : [];

    return {
      deposits,
      areDepositsLoading: isLoading,
    };
  },

  useFetchMineralsTypes: (limit: number = DEFAULT_RESULTS_PER_PAGE, offset?: number) => {
    const { data, isLoading } = useQuery(
      [
        MINERALS_TYPES_QUERY_KEY,
        {
          limit,
          offset,
        },
      ],
      () => explorationAPI.getMinerals({ limit, offset }),
      {
        staleTime: EXPLORATION_INITIAL_DATA_STALE_TIME,
        cacheTime: EXPLORATION_INITIAL_DATA_CACHE_TIME,
      },
    );

    const minerals = areMineralsValid(data) ? data.data.results : [];

    return {
      minerals,
      areMineralsLoading: isLoading,
    };
  },

  useFetchSpectralClassifiers: (limit: number = DEFAULT_RESULTS_PER_PAGE, offset?: number) => {
    const { data, isLoading } = useQuery(
      [
        SPECTRAL_CLASSIFIERS_QUERY_KEY,
        {
          limit,
          offset,
        },
      ],
      () => explorationAPI.getSpectralClassifiers({ limit, offset }),
      {
        staleTime: EXPLORATION_INITIAL_DATA_STALE_TIME,
        cacheTime: EXPLORATION_INITIAL_DATA_CACHE_TIME,
      },
    );

    const spectralClassifiers = areSpectralClassifiersValid(data) ? data.data.results : [];

    return {
      spectralClassifiers,
      areSpectralClassifiersLoading: isLoading,
    };
  },

  useFetchMineralsResults: (
    areaId: number,
    classifierId?: string,
    limit: number = DEFAULT_RESULTS_PER_PAGE,
    offset?: number,
  ) => {
    const { data, isLoading } = useQuery(
      [
        MINERALS_RESULTS_QUERY_KEY,
        {
          areaId,
          classifierId,
          limit,
          offset,
        },
      ],
      () => explorationAPI.getMineralizationResults({ areaId, classifierId, limit, offset }),
    );

    const mineralsResults = areMineralsResultsValid(data) ? data.data.results : [];

    return {
      mineralsResults,
      areMineralsResultsLoading: isLoading,
    };
  },

  useFetchLineaments: (areaName: string) => {
    const { data, isLoading, error } = useQuery(
      [
        LINEAMENTS_QUERY_KEY,
        // {
        //   areaId,
        // },
      ],
      // () => areasAPI.getLineamentsShapes(Number(areaId)), // TODO: restore when the API is ready
      () => explorationAPI.getLineamentsShapes({}),
    );

    const shapesData = data?.data?.results;

    if (!isLoading && !shapesData) {
      console.log(`Unable to fetch lineaments shapes for AOI ${areaName}`);
    }

    if (Array.isArray(shapesData) && !isGeoJsonResultsArrayValid(shapesData)) {
      console.log(`Invalid lineaments shapes for AOI no. ${areaName}`);
    }

    const lineaments = isGeoJsonResultsArrayValid(shapesData)
      ? shapesData.filter(({ aoi_name }) => aoi_name === areaName)
      : [];

    return { lineaments, lineamentsLoading: isLoading, lineamentsError: error };
  },

  useFetchLineamentDensityResults: (areaName: string | undefined, lengthRange: string | null) => {
    const { data } = useQuery([LINEAMENT_DENSITY_RESULTS_QUERY_KEY, { limit: DEFAULT_RESULTS_PER_PAGE }], () =>
      explorationAPI.getLineamentDensityResults({ limit: DEFAULT_RESULTS_PER_PAGE }),
    );

    const response = data?.data?.results;
    const results = areLineamentDensityResultsValid(response) ? response : [];

    const densityResults = results.filter(
      ({ aoi_name, length_range }) => aoi_name === areaName && length_range === lengthRange,
    );

    return {
      densityResults,
    };
  },

  useFetchLineamentsRangeResults: (areaName: string | undefined) => {
    const { data } = useQuery([LINEAMENTS_RANGE_RESULTS_QUERY_KEY, { limit: DEFAULT_RESULTS_PER_PAGE }], () =>
      explorationAPI.getLineamentsRangeResults({ limit: DEFAULT_RESULTS_PER_PAGE }),
    );

    const response = data?.data?.results;
    const results = areLineamentsRangeResultsValid(response) ? response : [];

    const rangeResult = results.find(({ aoi_name }) => aoi_name === areaName);

    return {
      rangeResult,
    };
  },

  // TODO: switch to private endpoint when the API is ready
  useFetchGeoJsonShape: (id: number, url: string) => {
    const { data, isLoading, error } = useQuery([LINEAMENT_SHAPE_QUERY_KEY, { id }], () => restAPI.external(url));

    return { geoJsonShape: data?.data, geoJsonShapeLoading: isLoading, geoJsonShapeError: error };
  },

  useFetchIndices: (limit: number = DEFAULT_RESULTS_PER_PAGE, offset?: number) => {
    const { data, isLoading } = useQuery(
      [
        INDICES_QUERY_KEY,
        {
          limit,
          offset,
        },
      ],
      () => explorationAPI.getIndices({ limit, offset }),
    );

    return {
      indices: data?.data?.results || [],
      indicesLoading: isLoading,
    };
  },

  useFetchIndicesResults: (areaId: number, limit: number = DEFAULT_RESULTS_PER_PAGE, offset?: number) => {
    const { data, isLoading } = useQuery(
      [
        INDICES_RESULTS_QUERY_KEY,
        {
          areaId,
          limit,
          offset,
        },
      ],
      () => explorationAPI.getIndicesResults({ areaId, limit, offset }),
    );

    return {
      indicesResults: data?.data?.results || [],
      areIndicesResultsLoading: isLoading,
    };
  },

  useFetchIndicatorResultById: (indicatorId: number) => {
    const { data, isLoading } = useQuery(
      [
        INDICATOR_DETAILS_QUERY_KEY,
        {
          indicatorId: indicatorId,
        },
      ],
      () => explorationAPI.getIndicatorResultById(indicatorId),
    );

    return {
      indicatorDetails: data?.data || undefined,
      areIndicatorDetailsLoading: isLoading,
    };
  },

  useFetchIndicatorResultsByIds: (indicatorResults: IndicesResultDTO[]) => {
    const response = useQueries({
      queries: indicatorResults.map(({ id }) => ({
        queryKey: [
          INDICATOR_DETAILS_QUERY_KEY,
          {
            indicatorId: id,
          },
        ],
        queryFn: () => explorationAPI.getIndicatorResultById(id),
      })),
    });

    const results = response.map((item) => item.data?.data).filter((item): item is IndicatorDetails => !!item);

    return {
      results,
    };
  },

  useFetchDepositClassificationSites: (
    areaId: number,
    depositId: number,
    limit: number = DEFAULT_RESULTS_PER_PAGE,
    offset?: number,
  ) => {
    const { data, isLoading } = useQuery(
      [
        DEPOSIT_CLASSIFICATION_SITES_QUERY_KEY,
        {
          areaId,
          depositId,
          limit,
          offset,
        },
      ],
      () => explorationAPI.getDepositClassificationSites({ areaId, depositId, limit, offset }),
      {
        staleTime: EXPLORATION_INITIAL_DATA_STALE_TIME,
        cacheTime: EXPLORATION_INITIAL_DATA_CACHE_TIME,
      },
    );

    const results = data?.data?.results?.features || [];
    const sites = areDepositClassificationSitesValid(data) ? results : [];
    const depositClassificationSites = withSwappedCoordinatesOrder(sites);

    return {
      depositClassificationSites,
      depositClassificationSitesLoading: isLoading,
    };
  },

  useFetchSamplingPoints: (
    areaId: number,
    depositId: number,
    mineralId: number,
    limit: number = DEFAULT_RESULTS_PER_PAGE,
    offset?: number,
  ) => {
    const { data, isLoading, error } = useQuery(
      [
        SAMPLING_POINTS_QUERY_KEY,
        {
          areaId,
          depositId,
          mineralId,
          limit,
          offset,
        },
      ],
      () => explorationAPI.getSamplingPoints({ areaId, depositId, mineralId, limit, offset }),
    );

    const samplingPointsCollection = isSamplingPointsCollectionValid(data) ? data.data.results : undefined;

    return {
      samplingPointsCollection,
      samplingPointsLoading: isLoading,
      samplingPointsError: error,
    };
  },

  useProspectingTargets: () => {
    const targets = localStorageService.getValue(PROSPECTING_TARGETS_LOCAL_STORAGE_KEY) || {};
    const prospectingTargets = areProspectingTargetsValid(targets) ? targets : {};

    const setProspectingTargets = (targets: AreaPropsectingSitesDTO) => {
      localStorageService.setValue(PROSPECTING_TARGETS_LOCAL_STORAGE_KEY, targets);
    };

    return { prospectingTargets, setProspectingTargets };
  },

  useFetchClustersDataSuppliers: () => {
    const { data, isLoading } = useQuery(
      [CLUSTERS_DATA_SUPPLIERS_QUERY_PARAMETER, { limit: DEFAULT_RESULTS_PER_PAGE }],
      () => explorationAPI.getClustersDataSuppliers({ limit: DEFAULT_RESULTS_PER_PAGE }),
    );

    const response = data?.data?.results;
    const clustersSuppliers = areClustersDataSuppliersValid(response) ? response : [];

    return {
      clustersSuppliers,
      areClustersSuppliersLoading: isLoading,
    };
  },

  useFetchClustersResults: (areaName: string | undefined) => {
    const { data, isLoading } = useQuery([CLUSTERS_RESULTS_QUERY_PARAMETER, { limit: DEFAULT_RESULTS_PER_PAGE }], () =>
      explorationAPI.getClustersResults({ limit: DEFAULT_RESULTS_PER_PAGE }),
    );

    const response = data?.data?.results;
    const results = areClustersResultsValid(response) ? response : [];

    const clustersResults = results.filter(({ aoi_name }) => aoi_name === areaName);

    return {
      clustersResults,
      areClustersResultsLoading: isLoading,
    };
  },
};
