import { useState } from 'react';
import { useMap } from 'react-leaflet';

import { type TooltipData } from 'common/types/mapData';
import { debounce } from 'common/utils';
import { TOOLTIP_DISPLAY_DELAY } from 'common/constants';
import { buildSingleViewTooltipStringHelper, checkIsOutsideBounds } from 'common/utils/map';
import { MapTooltip } from 'ui/map/tooltip';
import { titilerApiService } from 'services/titiler/titilerApiService';
import { titilerInfoService } from 'services/titiler/titilerInfoService';
import { getBoundsFromCogInfo } from 'services/titiler/utils';

interface SingleLayerValueTitilerTooltipProps {
  url: string;
  unit: string;
  precision: number;
}

export const SingleLayerClickToValueTitilerTooltip = ({
  url,
  unit,
  precision,
}: SingleLayerValueTitilerTooltipProps) => {
  const [tooltip, setTooltip] = useState<TooltipData | undefined>();

  const map = useMap();

  const { info } = titilerInfoService.useGetInfoGeneric(url);
  const bounds = getBoundsFromCogInfo(info);

  map.off('click');

  map.on(
    'click',
    debounce(async (event: { originalEvent: PointerEvent }) => {
      const latLng = await map.mouseEventToLatLng(event.originalEvent);

      if (!url || checkIsOutsideBounds(latLng, bounds)) {
        return;
      }

      const info = await titilerApiService.getPointInfoGeneric(url, latLng?.lng, latLng?.lat);

      const values = info?.values;

      if (info.detail === 'Point is outside dataset bounds') {
        setTooltip(undefined);
      }

      if (values && Array.isArray(values) && values.length > 0) {
        values.forEach((value: number) => {
          if (value > 0) {
            setTooltip({
              id: 500,
              lat: latLng?.lat,
              lng: latLng?.lng,
              value: `${value.toFixed(precision)} ${unit}`,
            });
          }
        });
      }
    }, TOOLTIP_DISPLAY_DELAY),
  );

  return tooltip ? <MapTooltip tooltipData={tooltip} content={buildSingleViewTooltipStringHelper(tooltip)} /> : null;
};
