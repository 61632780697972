import { PUBLIC_CONTAINER_URL } from 'common/constants/services';

export const WATER_API_URLS = {
  getWaterbodies: '/water/waterbodies/',
  getDates: '/water/results/dates/',

  getQualityIndicators: '/water/indicators/',
  getQualityResults: '/water/results/',
  getQualityCog: '/water/results/:resultId/get_cog/',

  getAreaResults: '/water/areas/',
  getAreaCog: '/water/areas/:resultId/get_cog/',
  getAreaComparatorCog: '/water/areas-comparator/get_cog/',

  getWaterbodyDefaultView: '/water/waterbodies/:waterbodyId/get_default_view/',
  getPointHistory: '/water/point-history/',
  getAreaHistory: '/water/area-history/',

  getMoistureIndicators: `${PUBLIC_CONTAINER_URL}/moisture/moisture_indicators.json`,
  getMoistureResults: `${PUBLIC_CONTAINER_URL}/moisture/moisture_results.json`,

  getSnowCoverIndicators: `${PUBLIC_CONTAINER_URL}/snow/indicators.json`,
  getSnowCoverResults: `${PUBLIC_CONTAINER_URL}/snow/results.json`,
};
