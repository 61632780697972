import { useQueries } from '@tanstack/react-query';
import { AxiosError } from 'axios';

import { waterApi } from 'infrastructure/water/waterApiAdapter';
import { titilerInfoService } from 'services/titiler/titilerInfoService';
import { getBoundsFromCogInfo } from 'services/titiler/utils';
import { useHandleWarningErrorUseCase } from 'views/@errors/handleTypicalWarningErrorUseCase';
import { WATER_AREA_COG_QUERY_KEY } from 'domain/water/constants';
import { WarningErrorType } from 'domain/@errors/enums';
import { useGetWaterAreaResultsUseCase } from './getWaterAreaResultsUseCase';

export const useWaterAreaDataService = () => {
  const { waterAreaResults, waterAreaResultsLoading } = useGetWaterAreaResultsUseCase();

  const waterAreaCogsResponse = useQueries({
    queries: waterAreaResults.map(({ id }) => ({
      queryKey: [WATER_AREA_COG_QUERY_KEY, { waterAreaResultId: id }],
      queryFn: () => waterApi.getWaterAreaCog(id),
    })),
  });

  const error = waterAreaCogsResponse.every((result) => result.isError)
    ? waterAreaCogsResponse.find((result) => result.error instanceof AxiosError)?.error
    : undefined;

  const isSuccess =
    waterAreaCogsResponse.every((result) => result.isSuccess) &&
    waterAreaCogsResponse.every((result) => !result.data?.data.url) &&
    !!waterAreaCogsResponse.length;

  useHandleWarningErrorUseCase(WarningErrorType.WATER_AREA_COGS, undefined, error, isSuccess);

  const cogResultUrls = waterAreaCogsResponse.map((item) => ({
    url: item.data?.data.url || '',
    id: item.data?.data.id as number,
  }));

  const results = titilerInfoService.useGetArrayInfo(cogResultUrls || []);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cogBounds = results.map((result: any) => {
    const bounds = getBoundsFromCogInfo(result.data?.geotiffInfo);
    return { bounds, id: result.data?.id };
  });

  const cogsUrlWithBounds = cogResultUrls.map((url) => ({
    ...url,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    bounds: cogBounds.find((bounds: any) => bounds.id === url.id)?.bounds,
    waterbodyId: waterAreaResults.find((area) => area.id === url.id)?.waterbody_id,
  }));

  const cogsLoading = waterAreaCogsResponse.some((item) => item.isLoading) || waterAreaResultsLoading;

  return {
    cogsUrlWithBounds,
    cogsLoading,
  };
};
