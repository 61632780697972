import { useAoiNumber } from 'common/navigation/hooks';
import { LayerManagerControlButton } from 'components/layerManager/LayerManagerControlButton';
import { ShareModalControlButton } from 'ui/organisms/shareModal/ShareModalControlButton';
import { ColorScale } from 'ui/colorScale';
import { Select } from 'ui/atoms';
import { ReturnButton } from 'ui/molecules/returnButton/ReturnButton';
import { useWaterUseCases } from 'views/water/useCases';
import { useWaterbodySelectService, useWaterIndicatorsSelectService } from 'views/water/services';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { AreaDetailsLayout } from 'views/areas/areaDetails/AreaDetailsLayout';
import { WaterQualityTimeline } from './WaterQualityTimeline';
import { useWaterIndicators } from './useWaterIndicators';
import { useWaterQualityLifeCycleUseCase } from './waterQualityLifeCycleUseCase';
import { WaterQualityInfoArea } from './WaterQualityInfoArea';
import { WaterQualityLayerManager } from './WaterQualityLayerManager';
import { useHandleWaterIndicatorChangeUseCase } from './handleWaterIndicatorChangeUseCase';

export const WaterQuality = () => {
  const areaId = useAoiNumber();

  const { waterbodySelectData } = useWaterbodySelectService(areaId);
  const { changeWaterbody, resetWaterbody } = useWaterUseCases();

  const { selectedIndicator } = useWaterIndicators();
  const { indicatorsSelectData } = useWaterIndicatorsSelectService();
  const { changeWaterIndicator } = useHandleWaterIndicatorChangeUseCase();

  const areReportsActive = useGetAreReportsActiveUseCase();

  useWaterQualityLifeCycleUseCase(waterbodySelectData.selectedOption?.value);

  return (
    <AreaDetailsLayout
      returnButton={
        <ReturnButton
          label="Back to all waterbodies"
          visible={!!waterbodySelectData.selectedOption && !areReportsActive}
          onClick={resetWaterbody}
        />
      }
      legend={
        selectedIndicator && (
          <ColorScale
            colormap={selectedIndicator.colormap}
            labels={selectedIndicator.labels}
            unit={selectedIndicator.unit}
            segmented={true}
          />
        )
      }
      toolbarContent={
        <>
          <WaterQualityInfoArea waterbodyId={waterbodySelectData.selectedOption?.value} />
          {!areReportsActive && (
            <>
              <Select {...waterbodySelectData} onChange={changeWaterbody} reset={resetWaterbody} />
              <Select {...indicatorsSelectData} onChange={changeWaterIndicator} />
            </>
          )}
        </>
      }
      toolboxContent={
        <>
          {!areReportsActive && <LayerManagerControlButton />}
          {!areReportsActive && <ShareModalControlButton />}
        </>
      }
    >
      {!areReportsActive && <WaterQualityLayerManager selectedIndicator={selectedIndicator} />}
      <WaterQualityTimeline
        waterbodyLabel={waterbodySelectData.selectedOption?.label}
        indicatorsLabel={selectedIndicator?.indicator_name}
        indicatorsUnit={selectedIndicator?.unit}
      />
    </AreaDetailsLayout>
  );
};
