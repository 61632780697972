/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from 'styled-components';

import { ReactComponent as HeroIcon } from 'ui/assets/terraeye-logo-small.svg';
import { type ActiveSubscription } from 'domain/subscription/types';
import { useGetAvailableQuota } from '../useGetAvailableQuota';

type SubscriptionPreviewVariant = 'SMALL' | 'DEFAULT' | 'EXTENDED';

interface SubscriptionPreviewProps {
  variant: SubscriptionPreviewVariant;
  subscription: ActiveSubscription;
  isWaiting?: boolean;
  info?: string;
}

export const SubscriptionPreview = ({ variant, subscription, isWaiting, info }: SubscriptionPreviewProps) => {
  const { capacity, name, price, expires_at } = subscription;

  const features = info ? info.split('\n').map((feature) => feature.slice(1)) : [];

  const activeSubscriptionCapacity = capacity * 1_000_000;
  const availableQuota = useGetAvailableQuota(activeSubscriptionCapacity);

  return (
    <Item disabled={isWaiting}>
      <Wrapper large={variant !== 'SMALL'}>
        <ProductIconWrapper large={variant !== 'SMALL'}>
          <HeroIcon />
        </ProductIconWrapper>
        <div>
          <Title large={variant !== 'SMALL'}>{name}</Title>
          <Content large={variant !== 'SMALL'}>Points: {capacity}</Content>
        </div>
      </Wrapper>
      {<Title>${price.toFixed(2)} / Month</Title>}
      {expires_at && <Content>{`Subscription active till ${new Date(expires_at).toLocaleDateString()}`}</Content>}
      {availableQuota !== undefined && ['SMALL', 'EXTENDED'].includes(variant) ? (
        <SurfaceCount>
          Remaining points{variant === 'EXTENDED' ? ' for this month' : ''}: {availableQuota.toFixed()}
        </SurfaceCount>
      ) : undefined}
      {info && (
        <ListWrapper>
          <Title>Features:</Title>
          <FeaturesList>
            {features.map((feature) => (
              <li key={feature}>{feature}</li>
            ))}
          </FeaturesList>
        </ListWrapper>
      )}
    </Item>
  );
};

const Item = styled.div<{ disabled?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  opacity: ${({ disabled }) => (disabled ? 0.4 : 1)};
`;

const ProductIconWrapper = styled.div<{ large?: boolean }>`
  width: ${({ large }) => (large ? 80 : 36)}px;
  height: ${({ large }) => (large ? 80 : 36)}px;
  color: ${({ theme }) => theme.color.primary};
  background-color: ${({ theme }) => theme.color.white};
  padding: ${({ large }) => (large ? 15 : 6)}px;
  border-radius: 4px;
`;

const Wrapper = styled.div<{ large?: boolean }>`
  display: flex;
  gap: 8px;
  margin-bottom: ${({ large }) => (large ? 28 : 16)}px;
`;

const Title = styled.h4<{ large?: boolean }>`
  font-family: ${({ large }) => (large ? 'Barlow Condensed' : 'Barlow')};
  font-size: ${({ large }) => (large ? 28 : 14)}px;
  line-height: ${({ large }) => (large ? 30 : 18)}px;
  font-weight: 600;
`;

const Content = styled.p<{ large?: boolean }>`
  margin: 0;
  font-family: ${({ large }) => (large ? 'Barlow Condensed' : 'Barlow')};
  font-size: ${({ large }) => (large ? 28 : 14)}px;
  line-height: ${({ large }) => (large ? 30 : 18)}px;
  font-weight: 600;
  opacity: 0.6;
`;

const SurfaceCount = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  margin: 0;
  margin-top: 24px;
`;

const ListWrapper = styled.p`
  margin: 28px 0 0;
  max-width: 280px;
`;

const FeaturesList = styled.ul`
  font-family: 'Barlow', sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  padding-left: 20px;
  margin: 0;

  @media (max-width: ${({ theme }) => theme.breakpoint.lg}px) {
    font-size: 14px;
    line-height: 18px;
  }
`;
