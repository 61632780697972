import { useMemo } from 'react';

import { buildGradientColormapArray } from 'common/utils/color';
import { getLayerNameWithAoiId } from 'common/utils';
import { useLayerOrderStore } from 'components';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { useSortedLayersData } from 'components/layerManager/useSortedLayersData';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { type ExtendedIndicesResult, type IndicesLayer } from 'domain/exploration/types';
import { useIndicesLayersVisibility } from './useIndicesLayersVisibility';
import { ExplorationMapLayer } from '../ExplorationMapLayer';

interface IndicesMapContentProps {
  areaId: number;
  indicesResults: ExtendedIndicesResult[];
  indexOffset?: number;
}

export const IndicesMapContent = ({ areaId, indicesResults, indexOffset = 0 }: IndicesMapContentProps) => {
  const layersTresholds = useLayerManagerStore.use.layersTresholdsValues();
  const { classesVisibility: layersVisibility } = useIndicesLayersVisibility();
  const { layersOrderRecord } = useLayerOrderStore();

  const layers = useMemo(
    () =>
      indicesResults
        ? indicesResults
            .map(({ id, name, url, colormap }) => {
              const layerName = getLayerNameWithAoiId(name, areaId);
              const threshold = layersTresholds[layerName];

              if (Array.isArray(threshold) && threshold.length === 2) {
                const expression = `((b1>=${threshold[0]})&(b1<=${threshold[1]}))*b1`;
                const colormapArray = buildGradientColormapArray(colormap, threshold[0], threshold[1]);

                return {
                  id,
                  name,
                  url,
                  params: { expression, colormap: JSON.stringify(colormapArray) },
                };
              }

              return null;
            })
            .filter((item): item is IndicesLayer => !!item)
        : [],
    [indicesResults, layersTresholds, areaId],
  );

  const sortedIndices = useSortedLayersData(layersOrderRecord, layers, 'id');

  return (
    <>
      {sortedIndices.map((layer, index) => (
        <ExplorationMapLayer
          key={layer.id}
          {...layer}
          index={index + indexOffset}
          builder={titilerApiAdapter.buildMonocolorCogUrl}
          opacity={1}
          isVisible={!!layersVisibility[layer.name]}
        />
      ))}
    </>
  );
};
