import { type AxiosResponse } from 'axios';

import { type ShapeResultsApiResponse, type CogResult } from 'common/types/api';
import { getApiData, getExternalData } from 'services/rest/restApiService';
import {
  type AreaOfInterestApiResponse,
  type AreaOfInterestDetails,
  type AreaOfInterestTopographyLayersApiResponse,
} from 'domain/areas/types';
import { AREAS_API_URLS } from './areasApiUrls';

export const areasAPI = {
  getAreasOfInterest: ({
    limit,
    offset,
  }: {
    limit: number | undefined;
    offset: number | undefined;
  }): Promise<AxiosResponse<AreaOfInterestApiResponse>> =>
    getApiData(AREAS_API_URLS.getAreasOfInterest, { limit, offset }),

  getAreaOfInterestById: async (areaId: number | undefined): Promise<AxiosResponse<AreaOfInterestDetails>> =>
    getApiData(AREAS_API_URLS.getAreaOfInterestById.replace(':areaId', String(areaId)), {}),

  getLatestAreaOfInterestSatellite: async (areaId: number): Promise<AxiosResponse<CogResult>> =>
    getApiData(AREAS_API_URLS.getAreaOfInterestSatellite.replace(':areaId', String(areaId)), {}),

  getAreaOfInterestSatelliteByDate: async (
    areaId: number,
    { img_date }: { img_date?: string },
  ): Promise<AxiosResponse<CogResult>> =>
    getApiData(AREAS_API_URLS.getAreaOfInterestSatellite.replace(':areaId', String(areaId)), { img_date }),

  getAreaOfInterestShapes: async ({ area_id }: { area_id?: number }): Promise<AxiosResponse<ShapeResultsApiResponse>> =>
    getExternalData(
      // return await restAPI.private.get(AREAS_API_URLS.getAreaOfInterestShapes, // TODO: restore when the API is ready
      AREAS_API_URLS.getAreaOfInterestShapes,
      { area_id },
    ),

  getTopographyLayers: async ({
    area_id,
  }: {
    area_id?: number;
  }): Promise<AxiosResponse<AreaOfInterestTopographyLayersApiResponse>> =>
    getExternalData(
      // return await restAPI.private.get(AREAS_API_URLS.getTopographyLayers, // TODO: restore when the API is ready
      AREAS_API_URLS.getTopographyLayers,
      { area_id },
    ),
};
