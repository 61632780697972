import { type ReactNode } from 'react';
import styled from 'styled-components';

export const TitleBodyTooltip = ({ title, body }: { title: string; body: string | ReactNode }) => {
  return (
    <Wrapper>
      <Title>{title}</Title>
      {body}
    </Wrapper>
  );
};

const Title = styled.h4`
  font-weight: 600;
`;

const Wrapper = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: ${({ theme }) => theme.color.white};
`;
