import { type ReactNode } from 'react';
import styled from 'styled-components';

import { InfoPoint } from 'ui';

interface SectionLayoutProps {
  title: string;
  description: string;
  children: ReactNode;
}

export const SectionLayout = ({ title, description, children }: SectionLayoutProps) => {
  return (
    <section>
      <Header>
        <Title>{title}</Title>
        <InfoPoint name={title} tooltipContent={description} />
      </Header>
      {children}
    </section>
  );
};

const Header = styled.header`
  display: flex;
  align-items: center;
  gap: 12px;
  border-bottom: 1px solid ${({ theme }) => theme.color.whiteTransparent30};
  padding-bottom: 12px;
  margin: 12px 0;
`;

const Title = styled.h4`
  font-family: 'Barlow', sans-serif;
  text-transform: capitalize;
  font-size: 12px;
  line-height: 1.5;
  font-weight: 600;
`;
