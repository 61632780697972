import { COMPARISON_OPTION_NAME, TIMELINE_OPTION_NAME } from 'common/navigation/constants';
import { Select } from 'ui/atoms';
import { greeneryControlsService } from 'views/greenery/services';
import { useGreeneryTimestamps } from 'views/greenery/useGreeneryTimestamps';
import { useGreeneryViewOption } from 'views/greenery/useGreeneryViewOption';

export const SegmentationViewOptionsSelect = () => {
  const { timestamps } = useGreeneryTimestamps();
  const { viewQuery, handleViewOptionChange, handleViewOptionReset } = useGreeneryViewOption();

  const { viewOptionsSelectData } = greeneryControlsService.useViewOptions(
    {
      options: [
        {
          value: COMPARISON_OPTION_NAME,
          label: 'comparison',
          disabled: timestamps.length <= 1,
        },
      ],
      defaultOption: TIMELINE_OPTION_NAME,
    },
    viewQuery,
  );

  return (
    <Select {...viewOptionsSelectData} onChange={handleViewOptionChange} reset={handleViewOptionReset} minWidth={150} />
  );
};
