import styled, { css } from 'styled-components';

export const FoldableSectionHeader = styled.div<{ disabled?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: stretch;
  gap: 10px;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
  padding: 10px 0;

  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;
