import { useEffect } from 'react';

import { useAoiNumber } from 'common/navigation/hooks';
import { waterRepository } from 'infrastructure/water/waterRepository';
import { type PointArray } from 'common/types/mapData';
import { type WaterbodyType } from 'domain/monitoring/types';
import { useMonitoringInfoStore } from './store';

const mockedGeometry: [PointArray, PointArray][] = [
  [
    [-33.42668858672478, 148.98349453473253],
    [-33.4328650328748, 148.99509929247944],
  ],
  [
    [-33.45108859460967, 148.986989940504],
    [-33.46192148935656, 149.00079267735453],
  ],
  [
    [-33.4742730970615, 149.012170206843],
    [-33.48763327263079, 149.0255179084127],
  ],
];

export const useWaterbodiesMock = () => {
  const areaId = useAoiNumber();

  const setWaterbodies = useMonitoringInfoStore.use.setWaterbodies();
  const { waterBodiesList: data } = waterRepository.useFetchWaterbodies(areaId);

  useEffect(() => {
    if (areaId !== 364 || !data || !data.length) return;

    const mock: WaterbodyType[] = data.map((result, i) => ({
      type: 'Feature',
      properties: {
        id: result.id,
        name: result.name,
      },
      geometry: {
        type: 'MultiLineString',
        coordinates: mockedGeometry[i],
      },
    }));

    setWaterbodies(mock);

    return () => {
      setWaterbodies(undefined);
    };
  }, [areaId, setWaterbodies, data]);
};
