import chroma from 'chroma-js';

import { useLayerOrderStore } from 'components';
import { useSortedLayersData } from 'components/layerManager/useSortedLayersData';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { type SurfaceMineralsDisplayed } from 'domain/exploration/types';
import { useMlLayersVisibility } from './useSurfaceLayersVisibility';
import { ExplorationMapLayer } from '../ExplorationMapLayer';

interface MlMineralsMapLayersProps {
  areaId: number;
  minerals: SurfaceMineralsDisplayed[];
  indexOffset?: number;
}

export const MlMineralsMapContent = ({ areaId, minerals, indexOffset = 0 }: MlMineralsMapLayersProps) => {
  const { layersOrderRecord } = useLayerOrderStore();
  const sortedMinerals = useSortedLayersData(layersOrderRecord, minerals, 'mineral_id');

  const { classesVisibility: layersVisibility } = useMlLayersVisibility();

  return (
    <>
      {sortedMinerals.map(({ id, name, url, color }, index) => (
        <ExplorationMapLayer
          key={id}
          index={index + indexOffset}
          {...{ areaId, url, color }}
          name={`ml_${name}`}
          builder={titilerApiAdapter.buildMonocolorFloatCogUrl}
          params={{ url, color: chroma(color).hex() }}
          isVisible={layersVisibility[name] || false}
        />
      ))}
    </>
  );
};
