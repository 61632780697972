import { useState } from 'react';
import styled, { css } from 'styled-components';

import { HideShowButton } from 'ui/atoms/hideShowButton';
import { StatisticsDrawerContent } from './StatisticsDrawerContent';

export const Statistics = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  return (
    <DrawerWrapper isOpen={isDrawerOpen}>
      <HideShowButtonWrapper>
        <HideShowButton
          isShown={isDrawerOpen}
          showLabel="show area information"
          hideLabel="hide area information"
          onClick={() => setIsDrawerOpen(!isDrawerOpen)}
        />
      </HideShowButtonWrapper>
      <StatisticsDrawerContent />
    </DrawerWrapper>
  );
};

const DrawerWrapper = styled.div<{ isOpen: boolean }>`
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1200;
  padding: 18px;
  background: ${({ theme }) => theme.color.blackTransparent90};
  font-size: 14px;
  font-weight: 400;
  font-family: 'Barlow';
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  line-height: 1.5;
  gap: 40px;
  bottom: 0;
  max-height: 90vw;
  transition: transform ${({ theme }) => theme.transitionFunction.main};

  ${({ isOpen }) =>
    !isOpen &&
    css`
      transform: translateY(100%);
    `}
`;

const HideShowButtonWrapper = styled.div`
  position: absolute;
  top: -40px;
  right: 10px;
`;
