import { useAoiNumber } from 'common/navigation/hooks';
import { useGetExtentPolygon } from 'common/hooks/getExtentPolygon';
import { waterRepository } from 'infrastructure/water/waterRepository';
import { useGraphStore } from 'components/graph/graphStore';

export const useWaterAreaSelectUseCase = (waterbodyIdQuery: string | null) => {
  const areaId = useAoiNumber();

  const graphData = useGraphStore.use.graphData();
  const resetGraphStore = useGraphStore.use.resetGraphStore();

  const standarizedExtentPolygon = useGetExtentPolygon(areaId);

  const setChosenPoint = useGraphStore.use.setChosenPoint();

  const { fetchWaterAreaHistory, isHistoryLoading } = waterRepository.useFetchWaterAreaHistory();

  const selectWaterArea = (lat: number, lng: number, value: number) => {
    if (graphData && !waterbodyIdQuery) {
      resetGraphStore();
    }

    if (standarizedExtentPolygon && waterbodyIdQuery) {
      fetchWaterAreaHistory(Number(waterbodyIdQuery));

      setChosenPoint({ lat, lng, value });
    }

    return () => {
      resetGraphStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  };

  return { isHistoryLoading, selectWaterArea };
};
