/* eslint-disable @typescript-eslint/no-explicit-any */
import { type Feature } from 'geojson';

import { AreaTypes, AreaEnvironments } from 'common/navigation/constants';
import { type AreaType, type AreaEnvironmentsTypes } from 'common/navigation/types';
import { AreaCardStatuses, AreaStatuses } from 'domain/areas/constants';
import {
  type InsertedAoiResponse,
  type AreaCardStatus,
  type AreaStatus,
  type AoiData,
  type AreaOfInterestTopographyLayerDto,
} from 'domain/areas/types';

export const isAreaCardStatusValid = (areaCardStatus: any): areaCardStatus is AreaCardStatus => {
  return Object.values(AreaCardStatuses).includes(areaCardStatus);
};

export const isAreaStatusValid = (areaStatus: any): areaStatus is AreaStatus => {
  return Object.values(AreaStatuses).includes(areaStatus);
};

export const isAreaTypeValid = (areaType: any): areaType is AreaType => {
  return Object.values(AreaTypes).includes(areaType);
};

export const isAreaEnvironmentValid = (areaEnvironment: any): areaEnvironment is AreaEnvironmentsTypes => {
  return Object.values(AreaEnvironments).includes(areaEnvironment);
};

export const isInsertedAoiResponseValid = (data: any): data is InsertedAoiResponse => {
  return data?.data?.insertAoi?.id && typeof data.data.insertAoi.id === 'number';
};

export const isFeatureValid = (feature: any): feature is Feature => {
  return (
    feature && typeof feature?.type === 'string' && !!feature?.properties && typeof feature?.geometry?.type === 'string'
  );
};

export const isAoiDataValid = (data: any): data is AoiData => {
  return (
    data &&
    typeof data.source === 'string' &&
    typeof data.name === 'string' &&
    typeof data.display_name === 'string' &&
    typeof data.user_id === 'number' &&
    typeof data.aoi_type === 'string' &&
    data.payload &&
    isFeatureValid(data.payload)
  );
};

export const isAreaOfInterestTopographyValid = (data: any): data is AreaOfInterestTopographyLayerDto => {
  return (
    data &&
    typeof data.id === 'number' &&
    typeof data.aoi_name === 'string' &&
    typeof data.label_name === 'string' &&
    typeof data.name === 'string' &&
    typeof data.hillshade_url === 'string' &&
    typeof data.topography_url === 'string' &&
    typeof data.topography_min === 'number' &&
    typeof data.topography_max === 'number'
  );
};

export const isAreaOfInterestTopographyArrayValid = (data: any): data is AreaOfInterestTopographyLayerDto[] => {
  return Array.isArray(data) && data.some((item) => isAreaOfInterestTopographyValid(item));
};
