import { type ReactNode } from 'react';
import MDEditor from '@uiw/react-md-editor';
import styled, { css } from 'styled-components';

import { type DisplayableMapPoint } from 'common/types/mapData';
import { HideShowButton } from 'ui/atoms/hideShowButton';
import { useOverviewInfoStore } from 'views/prospecting/store';
import { ScrollbarWrapper } from 'ui/atoms/scrollbarWrapper';

interface OverviewInfoProps {
  header: ReactNode;
  location: DisplayableMapPoint;
  description: string;
  middleColumn: ReactNode;
  children: ReactNode;
  onDescriptionSubmit: (value: string) => void;
}

export const AreaInfoPanel = ({ header, location, description, children }: OverviewInfoProps) => {
  const isAreaInfoOpen = useOverviewInfoStore.use.isAreaInfoOpen();
  const setIsAreaInfoOpen = useOverviewInfoStore.use.setIsAreaInfoOpen();

  return (
    <AreaInfoWrapper isOpen={isAreaInfoOpen}>
      <HideShowButtonWrapper>
        <HideShowButton
          isShown={isAreaInfoOpen}
          showLabel="show info"
          hideLabel="hide info"
          onClick={() => setIsAreaInfoOpen(!isAreaInfoOpen)}
        />
      </HideShowButtonWrapper>
      <LeftColumnWrapper>
        {header}
        <DescriptionWrapper>
          <LeftInnerColumnWrapper>
            <div>Location: {`${location.lat}, ${location.lng}`}</div>
            <br />
            {description && (
              <AreaDescription>
                <StyledMarkdownEditor source={description} />
              </AreaDescription>
            )}
          </LeftInnerColumnWrapper>
        </DescriptionWrapper>
      </LeftColumnWrapper>

      {children}
    </AreaInfoWrapper>
  );
};

const AreaInfoWrapper = styled.div<{ isOpen: boolean }>`
  backdrop-filter: blur(15px);
  position: absolute;
  left: 0;
  right: 0;
  z-index: 101;
  padding: 30px;
  padding-right: 0px;
  background: ${({ theme }) => theme.color.grayTransparent90};
  font-size: 14px;
  font-weight: 400;
  font-family: 'Barlow';
  display: grid;
  grid-template-columns: repeat(2, auto);
  justify-content: space-between;
  line-height: 1.5;
  gap: 40px;
  bottom: 0;
  max-height: 320px;
  transition: transform ${({ theme }) => theme.transitionFunction.main};

  ${({ isOpen }) =>
    !isOpen &&
    css`
      transform: translateY(100%);
    `}
`;

const HideShowButtonWrapper = styled.div`
  position: absolute;
  top: -40px;
  right: 10px;
`;

const DescriptionWrapper = styled.div`
  margin-top: 10px;
  display: flex;
  gap: 60px;
  flex-grow: 1;
`;

const LeftColumnWrapper = styled(ScrollbarWrapper)`
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: auto;

  ::-webkit-scrollbar {
    height: 6px;
  }
`;

const LeftInnerColumnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-width: fit-content;
`;

const AreaDescription = styled(ScrollbarWrapper)`
  max-height: 190px;
`;

const StyledMarkdownEditor = styled(MDEditor.Markdown)`
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  background-color: inherit;

  strong {
    font-weight: 800;
  }
`;
