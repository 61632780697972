import { useState } from 'react';
import { useMap } from 'react-leaflet';

import { type LayersVisibilityDictionary } from 'common/types';
import { type TooltipData, type LayerObject, type LeafletBounds } from 'common/types/mapData';
import { debounce } from 'common/utils';
import { TOOLTIP_DISPLAY_DELAY } from 'common/constants';
import { titilerApiService } from 'services/titiler/titilerApiService';
import { MapTooltip } from 'ui/map/tooltip';
import { checkIsOutsideBounds } from 'common/utils/map';
import { buildGreeneryLabelTooltipStringHelper } from 'domain/greenery/helpers';

interface SegmentationTitilerTooltipProps {
  greeneryObjects: LayerObject[];
  layersVisibility: LayersVisibilityDictionary;
  cogUrl: string;
  cogBounds: LeafletBounds;
  resultId: number;
  cogInfo: unknown;
}

export const SegmentationTitilerTooltip = ({
  greeneryObjects,
  layersVisibility,
  cogUrl,
  cogBounds,
  resultId,
}: SegmentationTitilerTooltipProps) => {
  const [tooltip, setTooltip] = useState<TooltipData | undefined>();

  const map = useMap();

  map.off('click');
  map.off('mousemove');

  map.on(
    'mousemove',
    debounce(async (event: { originalEvent: PointerEvent }) => {
      const latLng = await map.mouseEventToLatLng(event.originalEvent);

      if (!resultId || !cogUrl || checkIsOutsideBounds(latLng, cogBounds)) {
        return;
      }

      const info = await titilerApiService.getPointInfo(cogUrl, latLng?.lng, latLng?.lat);

      const values = info?.values;

      if (info.detail === 'Point is outside dataset bounds') {
        setTooltip(undefined);
      }

      if (values && Array.isArray(values) && values.length > 0) {
        values.forEach((value: number, index: number) => {
          if (value > 0) {
            const currentLayer = greeneryObjects.filter(({ id }) => id === index + 1)[0];
            const labelName = currentLayer?.label_name;
            const name = currentLayer?.name;

            layersVisibility[name] === true &&
              setTooltip({
                id: resultId,
                lat: latLng?.lat,
                lng: latLng?.lng,
                value: value,
                label: labelName,
              });
          }
        });
      }
    }, TOOLTIP_DISPLAY_DELAY),
  );

  return tooltip ? <MapTooltip tooltipData={tooltip} content={buildGreeneryLabelTooltipStringHelper(tooltip)} /> : null;
};
