import { useEffect } from 'react';

import { AreaEnvironments } from 'common/navigation/constants';
import { useAoiNumber } from 'common/navigation/hooks';
import { ROUTES } from 'common/navigation/routes';
import { useOverridableFeatureFlag } from 'common/hooks/useOverridableFeatureFlag';
import { useMonitoringInfoStore } from 'views/monitoring/store';
import { type NotificationsMockInterface } from 'domain/monitoring/types';

export const useNotificationsMock = () => {
  const enableNotifications = useOverridableFeatureFlag('enableNotifications', false, 'REACT_APP_ENABLE_NOTIFICATIONS');

  const areaId = useAoiNumber();
  const setNotifications = useMonitoringInfoStore.use.setNotifications();

  useEffect(() => {
    if (areaId !== 364 || !enableNotifications) return;

    const mock: NotificationsMockInterface = {
      type: 'FeatureCollection',
      properties: {
        notifications: [
          {
            type: 'Feature',
            properties: {
              id: 0,
              view: AreaEnvironments.DISPLACEMENT,
              route: ROUTES.subsidence.replace(':areaId', String(areaId)),
              text: 'Fast ground elevation change',
              danger: true,
            },
            geometry: {
              type: 'Point',
              coordinates: [-33.492424166406465, 148.97969454381962],
            },
          },
          {
            type: 'Feature',
            properties: {
              id: 1,
              route: `${ROUTES.vegetation.replace(':areaId', String(areaId))}?greenery-indicator=1`,
              view: AreaEnvironments.LAND_COVER,
              text: 'Drop in NDVI Value',
            },
            geometry: {
              type: 'Point',
              coordinates: [-33.452578975571036, 149.02732044941436],
            },
          },
          {
            type: 'Feature',
            properties: {
              id: 2,
              route: `${ROUTES.vegetation.replace(':areaId', String(areaId))}?greenery-indicator=2`,
              view: AreaEnvironments.LAND_COVER,
              text: 'Drop in NDII Value',
            },
            geometry: {
              type: 'Point',
              coordinates: [-33.49553467455859, 149.0276502640967],
            },
          },
          {
            type: 'Feature',
            properties: {
              id: 3,
              route: ROUTES.waterQuality.replace(':areaId', String(areaId)),
              view: AreaEnvironments.WATER,
              text: 'Water quality loss in xxx Value',
            },
            geometry: {
              type: 'Point',
              coordinates: [-33.48286974190819, 149.01843184360504],
            },
          },
        ],
      },
    };

    setNotifications(mock);

    return () => {
      setNotifications(undefined);
    };
  }, [areaId, enableNotifications, setNotifications]);
};
