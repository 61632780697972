import { type UrlWithNameAndLabel } from 'services/titiler/types';
import { explorationRepository } from 'infrastructure/exploration/explorationRepository';
import { type SurfaceMineralsDisplayed, type SurfaceMinerals } from 'domain/exploration/types';
import { useGetSelectedClassifierMineralsResults } from './useGetSelectedClassifierMineralsResults';

export const useSurfaceMinerals = (classifierId: number) => {
  const { minerals } = explorationRepository.useFetchMineralsTypes();

  const { selectedClassifierMineralsResults } = useGetSelectedClassifierMineralsResults(classifierId);

  const surfaceMinerals = selectedClassifierMineralsResults
    .map((result): SurfaceMinerals | undefined => {
      const currentMineral = minerals.find(({ id }) => id === result.mineral_id);

      if (!currentMineral) {
        return undefined;
      }

      const name = `${classifierId}_${currentMineral.name}`;
      const label = currentMineral.label_name;
      const color = currentMineral.color;

      return { ...result, name, label, color };
    })
    .filter((item): item is SurfaceMinerals => !!item);

  const selectedMinerals: SurfaceMineralsDisplayed[] = surfaceMinerals.map(
    ({ id, mineral_id, name, label, file_path, color }) => ({
      id,
      mineral_id,
      name,
      label,
      url: file_path,
      color,
    }),
  );

  const urlsWithNameAndLabel: UrlWithNameAndLabel[] = surfaceMinerals.map(({ name, label, file_path }) => ({
    url: file_path,
    name,
    label,
  }));

  return { selectedMinerals, urlsWithNameAndLabel };
};
