import styled from 'styled-components';

import { useAoiNumber } from 'common/navigation/hooks';
import { useOverridableFeatureFlag } from 'common/hooks/useOverridableFeatureFlag';
import { GeneralInfoPanel } from 'ui/organisms/areaInfoPanel/GeneralInfoPanel';
import { AreaInfoSup } from 'ui/organisms/areaInfoPanel/components';
import { WaterbodiesReclamationsSection } from 'ui/organisms/areaInfoPanel/WaterbodiesReclamationsSection';
import { NotificationsSection } from 'ui/organisms/areaInfoPanel/NotificationsSection';
import { areasRepository } from 'infrastructure/areas/areasRepository';

export const MonitoringPanel = () => {
  const enableNotifications = useOverridableFeatureFlag('enableNotifications', false, 'REACT_APP_ENABLE_NOTIFICATIONS');

  const areaId = useAoiNumber();

  const { areaOfInterestDetails } = areasRepository.useFetchAreaOfInterestDetails(areaId);
  if (!areaOfInterestDetails) return null;

  return (
    <GeneralInfoPanel
      areaDetails={areaOfInterestDetails}
      extraContent={
        <div>
          Area size: {areaOfInterestDetails.properties.area.toFixed(2)}km
          <AreaInfoSup>2</AreaInfoSup>
        </div>
      }
    >
      <InteractWrapper>
        <WaterbodiesReclamationsSection />
        {enableNotifications && <NotificationsSection />}
      </InteractWrapper>
    </GeneralInfoPanel>
  );
};

const InteractWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin-right: 30px;
`;
