import { DisplacementsActiveTab } from 'domain/displacements/enums';
import { GreeneryActiveTab } from 'domain/greenery/enums';
import { MineralizationActiveTab } from 'domain/exploration/enums';
import { WaterActiveTab } from 'domain/water/enums';
import { ExtractionActiveTab } from 'domain/extraction/enums';

export const ROUTES = {
  // user
  login: '/login',
  logout: '/logout',
  signup: '/signup',
  changePassword: '/change-password',
  // resetPassword: '/reset-password',
  // areas
  areasList: '/areas',
  // area
  area: '/area',
  areaRequestNew: '/area/request-new',
  areaDetails: '/area/details/',
  monitoring: '/area/details/:areaId/monitoring',
  prospecting: '/area/details/:areaId/prospecting',
  mineralization: '/area/details/:areaId/exploration',
  mineralsSurface: `/area/details/:areaId/exploration/${MineralizationActiveTab.SURFACE}`,
  // mine
  mine: '/area/details/:areaId/mine',
  terrain: `/area/details/:areaId/mine/${ExtractionActiveTab.TERRAIN}`,
  // water
  waterDetection: '/area/details/:areaId/water',
  waterQuality: `/area/details/:areaId/water/${WaterActiveTab.QUALITY}`,
  waterArea: `/area/details/:areaId/water/${WaterActiveTab.SURFACE}`,
  waterMoisture: `/area/details/:areaId/water/${WaterActiveTab.MOISTURE}`,
  snowCover: `/area/details/:areaId/water/${WaterActiveTab.SNOW_COVER}`,
  // greenery
  greenery: '/area/details/:areaId/greenery',
  landCover: `/area/details/:areaId/greenery/${GreeneryActiveTab.SEGMENTATION}`,
  quarterlyAnalysis: `/area/details/:areaId/greenery/${GreeneryActiveTab.QUARTERLY}`,
  vegetation: `/area/details/:areaId/greenery/${GreeneryActiveTab.VEGETATION}`,
  vhrSegmentation: `/area/details/:areaId/greenery/${GreeneryActiveTab.VHR}`,
  vci: `/area/details/:areaId/greenery/${GreeneryActiveTab.VCI}`,
  // displacements
  displacements: '/area/details/:areaId/displacements',
  subsidence: `/area/details/:areaId/displacements/${DisplacementsActiveTab.SUBSIDENCE}`,
  // temperature
  temperature: `/area/details/:areaId/temperature`,
  // payments
  payment: '/checkout',
  paymentProcess: '/checkout/process',
  summary: '/summary',
  // subscriptions
  subscriptions: '/subscriptions',
  subscriptionsStart: '/subscriptions/start',
  subscriptionsManage: '/subscriptions/manage',
};
