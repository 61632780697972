import { LAT_LNG_QUERY_PARAMETER, REPORT_QUERY_PARAMETERS, ZOOM_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { ROUTES } from 'common/navigation/routes';
import { MONITORING_PATH_NAME } from 'domain/monitoring/constants';

export const MONITORING_VIEW_CONFIG = {
  name: MONITORING_PATH_NAME,
  path: ROUTES.monitoring,
  params: [LAT_LNG_QUERY_PARAMETER, ZOOM_QUERY_PARAMETER, ...REPORT_QUERY_PARAMETERS],
  tabs: {},
};
