import { type AreaEnvironmentsTypes } from 'common/navigation/types';
import { AreaEnvironments } from 'common/navigation/constants';
import { getReadableArea } from 'common/utils/leafletDraw';
import {
  type ExplanationColumnVariant,
  type AreaOfInterest,
  type AreaRequestStatus,
  type AreaSelectionStatus,
  type SummaryModalVariant,
} from 'domain/areas/types';
import { MINE_EXTRACTION_PATH_NAME } from 'domain/extraction/constants';
import { WATER_PATH_NAME } from 'domain/water/constants';
import { GREENERY_PATH_NAME } from 'domain/greenery/constants';
import { DISPLACEMENTS_PATH_NAME } from 'domain/displacements/constants';
import { MINERALIZATION_PATH_NAME } from 'domain/exploration/constants';
import { MINIMUM_VIABLE_AREA } from 'domain/areas/constants';
import { type SubscriptionProductDTO } from 'domain/subscription/types';
import {
  AREA_CREATOR_LOWER_SUBSCRIPTION_INFORMATION,
  AREA_REQUEST_STANDARD_SUBSCRIPTION_NAME,
} from 'domain/areas/configs';

export const sortAreasByName = (a: AreaOfInterest, b: AreaOfInterest): number =>
  a.display_name.toLocaleLowerCase().localeCompare(b.display_name.toLowerCase());

export const parseEnvironmentNameToViewUrl = (environmentName: AreaEnvironmentsTypes) => {
  switch (true) {
    case environmentName === AreaEnvironments.MINE:
      return MINE_EXTRACTION_PATH_NAME;
    case environmentName === AreaEnvironments.WATER:
      return WATER_PATH_NAME;
    case environmentName === AreaEnvironments.LAND_COVER:
      return GREENERY_PATH_NAME;
    case environmentName === AreaEnvironments.DISPLACEMENT:
      return DISPLACEMENTS_PATH_NAME;
    case environmentName === AreaEnvironments.MINERALIZATION:
      return MINERALIZATION_PATH_NAME;
    default:
      return 'NOT_EXISTING_VIEW_URL';
  }
};

export const buildSelectionStatusHelper = (
  selectedAreaInMeters: number | undefined,
  selectedSubscriptionQuota: number | undefined,
  maximumSubscriptionQuota: number,
  availableQuota: number | undefined,
  activeSubscriptionCapacity: number | undefined,
): AreaSelectionStatus => {
  if (selectedAreaInMeters === undefined) return { mode: 'IDLE' };

  if (selectedAreaInMeters <= MINIMUM_VIABLE_AREA)
    return { mode: 'INSUFFICIENT_AREA', invalidArea: 'Your selection needs to be more than 1 km².' };

  const pointsForselectedArea = selectedAreaInMeters && calculatePointsForArea(selectedAreaInMeters, 1);

  if (maximumSubscriptionQuota !== activeSubscriptionCapacity && pointsForselectedArea > maximumSubscriptionQuota)
    return {
      invalidArea: `Your selection exceeds all of the available plans by ${(
        pointsForselectedArea - maximumSubscriptionQuota
      ).toFixed(1)} points.`,
      mode: 'LIMIT_EXCEEDED',
    };

  if (
    availableQuota !== undefined &&
    pointsForselectedArea > availableQuota &&
    (selectedSubscriptionQuota === undefined || selectedSubscriptionQuota === activeSubscriptionCapacity)
  )
    return {
      invalidArea: `Your selection exceeds your current subscription by ${(
        pointsForselectedArea - availableQuota
      ).toFixed(1)} points.`,
      mode: 'QUOTA_EXCEEDED',
    };

  if (selectedSubscriptionQuota !== undefined && pointsForselectedArea > selectedSubscriptionQuota)
    return {
      invalidArea: `Your selection exceeds selected plan by ${(
        pointsForselectedArea - selectedSubscriptionQuota
      ).toFixed(1)} points.`,
      mode: 'PLAN_EXCEEDED',
    };

  if (availableQuota === undefined || pointsForselectedArea <= availableQuota)
    return {
      message: `Currently Selected area size: ${getReadableArea(selectedAreaInMeters)}.`,
      mode: 'AREA_SELECTED',
    };

  return { mode: 'IDLE' };
};

export const buildRequestStatusHelper = (
  isTrialUser: boolean,
  selectedAreaInMeters: number | undefined,
  availableQuota: number | undefined,
  isDataLoading: boolean,
  activeSubscriptionName: string | undefined,
  activeSubscriptionCapacity: number | undefined,
  selectedSubscription: SubscriptionProductDTO | null,
): AreaRequestStatus => {
  if (
    !selectedSubscription ||
    selectedAreaInMeters === undefined ||
    selectedAreaInMeters <= MINIMUM_VIABLE_AREA ||
    isDataLoading
  )
    return { mode: 'DISABLED' };

  const pointsForselectedArea = selectedAreaInMeters && calculatePointsForArea(selectedAreaInMeters, 1);

  const isSelectedOptionButtonDisabled = getOptionButtonWarningStateHelper(
    activeSubscriptionName,
    selectedSubscription.name,
    selectedSubscription.area_quota,
    pointsForselectedArea,
    availableQuota,
  );

  if (isSelectedOptionButtonDisabled) return { mode: 'DISABLED' };

  if (isTrialUser && !activeSubscriptionName)
    return {
      mode: 'TRIAL_ENABLED',
    };

  if (availableQuota === undefined)
    return {
      mode: 'PAYMENT_REQUIRED',
    };

  if (activeSubscriptionCapacity !== undefined && selectedSubscription.area_quota > activeSubscriptionCapacity) {
    return {
      mode: 'PAYMENT_REQUIRED',
      isUpgrading: true,
    };
  }

  if (activeSubscriptionName === selectedSubscription.name && pointsForselectedArea <= availableQuota)
    return {
      mode: 'REQUEST_ALLOWED',
    };

  return {
    mode: 'DISABLED',
  };
};

export const calculatePointsForArea = (areaInMeters: number, factor: number): number => {
  const areaInKm = areaInMeters / 1_000_000;
  return areaInKm * factor;
};

const formatAnnotation = (annotation: string | number | undefined) => {
  if (!annotation) return '0.0';
  if (typeof annotation === 'string') return parseFloat(annotation).toFixed(1);
  return annotation.toFixed(1);
};

export const getOptionButtonCoverageAnnotationHelper = (
  activeSubscriptionName: string | undefined,
  activeSubscriptionCapacity: number | undefined,
  planName: string,
  planQuota: number,
  selectedAmount: number | undefined = 0,
  availableQuota: number | undefined,
) => {
  if (activeSubscriptionCapacity !== undefined && planQuota > activeSubscriptionCapacity) {
    return `Added Points: ${formatAnnotation(planQuota - activeSubscriptionCapacity)}`;
  }

  if (activeSubscriptionName !== planName || availableQuota === undefined) {
    return `Points: ${formatAnnotation(planQuota)}`;
  }

  if (selectedAmount <= availableQuota || (availableQuota === 0 && selectedAmount === 0)) {
    return `Remaining Points: ${formatAnnotation(availableQuota - selectedAmount)}`;
  }

  if (selectedAmount > availableQuota) {
    return `Missing Points: ${formatAnnotation(selectedAmount - availableQuota)}`;
  }

  return "Points: 'N/A'";
};

export const getOptionButtonWarningStateHelper = (
  activeSubscriptionName: string | undefined,
  planName: string,
  planAreaQuota: number,
  selectedAmount: number | undefined,
  availableQuota: number | undefined,
) => {
  if (selectedAmount === undefined) return false;

  if (activeSubscriptionName !== planName && selectedAmount <= planAreaQuota) return false;

  if (activeSubscriptionName === planName && availableQuota !== undefined && selectedAmount <= availableQuota)
    return false;

  return true;
};

export const getOptionButtonPermanentDisabledStateHelper = (
  isTrialUser: boolean,
  activeSubscriptionName: string | undefined,
  planName: string,
  topInfo?: string,
) => {
  if (isTrialUser && activeSubscriptionName === undefined && planName !== AREA_REQUEST_STANDARD_SUBSCRIPTION_NAME)
    return true;

  if (topInfo === AREA_CREATOR_LOWER_SUBSCRIPTION_INFORMATION) return true;

  return false;
};

export const getOptionButtonTopInfoHelper = (
  activeSubscriptionName: string | undefined,
  activeSubscriptionCapacity: number | undefined,
  planName: string,
  planAreaQuota: number,
): string | undefined => {
  return activeSubscriptionName !== undefined && activeSubscriptionCapacity !== undefined
    ? activeSubscriptionName === planName
      ? 'current plan'
      : planAreaQuota <= activeSubscriptionCapacity
      ? AREA_CREATOR_LOWER_SUBSCRIPTION_INFORMATION
      : 'upgrade to'
    : undefined;
};

export const getRequestButtonTextHelper = (status: AreaRequestStatus) => {
  switch (status.mode) {
    case 'TRIAL_ENABLED':
      return 'request & start trial';
    case 'PAYMENT_REQUIRED':
      return 'request & pay';
    default:
      return 'request';
  }
};

export const getExplanationColumnStateHelper = (
  isPropertiesColumnValid: boolean,
  isDepositsColumnValid: boolean,
  isSubscriptionsColumnValid: boolean,
): ExplanationColumnVariant => {
  switch (true) {
    case isPropertiesColumnValid && !isDepositsColumnValid && !isSubscriptionsColumnValid:
      return 'SECOND';
    case isPropertiesColumnValid && isDepositsColumnValid && !isSubscriptionsColumnValid:
      return 'THIRD';
    case isPropertiesColumnValid && isDepositsColumnValid && isSubscriptionsColumnValid:
      return 'FOURTH';
    default:
      return 'FIRST';
  }
};

export const defineModalType = (type: string | null): SummaryModalVariant => {
  switch (type) {
    case 'true':
      return 'PAYMENT_SUCCESS';
    case 'request':
      return 'REQUEST_SUCCESS';
    case 'custom':
      return 'CUSTOM_REQUEST';
    default:
      return 'PAYMENT_SUCCESS';
  }
};
