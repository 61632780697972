import { Lineament } from './Lineament';
import { useLineaments } from './useLineaments';
import { useExplorationLayerManagerStore } from '../explorationLayerManagerStore';

interface LineamentsCollectionProps {
  areaName: string;
}

export const LineamentsCollection = ({ areaName }: LineamentsCollectionProps) => {
  const areLineamentsVisible = useExplorationLayerManagerStore.use.areLineamentsVisible();
  const lineamentsOpacity = useExplorationLayerManagerStore.use.lineamentsOpacity();
  const isLineamentsSelectionMode = useExplorationLayerManagerStore.use.isLineamentsSelectionMode();

  const { currentThreshold } = useLineaments();

  const buildFileUrlFromName = (name: string) => {
    return `${process.env.REACT_APP_PUBLIC_CONTAINER_URL}/lineaments/${areaName}/${name}.geojson`;
  };

  if (!currentThreshold) {
    return null;
  }

  const lineaments = [currentThreshold];

  return (
    <>
      {lineaments.map((item) => {
        const id = item?.id;
        const value = item?.value;

        if (!value || !id) {
          return null;
        }

        const label = `${value[0]}-${value[1]}`;

        return (
          <Lineament
            key={id}
            id={id}
            url={buildFileUrlFromName(label)}
            name={label}
            isVisible={areLineamentsVisible}
            opacity={lineamentsOpacity}
            isSelectionMode={isLineamentsSelectionMode}
          />
        );
      })}
    </>
  );
};
