import { DataLayer } from 'components/map';
import { useGetCogWithBounds } from 'services/titiler/hooks';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';

interface ClustersMapLayerProps {
  url: string;
  amount: number;
  opacity: number;
  isVisible: boolean;
}

export const ClustersMapLayer = ({ url, amount, opacity, isVisible }: ClustersMapLayerProps) => {
  const { cogUrl, cogBounds } = useGetCogWithBounds(titilerApiAdapter.buildCogUrlWithColormapName, url, {
    range: [0, amount - 1],
    colormapName: 'cubehelix',
  });

  if (cogUrl && cogBounds) {
    return (
      <DataLayer
        isVisible={isVisible}
        layerName="clusters-layer"
        zIndex={440}
        cogUrl={cogUrl}
        bounds={cogBounds}
        opacity={opacity}
      />
    );
  }

  return null;
};
