import styled from 'styled-components';

import { SectionLayout } from './SectionLayout';

export const SurfaceAreaSection = () => {
  const data = [
    { name: 'Geothite', area: '123', color: '#5AE2A9' },
    { name: 'Kaolinite', area: '123', color: '#06A1A1' },
    { name: 'Hematite', area: '123', color: '#B770FF' },
    { name: 'Epidote', area: '123', color: '#FF3939' },
    { name: 'Alunite', area: '123', color: '#FFD600' },
    { name: 'Illite', area: '123', color: '#FF9A23' },
    { name: 'Jarosite', area: '123', color: '#48BDFF' },
    { name: 'Chlorite', area: '123', color: '#FF90B8' },
    { name: 'Sericite', area: '123', color: '#5C62FF' },
    { name: 'Smectite', area: '123', color: '#9F6A1A' },
    { name: 'Montmorillonite', area: '123', color: '#F34CB0' },
    { name: 'Magnetite', area: '123', color: '#10C200' },
  ];

  return (
    <SectionLayout title="surface area" description="lorem ipsum dolor sit">
      <List>
        {data.map(({ name, area, color }) => (
          <li key={name}>
            {name}
            <AreaValue color={color}>
              {area}
              <AreaValueUnit>
                km<sup>2</sup>
              </AreaValueUnit>
            </AreaValue>
          </li>
        ))}
      </List>
    </SectionLayout>
  );
};

const List = styled.ul`
  list-style: none;
  padding: 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
`;

const AreaValue = styled.p<{ color: string }>`
  color: ${({ color }) => color};
  font-family: 'Barlow', sans-serif;
  font-size: 40px;
  font-weight: 700;
  margin: 0;
`;

const AreaValueUnit = styled.span`
  font-size: 12px;
`;
