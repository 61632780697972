import { type ReactNode } from 'react';
import styled from 'styled-components';

import { ReactComponent as BrightnessIcon } from 'assets/svg/brightness-icon.svg';
import { Container, LabeledCheckbox, ModeSwitcher, Slider } from 'ui';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { useGraphStore } from 'components/graph/graphStore';
import { useGetAoiName } from 'common/hooks/getAoiName';
import { InfrastructureLayersGroup } from './InfrastructureLayersGroup';
import { TopographyLayersGroup } from './TopographyLayersGroup';
import { AuxiliaryControlsWrapper, IndentedContentWraper, LabeledSliderWrapper, LegendItemWraper } from './styled';

interface LayerManagerCommonContentProps {
  disableInfrastructureLayer?: boolean;
  mainLayerLabel?: string | JSX.Element;
  mainLayerOpacityAdjustable?: boolean;
  children?: ReactNode;
}

export const LayerManagerCommonContent = ({
  disableInfrastructureLayer,
  mainLayerLabel,
  mainLayerOpacityAdjustable,
  children,
}: LayerManagerCommonContentProps) => {
  const areaName = useGetAoiName();

  const isDataLayerVisible = useLayerManagerStore.use.isDataLayerVisible();
  const toggleDataLayerVisibility = useLayerManagerStore.use.toggleDataLayerVisibility();
  const dataLayerOpacity = useLayerManagerStore.use.dataLayerOpacity();
  const setDataLayerOpacity = useLayerManagerStore.use.setDataLayerOpacity();
  const isExtentVisible = useLayerManagerStore.use.isExtentVisible();
  const toggleExtentVisibility = useLayerManagerStore.use.toggleExtentVisibility();
  const isSatelliteVisible = useLayerManagerStore.use.isSatelliteVisible();
  const toggleSatelliteVisibility = useLayerManagerStore.use.toggleSatelliteVisibility();
  const isSatelliteGrayscale = useLayerManagerStore.use.isSatelliteGrayscale();
  const toggleSatelliteGrayscale = useLayerManagerStore.use.toggleSatelliteGrayscale();
  const grayscaleSatelliteBrightness = useLayerManagerStore.use.grayscaleSatelliteBrightness();
  const setGrayscaleSatelliteBrightness = useLayerManagerStore.use.setGrayscaleSatelliteBrightness();
  const resetPointHistory = useGraphStore.use.resetGraphData();

  const onDataLayerToggle = () => {
    toggleDataLayerVisibility();
    resetPointHistory();
  };

  const onOpacityChange = (value: number | number[]) => {
    setDataLayerOpacity(Array.isArray(value) ? value[0] : value);
  };

  const onBrightnessChange = (value: number | number[]) => {
    setGrayscaleSatelliteBrightness(Array.isArray(value) ? value[0] : value);
  };

  return (
    <LayerManagerContent>
      <ExtentItemWrapper>
        <IndentedContentWraper>
          <LabeledCheckbox
            children="Extent"
            variant="WIDE"
            onChange={toggleExtentVisibility}
            checked={isExtentVisible || false}
          />
        </IndentedContentWraper>
      </ExtentItemWrapper>
      {areaName && (
        <InfrastructureLayersGroup {...{ areaName }} disableInfrastructureLayer={!!disableInfrastructureLayer} />
      )}
      {mainLayerLabel ? (
        <LegendItemWraper>
          <IndentedContentWraper>
            <LabeledCheckbox
              children={mainLayerLabel}
              variant="WIDE"
              onChange={onDataLayerToggle}
              checked={isDataLayerVisible || false}
            />
            {mainLayerOpacityAdjustable && (
              <AuxiliaryControlsWrapper disabled={!isDataLayerVisible}>
                <LabeledSliderWrapper>
                  Opacity
                  <Slider
                    ariaLabel="Opacity slider"
                    marks={{
                      '0': '',
                      '1': '',
                    }}
                    value={dataLayerOpacity || 1}
                    onChange={onOpacityChange}
                    defaultValue={1}
                    min={0}
                    max={1}
                    step={0.01}
                  />
                </LabeledSliderWrapper>
              </AuxiliaryControlsWrapper>
            )}
          </IndentedContentWraper>
        </LegendItemWraper>
      ) : null}
      {children}
      {/* TODO: update when dedicated endpoint is ready */}
      {areaName && <TopographyLayersGroup areaName={areaName} />}
      <SatelliteLegendItemWraper>
        <IndentedContentWraper>
          <LabeledCheckbox variant="WIDE" onChange={toggleSatelliteVisibility} checked={isSatelliteVisible}>
            satellite
          </LabeledCheckbox>
          <AuxiliaryControlsWrapper disabled={!isSatelliteVisible}>
            <Container pv={4}>
              <ModeSwitcher
                isModeOn={isSatelliteGrayscale}
                label="black & white mode"
                toggleMode={toggleSatelliteGrayscale}
              />
            </Container>
            <LabeledSliderWrapper>
              <BrightnessIcon />
              <Slider
                ariaLabel="Brightness slider"
                marks={{
                  '0': '',
                  '1': '',
                  '2': '',
                  '3': '',
                }}
                value={grayscaleSatelliteBrightness}
                onChange={onBrightnessChange}
                defaultValue={1}
                min={0}
                max={3}
                step={1}
              />
            </LabeledSliderWrapper>
          </AuxiliaryControlsWrapper>
        </IndentedContentWraper>
      </SatelliteLegendItemWraper>
    </LayerManagerContent>
  );
};

const LayerManagerContent = styled.div`
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.mineShaftABitLighter};
`;

const ExtentItemWrapper = styled(LegendItemWraper)`
  padding: 10px 0;
`;

const SatelliteLegendItemWraper = styled(LegendItemWraper)`
  padding: 10px 0;
  border-bottom: none;
`;
