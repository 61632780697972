import { useLocation } from 'react-router';
import styled from 'styled-components';

import { Container } from 'ui';
import { LoadingDots } from 'ui/atoms/loadingDots';
import { BaseModal, ModalHeader } from 'ui/modal';
import { useShareModalStore } from 'ui/organisms/shareModal/shareModalStore';
import { AddNewAreaModalContent } from 'components/AddNewAreaModalContent';
import { useGetActiveSubscription } from 'views/subscriptions/useGetActiveSubscription';
import { AREA_REQUEST_NO_SUBSCRIPTION_INFORMATION } from 'domain/areas/configs';
import { MineralizationActiveTab } from 'domain/exploration/enums';
import { MineralsDownloadModal } from './minerals';

export const ExplorationDownloadModal = () => {
  const { pathname } = useLocation();

  const { activeSubscriptionData, isActiveSubscriptionLoading } = useGetActiveSubscription();

  const closeModal = useShareModalStore.use.closeShareModal();

  return (
    <BaseModal isOpen={true} closeModal={closeModal} maxWidth={500}>
      <ModalWrapper>
        <ModalHeader>Download Data Layers</ModalHeader>
        <Container mt={40}>
          {isActiveSubscriptionLoading ? (
            <Container gap={5}>
              Subscription checking <LoadingDots />
            </Container>
          ) : (
            <Container variant="VERTICAL_START" gap={35}>
              {!activeSubscriptionData ? (
                <AddNewAreaModalContent header={AREA_REQUEST_NO_SUBSCRIPTION_INFORMATION} handleClose={closeModal} />
              ) : pathname.includes(MineralizationActiveTab.SURFACE) ? (
                <MineralsDownloadModal />
              ) : null}
            </Container>
          )}
        </Container>
      </ModalWrapper>
    </BaseModal>
  );
};

const ModalWrapper = styled.div`
  min-width: 240px;
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
`;
