import { useRef, useState } from 'react';
import styled from 'styled-components';

import { copyInClipboard, getFullFileName, downloadAllFilesAsZip, saveFileFromUrlWithName } from 'common/utils';
import { Button } from 'ui/atoms/button';
import { Loading } from 'ui/molecules/loading';
import { Input } from 'ui/atoms/input';
import { ModalHeader } from 'ui/modal';

interface ShareModalContentProps {
  fileLinks: string[];
  fileType: string;
  fileName: string;
  getReadyLinks?: () => string[] | Promise<string[]>;
}

export const ShareModalContent = ({ fileLinks, fileType, fileName, getReadyLinks }: ShareModalContentProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [downloadLinksLoading, setDownloadLinksLoading] = useState(false);

  const filteredFileLinks = fileLinks.filter((url) => !!url);
  const isSingleLink = filteredFileLinks.length < 2;

  const sanitizedFileName = fileName.replaceAll(' ', '_');
  const fileTypeToDownload = isSingleLink ? fileType : 'zip';

  const copyUrl = async () => {
    try {
      await copyInClipboard(inputRef.current?.value);
    } catch (error) {
      console.error(error);
    }
  };

  const downloadFile = (filteredFileLinks: string[]) => {
    if (isSingleLink) {
      return saveFileFromUrlWithName(filteredFileLinks[0], getFullFileName(sanitizedFileName, fileTypeToDownload));
    }

    downloadAllFilesAsZip(filteredFileLinks, sanitizedFileName, fileType);
  };

  const onDownloadHandler = () => {
    if (getReadyLinks) {
      setDownloadLinksLoading(true);
      Promise.resolve(getReadyLinks()).then((readyLinks) => downloadFile(readyLinks));
      setDownloadLinksLoading(false);
    } else {
      downloadFile(filteredFileLinks);
    }
  };

  if (downloadLinksLoading) {
    return <Loading />;
  }

  return (
    <ShareWrapper>
      <ModalHeader>Share & Download</ModalHeader>

      <ShareBlockWrapper>
        <div>Copy the link below to share this view with your team.</div>
        <ShareBlockActionWrapper>
          <InputWrapper>
            <Input $variant="PRIMARY" ref={inputRef} value={window.location.href} readOnly />
          </InputWrapper>
          <Button onClick={copyUrl} squared>
            Copy Link
          </Button>
        </ShareBlockActionWrapper>
      </ShareBlockWrapper>

      {!!filteredFileLinks.length && (
        <ShareBlockWrapper>
          <div>Get the .{fileTypeToDownload} file</div>
          <ShareBlockActionWrapper>
            <span>{getFullFileName(sanitizedFileName, fileTypeToDownload)}</span>
            <Button onClick={onDownloadHandler} squared>
              Download {fileTypeToDownload}
            </Button>
          </ShareBlockActionWrapper>
        </ShareBlockWrapper>
      )}
    </ShareWrapper>
  );
};

const InputWrapper = styled.div`
  width: 390px;
  border-bottom: 1px solid ${({ theme }) => theme.color.silverDark};
`;

const ShareBlockActionWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  margin-top: 20px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 500;
`;

const ShareBlockWrapper = styled.div`
  margin: 0;
  margin-top: 60px;
`;

const ShareWrapper = styled.div`
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
  padding: 30px;
`;
