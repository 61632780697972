import { DataLayer } from 'components/map';
import { useGetCogWithBounds } from 'services/titiler/hooks';

interface ExplorationMapLayerProps<T> {
  index: number;
  name: string;
  url: string;
  isVisible: boolean;
  builder: (url: string, params: T) => string | undefined;
  params: T;
  opacity?: number;
}

export const ExplorationMapLayer = <T,>({
  index,
  name,
  url,
  isVisible,
  builder,
  params,
  opacity = 0.5,
}: ExplorationMapLayerProps<T>) => {
  const { cogUrl, cogBounds } = useGetCogWithBounds(builder, url, params);

  if (cogUrl && cogBounds) {
    return (
      <DataLayer
        key={index}
        isVisible={isVisible}
        layerName={name}
        zIndex={599 - index}
        cogUrl={cogUrl}
        bounds={cogBounds}
        opacity={opacity}
      />
    );
  }

  return null;
};
