import { useOverridableFeatureFlag } from 'common/hooks/useOverridableFeatureFlag';
import { ReactComponent as SquareIcon } from 'assets/svg/square-icon.svg';
import { MapControlButton } from 'ui/map/mapControlButton';
import { PluggedInMapToolbox } from 'views/areas/PluggedInMapToolbox';
import { useAreaRequestStore } from 'views/areas/areaRequestNew/AreaRequestStore';
// import { ReactComponent as EditIcon } from 'assets/svg/edit-icon.svg';
import { ReactComponent as TrashIcon } from 'assets/svg/trash-icon.svg';
import { DrawPolygonControlButton } from './DrawPolygonControlButton';
import { useSubscriptionColumnLoading } from './useSubscriptionColumnLoading';

export const DrawControlButtons = () => {
  const enableEditAndPolygonDraw = useOverridableFeatureFlag(
    'enableEditAndPolygonDraw',
    false,
    'REACT_APP_ENABLE_EDIT_AND_POLYGON_DRAW',
  );

  const features = useAreaRequestStore.use.features();
  const setFeatures = useAreaRequestStore.use.setFeatures();

  const setPolygonPoints = useAreaRequestStore.use.setPolygonPoints();

  const drawMode = useAreaRequestStore.use.drawMode();
  const toggleDrawMode = useAreaRequestStore.use.toggleDrawMode();

  const deleteMode = useAreaRequestStore.use.deleteMode();
  const toggleDeleteMode = useAreaRequestStore.use.toggleDeleteMode();

  const resetStatus = useAreaRequestStore.use.resetStatus();
  const resetArea = useAreaRequestStore.use.resetArea();

  const isDepositsColumnValid = useAreaRequestStore.use.isDepositsColumnValid();

  const { isLoading } = useSubscriptionColumnLoading();

  const handleSetRectangleDraw = () => {
    toggleDrawMode('rectangle');
    setPolygonPoints([]);
    setFeatures([]);
    resetArea();
    resetStatus();
  };

  const disabled = !!features.length || !isDepositsColumnValid || isLoading;

  return (
    <PluggedInMapToolbox>
      <MapControlButton
        icon={<SquareIcon />}
        iconWidth={20}
        active={drawMode === 'rectangle'}
        disabled={disabled}
        onClick={handleSetRectangleDraw}
      />
      {enableEditAndPolygonDraw && <DrawPolygonControlButton disabled={disabled} />}
      {/* <MapControlButton
        icon={<EditIcon />}
        iconWidth={20}
        active={editMode}
        disabled={!features.length}
        onClick={() => setEditMode(!editMode)}
      /> */}
      <MapControlButton
        icon={<TrashIcon />}
        iconWidth={20}
        active={deleteMode}
        disabled={!features.length}
        onClick={toggleDeleteMode}
      />
    </PluggedInMapToolbox>
  );
};
