import dayjs from 'dayjs';

import { type ImageDate } from 'common/types';
import { type Datetime, type TimestampsRange } from 'common/types/time';
import { MAXIMUM_RESULTS_DATE_SEARCHING_PERIOD } from 'common/constants';
import { DEFAULT_DATE_FORMAT_DOT, DEFAULT_DATE_TIME_ZONE_FORMAT } from 'common/constants/datetime';

export const formatDate = (date: string, format: string = DEFAULT_DATE_FORMAT_DOT): string =>
  dayjs(date).format(format);

export const formatDateTimeZone = (date: string, format: string = DEFAULT_DATE_TIME_ZONE_FORMAT): string =>
  dayjs(date).format(format);

export const sortByDate = (a: string, b: string): number => {
  if (dayjs(a) > dayjs(b)) {
    return -1;
  } else if (dayjs(a) < dayjs(b)) {
    return 1;
  }

  return 0;
};

export const mapQuarterlyParamsToOptions = (dates: string[]) => {
  return dates.map((date) => ({
    value: date,
    label: `${date.slice(0, 4)} - Q${Number(date.slice(5, 7)) / 3}`,
  }));
};

export const reverseDateStringsArray = (array: string[]): string[] => {
  const parsed = array.map((dateString) => Date.parse(dateString));
  const reversed = parsed.reverse();
  const stringified = reversed.map((dateNumber) => {
    const date = new Date(dateNumber);
    return date.toISOString();
  });

  return stringified;
};

export const extractDateParamsFromCogUrl = (cogUrl: string): RegExpMatchArray | null =>
  cogUrl?.match(/\d{4}-\d{2}-\d{2}/g);

export const convertStringToDateParams = (str: string): string =>
  [str.slice(0, 4), str.slice(4, 6), str.slice(6)].join('-');

export const convertStringToDateTimeParams = (str: string): string =>
  [str.slice(0, 2), str.slice(2, 4), str.slice(4, 6)].join(':');

export const convertStringsToTimestamp = (strs: string[]): string =>
  [convertStringToDateParams(strs[0]), convertStringToDateTimeParams(strs[1])].join(' ');

export const getLocaleDateFromUnixEpochTime = (dateTime: number): string => new Date(dateTime).toLocaleDateString();

export const getISO8601TimestampFromUnixEpochTime = (dateTime: number): string =>
  new Date(dateTime).toISOString().split('.')[0].concat('Z');

export const checkDateConformity = (originDate: string, comparedDate: string): boolean =>
  originDate.startsWith(comparedDate?.slice(0, 10));

const unifyTwoDigitsDate = (num: number) => (num < 10 ? `0${num}` : String(num));

export const getDottedDateFromUnixEpochTime = (dateTime: number): string => {
  const date = new Date(dateTime);
  const year = String(date.getFullYear());
  const month = unifyTwoDigitsDate(date.getMonth() + 1);
  const day = unifyTwoDigitsDate(date.getDate());
  return `${day}.${month}.${year}`;
};

export const convertDateToUnixTimestamp = (timestamp: string): number => new Date(timestamp).getTime();

export const convertDatesToUnixTimestamps = (timestamps: string[]): number[] =>
  timestamps.map((timestamp) => convertDateToUnixTimestamp(timestamp)).sort((a, b) => a - b);

export const findDateWithMostItemsHandler = (dates: string[]) => {
  const uniqueDates = dates && Array.from(new Set(dates));
  const datesWithValues: string[][] = [];

  uniqueDates.forEach((uniqueDate) => {
    const datetime = new Date(uniqueDate).getTime();
    const dayjs = Date.now();

    if (dayjs - datetime < MAXIMUM_RESULTS_DATE_SEARCHING_PERIOD) {
      const filteredDates = dates.filter((date) => date === uniqueDate);
      datesWithValues.push(filteredDates);
    }
  });

  const arraysLengths = datesWithValues.map((array) => array.length);

  const date = arraysLengths.length
    ? datesWithValues[arraysLengths.indexOf(Math.max(...arraysLengths))][0]
    : uniqueDates[0];

  return date;
};

export const sanitizeDate = (timestamp: Datetime): string =>
  timestamp.match(/T.*?$/) ? timestamp : timestamp.replaceAll(':', '-').replace('Z', '');

export const unsanitizeDate = (date: string): Datetime =>
  date.match(/Z/)
    ? date
    : date
        .replace(/-(?!.*-)/, ':')
        .replace(/-(?!.*-)/, ':')
        .concat('Z');

export const getDatesRange = (dates: string[]): TimestampsRange => {
  const timestamps = convertDatesToUnixTimestamps(dates);
  const firstTimestamp = timestamps[0];
  const lastTimestamp = timestamps[timestamps.length - 1];

  return { firstTimestamp, lastTimestamp };
};

export const getTimestamps = (results: ImageDate[] | undefined): string[] | undefined => {
  if (results) {
    return results.map(({ img_date }) => img_date).reverse();
  }
};

export const transformDateStringToUTC = (dateString: string) => {
  return dateString.replace(/(?<=T\d{2}:\d{2}:\d{2}).*$/, 'Z');
};

export const getUniqueSortedTimestamps = (timestamps: string[]): string[] => {
  return Array.from(new Set(timestamps)).sort((a, b) => sortByDate(a, b));
};
