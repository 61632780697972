import { useHandleQueryParameterByPrimitiveValue } from 'common/navigation/hooks';
import { DEPOSIT_CLASSIFICATION_SITE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { type PointArray } from 'common/types/mapData';
import { getSquareBoundsAroundPoint } from 'common/utils/map';
import { Layer } from 'components/map';
import { ProspectingOverviewLines } from 'views/prospecting/map/ProspectingOverviewLines';
import { ProspectingOverviewSitePoint } from 'views/prospecting/map/ProspectingOverviewSitePoint';
import { useOverviewInfoStore } from 'views/prospecting/store';
import {
  GRADE_MAPPING,
  TONNAGE_MAPPING,
  getCuttedBounds,
  getDepositClassificationSitePointColor,
  getDepositClassificationSitePointRadius,
} from 'views/prospecting/utils';
import { type DepositClassificationSite } from 'domain/exploration/types';
import { ProspectingOverviewSiteArea } from './ProspectingOverviewSiteArea';

interface ProspectingOverviewSitesProps {
  sites: DepositClassificationSite[];
  targets: string[];
  extentPolygon: PointArray[] | undefined;
}

export const ProspectingOverviewSites = ({ sites, targets, extentPolygon }: ProspectingOverviewSitesProps) => {
  const [_, setSelectedDepositSite] = useHandleQueryParameterByPrimitiveValue(
    DEPOSIT_CLASSIFICATION_SITE_QUERY_PARAMETER,
  );

  const hoveredDepositSiteName = useOverviewInfoStore.use.hoveredDepositSiteName();
  const setHoveredDepositSiteName = useOverviewInfoStore.use.setHoveredDepositSiteName();

  const hoveredSite = sites.find((site) => site.properties.index === hoveredDepositSiteName);
  const hoveredSiteCoordinates = hoveredSite?.geometry.coordinates;

  const bounds =
    hoveredSiteCoordinates && extentPolygon
      ? getCuttedBounds(extentPolygon, Object.values(getSquareBoundsAroundPoint(hoveredSiteCoordinates, 1).bounds))
      : [];

  return (
    <>
      {extentPolygon && <ProspectingOverviewLines extentPolygon={extentPolygon} />}
      <Layer name="deposit-classification-sites-points-layer" zIndex={404}>
        {sites.map((site) => (
          <ProspectingOverviewSitePoint
            key={site.properties.index}
            coordinates={site.geometry.coordinates}
            color={getDepositClassificationSitePointColor(site.properties.grade_id)}
            radius={getDepositClassificationSitePointRadius(site.properties.tonnage_id)}
            opacity={hoveredSite?.properties.index === site.properties.index ? 1 : 0}
            isTarget={targets.some((target) => target === site.properties.index)}
            onClick={() => setSelectedDepositSite(site.properties.index)}
            onHover={() => setHoveredDepositSiteName(site.properties.index)}
          />
        ))}
      </Layer>
      {hoveredSite && bounds.length > 1 && (
        <ProspectingOverviewSiteArea
          bounds={bounds}
          name={hoveredSite.properties.index}
          isTarget={targets.some((target) => target === hoveredSite.properties.index)}
          grade={GRADE_MAPPING[hoveredSite.properties.grade_id]}
          tonnage={TONNAGE_MAPPING[hoveredSite.properties.tonnage_id]}
          onClick={() => setSelectedDepositSite(hoveredSite.properties.index)}
          onMouseOut={() => setHoveredDepositSiteName(undefined)}
        />
      )}
    </>
  );
};
