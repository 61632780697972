import { useGreeneryStore } from 'infrastructure/greenery/greeneryStore';
import { useDeleteQueryParameters, useQueryParameter, useReplaceQueryParameters } from 'common/navigation/hooks';
import { SECOND_DATE_QUERY_PARAMETER, VIEW_MODE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { type ValueAndLabel } from 'common/types';

export const useGreeneryViewOption = () => {
  const viewQuery = useQueryParameter(VIEW_MODE_QUERY_PARAMETER);
  const clearComparatorUrl = useGreeneryStore.use.clearComparatorUrl();
  const replaceQueryParameter = useReplaceQueryParameters();
  const deleteQueryParameters = useDeleteQueryParameters();

  const handleViewOptionChange = (option: ValueAndLabel): void => {
    const chosenOption = option.value;
    chosenOption &&
      replaceQueryParameter([
        {
          key: VIEW_MODE_QUERY_PARAMETER,
          value: String(chosenOption).replaceAll(' ', '-'),
        },
      ]);

    clearComparatorUrl();
  };

  const handleViewOptionReset = (): void => {
    deleteQueryParameters([VIEW_MODE_QUERY_PARAMETER, SECOND_DATE_QUERY_PARAMETER]);

    clearComparatorUrl();
  };

  return { viewQuery, handleViewOptionChange, handleViewOptionReset };
};
