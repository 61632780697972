import { useState } from 'react';
import { useMap } from 'react-leaflet';

import { type LayersVisibilityDictionary } from 'common/types';
import { type TooltipData, type LayerObject } from 'common/types/mapData';
import { TOOLTIP_DISPLAY_DELAY } from 'common/constants';
import { debounce, simpleRound } from 'common/utils';
import { titilerApiService } from 'services/titiler/titilerApiService';
import { MapTooltip } from 'ui/map/tooltip';
import { type UrlsWithBounds } from 'services/titiler/types';
import { checkIsOutsideBounds } from 'common/utils/map';
import { buildGreeneryDetailedTooltipStringHelper } from 'domain/greenery/helpers';

interface VegetationTitilerTooltipProps {
  greeneryObjects: LayerObject[];
  layersVisibility: LayersVisibilityDictionary;
  urlsWithIds: UrlsWithBounds[];
  precision: number | undefined;
}

export const VegetationTitilerTooltip = ({
  greeneryObjects,
  layersVisibility,
  urlsWithIds,
  precision,
}: VegetationTitilerTooltipProps) => {
  const [tooltip, setTooltip] = useState<TooltipData | undefined>();

  const map = useMap();

  map.off('click');
  map.off('mousemove');

  map.on(
    'mousemove',
    debounce(async (event: { originalEvent: PointerEvent }) => {
      const latLng = await map.mouseEventToLatLng(event.originalEvent);

      urlsWithIds.forEach(async (set) => {
        if (!set.id || !set.url || checkIsOutsideBounds(latLng, set.bounds)) {
          setTooltip(undefined);
          return;
        }

        const info = await titilerApiService.getPointInfo(set.url, latLng?.lng, latLng?.lat, 1);

        const name = greeneryObjects.filter(({ id }) => id === set.id - 1)[0]?.name;
        const labelName = greeneryObjects.filter(({ id }) => id === set.id - 1)[0]?.label_name;

        if (info?.values && latLng.lat && latLng.lng && info.values[0] > 0 && layersVisibility[name] && labelName) {
          setTooltip({
            id: set.id,
            lat: latLng?.lat,
            lng: latLng?.lng,
            value: simpleRound(info.values[0], precision),
            label: labelName,
          });
        }
      });
    }, TOOLTIP_DISPLAY_DELAY),
  );

  return tooltip ? (
    <MapTooltip tooltipData={tooltip} content={buildGreeneryDetailedTooltipStringHelper(tooltip)} />
  ) : null;
};
