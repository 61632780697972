import { useEffect } from 'react';
import { useMap } from 'react-leaflet';
import L from 'leaflet';

import { GEOJSON_OBJECT_DEFAULT_COLOR } from 'common/constants';
import { type MultilineGeoJson } from 'common/types/mapData';

interface GeoJSONLayerProps {
  pane: string;
  geoJson: Partial<MultilineGeoJson>;
  color: string;
  opacity?: number;
}

export const GeoJSONLayer = ({ pane, geoJson, color, opacity = 1 }: GeoJSONLayerProps) => {
  const map = useMap();
  const geoJSON = L.geoJSON(geoJson, {
    pane,
    color: color || GEOJSON_OBJECT_DEFAULT_COLOR,
    opacity,
    interactive: false,
  });

  useEffect(() => {
    geoJSON.addTo(map);

    return () => {
      geoJSON.remove();
    };
  }, [geoJSON, map]);

  return null;
};
