import { useGetActiveOptionReportsCreatorUseCase } from 'views/reports/creator/useCases/getActiveOptionReportsCreatorUseCase';
import { WaterQualitySingleDateReportsExternalData } from 'views/reports/external/data/WaterQualitySingleDateReportsExternalData';
import { WaterQualityDobuleDateReportsExternalInitialData } from 'views/reports/external/data/WaterQualityDobuleDateReportsExternalInitialData';
import { useWaterIndicators } from 'views/water/quality/useWaterIndicators';
import { type ExternalReportHeaderCommon } from 'domain/reports/types';

interface WaterQualityReportsExternalDataProps {
  areaId: number;
  timestamp: string;
  reportInfo: ExternalReportHeaderCommon;
}

export const WaterQualityReportsExternalData = ({
  areaId,
  timestamp,
  reportInfo,
}: WaterQualityReportsExternalDataProps) => {
  const { isCompareDatesType } = useGetActiveOptionReportsCreatorUseCase();

  const { selectedIndicator } = useWaterIndicators();

  return (
    selectedIndicator &&
    (isCompareDatesType ? (
      <WaterQualityDobuleDateReportsExternalInitialData
        areaId={areaId}
        timestamp={timestamp}
        selectedIndicator={selectedIndicator}
        reportInfo={reportInfo}
      />
    ) : (
      <WaterQualitySingleDateReportsExternalData
        areaId={areaId}
        timestamp={timestamp}
        selectedIndicator={selectedIndicator}
        reportInfo={reportInfo}
      />
    ))
  );
};
