import { useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import {
  AreaInfoButtonInnerWrapper,
  AreaInfoButtonsWrapper,
  AreaInfoDescription,
} from 'ui/organisms/areaInfoPanel/components';
import { ReactComponent as TargetIcon } from 'assets/svg/target.svg';
import { Button } from 'ui/atoms/button';
import { HorizontalSwiper, type HorizontalSwiperRef } from 'ui/molecules/horizontalSwiper/HorizontalSwiper';

interface TargetsSectionProps {
  siteTargets: string[];
  recommendedSites: string[];
  setHoveredDepositSiteName: (hoveredDepositSiteName: string | undefined) => void;
  setSelectedDepositSiteName: (selectedDepositSiteName: string | undefined) => void;
}

export const TargetsSection = ({
  siteTargets,
  recommendedSites,
  setHoveredDepositSiteName,
  setSelectedDepositSiteName,
}: TargetsSectionProps) => {
  const [scrollExist, setScrollExist] = useState(false);
  const swipersRef = useRef<(HorizontalSwiperRef | null)[]>([]);
  const onScrollChangeVisibilityHandler = () => setScrollExist(!!swipersRef.current.find((el) => el?.scrollExist));

  return (
    <Wrapper scrollExist={scrollExist}>
      {!!siteTargets.length && (
        <div>
          <AreaInfoDescription paddingLeft={30}>IDENTIFIED TARGETS</AreaInfoDescription>
          <HorizontalSwiper
            width={350}
            ref={(el) => (swipersRef.current[0] = el)}
            onScrollChangeVisibility={onScrollChangeVisibilityHandler}
          >
            <AreaInfoButtonsWrapper>
              {siteTargets.map((target) => (
                <Button
                  minWidth={80}
                  key={target}
                  onClick={() => setSelectedDepositSiteName(target)}
                  onMouseEnter={() => setHoveredDepositSiteName(target)}
                  onMouseLeave={() => setHoveredDepositSiteName(undefined)}
                >
                  <AreaInfoButtonInnerWrapper>
                    <StyledTargetIcon /> {target}
                  </AreaInfoButtonInnerWrapper>
                </Button>
              ))}
            </AreaInfoButtonsWrapper>
          </HorizontalSwiper>
        </div>
      )}
      {!!recommendedSites.length && (
        <div>
          <AreaInfoDescription paddingLeft={30}>TOP RECOMMENDED LOCATIONS</AreaInfoDescription>
          <HorizontalSwiper
            width={350}
            ref={(el) => (swipersRef.current[1] = el)}
            onScrollChangeVisibility={onScrollChangeVisibilityHandler}
          >
            <AreaInfoButtonsWrapper>
              {recommendedSites.map((site) => (
                <Button
                  minWidth={80}
                  key={site}
                  onClick={() => setSelectedDepositSiteName(site)}
                  onMouseEnter={() => setHoveredDepositSiteName(site)}
                  onMouseLeave={() => setHoveredDepositSiteName(undefined)}
                >
                  <AreaInfoButtonInnerWrapper>{site}</AreaInfoButtonInnerWrapper>
                </Button>
              ))}
            </AreaInfoButtonsWrapper>
          </HorizontalSwiper>
        </div>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div<{ scrollExist?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 25px;
  position: relative;

  &::before,
  &::after {
    content: '';
    position: absolute;
    z-index: 1;
    pointer-events: none;
    bottom: 0;
    top: -30px;
    bottom: -30px;
  }

  ${({ scrollExist }) =>
    scrollExist &&
    css`
      &::before {
        left: 0;
        width: 30px;
        background: linear-gradient(90deg, #232020 0%, rgba(35, 32, 32, 0) 100%);
      }

      &::after {
        right: 0;
        width: 70px;
        background: linear-gradient(-90deg, rgba(35, 32, 32, 0.85) 6.14%, rgba(35, 32, 32, 0) 100%);
      }
    `}
`;

const StyledTargetIcon = styled(TargetIcon)`
  width: 18px;
  height: 18px;
`;
