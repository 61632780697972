import { useAoiNumber } from 'common/navigation/hooks';
import { areasRepository } from 'infrastructure/areas/areasRepository';

export const useGetAoiName = () => {
  const aoiId = useAoiNumber();

  const { areaOfInterestDetails } = areasRepository.useFetchAreaOfInterestDetails(aoiId);
  const areaName = areaOfInterestDetails?.properties.name;

  return areaName;
};
