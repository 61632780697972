import { Fragment } from 'react';
import { Polygon } from 'react-leaflet';

import { type LayerObject } from 'common/types/mapData';
import { Loading } from 'ui/molecules/loading';
import { useLayerOrderStore } from 'components';
import * as map from 'components/map';
import { useTimelineService } from 'components/Timeline/timelineService';
import { useSortedLayersData } from 'components/layerManager/useSortedLayersData';
import { useExtractionObjectTypesService } from 'views/mine/extractionLayersService';
import { useGetExtractionPolygonsUseCase } from 'views/mine/useGetExtractionPolygonsUseCase';
import { useGetExtractionResultsUseCase } from 'views/mine/useGetExtractionResultsUseCase';
import { type MinePolygonsTuple } from 'domain/extraction/types';
import { useExtractionLayersVisibility } from './useExtractionLayersVisibility';
import { MineActivityIndex } from './MineActivityIndex';

interface ExtractionMapContentProps {
  segmentationLegendOptions: LayerObject[];
  minePolygons: MinePolygonsTuple;
}

const ExtractionSortedLayers = ({ segmentationLegendOptions, minePolygons }: ExtractionMapContentProps) => {
  const { layersOrderRecord } = useLayerOrderStore();
  const sortedSegmentationLegendOptions = useSortedLayersData(layersOrderRecord, segmentationLegendOptions, 'id');

  const { classesVisibility } = useExtractionLayersVisibility();

  return (
    <>
      {sortedSegmentationLegendOptions.map(({ id, name, color }, index) => (
        <Fragment key={id}>
          <map.Layer key={index} visible={!!classesVisibility[name]} name={name} zIndex={599 - index}>
            {minePolygons[id] && <Polygon positions={minePolygons[id]} pane={name} pathOptions={{ color }} />}
          </map.Layer>
        </Fragment>
      ))}
    </>
  );
};

export const ExtractionMapContent = () => {
  const { timestamp } = useTimelineService();

  const { segmentationLegendOptions } = useExtractionObjectTypesService();

  const { mineResultsLoading } = useGetExtractionResultsUseCase();
  const { minePolygons, minePolygonsLoading } = useGetExtractionPolygonsUseCase();

  if (mineResultsLoading || minePolygonsLoading) {
    return <Loading />;
  }

  return (
    <>
      <ExtractionSortedLayers segmentationLegendOptions={segmentationLegendOptions} minePolygons={minePolygons} />
      {timestamp && <MineActivityIndex timestamp={timestamp} />}
    </>
  );
};
