import { useAoiNumber } from 'common/navigation/hooks';
import { useTimelineService } from 'components/Timeline/timelineService';
import { waterRepository } from 'infrastructure/water/waterRepository';

export const useGetWaterAreaResultsUseCase = (date?: string | null, waterbodyId?: number) => {
  const areaId = useAoiNumber();
  const { timestamp } = useTimelineService();

  const { waterAreaResults, waterAreaResultsLoading } = waterRepository.useFetchWaterAreaResults(
    date || timestamp || undefined,
    areaId,
    waterbodyId,
  );

  return { waterAreaResults, waterAreaResultsLoading };
};
