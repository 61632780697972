import { useCallback, useEffect, useMemo, useState } from 'react';
import { type AxiosResponse } from 'axios';

import { type BaseApiListResponse, type ResultsRange } from 'common/types/api';
import { QUERY_FETCHING_LIMIT } from 'services/rest/constants';
import { DEFAULT_RESULTS_PER_PAGE } from 'common/constants/services';

export const useAxios = <T, P>(
  asyncFunction: (...params: P[]) => Promise<AxiosResponse<T>>,
  params: P[] = [],
  immediate: boolean = true,
) => {
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [response, setResponse] = useState<T | null>(null);
  const [error, setError] = useState<string | null>(null);

  const execute = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (callbackParams: any[] = []) => {
      setIsFetching(true);
      setError(null);
      setResponse(null);

      asyncFunction(...callbackParams)
        .then((response) => {
          setResponse(response.data);
          setTimeout(() => setIsFetching(false), 0);
        })
        .catch((error) => {
          setError(error);
          setIsFetching(false);
        });
    },
    [asyncFunction],
  );

  useEffect(() => {
    if (immediate) {
      execute(params);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [execute, immediate]);

  return {
    isFetching,
    response,
    error,
    execute,
  };
};

export const useLoopedQuery = <P, R>(
  queryService: (
    params: P,
    limit: number,
    offset: number,
  ) => { data: AxiosResponse<BaseApiListResponse & R> | undefined; isLoading: boolean; error: unknown },
  params: P,
  dependencies: P[],
) => {
  const initialRange: ResultsRange = useMemo(
    () => ({
      limit: DEFAULT_RESULTS_PER_PAGE,
      offset: 0,
    }),
    [],
  );

  const [range, setRange] = useState<ResultsRange>(initialRange);
  const limit = range?.limit;
  const offset = range?.offset;

  const { data, isLoading, error } = queryService(params, limit, offset);

  useEffect(() => {
    setRange(initialRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [...dependencies]);

  useEffect(() => {
    if (data && typeof offset === 'number' && data.data.next !== null) {
      setRange((range) => ({
        limit: QUERY_FETCHING_LIMIT,
        offset: range.offset + QUERY_FETCHING_LIMIT,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.data?.next]);

  return { data, isLoading: isLoading && offset === initialRange.offset, error };
};
