import { useQueryParameter, useAoiNumber } from 'common/navigation/hooks';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { ShareModalContent } from 'ui/organisms/shareModal/ShareModalContent';
import { ShareModalBody } from 'ui/organisms/shareModal';
import { DATE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { formatDate } from 'common/utils/datetime';
import { DEFAULT_DATE_FORMAT_DASH } from 'common/constants/datetime';

interface ShareModalProps {
  links: string[];
  activeTab: string;
}

export const ShareModal = ({ links, activeTab }: ShareModalProps) => {
  const areaId = useAoiNumber();
  const date = useQueryParameter(DATE_QUERY_PARAMETER);

  const { areaOfInterestDetails } = areasRepository.useFetchAreaOfInterestDetails(areaId);

  const fileName = `${areaOfInterestDetails?.id}_${activeTab}${
    date ? `_${formatDate(date, DEFAULT_DATE_FORMAT_DASH)}` : ''
  }`;

  return (
    <ShareModalBody>
      <ShareModalContent fileLinks={links} fileType="tiff" fileName={fileName} />
    </ShareModalBody>
  );
};
