import { PUBLIC_CONTAINER_URL, RESEARCH_ML_STORAGE_URL } from 'common/constants/services';

export const GREENERY_API_URLS = {
  getObjectTypes: '/greenery/object-types/',
  getDateResults: '/greenery/results/dates/',

  getSegmentationResults: '/greenery/segmentations/',
  getSegmentationCog: '/greenery/segmentations/:resultId/get_cog/',
  getSegmentationComparatorCog: '/greenery/segmentations_comparator/get_cog/',
  getSegmentationAreaResults: '/greenery/areas/',
  getBiomassCog: `${RESEARCH_ML_STORAGE_URL}/frontend-mocks/biomass/aoi522.tif`,

  getQuarterlySegmentationResults: '/greenery/quarterly_segmentations/',
  getQuarterlySegmentationCog: '/greenery/quarterly_segmentations/:resultId/get_cog/',
  getQuarterlySegmentationComparatorCog: '/greenery/quarterly_segmentations_comparator/get_cog/',

  getVegetationIndicators: '/greenery/indicators/',
  getVegetationIndicatorStats: '/greenery/results/:resultId/',
  getVegetationResults: '/greenery/results/',
  getVegetationCog: '/greenery/results/:resultId/get_cog/',
  getVegetationComparatorCog: '/greenery/results_comparator/get_cog/',

  getHotspotsResults: '/greenery/hot-spots/',
  getHotspotsCog: '/greenery/hot-spots/:resultId/get_cog/',

  getVhrObjectTypes: `${PUBLIC_CONTAINER_URL}/vhr/vhr_object_types.json`,
  getVhrSegmentationCog: `${PUBLIC_CONTAINER_URL}/vhr/vhr_segmentation_cog_url.json`,

  getVciResults: '/greenery/vci-results/',
  getVciCog: '/greenery/vci-results/:resultId/get_cog/',
};
