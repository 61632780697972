import { RESEARCH_ML_STORAGE_URL } from 'common/constants/services';

export const pleiadesUrls = [
  {
    id: 1,
    date: '2022-10-25T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/pleiades/ndvi/pansharpened_2022-10-25_ndvi.tif`,
  },
  {
    id: 2,
    date: '2022-03-11T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/pleiades/ndvi/pansharpened_2022-03-11_ndvi.tif`,
  },
  {
    id: 3,
    date: '2021-10-20T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/pleiades/ndvi/pansharpened_2021-10-20_ndvi.tif`,
  },
  {
    id: 4,
    date: '2020-11-09T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/pleiades/ndvi/pansharpened_2020-11-09_ndvi.tif`,
  },
];

export const planetscopeUrls = [
  {
    id: 1,
    date: '2023-06-02T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/ndvi/SKYWATCH_PLSD_PS_20230602T0447_ALL_Tile_0_0_07ac_ndvi.tif`,
  },
  {
    id: 2,
    date: '2023-05-05T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/ndvi/SKYWATCH_PLSD_PS_20230505T0440_ALL_Tile_0_0_9edd_ndvi.tif`,
  },
  {
    id: 3,
    date: '2023-04-01T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/ndvi/SKYWATCH_PLSD_PS_20230401T0439_ALL_Tile_0_0_a119_ndvi.tif`,
  },
  {
    id: 4,
    date: '2023-03-02T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/ndvi/SKYWATCH_PLSD_PS_20230302T0443_ALL_Tile_0_0_40fc_ndvi.tif`,
  },
  {
    id: 5,
    date: '2023-02-03T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/ndvi/SKYWATCH_PLSD_PS_20230203T0435_ALL_Tile_0_0_9536_ndvi.tif`,
  },
  {
    id: 6,
    date: '2023-01-02T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/ndvi/SKYWATCH_PLSD_PS_20230102T0438_ALL_Tile_0_0_7145_ndvi.tif`,
  },
  {
    id: 7,
    date: '2022-12-01T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/ndvi/SKYWATCH_PLSD_PS_20221201T0514_ALL_Tile_0_0_4456_ndvi.tif`,
  },
  {
    id: 8,
    date: '2022-11-04T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/ndvi/SKYWATCH_PLSD_PS_20221104T0439_ALL_Tile_0_0_1358_ndvi.tif`,
  },
  {
    id: 9,
    date: '2022-10-09T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/ndvi/SKYWATCH_PLSD_PS_20221009T0515_ALL_Tile_0_0_093c_ndvi.tif`,
  },
  {
    id: 10,
    date: '2022-06-05T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/ndvi/SKYWATCH_PLSD_PS_20220605T0445_ALL_Tile_0_0_8735_ndvi.tif`,
  },
  {
    id: 11,
    date: '2022-05-03T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/ndvi/SKYWATCH_PLSD_PS_20220503T0442_ALL_Tile_0_0_ce05_ndvi.tif`,
  },
  {
    id: 12,
    date: '2022-04-03T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/ndvi/SKYWATCH_PLSD_PS_20220403T0441_ALL_Tile_0_0_ec4a_ndvi.tif`,
  },
  {
    id: 13,
    date: '2022-03-12T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/ndvi/SKYWATCH_PLSD_PS_20220312T0442_ALL_Tile_0_0_2fb8_ndvi.tif`,
  },
  {
    id: 14,
    date: '2022-02-03T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/ndvi/SKYWATCH_PLSD_PS_20220203T0514_ALL_Tile_0_0_7dd8_ndvi.tif`,
  },
];
