export const TIMELINE_OPTION_NAME = 'timeline';
export const COMPARISON_OPTION_NAME = 'comparison';
export const HOTSPOTS_OPTION_NAME = 'hotspots';

export const MAP_POSITION_SAVE_DELAY = 1_000;

export const TO_FULL_DAY_OFFSET = 86399999;
export const MILISECONDS_MULTIPLIER = 1_000;

export const AreaEnvironments = {
  DISPLACEMENT: 'displacement',
  MINE: 'mine',
  LAND_COVER: 'land_cover',
  WATER: 'water',
  MINERALIZATION: 'surface_mineralization',
  TEMPERATURE: 'temperature',
} as const;

export const AreaPages = {
  ...AreaEnvironments,
  OVERVIEW: 'overview',
} as const;

export const AreaTypes = {
  MONITORING: 'M',
  PROSPECTING: 'P',
} as const;
