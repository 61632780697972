import { useQueryParameter } from 'common/navigation/hooks';
import { type CogResult } from 'common/types/api';
import { type ValueAndLabel } from 'common/types';
import { type LeafletLatLng } from 'common/types/mapData';
import { COMPARISON_OPTION_NAME } from 'common/navigation/constants';
import { VIEW_MODE_QUERY_PARAMETER, WATERBODY_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { useReportsStore } from 'infrastructure/reports/reportsStore';
import { Loading } from 'ui/molecules/loading';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import * as map from 'components/map';
import { ShareModal } from 'components';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { WaterAreaMapLayer } from 'views/water/surface/WaterAreaMapLayer';
import { useWaterAreaDataService } from 'views/water/surface/waterAreaDataService';
import { useGetWaterAreaComparatorCogsUseCase } from 'views/water/surface/getWaterAreaComparatorCogsUseCase';
import { WaterAreaSpecialMapContent } from 'views/water/surface/WaterAreaSpecialMapContent';
import { WaterAreaTitilerTooltip } from 'views/water/surface/WaterAreaTitilerTooltip';
import { WaterAreaMapContentComparison } from 'views/water/surface/WaterAreaMapContentComparison';
import { useHandleWaterbodyChangeUseCase } from 'views/water/useCases/handleWaterbodyChangeUseCase';
import { useWaterAreaSelectUseCase } from 'views/water/surface/waterAreaSelectUseCase';
import { WATERMASKS_MAIN_COLOR, WATERMASKS_REPORT_COLOR } from 'domain/water/colors';
import { WaterActiveTab } from 'domain/water/enums';

export const WaterAreaMapContent = () => {
  const waterbodyIdQuery = useQueryParameter(WATERBODY_QUERY_PARAMETER);

  const isDataLayerVisible = useLayerManagerStore.use.isDataLayerVisible();
  const layersVisibility = useLayerManagerStore.use.layersVisibility();

  const takeScreenshotFlag = useReportsStore.use.takeScreenshotFlag();

  const areReportsActive = useGetAreReportsActiveUseCase();
  const waterAreaLayerColor = areReportsActive ? WATERMASKS_REPORT_COLOR : WATERMASKS_MAIN_COLOR;

  const viewQuery: string | null = useQueryParameter(VIEW_MODE_QUERY_PARAMETER);
  const isComparisonActive: boolean = viewQuery === COMPARISON_OPTION_NAME;

  const { cogsUrlWithBounds, cogsLoading } = useWaterAreaDataService();
  const { comparatorCogsUrlTuples, comparatorCogsLoading } = useGetWaterAreaComparatorCogsUseCase();

  const filteredCogsUrlWithBounds = waterbodyIdQuery
    ? cogsUrlWithBounds.filter(
        (item) => item.waterbodyId === Number(waterbodyIdQuery) || item.waterbodyId === undefined,
      )
    : cogsUrlWithBounds;

  const availableCogsUrlWithBounds = isComparisonActive
    ? filteredCogsUrlWithBounds.filter(({ id }) => comparatorCogsUrlTuples.some((tuple) => tuple[0] === String(id)))
    : filteredCogsUrlWithBounds;

  const shareModalLinks = filteredCogsUrlWithBounds.map(({ url }) => url);

  const { changeWaterbody } = useHandleWaterbodyChangeUseCase();

  const { selectWaterArea } = useWaterAreaSelectUseCase(waterbodyIdQuery);

  const handleWaterAreaClick = async (waterBody: ValueAndLabel, latLng: LeafletLatLng) => {
    if (!waterbodyIdQuery) {
      changeWaterbody(waterBody);
    } else {
      selectWaterArea(latLng.lat, latLng.lng, 0);
    }
  };

  // TODO: remove when mercedes data will be handled
  const isSpecialModeActive = layersVisibility['pleiades'] === true || layersVisibility['planet_scope'] === true;
  // TODO

  if (cogsLoading || comparatorCogsLoading) {
    return <Loading />;
  }

  return (
    <>
      <map.Layer visible={isDataLayerVisible || isSpecialModeActive} name={'dataLayer'} zIndex={490}>
        {/* // TODO: remove when mercedes data will be handled */}
        <WaterAreaSpecialMapContent />
        {/* TODO */}
        {!isSpecialModeActive &&
          availableCogsUrlWithBounds.map(
            (set: CogResult) =>
              set?.url && (
                <WaterAreaMapLayer key={set.id} url={set.url} pane={'dataLayer'} color={waterAreaLayerColor} />
              ),
          )}
        {!takeScreenshotFlag && (
          <WaterAreaTitilerTooltip
            isDataLayerVisible={isDataLayerVisible}
            urlsWithIds={filteredCogsUrlWithBounds}
            onWaterAreaClick={handleWaterAreaClick}
          />
        )}
      </map.Layer>
      {isComparisonActive ? <WaterAreaMapContentComparison /> : null}
      <ShareModal links={shareModalLinks} activeTab={WaterActiveTab.SURFACE} />
    </>
  );
};
