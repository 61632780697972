import styled from 'styled-components';

import { BaseModal, ModalHeader } from 'ui/modal';
import { type SubscriptionType } from 'domain/subscription/types';
import { SubscriptionUpgradeTile } from './SubscriptionUpgradeTile';

interface UpgradeModalProps {
  type: SubscriptionType;
  data: {
    addedQuota: number;
    price: string;
  };
  isOpen: boolean;
  closeModal: () => void;
  onConfirm: () => void;
}

export const UpgradeModal = ({ type, data, isOpen = true, closeModal, onConfirm }: UpgradeModalProps) => {
  const handleConfirm = () => {
    onConfirm();
    closeModal();
  };

  return (
    <BaseModal {...{ isOpen, closeModal }}>
      <ContentContainer>
        <ModalHeader>add more calculation points</ModalHeader>
        <SubscriptionUpgradeTile
          type={type}
          quota={data.addedQuota}
          price={data.price}
          handleUpgrade={handleConfirm}
          handleCancel={closeModal}
        />
      </ContentContainer>
    </BaseModal>
  );
};

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 54px;
`;
