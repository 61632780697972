import { Fragment } from 'react';

import { TileLayer } from 'components/map/layers/TileLayer';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { Loading } from 'ui/molecules/loading';
import { useGetCogWithBounds } from 'services/titiler/hooks';

interface TopographyMapContentProps {
  areaName: string;
}

export const TopographyMapContent = ({ areaName }: TopographyMapContentProps) => {
  const { layersList, layersLoading } = areasRepository.useFetchTopographyLayers(areaName);

  if (!layersList.length) {
    return null;
  }

  return (
    <>
      {layersLoading && <Loading />}
      {layersList.map((layer) => (
        <Fragment key={layer.id}>
          <HillshadeLayer url={layer.hillshadeUrl} />
          <TopographyLayer url={layer.topographyUrl} range={layer.topographyRange} />
        </Fragment>
      ))}
    </>
  );
};

interface HillshadeLayerProps {
  url: string;
}

const HillshadeLayer = ({ url }: HillshadeLayerProps) => {
  const isHillshadeVisible = useLayerManagerStore.use.isHillshadeVisible();
  const hillshadeOpacity = useLayerManagerStore.use.hillshadeOpacity();

  const { cogUrl, cogBounds } = useGetCogWithBounds(titilerApiAdapter.buildHillshadeCogUrl, url, {});

  if (!cogUrl || !cogBounds) {
    return null;
  }

  return (
    <TileLayer
      isVisible={isHillshadeVisible}
      zIndex={420}
      layerName="hillshade"
      bounds={cogBounds}
      opacity={hillshadeOpacity}
      cogUrl={cogUrl}
    />
  );
};

interface TopographyLayerProps {
  url: string;
  range: number[];
}

const TopographyLayer = ({ url, range }: TopographyLayerProps) => {
  const isTopographyVisible = useLayerManagerStore.use.isTopographyVisible();
  const topographyOpacity = useLayerManagerStore.use.topographyOpacity();

  const { cogUrl, cogBounds } = useGetCogWithBounds(titilerApiAdapter.buildDEMColormapUrl, url, { range });

  if (!cogUrl || !cogBounds) {
    return null;
  }

  return (
    <TileLayer
      isVisible={isTopographyVisible}
      zIndex={421}
      layerName="dem"
      bounds={cogBounds}
      opacity={topographyOpacity}
      cogUrl={cogUrl}
    />
  );
};
