import { Select, Slider, Container, Button } from 'ui';
import {
  FoldableSection,
  FoldableSectionCheckbox,
  FoldableSectionContentWrapper,
  AuxiliaryControlsWrapper,
  LegendItemWraper,
  useFoldableSectionStatus,
  REQUEST_MORE_DATA_MESSAGE,
} from 'components/layerManager';
import { CLUSTERS_INFO_POINT_DESCRIPTION } from 'domain/exploration/configs';
import { useClusters } from './useClusters';
import { useExplorationLayerManagerStore } from '../explorationLayerManagerStore';

export const ClustersLayerManager = () => {
  const { clustersResults, clustersAmountArray, clustersSuppliersSelectData, handleClustersSupplierChange } =
    useClusters();

  const isClustersVisible = useExplorationLayerManagerStore.use.isClustersVisible();
  const toggleClustersVisibility = useExplorationLayerManagerStore.use.toggleClustersVisibility();
  const clustersAmount = useExplorationLayerManagerStore.use.clustersAmount();
  const setClustersAmount = useExplorationLayerManagerStore.use.setClustersAmount();
  const isClustersSelectionMode = useExplorationLayerManagerStore.use.isClustersSelectionMode();
  const enableClustersSelectionMode = useExplorationLayerManagerStore.use.enableClustersSelectionMode();
  const selectedClustersSaved = useExplorationLayerManagerStore.use.selectedClustersSaved();
  const clearSelectedClusters = useExplorationLayerManagerStore.use.clearSelectedClusters();

  const marks = Object.assign({}, ...clustersAmountArray.map(String).map((i) => ({ [i]: i })));

  const onClustersAmountChange = (value: number | number[]) => {
    setClustersAmount(Array.isArray(value) ? value[0] : value);
  };

  const { description, areResultsAvailable } = useFoldableSectionStatus({
    defaultDescription: CLUSTERS_INFO_POINT_DESCRIPTION,
    noDataMessage: REQUEST_MORE_DATA_MESSAGE,
    areResultsAvailable: !!clustersResults.length,
  });

  return (
    <FoldableSection
      headerContent={
        <FoldableSectionCheckbox
          variant="NORMAL"
          title="AI clusters"
          description={description}
          isCheckboxChecked={isClustersVisible && areResultsAvailable}
          onCheckboxToggle={toggleClustersVisibility}
          disabled={!areResultsAvailable}
        />
      }
      disabled={!areResultsAvailable}
    >
      <LegendItemWraper>
        <FoldableSectionContentWrapper>
          <div>
            <AuxiliaryControlsWrapper
              disabled={!isClustersVisible || !!selectedClustersSaved.length || isClustersSelectionMode}
            >
              <Container variant="VERTICAL_START" gap={10} mt={10}>
                <Container gap={10}>
                  provider
                  <Select {...clustersSuppliersSelectData} onChange={handleClustersSupplierChange} />
                </Container>
                {!!clustersAmountArray.length && (
                  <Container wMax gap={10} pr={10}>
                    amount
                    <Slider
                      ariaLabel="Clusters amount slider"
                      marks={marks}
                      value={clustersAmount}
                      onChange={onClustersAmountChange}
                      defaultValue={clustersAmountArray[0]}
                      min={clustersAmountArray[0]}
                      max={clustersAmountArray[clustersAmountArray.length - 1]}
                      step={null}
                    />
                  </Container>
                )}
              </Container>
            </AuxiliaryControlsWrapper>
            <AuxiliaryControlsWrapper disabled={!isClustersVisible}>
              <Container gap={10} mt={10} pb={16}>
                <Button
                  variant="SECONDARY_SMALL_DARK"
                  onClick={enableClustersSelectionMode}
                  disabled={isClustersSelectionMode}
                  minWidth={140}
                >
                  {selectedClustersSaved.length ? 'Change Selection' : 'Select Clusters'}
                </Button>
                <Button
                  variant="SECONDARY_SMALL_DARK"
                  onClick={() => clearSelectedClusters()}
                  disabled={isClustersSelectionMode || !selectedClustersSaved.length}
                  minWidth={65}
                >
                  Reset
                </Button>
              </Container>
            </AuxiliaryControlsWrapper>
          </div>
        </FoldableSectionContentWrapper>
      </LegendItemWraper>
    </FoldableSection>
  );
};
