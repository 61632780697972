import { type LayerObject } from 'common/types/mapData';
import { useAoiNumber } from 'common/navigation/hooks';
import { LayerManagerLegend } from 'components/layerManager/legacy';
import { type MoistureResult } from 'domain/water/types';
import { useMoistureLayersVisibility } from './useMoistureLayersVisibility';

export const MoistureLayerManagerLegend = ({ results }: { results: MoistureResult[] }) => {
  const areaId = useAoiNumber();

  const legendOptions: LayerObject[] = results.map(({ id, name, label_name, color }) => ({
    id,
    name,
    label_name,
    color,
    status: 'active',
  }));

  const { classesVisibility, updateClassesVisibility, toggleMultipleQueries } = useMoistureLayersVisibility();

  return (
    <LayerManagerLegend
      areaId={areaId}
      options={legendOptions}
      objectsVisibility={classesVisibility}
      toggleLayerVisibility={toggleMultipleQueries}
      updateLayersVisibility={updateClassesVisibility}
    />
  );
};
