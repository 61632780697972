import { useQueryParameter } from 'common/navigation/hooks';
import { COMPARISON_OPTION_NAME, TIMELINE_OPTION_NAME } from 'common/navigation/constants';
import { VIEW_MODE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { useWaterTimestamps } from 'views/water/useWaterTimestamps';

export const useViewOptionsSelectService = () => {
  const viewQuery = useQueryParameter(VIEW_MODE_QUERY_PARAMETER);
  const mapViewOption = viewQuery ? { value: viewQuery, label: viewQuery } : undefined;

  const { timestamps } = useWaterTimestamps();

  const waterbodyViewOptions = [
    {
      value: COMPARISON_OPTION_NAME,
      label: 'comparison',
      disabled: timestamps.length <= 1,
    },
  ];

  const viewOptionsSelectService = {
    viewOptionsSelectData: {
      options: waterbodyViewOptions,
      selectedOption: mapViewOption,
      initialStateLabel: TIMELINE_OPTION_NAME,
      name: 'view selection',
      minWidth: 130,
    },
  };

  return viewOptionsSelectService;
};
