import { Slider, Container, Button, ModeSwitcher } from 'ui';
import {
  FoldableSectionCheckbox,
  FoldableSectionContentWrapper,
  FoldableSection,
  AuxiliaryControlsWrapper,
  LegendItemWraper,
  REQUEST_MORE_DATA_MESSAGE,
  useFoldableSectionStatus,
} from 'components/layerManager';
import { LayerManagerThresholdSlider } from 'components/layerManager/ThresholdSlider';
import { WithCogBandRange } from 'services/titiler/WithCogBandRange';
import {
  LINEAMENTS_INFO_POINT_DESCRIPTION,
  LINEAMENT_DENSITY_ERROR_INFO_POINT_DESCRIPTION,
  LINEAMENT_DENSITY_INFO_POINT_DESCRIPTION,
  LINEAMENT_DENSITY_LAYER_NAME,
  LINEAMENT_DENSITY_SLIDER_CONFIG,
} from 'domain/exploration/configs';
import { SPECTRAL_COLORMAP } from 'domain/exploration/colors';
import { useLineamentDensity } from './useLineamentDensity';
import { useLineaments } from './useLineaments';
import { useExplorationLayerManagerStore } from '../explorationLayerManagerStore';

interface LineamentsLayerManagerProps {
  areaName: string;
}

export const LineamentsLayerManager = ({ areaName }: LineamentsLayerManagerProps) => {
  const areLineamentsVisible = useExplorationLayerManagerStore.use.areLineamentsVisible();
  const toggleLineamentsVisibility = useExplorationLayerManagerStore.use.toggleLineamentsVisibility();
  const lineamentsOpacity = useExplorationLayerManagerStore.use.lineamentsOpacity();
  const setLineamentsOpacity = useExplorationLayerManagerStore.use.setLineamentsOpacity();
  const isLineamentsSelectionMode = useExplorationLayerManagerStore.use.isLineamentsSelectionMode();
  const enableLineamentsSelectionMode = useExplorationLayerManagerStore.use.enableLineamentsSelectionMode();
  const clearSelectedLineaments = useExplorationLayerManagerStore.use.clearSelectedLineaments();
  const selectedLineamentsSaved = useExplorationLayerManagerStore.use.selectedLineamentsSaved();
  const isLineamentDensityVisible = useExplorationLayerManagerStore.use.isLineamentDensityVisible();
  const toggleLineamentDensityVisibility = useExplorationLayerManagerStore.use.toggleLineamentDensityVisibility();
  const lineamentDensityOpacity = useExplorationLayerManagerStore.use.lineamentDensityOpacity();
  const setLineamentDensityOpacity = useExplorationLayerManagerStore.use.setLineamentDensityOpacity();

  const onLineamentsOpacityChange = (value: number | number[]) => {
    setLineamentsOpacity(Array.isArray(value) ? value[0] : value);
  };

  const onLineamentDensityOpacityChange = (value: number | number[]) => {
    setLineamentDensityOpacity(Array.isArray(value) ? value[0] : value);
  };

  const onTresholdsChange = (value: number | number[]) => {
    changeOption(Array.isArray(value) && value.length >= 2 ? [value[0], value[1]] : null);
  };

  const { selectedOption, defaultValue, rangeResult, ranges, changeOption } = useLineaments();

  const marks = Object.assign({}, ...ranges.map(String).map((i) => ({ [i]: i })));

  const { densityResult } = useLineamentDensity(areaName);

  const { description, areResultsAvailable } = useFoldableSectionStatus({
    defaultDescription: LINEAMENTS_INFO_POINT_DESCRIPTION,
    noDataMessage: REQUEST_MORE_DATA_MESSAGE,
    areResultsAvailable: !!rangeResult,
  });

  return (
    <FoldableSection
      headerContent={
        <FoldableSectionCheckbox
          variant="NORMAL"
          title="lineaments"
          description={description}
          isCheckboxChecked={areLineamentsVisible && areResultsAvailable}
          onCheckboxToggle={toggleLineamentsVisibility}
          disabled={!areResultsAvailable}
        />
      }
      disabled={!areResultsAvailable}
    >
      <LegendItemWraper>
        <FoldableSectionContentWrapper>
          <div>
            <AuxiliaryControlsWrapper
              disabled={!areLineamentsVisible || isLineamentsSelectionMode || !!selectedLineamentsSaved.length}
            >
              <Container wMax gap={10} pr={10} mt={10}>
                length range
                <Slider
                  ariaLabel="Lineaments opacity slider"
                  marks={marks}
                  value={selectedOption}
                  variant="RANGE"
                  onChange={onTresholdsChange}
                  defaultValue={defaultValue}
                  min={ranges[0]}
                  max={ranges[ranges.length - 1]}
                  step={null}
                />
              </Container>
            </AuxiliaryControlsWrapper>
            <AuxiliaryControlsWrapper disabled={!areLineamentsVisible}>
              <Container variant="VERTICAL_START" gap={10} mt={10}>
                <Container wMax gap={10} pr={10}>
                  opacity
                  <Slider
                    ariaLabel="Lineaments opacity slider"
                    marks={{
                      '0': '',
                      '1': '',
                    }}
                    value={lineamentsOpacity}
                    onChange={onLineamentsOpacityChange}
                    defaultValue={0.5}
                    min={0}
                    max={1}
                    step={0.01}
                  />
                </Container>
              </Container>
            </AuxiliaryControlsWrapper>
            <AuxiliaryControlsWrapper disabled={!areLineamentsVisible}>
              <Container gap={10} mt={10} pt={4}>
                <Button
                  variant="SECONDARY_SMALL_DARK"
                  onClick={enableLineamentsSelectionMode}
                  disabled={isLineamentsSelectionMode}
                  minWidth={140}
                >
                  {selectedLineamentsSaved.length ? 'Change Selection' : 'Select Lineaments'}
                </Button>
                <Button
                  variant="SECONDARY_SMALL_DARK"
                  onClick={clearSelectedLineaments}
                  disabled={isLineamentsSelectionMode || !selectedLineamentsSaved.length}
                  minWidth={65}
                >
                  Reset
                </Button>
              </Container>
            </AuxiliaryControlsWrapper>
            <Container mv={14} height={18}>
              <ModeSwitcher
                label="density map"
                isModeOn={isLineamentDensityVisible}
                disabled={!densityResult || !areLineamentsVisible || isLineamentsSelectionMode}
                tooltipContent={
                  !densityResult
                    ? LINEAMENT_DENSITY_ERROR_INFO_POINT_DESCRIPTION
                    : LINEAMENT_DENSITY_INFO_POINT_DESCRIPTION
                }
                toggleMode={toggleLineamentDensityVisibility}
                colormap={SPECTRAL_COLORMAP}
              />
            </Container>
            <AuxiliaryControlsWrapper
              disabled={
                !isLineamentDensityVisible || !densityResult || !areLineamentsVisible || isLineamentsSelectionMode
              }
            >
              {isLineamentDensityVisible && densityResult && (
                <Container variant="VERTICAL_STRETCH" gap={4} mb={14}>
                  <Container gap={10}>
                    cutoff
                    <WithCogBandRange
                      Component={LayerManagerThresholdSlider}
                      props={{ layerName: LINEAMENT_DENSITY_LAYER_NAME, config: LINEAMENT_DENSITY_SLIDER_CONFIG }}
                      url={densityResult.url}
                      precision={LINEAMENT_DENSITY_SLIDER_CONFIG.precision}
                      isFetchingEnabled={true}
                    />
                  </Container>
                  <Container wMax gap={10}>
                    opacity
                    <Slider
                      ariaLabel="Lineament density opacity slider"
                      marks={{
                        '0': '',
                        '1': '',
                      }}
                      value={lineamentDensityOpacity}
                      onChange={onLineamentDensityOpacityChange}
                      defaultValue={1}
                      min={0}
                      max={1}
                      step={0.01}
                    />
                  </Container>
                </Container>
              )}
            </AuxiliaryControlsWrapper>
          </div>
        </FoldableSectionContentWrapper>
      </LegendItemWraper>
    </FoldableSection>
  );
};
