import styled from 'styled-components';

import { COORDINATES_PRECISION } from 'common/constants';
import { ReactComponent as PinIconRounded } from 'assets/svg/pinIconRounded.svg';
import { useOverviewInfoStore } from 'views/prospecting/store';
import { type PinInfoType } from 'views/prospecting/types';
import { ScrollbarWrapper } from 'ui/atoms/scrollbarWrapper';

export const PinsSection = () => {
  const areaPinsInfo = useOverviewInfoStore.use.areaPinsInfo();
  if (!areaPinsInfo) return null;

  return (
    <Wrapper>
      <TextSimpleBar>
        <NotesWrapper>
          {areaPinsInfo.map((pin, i) => (
            <Note pin={pin} key={i} />
          ))}
        </NotesWrapper>
      </TextSimpleBar>
    </Wrapper>
  );
};

const Note = ({ pin }: { pin: PinInfoType }) => {
  return (
    <StyledNote>
      <StyledPin />
      <div>Location: {pin.geometry.coordinates.map((coord) => coord.toFixed(COORDINATES_PRECISION)).join(', ')}</div>
      <strong>{pin.properties.text}</strong>
    </StyledNote>
  );
};

const NotesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-size: 12px;
  line-height: 16px;
`;

const StyledNote = styled.div`
  background-color: ${({ theme }) => theme.color.selectGray};
  border-radius: 10px;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.25);
  padding: 12px;
  margin-right: 20px;
  position: relative;

  & > div {
    line-height: 18px;
  }
`;

const StyledPin = styled(PinIconRounded)`
  position: absolute;
  left: -25px;
  top: 0;
`;

const Wrapper = styled.div`
  flex-grow: 1;
  max-width: 400px;
  position: relative;
  margin-right: 20px;
`;

const TextSimpleBar = styled(ScrollbarWrapper)`
  position: absolute;
  inset: 0;
  padding-bottom: 10px;
  box-sizing: content-box;
  padding-left: 30px;

  .simplebar-scrollbar::before {
    background-color: ${({ theme }) => theme.color.chicago};
    opacity: 1;
  }

  .simplebar-track.simplebar-vertical {
    width: 12px;
    right: 0px;
    top: 0px;
    bottom: 0px;
  }
`;
