import { type LayerObject } from 'common/types/mapData';
import { useGetCogWithBoundsGeneric } from 'services/titiler/hooks';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { DataLayer } from 'components/map';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { Loading } from 'ui/molecules/loading';
import { getAzureBlobStorageUrl } from 'services/titiler/utils';
import { SegmentationTitilerTooltip } from '../components';

interface QuarterlyDataLayerProps {
  quarterlyCogUrl: string;
  chosenResultId: number;
  greeneryObjects: LayerObject[];
  isDataLayerVisible: boolean;
}

export const QuarterlySegmentationDataLayer = ({
  quarterlyCogUrl,
  chosenResultId,
  greeneryObjects,
  isDataLayerVisible,
}: QuarterlyDataLayerProps) => {
  const layersVisibility = useLayerManagerStore.use.layersVisibility();

  const { cogUrl, cogInfo, cogBounds, isInfoLoading } = useGetCogWithBoundsGeneric(
    titilerApiAdapter.buildMultilayerMonocolorCogUrlGeneric,
    getAzureBlobStorageUrl(quarterlyCogUrl),
    {
      objects: greeneryObjects.filter(({ name }) => layersVisibility[name]),
    },
  );

  if (isInfoLoading) {
    return <Loading />;
  }

  return (
    <>
      <DataLayer
        isVisible={isDataLayerVisible}
        layerName={'quarterlySegmentationLayer'}
        zIndex={490}
        cogUrl={cogUrl}
        bounds={cogBounds}
      />
      {quarterlyCogUrl && chosenResultId && (
        <SegmentationTitilerTooltip
          greeneryObjects={greeneryObjects}
          layersVisibility={layersVisibility}
          cogUrl={quarterlyCogUrl}
          cogBounds={cogBounds}
          resultId={chosenResultId}
          cogInfo={cogInfo}
        />
      )}
    </>
  );
};
