import { type ColormapDictionary } from 'common/types';
import {
  COMMON_QUERY_PARAMETERS,
  SECOND_DATE_QUERY_PARAMETER,
  VIEW_MODE_QUERY_PARAMETER,
  WATERBODY_QUERY_PARAMETER,
  WATER_INDICATOR_QUERY_PARAMETER,
  MOISTURE_INDICATOR_QUERY_PARAMETER,
  SNOW_COVER_INDICATOR_QUERY_PARAMETER,
  MOISTURE_CLASSES_QUERY_PARAMETER,
} from 'common/navigation/queryParams';
import { ROUTES } from 'common/navigation/routes';
import { WATER_PATH_NAME } from 'domain/water/constants';
import { WaterActiveTab } from 'domain/water/enums';
import { IRON_OXIDE_COLORMAP, WATER_QUALITY_COLORMAP, WATER_QUALITY_NORMALIZED_COLORMAP } from 'domain/water/colors';

export const WATER_VIEW_CONFIG = {
  name: WATER_PATH_NAME,
  path: ROUTES.waterDetection,
  params: [...COMMON_QUERY_PARAMETERS],
  tabs: {
    [WaterActiveTab.QUALITY]: {
      path: ROUTES.waterQuality,
      params: [WATER_INDICATOR_QUERY_PARAMETER, SECOND_DATE_QUERY_PARAMETER, WATERBODY_QUERY_PARAMETER],
    },
    [WaterActiveTab.SURFACE]: {
      path: ROUTES.waterArea,
      params: [VIEW_MODE_QUERY_PARAMETER, SECOND_DATE_QUERY_PARAMETER, WATERBODY_QUERY_PARAMETER],
    },
    [WaterActiveTab.MOISTURE]: {
      path: ROUTES.waterMoisture,
      params: [MOISTURE_INDICATOR_QUERY_PARAMETER, MOISTURE_CLASSES_QUERY_PARAMETER],
    },
    [WaterActiveTab.SNOW_COVER]: {
      path: ROUTES.snowCover,
      params: [SNOW_COVER_INDICATOR_QUERY_PARAMETER],
    },
  },
};

export const WATERBODY_TAB_SWITCHER_BUTTONS = [
  {
    id: 1,
    label: 'water quality',
    value: WaterActiveTab.QUALITY,
  },
  {
    id: 2,
    label: 'surface area',
    value: WaterActiveTab.SURFACE,
  },
  {
    id: 3,
    label: 'moisture',
    value: WaterActiveTab.MOISTURE,
  },
  {
    id: 4,
    label: 'snow cover',
    value: WaterActiveTab.SNOW_COVER,
  },
];

export const WATER_COMPARISON_COLOR_SCALE_RANGE_NAMES = ['loss of surface', 'no change', 'gain of surface'];

export const WATERBODY_GEOTIFF_EXCLUDED_VALUES: number[] = [65535];

export const WATERMASKS_GEOTIFF_EXCLUDED_VALUES: number[] = [0, 65535];

export const WATERBODY_GEOTIFF_LIMITS = {
  min: -1,
  max: 1,
};

export const WATERBODY_GEOTIFF_MASK_LIMITS = {
  min: 0,
  max: 1,
};

export const WATERBODY_INDICATORS_CONFIGURATION: {
  [name: string]: { colormap: ColormapDictionary; precision: number; description: string };
} = {
  CHLOROPHYLL: { colormap: WATER_QUALITY_COLORMAP, precision: 0, description: '' },
  TURBIDITY: { colormap: WATER_QUALITY_COLORMAP, precision: 0, description: '' },
  NDCI: { colormap: WATER_QUALITY_NORMALIZED_COLORMAP, precision: 2, description: '' },
  DOC: { colormap: WATER_QUALITY_NORMALIZED_COLORMAP, precision: 0, description: '' },
  CDOM: { colormap: WATER_QUALITY_NORMALIZED_COLORMAP, precision: 2, description: '' },
  TSS: { colormap: WATER_QUALITY_COLORMAP, precision: 0, description: '' },
  NDTI: { colormap: WATER_QUALITY_NORMALIZED_COLORMAP, precision: 2, description: '' },
  IRON_OXIDE: { colormap: IRON_OXIDE_COLORMAP, precision: 2, description: '' },
};

export const WATER_QUALITY_INFO_AREA_DESCRIPTION =
  'This view represents water quality results for all waterbodies associated with the area of interest. Use the first dropdown list or click on the map to select a specific waterbody to see the detailed view. You can choose a specific index from the second dropdown list. Use the timeline below to browse available dates. Missing areas in visualization might be caused by clouds present during data collection.';
export const WATER_AREA_INFO_AREA_DESCRIPTION =
  'This view represents water surface area results for all waterbodies associated with the area of interest. Use the first dropdown list or click on the map to select a specific waterbody to see the detailed view. Use the timeline below to browse available dates. You can choose a comparison option from the second dropdown list to see the comparison view. Missing areas in visualization might be caused by clouds present during data collection.';
