import { useEffect } from 'react';

import { type ViewOptionsConfig } from 'common/types';
import { type LayerObject } from 'common/types/mapData';
import { useQueryParameter } from 'common/navigation/hooks';
import { GREENERY_OBJECT_QUERY_PARAMETER, VIEW_MODE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { COMPARISON_OPTION_NAME } from 'common/navigation/constants';
import { useHandleGreneryObjectsSelectUseCase } from 'views/greenery/useCases/handleGreneryObjectsSelectUseCase';

export const greeneryControlsService = {
  useViewOptions: (config: ViewOptionsConfig, viewQuery: string | null) => {
    const mapViewOption = viewQuery
      ? {
          value: viewQuery,
          label: config.options.find(({ value }) => value === viewQuery)?.label || config.defaultOption,
        }
      : undefined;

    return {
      viewOptionsSelectData: {
        options: config.options,
        selectedOption: mapViewOption,
        initialStateLabel: config.defaultOption,
        noOptionsLabel: config.defaultOption,
        name: 'view selection',
        minWidth: 148,
      },
    };
  },

  useGreeneryObjectsOptions: (greeneryObjectTypes: LayerObject[], greeneryObjectsLoading: boolean) => {
    const greeneryObjectQuery = useQueryParameter(GREENERY_OBJECT_QUERY_PARAMETER);
    const viewQuery = useQueryParameter(VIEW_MODE_QUERY_PARAMETER);
    const isComparisonActive: boolean = viewQuery === COMPARISON_OPTION_NAME;

    const mappedObjects = greeneryObjectTypes.map(({ name, label_name }) => ({ value: name, label: label_name }));

    const greeneryObject = mappedObjects.filter(({ value }) => value === greeneryObjectQuery)[0];

    const { selectGreeneryObject } = useHandleGreneryObjectsSelectUseCase();

    useEffect(() => {
      if (isComparisonActive && !greeneryObject?.value && mappedObjects.length) {
        selectGreeneryObject(mappedObjects[0]);
      }
    }, [isComparisonActive, greeneryObject, mappedObjects, selectGreeneryObject]);

    return {
      segmentationObjectsSelectData: {
        options: mappedObjects,
        selectedOption: greeneryObject,
        initialStateLabel: 'choose greenery class',
        name: 'greenery object selection',
        isFetching: greeneryObjectsLoading,
      },
    };
  },
};
