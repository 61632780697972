import { Circle } from 'react-leaflet';

import {
  PROSPECTING_OVERVIEW_SITE_HOVER_C0LOR,
  PROSPECTING_OVERVIEW_SITE_TARGET_POINT_C0LOR,
} from 'domain/exploration/colors';

interface ProspectingOverviewSitePointProps {
  coordinates: [number, number];
  color: string;
  radius: number;
  opacity: number;
  isTarget: boolean;
  onClick: () => void;
  onHover: () => void;
}

export const ProspectingOverviewSitePoint = ({
  coordinates,
  color,
  radius,
  opacity,
  isTarget,
  onClick,
  onHover,
}: ProspectingOverviewSitePointProps) => {
  return (
    <>
      <Circle
        center={coordinates}
        radius={radius}
        pane="deposit-classification-sites-points-layer"
        pathOptions={{
          weight: 2,
          color: PROSPECTING_OVERVIEW_SITE_HOVER_C0LOR,
          opacity,
          fillColor: color,
          fillOpacity: 1,
        }}
        eventHandlers={{
          click: onClick,
          mouseover: onHover,
        }}
      />
      {isTarget && (
        <Circle
          center={coordinates}
          radius={30}
          pane="deposit-classification-sites-points-layer"
          pathOptions={{
            weight: 4,
            color: PROSPECTING_OVERVIEW_SITE_HOVER_C0LOR,
            fillColor: PROSPECTING_OVERVIEW_SITE_TARGET_POINT_C0LOR,
            opacity: 1,
            fillOpacity: 1,
          }}
          eventHandlers={{
            click: onClick,
            mouseover: onHover,
          }}
        />
      )}
    </>
  );
};
