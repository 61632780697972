import { useState } from 'react';

import { useQueryParameter } from 'common/navigation/hooks';
import { WATERBODY_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { capitalizeAllFirstLetters, replaceSquareMetersWithHectares } from 'common/utils';
import { SURFACE_PRECISION_HA } from 'common/constants';
import { waterRepository } from 'infrastructure/water/waterRepository';
import { ReportPdfHeader } from 'views/reports/external/pdfGenerator/components/ReportPdfHeader';
import { ReportPdf } from 'views/reports/external/pdfGenerator/ReportPdf';
import { ReportPdfBarChartGenerate } from 'views/reports/external/pdfGenerator/extended/ReportPdfBarChartGenerate';
import { ReportPdfExtendedContent } from 'views/reports/external/pdfGenerator/extended/ReportPdfExtendedContent';
import { useGetWaterAreaResultsUseCase } from 'views/water/surface/getWaterAreaResultsUseCase';
import { EXTERNAL_REPORT_SURFACE_AREA_UNIT, REPORTS_ALL_WATERBODIES_LABEL } from 'domain/reports/constants';
import { type ExternalReportHeaderCommon, type ReportsInitialDataset } from 'domain/reports/types';
import { getExtendedTableData } from 'domain/reports/helpers';
import { WATERMASKS_REPORT_COLOR } from 'domain/water/colors';
import { isExternalReportExtendedTableDataValid } from 'domain/reports/typeguards';

interface WaterAreaDobuleDateReportsExternalDataProps {
  areaId: number;
  initialDataset: ReportsInitialDataset;
  reportInfo: ExternalReportHeaderCommon;
}

export const WaterAreaDobuleDateReportsExternalData = ({
  areaId,
  initialDataset,
  reportInfo,
}: WaterAreaDobuleDateReportsExternalDataProps) => {
  const [chartSrc, setChartSrc] = useState<string | null>(null);

  const { waterBodiesList: waterbodies } = waterRepository.useFetchWaterbodies(areaId);

  const selectedWaterbodyId = useQueryParameter(WATERBODY_QUERY_PARAMETER);

  const selectedWaterbodyLabel = selectedWaterbodyId
    ? waterbodies.find(({ id }) => String(id) === selectedWaterbodyId)?.name || ''
    : REPORTS_ALL_WATERBODIES_LABEL;

  const { firstTimestamp, firstDate, firstScreenshotSrc, secondTimestamp, secondDate, secondScreenshotSrc } =
    initialDataset;

  const { waterAreaResults: firstWaterAreaResults } = useGetWaterAreaResultsUseCase(firstTimestamp);
  const { waterAreaResults: secondWaterAreaResults } = useGetWaterAreaResultsUseCase(secondTimestamp);

  const first = selectedWaterbodyId
    ? replaceSquareMetersWithHectares(
        firstWaterAreaResults.find((item) => String(item.waterbody_id) === selectedWaterbodyId)?.area,
      )
    : replaceSquareMetersWithHectares(firstWaterAreaResults.reduce((acc, { area }) => acc + area, 0));

  const second = selectedWaterbodyId
    ? replaceSquareMetersWithHectares(
        secondWaterAreaResults.find((item) => String(item.waterbody_id) === selectedWaterbodyId)?.area,
      )
    : replaceSquareMetersWithHectares(secondWaterAreaResults.reduce((acc, { area }) => acc + area, 0));

  const { firstValue, secondValue, difference, trend } = getExtendedTableData(first, second, SURFACE_PRECISION_HA);

  const data = [
    {
      id: selectedWaterbodyId ? Number(selectedWaterbodyId) : -1,
      label: capitalizeAllFirstLetters(selectedWaterbodyLabel),
      color: WATERMASKS_REPORT_COLOR,
      firstValue,
      secondValue,
      difference,
      trend,
    },
  ];

  const validData = isExternalReportExtendedTableDataValid(data) ? data : [];

  const header = {
    ...reportInfo,
    reportType: 'Comparison Report',
    reportFunctionality: 'Water Surface Area',
    dates: {
      first: firstDate,
      second: secondDate,
    },
  };

  const table = {
    tableHeader: 'Water Surface Area Comparison',
    dataHeader: 'Waterbody Name',
    unit: EXTERNAL_REPORT_SURFACE_AREA_UNIT,
    firstDate,
    secondDate,
    data: validData,
  };

  const screenshots = {
    first: {
      src: firstScreenshotSrc,
      label: `Date 1: ${firstDate}`,
    },
    second: {
      src: secondScreenshotSrc,
      label: `Date 2: ${secondDate}`,
    },
  };

  return (
    <>
      <ReportPdfBarChartGenerate
        data={data}
        header="Water Surface Area Comparison"
        unit={EXTERNAL_REPORT_SURFACE_AREA_UNIT}
        setChartSrc={setChartSrc}
      />
      <ReportPdf>
        <>
          <ReportPdfHeader header={header} />
          <ReportPdfExtendedContent screenshots={screenshots} table={table} chartSrc={chartSrc} />
        </>
      </ReportPdf>
    </>
  );
};
