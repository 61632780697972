import { type ValueAndLabel } from 'common/types';
import { useDeleteQueryParameters, useReplaceQueryParameters } from 'common/navigation/hooks';
import { FALSY_QUERY_PARAMETERS, WATERBODY_QUERY_PARAMETER } from 'common/navigation/queryParams';

export const useHandleWaterbodyChangeUseCase = () => {
  const replaceQueryParameter = useReplaceQueryParameters();
  const deleteQueryParameters = useDeleteQueryParameters();

  const changeWaterbody = (option: ValueAndLabel): void => {
    FALSY_QUERY_PARAMETERS.includes(option.value)
      ? deleteQueryParameters([WATERBODY_QUERY_PARAMETER])
      : replaceQueryParameter([
          {
            key: WATERBODY_QUERY_PARAMETER,
            value: String(option.value),
          },
        ]);
  };

  return { changeWaterbody };
};
