import { mapIndicatorsToOptions } from 'common/utils';
import { areExtendedIndicatorsValid } from 'common/typeguards';
import { withIndicatorConfig, withIndicatorLabels } from 'common/decorators';
import { useQueryParameter } from 'common/navigation/hooks';
import { WATER_INDICATOR_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { useGetAoiName } from 'common/hooks/getAoiName';
import { waterRepository } from 'infrastructure/water/waterRepository';
import { WATERBODY_INDICATORS_CONFIGURATION } from 'domain/water/configs';

export const useWaterIndicators = () => {
  const areaName = useGetAoiName();
  const { waterIndicators, areWaterIndicatorsLoading } = waterRepository.useFetchWaterQualityIndicators();

  // TODO: move indicators filtering based on AOI to backend
  const filteredWaterIndicators = waterIndicators.filter(({ indicator_name }) =>
    areaName === 'viiankiaapa' ? indicator_name === 'NDCI' || indicator_name === 'NDTI' : true,
  );

  const indicatorsWithLabels = withIndicatorLabels(filteredWaterIndicators);
  const indicatorsWithConfig: unknown = withIndicatorConfig(indicatorsWithLabels, WATERBODY_INDICATORS_CONFIGURATION);
  const extendedIndicators = areExtendedIndicatorsValid(indicatorsWithConfig) ? indicatorsWithConfig : [];

  const selectedIndicatorId = useQueryParameter(WATER_INDICATOR_QUERY_PARAMETER);
  const selectedIndicator = extendedIndicators.find(({ id }) => selectedIndicatorId === String(id));

  const indicatorOptions = mapIndicatorsToOptions(extendedIndicators);

  return {
    indicators: extendedIndicators,
    selectedIndicator,
    indicatorOptions,
    areIndicatorsLoading: areWaterIndicatorsLoading,
  };
};
