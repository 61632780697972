import { type CSSProperties, type ReactNode } from 'react';
import styled, { css } from 'styled-components';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';

import { type LayerObject } from 'common/types/mapData';
import { Container, ColorScaleBadge, LabeledCheckbox, InfoPoint } from 'ui';
import { MarkdownMathPreview } from 'ui/atoms/markdownMathPreview/MarkdownMathPreview';
import { LegendItemWraper } from './styled';

interface LayerManagerLegendItemProps {
  option: LayerObject;
  handleObjectsVisibility: (name: string) => void;
  objectVisibility: boolean;
  noBadges?: boolean;
  radio?: boolean;
  children?: ReactNode;
  isDndEnabled?: boolean;
}

export const LayerManagerLegendItem = ({
  option,
  handleObjectsVisibility,
  objectVisibility,
  noBadges,
  radio,
  children,
  isDndEnabled,
}: LayerManagerLegendItemProps) => {
  const { attributes, listeners, setNodeRef, transform, transition, setActivatorNodeRef, active } = useSortable({
    id: option.id,
  });

  const style: CSSProperties = {
    transform: CSS.Translate.toString({
      x: 0,
      y: transform?.y || 0,
      scaleX: transform?.scaleX || 1,
      scaleY: transform?.scaleY || 1,
    }),
    transition,
  };

  return (
    <DraggableLegendItem
      data-testid={`legend-item-${option.name}`}
      ref={setNodeRef}
      style={active ? style : undefined}
      active={!!active}
      isDragged={active?.id === option.id}
    >
      {isDndEnabled ? (
        <DragIconWrapper ref={setActivatorNodeRef} {...attributes} {...listeners}>
          <DragIcon>
            <span />
            <span />
            <span />
          </DragIcon>
        </DragIconWrapper>
      ) : (
        <DragIconWrapper />
      )}
      <LegendItemContent>
        <LabeledCheckbox
          variant="WIDE"
          onChange={() => handleObjectsVisibility(option.name)}
          checked={objectVisibility || false}
          radio={radio}
        >
          <Container>
            {option.colormap ? (
              <ColorScaleBadge colormap={option.colormap} label={option.label_name} />
            ) : (
              <>
                {option.color && !noBadges && (
                  <LegendIcon data-testid="color-badge" color={option.color} variant={option.variant} />
                )}
                {option.label_name}
              </>
            )}
            {!!option.info && (
              <Container ml={6}>
                <InfoPoint
                  name={option.name}
                  tooltipContent={
                    <Container width={212}>
                      <MarkdownMathPreview content={option.info} />
                    </Container>
                  }
                  tooltipPlacement="bottom"
                />
              </Container>
            )}
          </Container>
        </LabeledCheckbox>
        {children}
      </LegendItemContent>
    </DraggableLegendItem>
  );
};

const DraggableLegendItem = styled(LegendItemWraper)<{ active: boolean; isDragged: boolean }>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 10px 0;

  ${({ active, isDragged }) =>
    active &&
    !isDragged &&
    css`
      opacity: 0.6;
    `};

  ${({ active }) =>
    active &&
    css`
      &::before {
        content: '';
        display: block;
        position: absolute;
        inset: 0;
        cursor: move;
        z-index: 2;
      }
    `};
`;

const LegendItemContent = styled.div`
  flex-grow: 1;
`;

const DragIconWrapper = styled.div`
  width: 1.5rem;
  height: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  cursor: move;
  margin-right: 10px;
`;

const DragIcon = styled.div`
  position: relative;
  width: 6px;
  height: 9px;
  margin-right: 2px;
  background-color: transparent;

  span {
    position: absolute;
    background-color: ${({ theme }) => theme.color.lightGray};
    width: 100%;
    height: 1px;
    border-radius: 1px;
  }

  span:nth-child(1) {
    top: 0;
  }

  span:nth-child(2) {
    top: 50%;
    transform: translateY(-50%);
  }

  span:nth-child(3) {
    bottom: 0;
  }
`;

const LegendIcon = styled.div<{ color: string; variant?: string }>`
  flex-shrink: 0;
  width: 22px;
  height: 16px;
  background-color: ${({ color }) => color};
  border-radius: 4px;
  margin-right: 8px;

  ${({ variant }) =>
    variant === 'bordered' &&
    css`
      border: 3px solid ${({ theme }) => theme.color.white};
    `};
`;
