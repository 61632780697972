import { useShareModalStore } from 'ui/organisms/shareModal/shareModalStore';
import { BaseModal } from 'ui/modal';

interface ShareModalProps {
  children: React.ReactNode;
}

export const ShareModalBody = ({ children }: ShareModalProps) => {
  const isModalOpen = useShareModalStore.use.isShareModalOpen();
  const closeModal = useShareModalStore.use.closeShareModal();

  return (
    <BaseModal isOpen={isModalOpen} closeModal={closeModal} maxWidth={1000}>
      {children}
    </BaseModal>
  );
};
