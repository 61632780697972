import styled from 'styled-components';

import { InfoPoint, Select } from 'ui';
import { SectionLayout } from './SectionLayout';
import { SurfaceAreaSection } from './SurfaceAreaSection';
import { CommonCoverageSection } from './CommonCoverageSection';
import { PixelStatisticsSection } from './PixelStatisticsSection';

export const StatisticsDrawerContent = () => {
  return (
    <Layout>
      <div>
        <Header>
          <Title>Area Information</Title>
          <SelectWrapper>
            <Select
              name="statistics scope"
              options={[]}
              selectedOption={undefined}
              noOptionsLabel="data for total area"
            />
          </SelectWrapper>
          <InfoPoint name="area info description" tooltipContent="lorem ipsum dolor sit" />
        </Header>
        <SectionLayout title="distribution of SAM results" description="lorem ipsum dolor sit">
          <Select name="mineral selection" options={[]} selectedOption={undefined} noOptionsLabel="kaolinite" />
          <GraphPlaceholder />
        </SectionLayout>
        <SurfaceAreaSection />
        <CommonCoverageSection />
      </div>
      <div>
        <Header>
          <Title>Pixel Information</Title>
          (Coordinates: 00.000000 00.000000)
        </Header>
        <SectionLayout title="curve information" description="lorem ipsum dolor sit">
          <Select
            name="curve selection"
            options={[]}
            selectedOption={undefined}
            noOptionsLabel="Reference Curve Selection"
          />
          <CurvesPlaceholder />
        </SectionLayout>
        <PixelStatisticsSection />
      </div>
    </Layout>
  );
};

const Layout = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 26px;
`;

const Header = styled.header`
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 32px;
`;

const Title = styled.h3`
  font-family: 'Barlow', sans-serif;
  text-transform: uppercase;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 600;
  line-height: 36px;
`;

const SelectWrapper = styled.div`
  flex-grow: 0;
`;

const GraphPlaceholder = styled.div`
  width: 100%;
  aspect-ratio: 16 / 9;
  background-image: url('./graphPlaceholder.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 8px;
`;

const CurvesPlaceholder = styled.div`
  width: 100%;
  aspect-ratio: 16 / 10;
  background-image: url('./curvesPlaceholder.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-top: 8px;
`;
