import { type ReactNode } from 'react';

import { type DisplayableMapPoint } from 'common/types/mapData';
import { AreaInfoPanel } from 'ui/organisms/areaInfoPanel/AreaInfoPanel';
import { DetailedInfoPanelHeader } from 'ui/organisms/areaInfoPanel/DetailedInfoPanelHeader';

interface DetailedInfoPanelProps {
  areaId: number;
  siteName: string;
  location: DisplayableMapPoint;
  children: ReactNode;
  extraContent: ReactNode;
}

export const DetailedInfoPanel = ({ areaId, siteName, location, extraContent, children }: DetailedInfoPanelProps) => {
  const onDescriptionSubmitHandler = (value: string) => {
    console.log(value);
  };

  return (
    <AreaInfoPanel
      header={<DetailedInfoPanelHeader siteName={siteName} areaId={areaId} />} // TODO: Add 'define_target' button
      location={location}
      description={'Short site summary with key findings for future reference written by the user. '} // TODO: no decs field in marker
      middleColumn={extraContent}
      onDescriptionSubmit={onDescriptionSubmitHandler}
    >
      {children}
    </AreaInfoPanel>
  );
};
