export const DATE_QUERY_PARAMETER = 'date';
export const SECOND_DATE_QUERY_PARAMETER = 'second-date';
export const VIEW_MODE_QUERY_PARAMETER = 'view';

export const GREENERY_OBJECT_QUERY_PARAMETER = 'object';
export const GREENERY_INDICATOR_QUERY_PARAMETER = 'greenery-indicator';

export const DEPOSIT_CLASSIFICATION_SITE_QUERY_PARAMETER = 'deposit-class-site';
export const MINERAL_QUERY_PARAMETER = 'mineral';
export const SPECTRAL_CLASSIFIER_QUERY_PARAMETER = 'classifier';
export const MINERAL_INDICATOR_QUERY_PARAMETER = 'mineral-indicator';
export const LINEAMENT_THRESHOLD_QUERY_PARAMETER = 'lineament-threshold';

export const WATERBODY_QUERY_PARAMETER = 'waterbody';
export const WATER_INDICATOR_QUERY_PARAMETER = 'water-indicator';
export const MOISTURE_INDICATOR_QUERY_PARAMETER = 'moisture-indicator';
export const SNOW_COVER_INDICATOR_QUERY_PARAMETER = 'snow-cover-indicator';

export const SPATIAL_RESOLUTION_QUERY_PARAMETER = 'resolution';

export const REPORTS_QUERY_PARAMETER = 'reports';
export const REPORTS_QUERY_PARAMETER_VALUE = '1';
export const REPORT_TYPE_QUERY_PARAMETER = 'report-type';
export const REPORTS_INDICATOR_STATS_QUERY_PARAMETER = 'stats';

export const SEARCH_QUERY_PARAM_NAME = 'search';
export const FILTERS_STATUS_QUERY_PARAM_NAME = 'rejected-status';
export const FILTERS_TAG_QUERY_PARAM_NAME = 'rejected-tag';
export const FILTERS_ENVIRONMENT_QUERY_PARAM_NAME = 'rejected-environment';
export const FILTERS_TYPE_QUERY_PARAM_NAME = 'rejected-type';

export const LAT_LNG_QUERY_PARAMETER = 'lat-lng';
export const ZOOM_QUERY_PARAMETER = 'zoom';
export const COHERENCE_QUERY_PARAMETER = 'coh';
export const MARKER_INDEX_QUERY_PARAMETER = 'mi';

export const TERRAIN_CLASSES_QUERY_PARAMETER = 'terrain-classes';
export const MOISTURE_CLASSES_QUERY_PARAMETER = 'moisture-classes';
export const GREENERY_CLASSES_QUERY_PARAMETER = 'greenery-classes';
export const SAM_CLASSES_QUERY_PARAMETER = 'sam-classes';
export const MTMF_CLASSES_QUERY_PARAMETER = 'mtmf-classes';
export const ML_CLASSES_QUERY_PARAMETER = 'ml-classes';
export const INDICES_CLASSES_QUERY_PARAMETER = 'indices-classes';

export const REPORT_QUERY_PARAMETERS = [
  REPORTS_QUERY_PARAMETER,
  REPORT_TYPE_QUERY_PARAMETER,
  REPORTS_INDICATOR_STATS_QUERY_PARAMETER,
];

export const COMMON_QUERY_PARAMETERS = [
  DATE_QUERY_PARAMETER,
  LAT_LNG_QUERY_PARAMETER,
  ZOOM_QUERY_PARAMETER,
  ...REPORT_QUERY_PARAMETERS,
];

export const FALSY_QUERY_PARAMETERS = [undefined, null, 0, '0', '', 'null', 'undefined'];

export const USER_UDI_QUERY_PARAMETER = 'uid';
export const USER_TOKEN_QUERY_PARAMETER = 'token';
