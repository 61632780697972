/* eslint-disable @typescript-eslint/no-explicit-any */
import { type QuarterlyResult } from 'common/types';
import { type AxiosResponse } from 'axios';
import { type LayerObjectsApiResponse } from 'common/types/api';
import { type LayerObject } from 'common/types/mapData';
import {
  type GreeneryHotspot,
  type GreeneryHotspotsApiResponse,
  type GreeneryDateResult,
  type GreeneryIndicatorStats,
  type GreeneryIndicatorsApiResponse,
  type GreeneryQuarterlyResultsApiResponse,
  type GreeneryResult,
  type GreeneryResultsApiResponse,
  type GreeneryDateResultsApiResponse,
  type GreeneryVciResult,
  type GreeneryVciResultsApiResponse,
  type SegmentationResultsApiResponse,
  type SegmentationAreaResultsApiResponse,
  type SegmentationResult,
  type SegmentationAreaResult,
  type VegetationIndicator,
} from 'domain/greenery/types';
import { type HotspotType, type HotspotTypeApiResponse } from 'domain/hotspots/types';

export const areVegetationIndicatorsValid = (
  indicators: any,
): indicators is AxiosResponse<GreeneryIndicatorsApiResponse, any> => {
  return (
    Array.isArray(indicators?.data?.results) &&
    indicators.data.results.every(
      (indicator: VegetationIndicator) =>
        indicator &&
        typeof indicator.id === 'number' &&
        typeof indicator.indicator_name === 'string' &&
        typeof indicator.min_value === 'number' &&
        typeof indicator.max_value === 'number' &&
        typeof indicator.colormap === 'object' &&
        Object.values(indicator.colormap).every(String),
    )
  );
};

export const areGreeneryObjectsValid = (response: any): response is AxiosResponse<LayerObjectsApiResponse, any> => {
  return (
    response &&
    Array.isArray(response?.data?.results) &&
    response.data.results.every((object: LayerObject) => typeof object.id === 'number')
  );
};

export const areGreeneryDateResultsValid = (
  results: any,
): results is AxiosResponse<GreeneryDateResultsApiResponse, any> => {
  return (
    results &&
    Array.isArray(results?.data?.results) &&
    results.data.results.every(
      (result: GreeneryDateResult) => typeof result.aoi_id === 'number' && typeof result.img_date === 'string',
    )
  );
};

export const areGreeneryResultsValid = (results: any): results is AxiosResponse<GreeneryResultsApiResponse, any> => {
  return (
    results &&
    Array.isArray(results?.data?.results) &&
    results.data.results.every(
      (result: GreeneryResult) =>
        typeof result.id === 'number' &&
        typeof result.aoi_id === 'number' &&
        typeof result.greenery_object_id === 'number' &&
        typeof result.greenery_indicator_id === 'number' &&
        typeof result.img_date === 'string',
    )
  );
};

export const areGreeneryIndicatorStatsValid = (stats: any): stats is AxiosResponse<GreeneryIndicatorStats, any> => {
  return (
    stats &&
    typeof stats.data.id === 'number' &&
    typeof stats.data.aoi_id === 'number' &&
    typeof stats.data.greenery_object_id === 'number' &&
    typeof stats.data.greenery_indicator_id === 'number' &&
    typeof stats.data.img_date === 'string' &&
    typeof stats.data.avg_value === 'number' &&
    typeof stats.data.std_dev === 'number' &&
    typeof stats.data.max_value === 'number' &&
    typeof stats.data.min_value === 'number' &&
    typeof stats.data.indicator_file_path === 'string'
  );
};

export const areSegmentationAreaResultsValid = (
  results: any,
): results is AxiosResponse<SegmentationAreaResultsApiResponse, any> => {
  return (
    results &&
    Array.isArray(results?.data?.results) &&
    results.data.results.every(
      (result: SegmentationAreaResult) =>
        typeof result.id === 'number' &&
        typeof result.aoi_id === 'number' &&
        typeof result.greenery_object_id === 'number' &&
        typeof result.img_date === 'string' &&
        typeof result.area === 'number',
    )
  );
};

export const areGreeneryHotspotTypesValid = (hotspots: any): hotspots is AxiosResponse<HotspotTypeApiResponse, any> => {
  return (
    hotspots &&
    Array.isArray(hotspots?.data?.results) &&
    hotspots.data.results.every(
      (hotspot: HotspotType) =>
        typeof hotspot.id === 'number' &&
        typeof hotspot.name === 'string' &&
        typeof hotspot.label_name === 'string' &&
        typeof hotspot.color === 'string',
    )
  );
};

export const isGreeneryHotspotApiResponseValid = (response: any): response is GreeneryHotspotsApiResponse => {
  return (
    response &&
    typeof response?.data?.count === 'number' &&
    Array.isArray(response?.data?.results) &&
    response.data.results.every((hotspot: GreeneryHotspot) => {
      return (
        hotspot &&
        typeof hotspot.id === 'number' &&
        typeof hotspot.aoi_id === 'number' &&
        typeof hotspot.indicator_id === 'number' &&
        typeof hotspot.cog_file_path === 'string'
      );
    })
  );
};

export const areSegmentationResultsValid = (
  results: any,
): results is AxiosResponse<SegmentationResultsApiResponse, any> => {
  return (
    results &&
    Array.isArray(results?.data?.results) &&
    results.data.results.every(
      (result: SegmentationResult) =>
        typeof result.id === 'number' && typeof result.aoi_id === 'number' && typeof result.img_date === 'string',
    )
  );
};

export const areQuarterlySegmentationResultsValid = (
  results: any,
): results is AxiosResponse<GreeneryQuarterlyResultsApiResponse, any> => {
  return (
    results &&
    Array.isArray(results?.data?.results) &&
    results.data.results.every(
      (result: QuarterlyResult) =>
        typeof result.id === 'number' &&
        typeof result.aoi_id === 'number' &&
        typeof result.year === 'number' &&
        typeof result.quarter === 'number',
    )
  );
};

export const areVhrObjectResultsValid = (results: any): results is AxiosResponse<LayerObject[], any> => {
  return (
    results &&
    Array.isArray(results?.data) &&
    results.data.every(
      (result: LayerObject) =>
        typeof result.id === 'number' &&
        typeof result.name === 'string' &&
        typeof result.label_name === 'string' &&
        typeof result.color === 'string',
    )
  );
};

export const isGreeneryVciResultValid = (result: any): result is GreeneryVciResult => {
  return (
    result &&
    typeof result.id === 'number' &&
    typeof result.aoi_id === 'number' &&
    typeof result.img_date === 'string' &&
    typeof result.cog_file_path === 'string'
  );
};

export const isGreeneryVciResultsApiResponseValid = (response: any): response is GreeneryVciResultsApiResponse => {
  return (
    response &&
    typeof response?.data?.count === 'number' &&
    Array.isArray(response?.data?.results) &&
    response.data.results.some(isGreeneryVciResultValid)
  );
};
