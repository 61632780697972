import { DataLayer } from 'components/map';
import { useGetCogWithBounds } from 'services/titiler/hooks';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { Loading } from 'ui/molecules/loading';
import {
  GREENERY_COMPARATOR_GAIN_COLOR,
  GREENERY_COMPARATOR_LOSS_COLOR,
  GREENERY_COMPARATOR_NO_CHANGE_COLOR,
} from 'domain/greenery/colors';

interface SegmentationComparatorDataLayerProps {
  url: string;
  currentObjectId: number;
}

export const SegmentationComparatorDataLayer = ({ url, currentObjectId }: SegmentationComparatorDataLayerProps) => {
  const { cogUrl, cogBounds, isInfoLoading } = useGetCogWithBounds(titilerApiAdapter.buildComparatorImageUrl, url, {
    currentLayerId: currentObjectId,
    gainColor: GREENERY_COMPARATOR_GAIN_COLOR,
    lossColor: GREENERY_COMPARATOR_LOSS_COLOR,
    noChangeColor: GREENERY_COMPARATOR_NO_CHANGE_COLOR,
  });

  const isDataLayerVisible = useLayerManagerStore.use.isDataLayerVisible();

  if (isInfoLoading) {
    return <Loading />;
  }

  if (!cogUrl) {
    return null;
  }

  return (
    <DataLayer
      isVisible={isDataLayerVisible}
      layerName={'segmentationComparatorLayer'}
      zIndex={495}
      cogUrl={cogUrl}
      bounds={cogBounds}
      fixed
    />
  );
};
