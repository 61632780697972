import { useEffect } from 'react';
import { Container, LabeledCheckbox } from 'ui';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { useLayerManagerStore } from './layerManagerStore';
import { LegendItemWraper, IndentedContentWraper } from './styled';

interface InfrastructureLayersGroupProps {
  areaName: string;
  disableInfrastructureLayer: boolean;
}

export const InfrastructureLayersGroup = ({ areaName, disableInfrastructureLayer }: InfrastructureLayersGroupProps) => {
  const geoJSONVisibility = useLayerManagerStore.use.geoJSONsVisibility();
  const toggleGeoJSONVisibility = useLayerManagerStore.use.toggleGeoJSONVisibility();
  const updateGeoJSONsVisibility = useLayerManagerStore.use.updateGeoJSONsVisibility();

  const { shapes } = areasRepository.useFetchInfrastructureShapes(areaName);

  useEffect(() => {
    if (!disableInfrastructureLayer) {
      updateGeoJSONsVisibility(shapes.reduce((acc, { name }) => ({ ...acc, [name]: true }), {}));
    }
  }, [shapes?.length]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {disableInfrastructureLayer
        ? null
        : shapes.map(({ id, name, label_name }) => (
            <LegendItemWraper key={id}>
              <Container pv={10}>
                <IndentedContentWraper>
                  <LabeledCheckbox
                    children={label_name}
                    variant="WIDE"
                    onChange={() => toggleGeoJSONVisibility(name)}
                    checked={geoJSONVisibility[name] || false}
                  />
                </IndentedContentWraper>
              </Container>
            </LegendItemWraper>
          ))}
    </>
  );
};
