import styled from 'styled-components';

import { Button, Container } from 'ui';
import { ReactComponent as HeroIcon } from 'ui/assets/terraeye-logo-small.svg';
import { type SubscriptionType } from 'domain/subscription/types';

interface SubscriptionUpgradeTileProps {
  type: SubscriptionType;
  quota: number;
  price: string;
  handleUpgrade: () => void;
  handleCancel: () => void;
}

export const SubscriptionUpgradeTile = ({
  type,
  quota,
  price,
  handleUpgrade,
  handleCancel,
}: SubscriptionUpgradeTileProps) => (
  <>
    <Container gap={20}>
      <ProductBody>
        <HeroIcon />
      </ProductBody>
      <div>
        <ProductTitleLabel>Upgrade to</ProductTitleLabel>
        <ProductTitle>{type}</ProductTitle>
        <ProductParagraph>Added Points: {quota}</ProductParagraph>
        <ProductParagraph>Price: {price}</ProductParagraph>
      </div>
    </Container>
    <Container gap={10}>
      <Button variant="SECONDARY" squared minWidth={125} onClick={handleCancel}>
        cancel
      </Button>
      <Button variant="PRIMARY" squared minWidth={125} onClick={handleUpgrade}>
        add & pay
      </Button>
    </Container>
  </>
);

const ProductBody = styled.div`
  width: 80px;
  height: 80px;
  color: ${({ theme }) => theme.color.primary};
  background-color: ${({ theme }) => theme.color.white};
  padding: 15px;
  border-radius: 6px;
`;

const ProductTitleLabel = styled.div`
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.15;
  text-transform: uppercase;
`;

const ProductTitle = styled.h4`
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-bottom: 8px;
`;

const ProductParagraph = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
`;
