import { type LayerObject } from 'common/types/mapData';

export const layerManagerOptionsBuildHelper = (objectsConfiguration: LayerObject[], onlyVisibleIds?: number[]) => {
  const options = objectsConfiguration.map(({ id, name }) => {
    const visibility = onlyVisibleIds ? !!onlyVisibleIds?.some((visibleId) => visibleId === id) : true;
    return [name, visibility];
  });

  return Object.fromEntries(options);
};
