import { useEffect } from 'react';

import { useGraphStore } from 'components/graph/graphStore';

export const useWaterQualityLifeCycleUseCase = (waterbodyId: string | number | undefined) => {
  const resetGraphStore = useGraphStore.use.resetGraphStore();
  const graphData = useGraphStore.use.graphData();

  useEffect(() => {
    if (graphData && !waterbodyId) {
      resetGraphStore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [graphData, waterbodyId]);

  useEffect(() => {
    return () => {
      resetGraphStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterbodyId]);
};
