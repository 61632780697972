import { create } from 'zustand';
import { devtools, persist } from 'zustand/middleware';

import { createSelectors } from 'common/utils/createSelectors';

interface LayerOrderState {
  layersOrderRecord: Record<string, number[]> | null;
  setLayersOrderRecord: (key: string, layersOrder: number[]) => void;
}

const useLayerOrderStoreBase = create(
  persist(
    devtools<LayerOrderState>(
      (set) => ({
        layersOrderRecord: null,
        setLayersOrderRecord: (key, layersOrder) => {
          set((state) => ({
            ...state,
            layersOrderRecord: {
              ...state.layersOrderRecord,
              [key]: layersOrder,
            },
          }));
        },
      }),
      { name: 'layerOrderStore' },
    ),
    { name: 'RSOM_layers_order' },
  ),
);

export const useLayerOrderStore = createSelectors(useLayerOrderStoreBase);
