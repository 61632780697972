import { type ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ROUTES } from 'common/navigation/routes';
import { useOverridableFeatureFlag } from 'common/hooks/useOverridableFeatureFlag';
import { ReactComponent as Logo } from 'assets/svg/hero-logo.svg';
import { SidebarContainer } from 'ui/sidebar/SidebarContainer';
import { TextAndLink } from 'ui/atoms/textAndLink';
import { Button } from 'ui/atoms/button';

interface LoginSidebarProps {
  children: ReactNode;
}

export const LoginSidebar = ({ children }: LoginSidebarProps) => {
  const enableUserRegistration = useOverridableFeatureFlag(
    'enableUserRegistration',
    false,
    'REACT_APP_ENABLE_USER_REGISTRATION',
  );

  const navigate = useNavigate();

  return (
    <LoginWrapper>
      <SidebarMainContent>
        <LogoWrapper>
          <Logo />
        </LogoWrapper>
        {children}
      </SidebarMainContent>
      <LoginFooter>
        <LoginFooterContent>
          {enableUserRegistration ? (
            <SignUpSection>
              <SignUpLabel>New to TerraEye?</SignUpLabel>
              <Button squared onClick={() => navigate(ROUTES.signup)} minWidth={120}>
                Sign up
              </Button>
            </SignUpSection>
          ) : (
            <TextAndLink
              text="Request access to the demo"
              linkedText="here"
              link="https://terraeye.co/request-access"
              markedText="New to TerraEye?"
            />
          )}
        </LoginFooterContent>
      </LoginFooter>
    </LoginWrapper>
  );
};

LoginSidebar.displayName = 'LoginSidebar';

const LoginWrapper = styled(SidebarContainer)`
  display: flex;
  position: absolute;
  margin-right: -300px;
  height: 100%;
  width: 100%;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-grow: 1;
  padding: 30px;
  z-index: 1501;
  opacity: 0.8;
  color: ${({ theme }) => theme.color.white};
  background: ${({ theme }) => theme.color.mineShaftDark};
`;

const SidebarMainContent = styled.div`
  height: 100%;
  display: grid;
  place-content: center;
`;

const LogoWrapper = styled.div`
  width: 200px;
  color: ${({ theme }) => theme.color.white};
  margin-bottom: 36px;
`;

const LoginFooter = styled.div`
  margin-top: -32px;
`;

const LoginFooterContent = styled.div`
  align-self: flex-end;
`;

const SignUpSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;

const SignUpLabel = styled.h4`
  font-family: 'Barlow Condensed';
  font-size: 14px;
  font-weight: 700;
`;
