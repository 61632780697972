import { useState } from 'react';

import { downloadAllFilesWithNamesAsZip, getFullFileName, saveFileFromUrlWithName } from 'common/utils';
import { DownloadModal } from 'components/DownloadModal';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { useMlLayersVisibility, useMtmfLayersVisibility, useSamLayersVisibility } from './useSurfaceLayersVisibility';
import { useGetExplorationFileNameEnding } from '../useGetExplorationFileNameEnding';
import { useSurfaceMinerals } from '../useSurfaceMinerals';
import { useIndicesLayersVisibility, useIndices } from '../indices';

export const MineralsDownloadModal = () => {
  const [isDownloading, setIsDownloading] = useState(false);

  const explorationFileNameEnding = useGetExplorationFileNameEnding();

  const { classesVisibility: samClassesVisibility } = useSamLayersVisibility();
  const { selectedMinerals: samMinerals } = useSurfaceMinerals(1);
  const selectedSamMinerals = samMinerals.filter(({ name }) => samClassesVisibility[name]);

  const { classesVisibility: mtmfClassesVisibility } = useMtmfLayersVisibility();
  const { selectedMinerals: mtmfMinerals } = useSurfaceMinerals(2);
  const selectedMtmfMinerals = mtmfMinerals.filter(({ name }) => mtmfClassesVisibility[name]);

  const { classesVisibility: mlClassesVisibility } = useMlLayersVisibility();
  const { selectedMinerals: mlMinerals } = useSurfaceMinerals(3);
  const selectedMlMinerals = mlMinerals.filter(({ name }) => mlClassesVisibility[name]);

  const { classesVisibility: indicesClassesVisibility } = useIndicesLayersVisibility();
  const { indicesResults: indices } = useIndices();
  const selectedIndices = indices.filter(({ name }) => indicesClassesVisibility[name]);

  const samFilesList = selectedSamMinerals.map(({ id, url, label, name }) => ({
    id,
    url: titilerApiAdapter.buildMineralTitilerImageUrl(url),
    label,
    name: `${name}_sam${explorationFileNameEnding}`,
  }));

  const mtmfFilesList = selectedMtmfMinerals.map(({ id, url, label, name }) => ({
    id,
    url: titilerApiAdapter.buildMineralTitilerImageUrl(url),
    label,
    name: `${name}_mtmf${explorationFileNameEnding}`,
  }));

  const mlFilesList = selectedMlMinerals.map(({ id, url, label, name }) => ({
    id,
    url: titilerApiAdapter.buildMineralTitilerImageUrl(url),
    label,
    name: `${name}_ml${explorationFileNameEnding}`,
  }));

  const indicesFilesList = selectedIndices.map(({ id, url, label_name, name }) => ({
    id,
    url: titilerApiAdapter.buildGenericPreviewTifUrl(url),
    label: label_name,
    name: `${name}_indices${explorationFileNameEnding}`,
  }));

  const filesList = [...samFilesList, ...mtmfFilesList, ...mlFilesList, ...indicesFilesList];

  const filesListToDisplay = [
    {
      id: 1,
      title: 'Minerals - SAM',
      list: samFilesList,
    },
    {
      id: 2,
      title: 'Minerals - MTMF',
      list: mtmfFilesList,
    },
    {
      id: 3,
      title: 'Minerals - ML',
      list: mlFilesList,
    },
    {
      id: 4,
      title: 'Geological Spectral Indices',
      list: indicesFilesList,
    },
  ].filter((item) => item.list.length);

  if (!filesList.length) {
    return 'No layer selected.';
  }

  const isSingleLink = filesList.length === 1;
  const fileName = isSingleLink ? filesList[0].name : `mineralization${explorationFileNameEnding}`;
  const fileType = 'tiff';
  const fileExtension = isSingleLink ? fileType : 'zip';

  const handleDownload = () => {
    isSingleLink
      ? saveFileFromUrlWithName(filesList[0].url, getFullFileName(fileName, fileType), setIsDownloading)
      : downloadAllFilesWithNamesAsZip(filesList, fileName, fileType, setIsDownloading);
  };

  return (
    <DownloadModal {...{ filesList: filesListToDisplay, fileName, fileExtension, isDownloading, handleDownload }} />
  );
};
