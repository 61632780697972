import { LabeledCheckbox, Slider } from 'ui';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { AuxiliaryControlsWrapper, LabeledSliderWrapper, LegendItemWraper } from './styled';
import { useLayerManagerStore } from '../layerManagerStore';

interface TopographyLayersGroupProps {
  areaName: string;
}

export const TopographyLayersGroup = ({ areaName }: TopographyLayersGroupProps) => {
  const { layersList } = areasRepository.useFetchTopographyLayers(areaName);

  const isHillshadeVisible = useLayerManagerStore.use.isHillshadeVisible();
  const toggleHillshadeVisibility = useLayerManagerStore.use.toggleHillshadeVisibility();
  const hillshadeOpacity = useLayerManagerStore.use.hillshadeOpacity();
  const setHillshadeOpacity = useLayerManagerStore.use.setHillshadeOpacity();
  const isTopographyVisible = useLayerManagerStore.use.isTopographyVisible();
  const toggleTopographyVisibility = useLayerManagerStore.use.toggleTopographyVisibility();
  const topographyOpacity = useLayerManagerStore.use.topographyOpacity();
  const setTopographyOpacity = useLayerManagerStore.use.setTopographyOpacity();

  const onTopographyOpacityChange = (value: number | number[]) => {
    setTopographyOpacity(Array.isArray(value) ? value[0] : value);
  };

  const onHillshadeOpacityChange = (value: number | number[]) => {
    setHillshadeOpacity(Array.isArray(value) ? value[0] : value);
  };

  if (!layersList.length) {
    return null;
  }

  return (
    <>
      <LegendItemWraper>
        <LabeledCheckbox variant="WIDE" onChange={toggleTopographyVisibility} checked={isTopographyVisible}>
          hypsometric
        </LabeledCheckbox>
        <AuxiliaryControlsWrapper disabled={!isTopographyVisible}>
          <LabeledSliderWrapper>
            opacity
            <Slider
              ariaLabel="Opacity slider"
              marks={{
                '0': '',
                '1': '',
              }}
              value={topographyOpacity}
              onChange={onTopographyOpacityChange}
              defaultValue={1}
              min={0}
              max={1}
              step={0.01}
            />
          </LabeledSliderWrapper>
        </AuxiliaryControlsWrapper>
      </LegendItemWraper>
      <LegendItemWraper>
        <LabeledCheckbox variant="WIDE" onChange={toggleHillshadeVisibility} checked={isHillshadeVisible}>
          hillshade
        </LabeledCheckbox>
        <AuxiliaryControlsWrapper disabled={!isHillshadeVisible}>
          <LabeledSliderWrapper>
            opacity
            <Slider
              ariaLabel="Opacity slider"
              marks={{
                '0': '',
                '1': '',
              }}
              value={hillshadeOpacity}
              onChange={onHillshadeOpacityChange}
              defaultValue={1}
              min={0}
              max={1}
              step={0.01}
            />
          </LabeledSliderWrapper>
        </AuxiliaryControlsWrapper>
      </LegendItemWraper>
    </>
  );
};
