import { Polyline } from 'react-leaflet';
import styled from 'styled-components';

import { type PointArray } from 'common/types/mapData';
import { getLines } from 'views/prospecting/utils';
import { Layer } from 'components/map';
import { PROSPECTING_OVERVIEW_LINES_C0LOR } from 'domain/exploration/colors';
import { PROSPECTING_OVERVIEW_LINES_OPACITY } from 'domain/exploration/configs';

interface ProspectingOverviewLinesProps {
  extentPolygon: PointArray[];
}

export const ProspectingOverviewLines = ({ extentPolygon }: ProspectingOverviewLinesProps) => {
  const solidLines = getLines(extentPolygon, 0.5);

  return (
    <StyledLayer name="InfoMapContent__lines" zIndex={403}>
      {/*TODO change extend bound color to merge colors with these lines*/}
      {solidLines.map((line, index) => (
        <Polyline
          key={index}
          pathOptions={{
            weight: 3,
            color: PROSPECTING_OVERVIEW_LINES_C0LOR,
            interactive: false,
            bubblingMouseEvents: false,
            opacity: PROSPECTING_OVERVIEW_LINES_OPACITY,
          }}
          positions={line}
        />
      ))}
    </StyledLayer>
  );
};

const StyledLayer = styled(Layer)`
  & svg path {
    cursor: grab;
  }
`;
