import { Rectangle, Tooltip } from 'react-leaflet';
import styled from 'styled-components';

import { type PointArray } from 'common/types/mapData';
import { ReactComponent as TargetIcon } from 'assets/svg/target.svg';
import { Layer } from 'components/map';
import { PROSPECTING_OVERVIEW_SITE_HOVER_C0LOR } from 'domain/exploration/colors';

interface ProspectingOverviewSiteAreaProps {
  bounds: PointArray[];
  name: string;
  isTarget: boolean;
  isSelected?: boolean;
  grade?: string;
  tonnage?: string;
  onClick?: () => void;
  onMouseOut?: () => void;
}

export const ProspectingOverviewSiteArea = ({
  bounds,
  name,
  isTarget,
  isSelected = false,
  grade,
  tonnage,
  onClick,
  onMouseOut,
}: ProspectingOverviewSiteAreaProps) => (
  <Layer name="deposit-classification-site-area-layer" zIndex={405}>
    <Rectangle
      bounds={bounds}
      pathOptions={{
        color: PROSPECTING_OVERVIEW_SITE_HOVER_C0LOR,
        pane: 'deposit-classification-site-area-layer',
        dashArray: isSelected ? '5 10' : null,
      }}
      eventHandlers={{
        click: onClick ? onClick : () => {},
        mouseout: onMouseOut ? onMouseOut : () => {},
      }}
    >
      <Layer visible={true} name="frame-tooltip" zIndex={404}>
        <StyledTooltip direction="top" permanent={true} position={bounds[1]}>
          <TooltipContentWrapper>
            <TooltipSiteName>
              {isTarget && <TargetIcon />}
              {name}
            </TooltipSiteName>
            {!isSelected && (grade || tonnage) && (
              <TooltipMineralInfo>
                {grade && <div>cu grade: {grade}</div>}
                {tonnage && <div>tonnage: {tonnage}</div>}
              </TooltipMineralInfo>
            )}
          </TooltipContentWrapper>
        </StyledTooltip>
      </Layer>
    </Rectangle>
  </Layer>
);

const TooltipContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  line-height: 1;
`;

const TooltipSiteName = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  font-weight: 600;

  & svg {
    width: 12px;
    height: 12px;
  }

  & svg path {
    stroke: black;
    stroke-width: 2px;
  }
`;

const TooltipMineralInfo = styled.div`
  font-size: 10px;
  font-weight: 600;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-top: 5px;
`;

const StyledTooltip = styled(Tooltip)`
  &::before,
  &::after {
    content: none;
  }

  & {
    padding: 5px;
    margin-left: 15px;
    border-radius: 10px;
  }
`;
