import { type LayersVisibilityDictionary, type ListItemStatus } from 'common/types';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { useGetCogWithBoundsGeneric } from 'services/titiler/hooks';
import { DataLayer } from 'components/map';
import { getAzureBlobStorageUrl } from 'services/titiler/utils';
import { type HotspotType } from 'domain/hotspots/types';

interface VegetationHotspotsDataLayerProps {
  currentHotspotUrl: string;
  greeneryObjects: HotspotType[];
  layersVisibility: LayersVisibilityDictionary;
}

export const VegetationHotspotsDataLayer = ({
  currentHotspotUrl,
  greeneryObjects,
  layersVisibility,
}: VegetationHotspotsDataLayerProps) => {
  const {
    cogUrl: titilerDataCogUrl,
    cogBounds: titilerDataBounds,
    isInfoLoading,
  } = useGetCogWithBoundsGeneric(
    titilerApiAdapter.buildMultilayerMonocolorCogUrlGeneric,
    getAzureBlobStorageUrl(`/${currentHotspotUrl}`),
    {
      objects: greeneryObjects
        .filter(({ name }) => layersVisibility[name])
        .map((object) => ({ ...object, status: 'active' as ListItemStatus })),
    },
  );

  return titilerDataCogUrl && titilerDataBounds ? (
    <DataLayer
      isVisible={!isInfoLoading}
      layerName={'hotspotsLayer'}
      zIndex={490}
      cogUrl={titilerDataCogUrl}
      bounds={titilerDataBounds}
    />
  ) : null;
};
