import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { isSuccessfulQueryUrl } from 'common/typeguards';
import { BACKGROUND_LAYER_NAME } from 'common/constants';
import { DEFAULT_RESULTS_PER_PAGE } from 'common/constants/services';
import { routeLoader } from 'services/query/routeLoader';
import { useHandleWarningErrorUseCase } from 'views/@errors/handleTypicalWarningErrorUseCase';
import {
  areGreeneryIndicatorStatsValid,
  areVegetationIndicatorsValid,
  areGreeneryDateResultsValid,
  areGreeneryResultsValid,
  areSegmentationResultsValid,
  areSegmentationAreaResultsValid,
  areQuarterlySegmentationResultsValid,
  areVhrObjectResultsValid,
  isGreeneryHotspotApiResponseValid,
  areGreeneryObjectsValid,
  isGreeneryVciResultsApiResponseValid,
} from 'domain/greenery/typeguards';
import { WarningErrorType } from 'domain/@errors/enums';
import { greeneryApi } from './greeneryApiAdapter';
import {
  GREENERY_QUARTERLY_RESULTS_COG_QUERY_KEY,
  GREENERY_QUARTERLY_RESULTS_QUERY_KEY,
  GREENERY_DATES_QUERY_KEY,
  GREENERY_INDICATORS_QUERY_KEY,
  GREENERY_SEGMENTATION_RESULTS_QUERY_KEY,
  GREENERY_SEGMENTATION_RESULTS_COG_QUERY_KEY,
  GREENERY_QUARTERLY_COMPARATOR_COG_QUERY_KEY,
  GREENERY_VHR_OBJECTS_QUERY_KEY,
  GREENERY_VEGETATION_COMPARATOR_COG_QUERY_KEY,
  GREENERY_VHR_SEGMENTATION_COG_URL_QUERY_KEY,
  GREENERY_INDICATOR_STATS_QUERY_KEY,
  GREENERY_AREAS_QUERY_KEY,
  GREENERY_VCI_RESULTS_QUERY_KEY,
  GREENERY_VEGETATION_HOTSPOTS_QUERY_KEY,
  GREENERY_INITIAL_DATA_STALE_TIME,
  GREENERY_INITIAL_DATA_CACHE_TIME,
  GREENERY_OBJECTS_QUERY_KEY,
  GREENERY_SEGMENTATION_COMPARATOR_COG_QUERY_KEY,
  GREENERY_VEGETATION_RESULTS_QUERY_KEY,
} from './constants';

const greeneryObjectsQuery = (params: { limit?: number; offset?: number }) => ({
  queryKey: [GREENERY_OBJECTS_QUERY_KEY, params] as unknown as string[],
  queryFn: () => greeneryApi.getObjectTypes(params),
});

const greeneryIndicatorsQuery = (params: { limit?: number; offset?: number }) => ({
  queryKey: [GREENERY_INDICATORS_QUERY_KEY, params] as unknown as string[],
  queryFn: () => greeneryApi.getVegetationIndicators(params),
});

export const greeneryRepository = {
  greeneryObjectsLoader: routeLoader(greeneryObjectsQuery({ limit: DEFAULT_RESULTS_PER_PAGE })),

  useFetchGreeneryObjects: () => {
    const { data, isLoading, isSuccess, error } = useQuery({
      ...greeneryObjectsQuery({ limit: DEFAULT_RESULTS_PER_PAGE }),
      staleTime: GREENERY_INITIAL_DATA_STALE_TIME,
      cacheTime: GREENERY_INITIAL_DATA_CACHE_TIME,
    });

    useHandleWarningErrorUseCase(WarningErrorType.LAND_COVER_CLASSES, data, error, isSuccess);

    const results = areGreeneryObjectsValid(data) ? data.data.results : [];

    const filteredResults = results.filter(({ name }) => name !== BACKGROUND_LAYER_NAME);

    return {
      greeneryObjects: filteredResults,
      greeneryObjectsLoading: isLoading,
    };
  },

  useFetchGreeneryDateResults: (areaId: number) => {
    const { isLoading, error, data, isSuccess } = useQuery(
      [
        GREENERY_DATES_QUERY_KEY,
        {
          areaId,
          limit: DEFAULT_RESULTS_PER_PAGE,
        },
      ],
      () => greeneryApi.getDateResults({ areaId, limit: DEFAULT_RESULTS_PER_PAGE }),
      {
        refetchOnMount: 'always',
      },
    );

    useHandleWarningErrorUseCase(WarningErrorType.LAND_COVER_DATES, data, error, isSuccess);

    const greeneryDateResults = areGreeneryDateResultsValid(data) ? data.data.results : [];

    return {
      greeneryDateResults,
      areGreeneryDateResultsLoading: isLoading,
    };
  },

  useFetchSegmentationResults: (areaId: number, date: string) => {
    const { data, isLoading, error, isSuccess } = useQuery(
      [
        GREENERY_SEGMENTATION_RESULTS_QUERY_KEY,
        {
          areaId,
          date,
          limit: DEFAULT_RESULTS_PER_PAGE,
        },
      ],
      () => greeneryApi.getSegmentationResults({ areaId, date, limit: DEFAULT_RESULTS_PER_PAGE }),
    );

    useHandleWarningErrorUseCase(WarningErrorType.DAILY_SEGMENTATION_COG, data, error, isSuccess);

    const segmentationResults = areSegmentationResultsValid(data) ? data.data.results : [];

    return {
      segmentationResults,
      areSegmentationResultsLoading: isLoading,
    };
  },

  useFetchSegmentationCog: (resultId: number) => {
    const { data, isInitialLoading, error, isSuccess } = useQuery(
      [GREENERY_SEGMENTATION_RESULTS_COG_QUERY_KEY, { resultId }],
      () => greeneryApi.getSegmentationCog(resultId),
      {
        enabled: !!resultId,
      },
    );

    useHandleWarningErrorUseCase(WarningErrorType.DAILY_SEGMENTATION_COG, data, error, isSuccess, 'url');

    const segmentationCogUrl = isSuccessfulQueryUrl(data) ? data.data.url : undefined;

    return {
      segmentationCogUrl,
      isSegmentationCogLoading: isInitialLoading,
    };
  },

  useFetchSegmentationComparatorCog: (firstResultId: number, secondResultId: number, objectId: number) => {
    const { data, error, isInitialLoading, isSuccess } = useQuery(
      [GREENERY_SEGMENTATION_COMPARATOR_COG_QUERY_KEY, { firstResultId, secondResultId, objectId }],
      () => greeneryApi.getSegmentationComparatorCog({ firstResultId, secondResultId }),
      {
        enabled: !!firstResultId && !!secondResultId,
      },
    );

    useHandleWarningErrorUseCase(WarningErrorType.DAILY_SEGMENTATION_COMPARATOR_COG, data, error, isSuccess, 'url');

    const segmentationComparatorCogUrl = isSuccessfulQueryUrl(data) ? data.data.url : undefined;

    return {
      segmentationComparatorCogUrl,
      isSegmentationComparatorCogLoading: isInitialLoading,
    };
  },

  useFetchSegmentationAreaResults: (areaId: number, date: string) => {
    const { data, isLoading } = useQuery(
      [
        GREENERY_AREAS_QUERY_KEY,
        {
          areaId,
          date,
          limit: DEFAULT_RESULTS_PER_PAGE,
        },
      ],
      () => greeneryApi.getSegmentationAreaResults({ areaId, date, limit: DEFAULT_RESULTS_PER_PAGE }),
    );

    const segmentationAreaResults = areSegmentationAreaResultsValid(data) ? data.data.results : [];

    return {
      segmentationAreaResults,
      areSegmentationAreaResultsLoading: isLoading,
    };
  },

  useGetQuarterlySegmentationResults: (areaId: number) => {
    const { data, isLoading, error, isSuccess } = useQuery(
      [
        GREENERY_QUARTERLY_RESULTS_QUERY_KEY,
        {
          areaId,
          limit: DEFAULT_RESULTS_PER_PAGE,
        },
      ],
      () => greeneryApi.getQuarterlySegmentationResults({ areaId, limit: DEFAULT_RESULTS_PER_PAGE }),
    );

    useHandleWarningErrorUseCase(WarningErrorType.QUARTERLY_SEGMENTATION_RESULTS, data, error, isSuccess);

    const quarterlyResults = areQuarterlySegmentationResultsValid(data) ? data.data.results : [];

    return {
      quarterlyResults,
      quarterlyResultsLoading: isLoading,
    };
  },

  useGetQuarterlySegmentationCogUrl: (resultId: number | undefined) => {
    const { data, isInitialLoading, error, isSuccess } = useQuery(
      [GREENERY_QUARTERLY_RESULTS_COG_QUERY_KEY, { resultId }],
      () =>
        !resultId ? Promise.reject(new Error('invalid result id')) : greeneryApi.getQuarterlySegmentationCog(resultId),
      {
        enabled: !!resultId,
      },
    );

    useHandleWarningErrorUseCase(WarningErrorType.QUARTERLY_SEGMENTATION_COG, data, error, isSuccess, 'url');

    const cogUrl = isSuccessfulQueryUrl(data) ? data.data.url : undefined;

    return {
      isQuarterlyCogLoading: isInitialLoading,
      quarterlyCogUrl: cogUrl,
    };
  },

  useGetQuarterlySegmentationsComparatorCog: () => {
    const [values, setValues] = useState<{ firstResultId?: number; secondResultId?: number }>({
      firstResultId: undefined,
      secondResultId: undefined,
    });

    const { firstResultId, secondResultId } = values;

    const { data, error, isSuccess } = useQuery(
      [GREENERY_QUARTERLY_COMPARATOR_COG_QUERY_KEY, { firstResultId, secondResultId }],
      () =>
        !firstResultId || !secondResultId
          ? Promise.reject(new Error('invalid result ids'))
          : greeneryApi.getQuarterlySegmentationComparatorCog({ firstResultId, secondResultId }),
      {
        enabled: !!firstResultId && !!secondResultId,
      },
    );

    useHandleWarningErrorUseCase(WarningErrorType.QUARTERLY_SEGMENTATION_COMPARATOR_COG, data, error, isSuccess, 'url');

    const execute = (firstResultId?: number, secondResultId?: number) => setValues({ firstResultId, secondResultId });

    const cogUrl = isSuccessfulQueryUrl(data) ? data.data.url : undefined;

    return {
      comparatorCogUrl: cogUrl,
      execute,
    };
  },

  greeneryIndicatorsLoader: routeLoader(greeneryIndicatorsQuery({ limit: DEFAULT_RESULTS_PER_PAGE })),

  useFetchGreeneryIndicators: () => {
    const { data, isLoading, error, isSuccess } = useQuery({
      ...greeneryIndicatorsQuery({ limit: DEFAULT_RESULTS_PER_PAGE }),
      staleTime: GREENERY_INITIAL_DATA_STALE_TIME,
      cacheTime: GREENERY_INITIAL_DATA_CACHE_TIME,
    });

    useHandleWarningErrorUseCase(WarningErrorType.VEGETATION_INDICATORS, data, error, isSuccess);
    const greeneryIndicators = areVegetationIndicatorsValid(data) ? data.data.results : [];

    return {
      greeneryIndicators,
      greeneryIndicatorsLoading: isLoading,
    };
  },

  useGetGreeneryIndicatorStats: (resultId: number | undefined) => {
    const { data, isSuccess } = useQuery(
      [GREENERY_INDICATOR_STATS_QUERY_KEY, { resultId }],
      () =>
        !resultId ? Promise.reject(new Error('invalid result id')) : greeneryApi.getVegetationIndicatorStats(resultId),
      { enabled: !!resultId },
    );

    const greeneryIndicatorStats = areGreeneryIndicatorStatsValid(data) ? data.data : undefined;

    return {
      greeneryIndicatorStats,
      isGreeneryIndicatorStatsFetchingSuccess: isSuccess,
    };
  },

  useFetchVegetationResults: (areaId: number, indicatorId: number | undefined, date: string | null) => {
    const { isInitialLoading, error, data, isSuccess } = useQuery(
      [
        GREENERY_VEGETATION_RESULTS_QUERY_KEY,
        {
          areaId,
          date,
          indicatorId,
          limit: DEFAULT_RESULTS_PER_PAGE,
        },
      ],
      () =>
        !indicatorId || !date
          ? Promise.reject(new Error('invalid timestamp or indicator id'))
          : greeneryApi.getVegetationResults({ areaId, date, indicatorId, limit: DEFAULT_RESULTS_PER_PAGE }),
      {
        enabled: !!indicatorId && !!date,
      },
    );

    useHandleWarningErrorUseCase(WarningErrorType.VEGETATION_RESULTS, data, error, isSuccess);

    const greeneryResults = areGreeneryResultsValid(data) ? data.data.results : [];

    return {
      greeneryResults,
      greeneryResultsLoading: isInitialLoading,
    };
  },

  useGetVegetationComparatorCog: (
    firstResultId: number | undefined,
    secondResultId: number | undefined,
    segmentationClass: string | null,
  ) => {
    const [comparatorCogLoadingEnabled, setComparatorCogLoadingEnabled] = useState(false);

    const { data, error, isInitialLoading, isSuccess } = useQuery(
      [GREENERY_VEGETATION_COMPARATOR_COG_QUERY_KEY, { firstResultId, secondResultId, segmentationClass }],
      () =>
        !firstResultId || !secondResultId || !segmentationClass
          ? Promise.reject(new Error('invalid result ids or segmentation class'))
          : greeneryApi.getVegetationComparatorCog({ firstResultId, secondResultId }),
      {
        enabled: comparatorCogLoadingEnabled && !!firstResultId && !!secondResultId && !!segmentationClass,
      },
    );

    useHandleWarningErrorUseCase(WarningErrorType.VEGETATION_COMPARATOR_COG, data, error, isSuccess, 'url');

    const cogUrl = isSuccessfulQueryUrl(data) ? data.data.url : undefined;

    return {
      comparatorCogUrl: cogUrl,
      isComparatorCogLoading: isInitialLoading,
      setComparatorCogLoadingEnabled,
    };
  },

  useFetchVegetationHotspotResults: (areaId: number) => {
    const { data, isLoading, error } = useQuery(
      [GREENERY_VEGETATION_HOTSPOTS_QUERY_KEY, { areaId, limit: DEFAULT_RESULTS_PER_PAGE }],
      () => greeneryApi.getHotspotsResults({ areaId, limit: DEFAULT_RESULTS_PER_PAGE }),
    );

    useEffect(() => {
      if (error) {
        console.error('Unable te fetch hotspots data', error);
      }
    }, [error]);

    const hotspotsData = isGreeneryHotspotApiResponseValid(data) ? data.data.results : [];

    return { hotspotsData, areaHotspotsLoading: isLoading, hotspotsError: error };
  },

  useFetchVhrObjects: () => {
    const { isLoading, data } = useQuery([GREENERY_VHR_OBJECTS_QUERY_KEY, { limit: DEFAULT_RESULTS_PER_PAGE }], () =>
      greeneryApi.getVhrObjectTypes({ limit: DEFAULT_RESULTS_PER_PAGE }),
    );

    const vhrObjectsData = areVhrObjectResultsValid(data) ? data.data : [];

    return { vhrObjectsData, isLoading };
  },

  useGetVhrSegmentationCogUrl: () => {
    const { isLoading, data } = useQuery([GREENERY_VHR_SEGMENTATION_COG_URL_QUERY_KEY], () =>
      greeneryApi.getVhrSegmentationCog(),
    );

    const cogUrl = isSuccessfulQueryUrl(data) ? data.data.url : undefined;

    const vhrCogUrl = cogUrl;

    return { vhrCogUrl, isLoading };
  },

  useGetVciResults: (areaId: number) => {
    const { data, isLoading, error, isSuccess } = useQuery(
      [GREENERY_VCI_RESULTS_QUERY_KEY, { areaId, limit: DEFAULT_RESULTS_PER_PAGE }],
      () => greeneryApi.getVciResults({ areaId, limit: DEFAULT_RESULTS_PER_PAGE }),
    );

    useHandleWarningErrorUseCase(WarningErrorType.VCI_DATA, data, error, isSuccess);

    const vciResults = isGreeneryVciResultsApiResponseValid(data) ? data.data.results : [];

    return {
      vciResults,
      vciResultsLoading: isLoading,
    };
  },
};
