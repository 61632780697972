import { Outlet } from 'react-router-dom';

import { Loading } from 'ui/molecules/loading';
import { AppLayout } from 'layout/appLayout/AppLayout';
import { Map } from 'views/areas/Map';
import { ReportsCreator } from 'views/reports/creator/ReportsCreator';
import { PluggedInCustomMapScale } from 'views/areas/PluggedInCustomMapScale';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { useNotificationsMock } from 'views/monitoring/useNotificationsMock';
import { DataRequestModal } from 'views/dataRequest/DataRequestModal';
import { useClearClustersData } from 'views/exploration/clusters/useClearClustersData';
import { useReportsStore } from 'infrastructure/reports/reportsStore';
import { useDataRequestStore } from 'infrastructure/dataRequest/dataRequestStore';
import { Screenshoter } from 'components/map';
import { useMapStore } from 'components/map/mapStore';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { useAoiNumber } from 'common/navigation/hooks';
import { useGetAoiName } from 'common/hooks/getAoiName';
import { CenteringExtent } from './CenteringExtent';
import { MapContent } from './MapContent';
import { SatelliteImage } from './SatelliteImage';
import { ShapesCollection } from './ShapesCollection';
import { useChangeAreaUseCase } from './areaDetailsSidebar/changeAreaUseCase';
import { TopographyMapContent } from './TopographyMapContent';
import { AreaDetailsSidebar } from './areaDetailsSidebar/AreaDetailsSidebar';

export const Area = () => {
  const areaId = useAoiNumber();
  const areaName = useGetAoiName();

  const takeScreenshotFlag = useReportsStore.use.takeScreenshotFlag();

  const areReportsActive = useGetAreReportsActiveUseCase();

  const mapReady = useMapStore.use.mapReady();

  const { isNextAreaDetailsLoading } = useChangeAreaUseCase();

  const isDataRequestModalOpen = useDataRequestStore.use.isDataRequestModalOpen();

  useNotificationsMock();
  useClearClustersData();

  const isSatelliteGrayscale = useLayerManagerStore.use.isSatelliteGrayscale();
  const isGrayscaleSatelliteActive = areReportsActive || isSatelliteGrayscale;

  return (
    <AppLayout sidebar={<AreaDetailsSidebar />}>
      <Outlet />
      <Map mapVariant={areReportsActive ? 'DEFAULT' : 'DARK'}>
        {takeScreenshotFlag && (
          <>
            <Screenshoter />
            <PluggedInCustomMapScale variantOverride="EXTERNAL" />
          </>
        )}
        {areaId && <CenteringExtent areaId={areaId} enabled={mapReady} />}
        {areaId && mapReady && (
          <>
            <SatelliteImage areaId={areaId} isGrayscaleSatelliteActive={isGrayscaleSatelliteActive} />
            {/* TODO: update when dedicated endpoint is ready */}
            {areaName && <TopographyMapContent areaName={areaName} />}
            {areaName && <ShapesCollection areaName={areaName} areReportsActive={areReportsActive} />}
          </>
        )}
        <MapContent />
        {isNextAreaDetailsLoading && <Loading />}
      </Map>
      {areReportsActive && areaId && <ReportsCreator areaId={areaId} />}
      {isDataRequestModalOpen && <DataRequestModal />}
    </AppLayout>
  );
};
