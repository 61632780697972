import styled from 'styled-components';

import { useAoiNumber, useGetIsComparisonActive, useQueryParameter } from 'common/navigation/hooks';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { Comparator } from 'components';
import { ReturnButton } from 'ui/molecules/returnButton/ReturnButton';
import { LayerManagerControlButton } from 'components/layerManager/LayerManagerControlButton';
import { ShareModalControlButton } from 'ui/organisms/shareModal/ShareModalControlButton';
import { ColorScale } from 'ui/colorScale';
import { AreaDetailsLayout } from 'views/areas/areaDetails/AreaDetailsLayout';
import { useViewOptionsSelectService, useWaterbodySelectService } from 'views/water/services';
import { useWaterTimestamps } from 'views/water/useWaterTimestamps';
import { WATERBODY_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { Select } from 'ui/atoms';
import { WATER_COMPARISON_COLOR_SCALE_RANGE_NAMES } from 'domain/water/configs';
import { WATER_AREA_COMPARISON_COLORMAP } from 'domain/water/colors';
import { useWaterAreaLifeCycleUseCase } from './waterAreaLifeCycleUseCase';
import { WaterAreaInfoArea } from './WaterAreaInfoArea';
import { WaterAreaLayerManager } from './WaterAreaLayerManager';
import { useGetWaterAreaComparatorCogsUseCase } from './getWaterAreaComparatorCogsUseCase';
import { WaterAreaTimeline } from './WaterAreaTimeline';
import { useWaterUseCases } from '../useCases';

export const WaterArea = () => {
  const areaId = useAoiNumber();

  const { waterbodySelectData } = useWaterbodySelectService(areaId);
  const { viewOptionsSelectData } = useViewOptionsSelectService();
  const { timestamps, areTimestampsLoading } = useWaterTimestamps();

  const { changeViewOption, resetViewOption, changeWaterbody, resetWaterbody } = useWaterUseCases();

  const { comparatorGenerateDisabled, updateComparatorCogs } = useGetWaterAreaComparatorCogsUseCase();

  const waterbodyIdQuery = useQueryParameter(WATERBODY_QUERY_PARAMETER);

  const areReportsActive = useGetAreReportsActiveUseCase();

  useWaterAreaLifeCycleUseCase(waterbodySelectData.selectedOption?.value);

  const { isComparisonActive } = useGetIsComparisonActive();

  return (
    <AreaDetailsLayout
      returnButton={
        <ReturnButton
          label="Back to all waterbodies"
          visible={!!waterbodySelectData.selectedOption && !areReportsActive}
          onClick={resetWaterbody}
        />
      }
      legend={
        isComparisonActive && !areReportsActive ? (
          <ColorScale
            colormap={WATER_AREA_COMPARISON_COLORMAP}
            labels={WATER_COMPARISON_COLOR_SCALE_RANGE_NAMES}
            segmented
            divided
          />
        ) : null
      }
      toolbarContent={
        <>
          <WaterAreaInfoArea waterbodyId={waterbodySelectData.selectedOption?.value} />
          {!areReportsActive && (
            <>
              <Select {...waterbodySelectData} onChange={changeWaterbody} reset={resetWaterbody} />
              <Select {...viewOptionsSelectData} onChange={changeViewOption} reset={resetViewOption} />
            </>
          )}
        </>
      }
      toolboxContent={
        <>
          {!areReportsActive && <LayerManagerControlButton />}
          {!areReportsActive && !isComparisonActive && <ShareModalControlButton />}
        </>
      }
    >
      {isComparisonActive && !areReportsActive && (
        <>
          <Offset exist={!!waterbodyIdQuery} />
          <Comparator
            variant="NEW_TO_OLDER"
            timestamps={timestamps}
            areTimestampsLoading={areTimestampsLoading}
            isCompareDisabled={comparatorGenerateDisabled}
            handleCompare={updateComparatorCogs}
          />
        </>
      )}
      {!areReportsActive && (
        <WaterAreaLayerManager isComparisonActive={isComparisonActive} isReportLayerManager={areReportsActive} />
      )}
      <WaterAreaTimeline waterbodyLabel={waterbodySelectData.selectedOption?.label} />
    </AreaDetailsLayout>
  );
};

const Offset = styled.div<{ exist: boolean }>`
  margin-top: ${({ exist }) => exist && 48}px;
  transition: margin-top ${({ theme }) => theme.transitionFunction.main};
`;
