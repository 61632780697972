import { Fragment, useEffect, useState } from 'react';

import { COMPARISON_OPTION_NAME, HOTSPOTS_OPTION_NAME } from 'common/navigation/constants';
import { useQueryParameter } from 'common/navigation/hooks';
import { VIEW_MODE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import * as map from 'components/map';
import { ShareModal } from 'components';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { useReportsStore } from 'infrastructure/reports/reportsStore';
import { useGetGreeneryCogUrlsWithIdsUseCase } from 'views/greenery/useCases/getGreeneryCogUrlsWithIdsUseCase';
import { VegetationMapContentComparator } from 'views/greenery/vegetation/VegetationMapContentComparator';
import { VegetationMapContentHotspots } from 'views/greenery/vegetation/VegetationMapContentHotspots';
import { VegetationMapLayer } from 'views/greenery/vegetation/VegetationMapLayer';
import { VegetationPuneDataLayer } from 'views/greenery/vegetation/VegetationPuneDataLayer';
import { VegetationTitilerTooltip } from 'views/greenery/vegetation/VegetationTitilerTooltip';
import { planetscopeUrls, pleiadesUrls } from 'views/greenery/vegetation/puneData';
import { vegetationDataService } from 'views/greenery/vegetation/vegetationDataService';
import { useGreeneryLayersVisibility } from 'views/greenery/useGreeneryLayersVisibility';
import { useVegetationIndicators } from 'views/greenery/vegetation/useVegetationIndicators';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { useTimelineService } from 'components/Timeline/timelineService';
import { Loading } from 'ui/molecules/loading';
import { VEGETATION_VIEW_SPECIFIC_DISABLED_LAYERS } from 'domain/greenery/configs';
import { GreeneryActiveTab } from 'domain/greenery/enums';

export const VegetationMapContent = () => {
  const viewQuery = useQueryParameter(VIEW_MODE_QUERY_PARAMETER);
  const isHotspotViewActive: boolean = viewQuery === HOTSPOTS_OPTION_NAME;
  const isComparisonActive: boolean = viewQuery === COMPARISON_OPTION_NAME;

  const isDataLayerVisible = useLayerManagerStore.use.isDataLayerVisible();
  const { classesVisibility } = useGreeneryLayersVisibility();
  const takeScreenshotFlag = useReportsStore.use.takeScreenshotFlag();

  const { timestamp } = useTimelineService();

  const { selectedIndicator } = useVegetationIndicators();
  const { greeneryObjects, greeneryObjectsLoading } = greeneryRepository.useFetchGreeneryObjects();
  const { greeneryResults, greeneryResultsLoading } = vegetationDataService.useFetchVegetationResults();

  const filteredResults = greeneryResults.filter(
    ({ greenery_object_id }) =>
      !VEGETATION_VIEW_SPECIFIC_DISABLED_LAYERS.includes(
        greeneryObjects.find(({ id }) => id === greenery_object_id)?.name || '',
      ),
  );

  const { urlsWithBounds } = useGetGreeneryCogUrlsWithIdsUseCase(filteredResults);

  const shareModalLinks = urlsWithBounds
    .filter((item) => {
      const objectName = greeneryObjects.find(({ id }) => id === item.id)?.name;

      return objectName && classesVisibility[objectName];
    })
    .map(({ url }) => url);

  // TODO: refactor when mercedes data will be handled
  const [url, setUrl] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (timestamp) {
      if (classesVisibility['pleiades']) {
        setUrl(pleiadesUrls.find((url) => url.date === timestamp)?.url || '');
      }
      if (classesVisibility['planet_scope']) {
        setUrl(planetscopeUrls.find((url) => url.date === timestamp)?.url || '');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timestamp]);

  // TODO: refactor
  const isSpecialModeActive = classesVisibility['pleiades'] || classesVisibility['planet_scope'];

  if (greeneryObjectsLoading || greeneryResultsLoading) {
    return <Loading />;
  }

  if (isComparisonActive && selectedIndicator) {
    return <VegetationMapContentComparator range={[selectedIndicator.min_value, selectedIndicator.max_value]} />;
  }

  if (isHotspotViewActive && selectedIndicator) {
    return <VegetationMapContentHotspots greeneryIndicator={selectedIndicator.id} />;
  }

  return (
    <>
      <map.Layer visible={isDataLayerVisible} name={'dataLayer'} zIndex={490}>
        {selectedIndicator &&
          urlsWithBounds.map(({ id, url, bounds }) => (
            <Fragment key={url}>
              {url && bounds && (
                <VegetationMapLayer
                  resultId={id}
                  vegetationCogUrl={url}
                  layerId={id}
                  greeneryObjects={greeneryObjects}
                  layersVisibility={!isSpecialModeActive ? classesVisibility : {}}
                  colormap={selectedIndicator.colormap}
                  range={[selectedIndicator.min_value, selectedIndicator.max_value]}
                />
              )}
            </Fragment>
          ))}
        {/* TODO: refactor when mercedes data will be handled */}
        {url && <VegetationPuneDataLayer isSpecialModeActive={isSpecialModeActive} url={url} />}
        {/* TODO */}
      </map.Layer>
      {!takeScreenshotFlag && (
        <VegetationTitilerTooltip
          greeneryObjects={greeneryObjects}
          layersVisibility={classesVisibility}
          urlsWithIds={urlsWithBounds}
          precision={selectedIndicator?.precision}
        />
      )}
      <ShareModal links={shareModalLinks} activeTab={GreeneryActiveTab.VEGETATION} />
    </>
  );
};
