import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';

import { useQueryParameter } from 'common/navigation/hooks';
import { SPATIAL_RESOLUTION_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { getCelciusFromKelvin } from 'common/utils';
import { DEFAULT_RESULTS_PER_PAGE } from 'common/constants/services';
import { useGetAoiName } from 'common/hooks/getAoiName';
import { type GraphData } from 'components/graph/types';
import { titilerApiService } from 'services/titiler/titilerApiService';
import { temperatureApiAdapter } from 'infrastructure/temperature/temperatureApiAdapter';
import { areTemperatureResultsValid } from 'domain/temperature/typeguards';
import { TEMPERATURE_RESULTS_QUERY_KEY } from 'domain/temperature/constants';

export const temperatureRepository = {
  useFetchTemperatureResults: (areaName: string | undefined) => {
    const { data } = useQuery([TEMPERATURE_RESULTS_QUERY_KEY, { limit: DEFAULT_RESULTS_PER_PAGE }], () =>
      temperatureApiAdapter.getResults({ limit: DEFAULT_RESULTS_PER_PAGE }),
    );

    const results = areTemperatureResultsValid(data) ? data.data.results : [];
    const resultsForSelectedAOI = results.filter(({ aoi_name }) => aoi_name === areaName);

    return {
      results: resultsForSelectedAOI,
    };
  },

  useFetchTemperaturePointHistory: () => {
    const areaName = useGetAoiName();
    const { results } = temperatureRepository.useFetchTemperatureResults(areaName);

    const resolutionQuery = useQueryParameter(SPATIAL_RESOLUTION_QUERY_PARAMETER);
    const resolution = resolutionQuery ? parseInt(resolutionQuery) : undefined;

    const [history, setHistory] = useState<GraphData>([]);
    const [isHistoryLoading, setIsHistoryLoading] = useState(false);

    const fetchTemperaturePointHistory = (lat: number, lng: number) => {
      if (!resolution) {
        return;
      }

      try {
        setIsHistoryLoading(true);
        const cogs = results
          .filter((result) => result.resolution === resolution)
          .map(({ img_date, url }) => [img_date, url]);

        setHistory([]);

        const historyPoints = Promise.all(
          cogs.map(async ([date, url]) => {
            const info = await titilerApiService.getPointInfoGeneric(url, lng, lat);
            if (!info?.values || !info.values.length || typeof info.values[0] !== 'number') {
              return [new Date(date), null] as [Date, number | null];
            }
            return [new Date(date), getCelciusFromKelvin(info.values[0])] as [Date, number];
          }),
        );

        const getHistoryPoints = async () => {
          const points = await historyPoints;
          const validPoints = points.filter(([, value]) => value !== null);

          setHistory(validPoints as GraphData);
        };

        getHistoryPoints();
        setIsHistoryLoading(false);
      } catch (error) {
        console.error('Error fetching temperature history', error);
        setIsHistoryLoading(false);
      }
    };

    return { history, fetchTemperaturePointHistory, isHistoryLoading };
  },
};
