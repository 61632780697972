import { useMemo } from 'react';

import { standarizeLatLngArray } from 'common/utils/map';
import { areasRepository } from 'infrastructure/areas/areasRepository';

export const useGetExtentPolygon = (areaId: number) => {
  const { areaOfInterestDetails } = areasRepository.useFetchAreaOfInterestDetails(areaId);

  const extentPolygon = areaOfInterestDetails?.geometry.coordinates[0];

  const standarizedExtentPolygon = useMemo(
    () => extentPolygon && standarizeLatLngArray(extentPolygon),
    [extentPolygon],
  );

  return standarizedExtentPolygon;
};
