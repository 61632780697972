import { useQueryParameter } from 'common/navigation/hooks';
import { COMPARISON_OPTION_NAME } from 'common/navigation/constants';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { VIEW_MODE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { Shape } from 'views/areas/areaDetails/Shape';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import {
  INFRASTRUCTURE_LAYER_MAIN_COLOR,
  INFRASTRUCTURE_LAYER_SECONDARY_COLOR,
  INFRASTRUCTURE_LAYER_REPORT_COLOR,
} from 'domain/areas/constants';

interface ShapesCollectionProps {
  areaName: string;
  areReportsActive?: boolean;
}

export const ShapesCollection = ({ areaName, areReportsActive = false }: ShapesCollectionProps) => {
  const viewQuery = useQueryParameter(VIEW_MODE_QUERY_PARAMETER);
  const isComparisonActive: boolean = viewQuery === COMPARISON_OPTION_NAME;

  const geoJSONsVisibility = useLayerManagerStore.use.geoJSONsVisibility();

  const { shapes } = areasRepository.useFetchInfrastructureShapes(areaName);

  const infrastructureLayerColor = areReportsActive
    ? INFRASTRUCTURE_LAYER_REPORT_COLOR
    : isComparisonActive
    ? INFRASTRUCTURE_LAYER_SECONDARY_COLOR
    : INFRASTRUCTURE_LAYER_MAIN_COLOR;

  return (
    <>
      {shapes.map(({ id, name, shape_url }) => (
        <Shape
          key={id}
          id={id}
          url={shape_url}
          name={name}
          color={infrastructureLayerColor}
          isVisible={geoJSONsVisibility[name]}
        />
      ))}
    </>
  );
};
