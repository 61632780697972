import { useLoopedQuery } from 'services/rest/hooks';
import { useWaterStore } from 'infrastructure/water/waterStore';
import { waterRepository } from 'infrastructure/water/waterRepository';
import { getUniqueSortedTimestamps } from 'common/utils/datetime';
import { useAoiNumber } from 'common/navigation/hooks';

export const useWaterTimestamps = () => {
  const areaId = useAoiNumber();

  const { isLoading: areTimestampsLoading } = useLoopedQuery(waterRepository.useFetchWaterDates, areaId, [areaId]);

  const timestampsIndex = useWaterStore.use.timestampsIndex();
  const timestamps = getUniqueSortedTimestamps(Object.keys(timestampsIndex));

  return {
    timestamps,
    areTimestampsLoading,
  };
};
