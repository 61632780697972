import { type MoistureIndicator, type MoistureResult } from 'domain/water/types';
import { MoistureMapMultiDataLayer } from './MoistureMapMultiDataLayer';

export const MoistureMapMultiDataLayers = ({
  results,
  indicator,
}: {
  results: MoistureResult[];
  indicator: MoistureIndicator;
}) => results.map((result) => <MoistureMapMultiDataLayer key={result.id} result={result} indicator={indicator} />);
