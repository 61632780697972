import { RESEARCH_ML_STORAGE_URL } from 'common/constants/services';

export const pleiadesWaterbodies = [
  {
    id: 101,
    waterbodyId: 8,
    waterbody: 'pune_lake',
    date: '2022-10-25T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/pleiades/pune_lake/pansharpened_2022-10-25_pune_lake.tif`,
  },
  {
    id: 102,
    waterbodyId: 8,
    waterbody: 'pune_lake',
    date: '2022-03-11T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/pleiades/pune_lake/pansharpened_2022-03-11_pune_lake.tif`,
  },
  {
    id: 103,
    waterbodyId: 8,
    waterbody: 'pune_lake',
    date: '2021-10-20T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/pleiades/pune_lake/pansharpened_2021-10-20_pune_lake.tif`,
  },
  {
    id: 104,
    waterbodyId: 8,
    waterbody: 'pune_lake',
    date: '2020-11-09T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/pleiades/pune_lake/pansharpened_2020-11-09_pune_lake.tif`,
  },
  {
    id: 201,
    waterbodyId: 9,
    waterbody: 'pune_pond',
    date: '2022-10-25T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/pleiades/pune_pond/pansharpened_2022-10-25_pune_pond.tif`,
  },
  {
    id: 202,
    waterbodyId: 9,
    waterbody: 'pune_pond',
    date: '2022-03-11T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/pleiades/pune_pond/pansharpened_2022-03-11_pune_pond.tif`,
  },
  {
    id: 203,
    waterbodyId: 9,
    waterbody: 'pune_pond',
    date: '2021-10-20T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/pleiades/pune_pond/pansharpened_2021-10-20_pune_pond.tif`,
  },
  {
    id: 204,
    waterbodyId: 9,
    waterbody: 'pune_pond',
    date: '2020-11-09T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/pleiades/pune_pond/pansharpened_2020-11-09_pune_pond.tif`,
  },
];

export const planetscopeWaterbodies = [
  {
    id: 301,
    waterbodyId: 8,
    waterbody: 'pune_lake',
    date: '2023-06-02T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/pune_lake/SKYWATCH_PLSD_PS_20230602T0447_ALL_Tile_0_0_07ac_pune_lake.tif`,
  },
  {
    id: 302,
    waterbodyId: 8,
    waterbody: 'pune_lake',
    date: '2023-05-05T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/pune_lake/SKYWATCH_PLSD_PS_20230505T0440_ALL_Tile_0_0_9edd_pune_lake.tif`,
  },
  {
    id: 303,
    waterbodyId: 8,
    waterbody: 'pune_lake',
    date: '2023-04-01T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/pune_lake/SKYWATCH_PLSD_PS_20230401T0439_ALL_Tile_0_0_a119_pune_lake.tif`,
  },
  {
    id: 304,
    waterbodyId: 8,
    waterbody: 'pune_lake',
    date: '2023-03-02T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/pune_lake/SKYWATCH_PLSD_PS_20230302T0443_ALL_Tile_0_0_40fc_pune_lake.tif`,
  },
  {
    id: 305,
    waterbodyId: 8,
    waterbody: 'pune_lake',
    date: '2023-02-03T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/pune_lake/SKYWATCH_PLSD_PS_20230203T0435_ALL_Tile_0_0_9536_pune_lake.tif`,
  },
  {
    id: 306,
    waterbodyId: 8,
    waterbody: 'pune_lake',
    date: '2023-01-02T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/pune_lake/SKYWATCH_PLSD_PS_20230102T0438_ALL_Tile_0_0_7145_pune_lake.tif`,
  },
  {
    id: 307,
    waterbodyId: 8,
    waterbody: 'pune_lake',
    date: '2022-12-01T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/pune_lake/SKYWATCH_PLSD_PS_20221201T0514_ALL_Tile_0_0_4456_pune_lake.tif`,
  },
  {
    id: 308,
    waterbodyId: 8,
    waterbody: 'pune_lake',
    date: '2022-11-04T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/pune_lake/SKYWATCH_PLSD_PS_20221104T0439_ALL_Tile_0_0_1358_pune_lake.tif`,
  },
  {
    id: 309,
    waterbodyId: 8,
    waterbody: 'pune_lake',
    date: '2022-10-09T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/pune_lake/SKYWATCH_PLSD_PS_20221009T0515_ALL_Tile_0_0_093c_pune_lake.tif`,
  },
  {
    id: 310,
    waterbodyId: 8,
    waterbody: 'pune_lake',
    date: '2022-06-05T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/pune_lake/SKYWATCH_PLSD_PS_20220605T0445_ALL_Tile_0_0_8735_pune_lake.tif`,
  },
  {
    id: 311,
    waterbodyId: 8,
    waterbody: 'pune_lake',
    date: '2022-05-03T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/pune_lake/SKYWATCH_PLSD_PS_20220503T0442_ALL_Tile_0_0_ce05_pune_lake.tif`,
  },
  {
    id: 312,
    waterbodyId: 8,
    waterbody: 'pune_lake',
    date: '2022-04-03T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/pune_lake/SKYWATCH_PLSD_PS_20220403T0441_ALL_Tile_0_0_ec4a_pune_lake.tif`,
  },
  {
    id: 313,
    waterbodyId: 8,
    waterbody: 'pune_lake',
    date: '2022-03-12T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/pune_lake/SKYWATCH_PLSD_PS_20220312T0442_ALL_Tile_0_0_2fb8_pune_lake.tif`,
  },
  {
    id: 314,
    waterbodyId: 8,
    waterbody: 'pune_lake',
    date: '2022-02-03T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/pune_lake/SKYWATCH_PLSD_PS_20220203T0514_ALL_Tile_0_0_7dd8_pune_lake.tif`,
  },
  {
    id: 401,
    waterbodyId: 9,
    waterbody: 'pune_pond',
    date: '2023-06-02T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/pune_pond/SKYWATCH_PLSD_PS_20230602T0447_ALL_Tile_0_0_07ac_pune_pond.tif`,
  },
  {
    id: 402,
    waterbodyId: 9,
    waterbody: 'pune_pond',
    date: '2023-05-05T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/pune_pond/SKYWATCH_PLSD_PS_20230505T0440_ALL_Tile_0_0_9edd_pune_pond.tif`,
  },
  {
    id: 403,
    waterbodyId: 9,
    waterbody: 'pune_pond',
    date: '2023-04-01T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/pune_pond/SKYWATCH_PLSD_PS_20230401T0439_ALL_Tile_0_0_a119_pune_pond.tif`,
  },
  {
    id: 404,
    waterbodyId: 9,
    waterbody: 'pune_pond',
    date: '2023-03-02T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/pune_pond/SKYWATCH_PLSD_PS_20230302T0443_ALL_Tile_0_0_40fc_pune_pond.tif`,
  },
  {
    id: 405,
    waterbodyId: 9,
    waterbody: 'pune_pond',
    date: '2023-02-03T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/pune_pond/SKYWATCH_PLSD_PS_20230203T0435_ALL_Tile_0_0_9536_pune_pond.tif`,
  },
  {
    id: 406,
    waterbodyId: 9,
    waterbody: 'pune_pond',
    date: '2023-01-02T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/pune_pond/SKYWATCH_PLSD_PS_20230102T0438_ALL_Tile_0_0_7145_pune_pond.tif`,
  },
  {
    id: 407,
    waterbodyId: 9,
    waterbody: 'pune_pond',
    date: '2022-12-01T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/pune_pond/SKYWATCH_PLSD_PS_20221201T0514_ALL_Tile_0_0_4456_pune_pond.tif`,
  },
  {
    id: 408,
    waterbodyId: 9,
    waterbody: 'pune_pond',
    date: '2022-11-04T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/pune_pond/SKYWATCH_PLSD_PS_20221104T0439_ALL_Tile_0_0_1358_pune_pond.tif`,
  },
  {
    id: 409,
    waterbodyId: 9,
    waterbody: 'pune_pond',
    date: '2022-10-09T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/pune_pond/SKYWATCH_PLSD_PS_20221009T0515_ALL_Tile_0_0_093c_pune_pond.tif`,
  },
  {
    id: 410,
    waterbodyId: 9,
    waterbody: 'pune_pond',
    date: '2022-06-05T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/pune_pond/SKYWATCH_PLSD_PS_20220605T0445_ALL_Tile_0_0_8735_pune_pond.tif`,
  },
  {
    id: 411,
    waterbodyId: 9,
    waterbody: 'pune_pond',
    date: '2022-05-03T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/pune_pond/SKYWATCH_PLSD_PS_20220503T0442_ALL_Tile_0_0_ce05_pune_pond.tif`,
  },
  {
    id: 412,
    waterbodyId: 9,
    waterbody: 'pune_pond',
    date: '2022-04-03T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/pune_pond/SKYWATCH_PLSD_PS_20220403T0441_ALL_Tile_0_0_ec4a_pune_pond.tif`,
  },
  {
    id: 413,
    waterbodyId: 9,
    waterbody: 'pune_pond',
    date: '2022-03-12T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/pune_pond/SKYWATCH_PLSD_PS_20220312T0442_ALL_Tile_0_0_2fb8_pune_pond.tif`,
  },
  {
    id: 414,
    waterbodyId: 9,
    waterbody: 'pune_pond',
    date: '2022-02-03T00:00:00Z',
    url: `${RESEARCH_ML_STORAGE_URL}/users/mcbg/aoi_id=5/processing_results/planetscope/pune_pond/SKYWATCH_PLSD_PS_20220203T0514_ALL_Tile_0_0_7dd8_pune_pond.tif`,
  },
];
