import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { useReplaceQueryParameters } from 'common/navigation/hooks';
import { WATER_INDICATOR_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { useWaterIndicators } from 'views/water/quality/useWaterIndicators';
import { WaterActiveTab } from 'domain/water/enums';

export const useWaterIndicatorsSelectService = () => {
  const { selectedIndicator, indicatorOptions, areIndicatorsLoading } = useWaterIndicators();

  const { pathname } = useLocation();
  const isQualityTabActive = pathname.includes(WaterActiveTab.QUALITY);

  const replaceQueryParameter = useReplaceQueryParameters();

  useEffect(() => {
    if (!selectedIndicator && isQualityTabActive && indicatorOptions[0]?.value) {
      setTimeout(() => {
        replaceQueryParameter([
          {
            key: WATER_INDICATOR_QUERY_PARAMETER,
            value: String(indicatorOptions[0].value),
          },
        ]);
      }, 500);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, JSON.stringify(indicatorOptions.map(({ value }) => value))]);

  return {
    indicatorsSelectData: {
      options: indicatorOptions,
      selectedOption: indicatorOptions.find(({ value }) => value === selectedIndicator?.id),
      initialStateLabel: 'Select Water Indicator',
      noOptionsLabel: 'No Water Indicators',
      name: 'water indicator selection',
      title: '',
      isFetching: areIndicatorsLoading,
    },
  };
};
