import { useEffect, useState } from 'react';

import { buildSliderMarks, isValueInsideRange, roundNumber } from 'common/utils';
import { type LayerRange, type LayerManagerSliderConfig } from 'common/types';
import { Container, Slider } from 'ui';
import { useLayerManagerStore } from './layerManagerStore';
import { LayerManagerThresholdInput } from './ThresholdInput';

interface ThresholdSliderProps {
  layerRange: LayerRange;
  layerName: string;
  config: LayerManagerSliderConfig;
}

export const LayerManagerThresholdSlider = ({ layerRange, layerName, config }: ThresholdSliderProps) => {
  const layersTresholds = useLayerManagerStore.use.layersTresholdsValues();
  const changeLayerTreshold = useLayerManagerStore.use.updateLayersTresholdsValues();

  const [showInput, setShowInput] = useState(false);

  const { step, precision, isDoubleHandle, variant, restricted, withInput } = config;
  const { min, max, initialValue } = layerRange;

  const marks = buildSliderMarks(min, max, 1, precision);
  const threshold = layersTresholds[layerName];
  const defaultValue = isDoubleHandle ? [max, max] : max;

  const isValueValid = isValueInsideRange(threshold, isDoubleHandle, min, max, step, restricted.min, restricted.max);
  const value = isValueValid ? threshold : defaultValue;

  const onChange = (value: number | number[]) => {
    if (Array.isArray(value) && value.length === 2 && value[0] === value[1]) {
      return;
    }

    if (isValueInsideRange(value, isDoubleHandle, min, max, step, restricted.min, restricted.max)) {
      withInput && !showInput && setShowInput(true);

      const newValue =
        typeof value === 'number'
          ? roundNumber(value, precision)
          : [roundNumber(value[0], precision), roundNumber(value[1], precision)];

      changeLayerTreshold({
        [layerName]: newValue,
      });
    }
  };

  useEffect(() => {
    if (!isValueValid) {
      const newValue = isDoubleHandle ? [initialValue, max] : initialValue;
      onChange(newValue);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container ph={4} pt={10} wMax variant="VERTICAL">
      <Slider
        ariaLabel="Treshold adjustment"
        {...{ variant, marks, value, onChange, defaultValue, min, max, step }}
        isThumbLabel
      />
      {withInput && <LayerManagerThresholdInput {...{ value, min, max, step, onChange, showInput, setShowInput }} />}
    </Container>
  );
};
