import { useEffect } from 'react';

import { type ListItemStatus } from 'common/types';
import { restrictValue } from 'common/utils';
import { LayerManagerLegendItem } from 'components/layerManager/legacy/LegendItem';
import { Slider } from 'ui';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { type MoistureCommonProps } from 'domain/water/types';

export const MoistureLayerManagerLegendItem = ({ indicator, layerName, min, max }: MoistureCommonProps) => {
  const isDataLayerVisible = useLayerManagerStore.use.isDataLayerVisible();
  const toggleDataLayerVisibility = useLayerManagerStore.use.toggleDataLayerVisibility();

  const layersTresholds = useLayerManagerStore.use.layersTresholdsValues();
  const changeLayerTreshold = useLayerManagerStore.use.changeLayerTresholdValue();

  const layerManagerOption = {
    id: indicator.id,
    name: indicator.indicator_name,
    label_name: indicator.indicator_name,
    color: '',
    status: 'active' as ListItemStatus,
  };

  const sliderValue = layersTresholds[layerName];

  const sliderConfig = {
    defaultValue: min,
    value: sliderValue,
    onChange: (value: number | number[]) => {
      changeLayerTreshold({
        ...layersTresholds,
        [layerName]: restrictValue(value, max, min, indicator.precision),
      });
    },
    min,
    max,
    step: 10 ** -indicator.precision,
    marks: {},
  };

  useEffect(() => {
    if (typeof sliderValue !== 'number') {
      changeLayerTreshold({
        ...layersTresholds,
        [layerName]: min,
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [layerName]);

  if (typeof sliderValue !== 'number') {
    return null;
  }

  return (
    <LayerManagerLegendItem
      option={layerManagerOption}
      objectVisibility={isDataLayerVisible}
      handleObjectsVisibility={toggleDataLayerVisibility}
      iconColormap={indicator.colormap}
    >
      <Slider ariaLabel="Moisture treshold adjustment" variant="RANGE_INVERTED" {...sliderConfig} isThumbLabel />
    </LayerManagerLegendItem>
  );
};
