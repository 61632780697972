import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { createSelectors } from 'common/utils/createSelectors';

interface GreenerySliceState {
  comparatorUrl: string | undefined;
  setComparatorUrl: (url: string) => void;
  clearComparatorUrl: () => void;
}

const initialState = {
  comparatorUrl: undefined,
};

const useGreeneryStoreBase = create<GreenerySliceState>()(
  devtools(
    (set) => ({
      comparatorUrl: initialState.comparatorUrl,
      setComparatorUrl: (url) => set({ comparatorUrl: url }, false, 'greenery/setComparatorUrl'),
      clearComparatorUrl: () =>
        set({ comparatorUrl: initialState.comparatorUrl }, false, 'greenery/clearComparatorUrl'),
    }),
    {
      name: 'greeneryStore',
    },
  ),
);

export const useGreeneryStore = createSelectors(useGreeneryStoreBase);
