import { useCallback, useEffect } from 'react';
import {
  useAoiNumber,
  useDeleteQueryParameters,
  useQueryParameter,
  useReplaceQueryParameters,
} from 'common/navigation/hooks';
import { LINEAMENT_THRESHOLD_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { useGetAoiName } from 'common/hooks/getAoiName';
import { explorationRepository } from 'infrastructure/exploration/explorationRepository';

export const useLineaments = () => {
  const areaId = useAoiNumber();
  const areaName = useGetAoiName();
  const thresholdQuery = useQueryParameter(LINEAMENT_THRESHOLD_QUERY_PARAMETER);
  const { rangeResult } = explorationRepository.useFetchLineamentsRangeResults(areaName);

  const ranges = rangeResult && rangeResult.ranges.length >= 2 ? rangeResult.ranges.sort((a, b) => a - b) : [0, 1];
  const defaultValue = [ranges[0], ranges[1]];
  const selectedOption = thresholdQuery ? thresholdQuery.split('-').map((value) => parseFloat(value)) : defaultValue;

  const currentThreshold = {
    id: Number(`${areaId}${selectedOption.join('')}`),
    value: selectedOption,
  };

  const replaceQueryParameter = useReplaceQueryParameters();
  const deleteQueryParameters = useDeleteQueryParameters();

  const changeOption = useCallback(
    (value: [number, number] | null) => {
      if (value && value[0] === value[1]) {
        return;
      }

      const threshold = Array.isArray(value) ? `${value[0]}-${value[1]}` : undefined;

      threshold
        ? replaceQueryParameter([
            {
              key: LINEAMENT_THRESHOLD_QUERY_PARAMETER,
              value: threshold,
            },
          ])
        : deleteQueryParameters([LINEAMENT_THRESHOLD_QUERY_PARAMETER]);
    },
    [deleteQueryParameters, replaceQueryParameter],
  );

  useEffect(() => {
    if (!selectedOption || !(ranges.includes(selectedOption[0]) && ranges.includes(selectedOption[1]))) {
      setTimeout(() => changeOption([ranges[0], ranges[1]]));
    }
  }, [selectedOption, ranges]); // eslint-disable-line react-hooks/exhaustive-deps

  return { currentThreshold, selectedOption, defaultValue, rangeResult, ranges, changeOption };
};
