import styled, { css } from 'styled-components';

export const LegendItemWraper = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`;

export const AuxiliaryControlsWrapper = styled.div<{ disabled: boolean }>`
  display: flex;
  flex-direction: column;

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `};
`;

export const LabeledSliderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  text-transform: capitalize;
`;

export const FoldableSectionContentWrapper = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  text-transform: capitalize;
  background-color: ${({ theme }) => theme.color.mineShaftLight};
  margin: 0 -14px;

  > div {
    padding: 0 1rem 0 3rem;
  }
`;

export const DndListWrapper = styled.div`
  background-color: ${({ theme }) => theme.color.mineShaftLight};
  margin: 0 -1rem;
`;

export const IndentedContentWraper = styled.div`
  padding-left: 2rem;
`;
