import { useTreeShakeQueryParams } from 'common/navigation/hooks';
import { useWaterbodiesMock } from 'views/monitoring/useWaterbodiesMock';
import { useReclamationsMock } from 'views/monitoring/useReclamationsMock';
import { MonitoringPanel } from 'views/monitoring/MonitoringPanel';
import { AreaDetailsLayout } from 'views/areas/areaDetails/AreaDetailsLayout';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { AppLayerManager } from 'components/layerManager/legacy';
import { LayerManagerControlButton } from 'components/layerManager/LayerManagerControlButton';
import { MONITORING_VIEW_CONFIG } from 'domain/monitoring/configs';

export const Monitoring = () => {
  useTreeShakeQueryParams(MONITORING_VIEW_CONFIG);

  const areReportsActive = useGetAreReportsActiveUseCase();

  useWaterbodiesMock();
  useReclamationsMock();

  return (
    <AreaDetailsLayout toolboxContent={<>{!areReportsActive && <LayerManagerControlButton />}</>}>
      {!areReportsActive && (
        <>
          <MonitoringPanel />
          <AppLayerManager />
        </>
      )}
    </AreaDetailsLayout>
  );
};
