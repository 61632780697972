import { create } from 'zustand';
import { devtools } from 'zustand/middleware';

import { createSelectors } from 'common/utils/createSelectors';
import { type PinInfoType } from 'views/prospecting/types';
import { type AreaPropsectingSitesDTO } from 'domain/exploration/types';

interface OverviewInfoStore {
  isAreaInfoOpen: boolean;
  setIsAreaInfoOpen: (isAreaInfoOpen: boolean) => void;

  hoveredDepositSiteName: string | undefined;
  setHoveredDepositSiteName: (hoveredDepositSiteName: string | undefined) => void;

  recommendedSites: AreaPropsectingSitesDTO;

  areaPinsInfo: PinInfoType[] | undefined;
  setAreaPinsInfo: (areaPinsInfo: PinInfoType[] | undefined) => void;
}

const useOverviewStoreBase = create<OverviewInfoStore>()(
  devtools(
    (set) => ({
      isAreaInfoOpen: true,
      setIsAreaInfoOpen: (isAreaInfoOpen: boolean) =>
        set({ isAreaInfoOpen: isAreaInfoOpen }, false, 'OverviewInfoStore/setIsAreaInfoOpen'),

      hoveredDepositSiteName: undefined,
      setHoveredDepositSiteName: (hoveredDepositSiteName: string | undefined) =>
        set({ hoveredDepositSiteName: hoveredDepositSiteName }, false, 'OverviewInfoStore/setHoveredDepositSiteName'),

      recommendedSites: {},

      areaPinsInfo: undefined,
      setAreaPinsInfo: (areaPinsInfo: PinInfoType[] | undefined) =>
        set({ areaPinsInfo: areaPinsInfo }, false, 'OverviewInfoStore/setAreaPinsInfo'),
    }),
    { name: 'OverviewInfoStore' },
  ),
);

export const useOverviewInfoStore = createSelectors(useOverviewStoreBase);
