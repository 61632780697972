export const theme = {
  color: {
    white: '#FFFFFF',
    black: '#000000',
    red: '#FF0000',
    redLoginError: '#FF9F8A',
    codGray: '#111111',
    mineShaftDark: '#232020',
    mineShaftDarkBlur: '#232020CC',
    mineShaftABitDarker: '#272424',
    mineShaft: '#292929',
    mineShaftABitLighter: '#2E2B2B',
    mineShaftLight: '#383434',
    masala: '#403B37',
    lightGray: '#A09F9F',
    gray: '#888888',
    doveGray: '#646464',
    midGray: '#504E4D',
    suvaGray: '#8D8D8D',
    fuscousGrayLight: '#595651',
    fuscousGray: '#4E4B45',
    cloud: '#C3C0BB',
    chicago: '#5C5650',
    alto: '#DEDEDE',
    silver: '#CCCCCC',
    silverDark: '#C4C4C4',
    selectGray: '#3D3B3B',
    emperor: '#515151',
    dawn: '#A39E9A',
    duneLighter: '#353230',
    duneLight: '#33302E',
    dune: '#2C2826',
    duneDark: '#292726',
    duneDarker: '#22201E',
    alabaster: '#F8F8F8',
    concrete: '#F3F3F3',
    tapa: '#77716C',
    tapaLight: '#7A7570',
    flint: '#716A63',
    keppel: '#419E9E',
    paradiso: '#2E7777',
    copperRust: '#974F4F',
    spicyMix: '#7E4646',
    notificationPink: '#FFDEDE',
    notificationDarkPink: '#FFAAAA',
    notificationDarkRed: '#881F1F',
    darkTan: '#640F0F',
    donkeyBrown: '#A3917F',
    shadow: '#866E56',
    tuatara: '#2B2A29',

    scorpion: '#595858',
    gallery: '#EDEBEB',
    swissCoffee: '#E0DDDB',
    tumbleweed: '#E0B080',

    primaryLight: '#CA8542',
    primary: '#B6722F',
    primaryDark: '#8A5A2B',

    blackTransparent20: 'rgba(0,0,0,0.2)',
    whiteTransparent30: 'rgba(255,255,255,0.3)',
    whiteTransparent40: 'rgba(255,255,255,0.4)',
    whiteTransparent50: 'rgba(255,255,255,0.5)',
    blackTransparent40: '#00000066',
    blackTransparent50: 'rgba(0, 0, 0, 0.5)',
    blackTransparent60: 'rgba(0, 0, 0, 0.6)',
    blackTransparent70: 'rgba(0,0,0,0.7)',
    blackTransparent78: 'rgba(0,0,0,0.78)',
    blackTransparent90: 'rgba(0,0,0,0.9)',
    grayTransparent90: 'rgba(28, 26, 25, 0.90)',

    congoBrown: '#5C3636',
    sundown: '#FFB4B4',

    error: '#FF000087',

    errorRed: '#FF8B68',
    errorYellow: '#FFDD26',
    errorPink: '#E07C5C',

    transparent: 'transparent',
  },

  breakpoint: {
    xs: '320px',
    sm: '576px',
    md: '768px',
    lg: '992px',
    xl: '1200px',
    xxl: '1400px',
  },

  size: {
    toolboxPadding: '1.7rem',
  },

  transitionFunction: {
    main: '300ms ease-in-out',
  },
};
