import { useEffect, useState } from 'react';

import { type ColormapDictionary } from 'common/types';
import { type LeafletBounds } from 'common/types/mapData';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { useGetCogWithBoundsGeneric } from 'services/titiler/hooks';
import { getAzureBlobStorageUrl } from 'services/titiler/utils';

export const useGetVegetationMapLayerDataUseCase = (url: string, colormap: ColormapDictionary, range: number[]) => {
  const [cogData, setCogData] = useState<{ url: string; bounds: LeafletBounds } | undefined>(undefined);

  const fileUrl = getAzureBlobStorageUrl(url);
  const { cogUrl, cogBounds } = useGetCogWithBoundsGeneric(
    titilerApiAdapter.buildDiscreteColorScaleFloatCogUrl,
    fileUrl,
    {
      range,
      colormapObject: colormap,
      noDataValue: 0,
    },
  );

  useEffect(() => {
    if (cogUrl && cogBounds) {
      setCogData({ url: cogUrl, bounds: cogBounds });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cogUrl, !!cogBounds]);

  return { cogUrl: cogData?.url, cogBounds: cogData?.bounds, originalUrl: url };
};
