import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { ROUTES } from 'common/navigation/routes';
import { Button, Container } from 'ui';

interface AddNewAreaModalContentProps {
  header: string;
  handleClose?: () => void;
}

export const AddNewAreaModalContent = ({ header, handleClose }: AddNewAreaModalContentProps) => {
  const navigate = useNavigate();

  const onClick = () => {
    navigate(ROUTES.areaRequestNew);
    handleClose && handleClose();
  };

  return (
    <Container variant="VERTICAL_START" gap={35}>
      <ModalContentText>{header}</ModalContentText>
      <div>
        <Button variant="PRIMARY_BIG" onClick={onClick} squared>
          + exploration area
        </Button>
      </div>
    </Container>
  );
};

const ModalContentText = styled.div`
  max-width: 310px;
`;
