import { type LayerObject } from 'common/types/mapData';
import { useAoiNumber } from 'common/navigation/hooks';
import { useGreeneryObjectsUpdateUseCase } from 'views/greenery/useCases/greeneryObjectsUpdateUseCase';
import { segmentationDataService } from 'views/greenery/segmentation/segmentationDataService';
import { LoadingDots } from 'ui/atoms/loadingDots/LoadingDots';
import { Container } from 'ui/atoms/container';
import { VegetationLayerManagerLegendWrapper } from './VegetationLayerManagerLegendWrapper';

interface VegetationLayerManagerProps {
  layerObjects: LayerObject[];
  greeneryObjectsLoading: boolean;
  viewSpecificDisabledLayers?: string[];
  noBadges?: boolean;
  timestamp: string;
}

export const VegetationLayerManager = ({
  layerObjects,
  greeneryObjectsLoading,
  viewSpecificDisabledLayers = [],
  noBadges,
  timestamp,
}: VegetationLayerManagerProps) => {
  const areaId = useAoiNumber();

  const { resultId } = segmentationDataService.useSegmentationResult(timestamp);

  useGreeneryObjectsUpdateUseCase(layerObjects, areaId);

  return (
    <>
      {resultId &&
        (greeneryObjectsLoading ? (
          <Container mt={10}>
            <LoadingDots />
          </Container>
        ) : (
          <VegetationLayerManagerLegendWrapper
            areaId={areaId}
            layerObjects={layerObjects}
            viewSpecificDisabledLayers={viewSpecificDisabledLayers}
            noBadges={noBadges}
            chosenResultId={resultId}
          />
        ))}
    </>
  );
};
