import { useAoiNumber, useHandleQueryParameterByPrimitiveValue } from 'common/navigation/hooks';
import { DEPOSIT_CLASSIFICATION_SITE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { getSquareBoundsAroundPoint, standarizeLatLngArray } from 'common/utils/map';
import { Loading } from 'ui/molecules/loading';
import { explorationRepository } from 'infrastructure/exploration/explorationRepository';
import { areasRepository } from 'infrastructure/areas/areasRepository';
import { ProspectingOverviewSites } from 'views/prospecting/map/ProspectingOverviewSites';
import { useOverviewInfoStore } from 'views/prospecting/store';
import { ProspectingOverviewSiteArea } from 'views/prospecting/map/ProspectingOverviewSiteArea';
import { ProspectingOverviewBackground } from 'views/prospecting/map/ProspectingOverviewBackground';
import { getCuttedBounds } from 'views/prospecting/utils';

export const ProspectingMapContent = () => {
  const areaId = useAoiNumber();

  const { areaOfInterestDetails } = areasRepository.useFetchAreaOfInterestDetails(areaId);
  const areaCoordinates = areaOfInterestDetails?.geometry.coordinates[0];

  const { depositClassificationSites, depositClassificationSitesLoading } =
    explorationRepository.useFetchDepositClassificationSites(areaId, 1);

  const [selectedDepositSite] = useHandleQueryParameterByPrimitiveValue(DEPOSIT_CLASSIFICATION_SITE_QUERY_PARAMETER);
  const hoveredDepositSiteName = useOverviewInfoStore.use.hoveredDepositSiteName();

  const { prospectingTargets } = explorationRepository.useProspectingTargets();
  const targets = prospectingTargets[areaId] || [];

  if (depositClassificationSitesLoading) {
    return <Loading />;
  }

  if (!depositClassificationSites.length) {
    return null;
  }

  const selectedSite = depositClassificationSites.find((site) => site.properties.index === selectedDepositSite);
  const hoveredSite = depositClassificationSites.find((site) => site.properties.index === hoveredDepositSiteName);

  const siteCoordinates = selectedSite?.geometry.coordinates || hoveredSite?.geometry.coordinates;
  const extentPolygon = areaCoordinates && standarizeLatLngArray(areaCoordinates);

  const bounds =
    siteCoordinates && extentPolygon
      ? getCuttedBounds(extentPolygon, Object.values(getSquareBoundsAroundPoint(siteCoordinates, 1).bounds))
      : [];

  return (
    <ProspectingOverviewBackground bounds={bounds}>
      {selectedSite && bounds.length > 1 ? (
        <ProspectingOverviewSiteArea
          bounds={bounds}
          name={selectedSite.properties.index}
          isTarget={targets.some((target) => target === selectedSite.properties.index)}
          isSelected
        />
      ) : (
        <ProspectingOverviewSites sites={depositClassificationSites} targets={targets} extentPolygon={extentPolygon} />
      )}
    </ProspectingOverviewBackground>
  );
};
