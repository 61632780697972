import { useEffect } from 'react';

import { useQueryParameter } from 'common/navigation/hooks';
import { WATERBODY_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { Layer, Marker } from 'components/map';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { GenericMapTooltip } from 'components/map/tooltip/GenericMapTooltip';
import { useSelectedMapPoint } from 'components/map/useSelectedMapPoint';
import { ShareModal } from 'components';
import { useReportsStore } from 'infrastructure/reports/reportsStore';
import { useWaterStore } from 'infrastructure/water/waterStore';
import { Loading } from 'ui/molecules/loading';
import { useGraphStore } from 'components/graph/graphStore';
import { WaterActiveTab } from 'domain/water/enums';
import { buildWaterBodyTooltipStringHelper } from 'domain/water/helpers';
import { WaterQualityDataLayer } from './WaterQualityDataLayer';
import { useWaterIndicators } from './useWaterIndicators';
import { useGetWaterQualityRemappedResults } from './useGetWaterQualityRemappedResults';
import { useWaterPointHistory } from './useWaterPointHistory';

export const WaterQualityMapContent = () => {
  const waterbodyIdQuery = useQueryParameter(WATERBODY_QUERY_PARAMETER);

  const isDataLayerVisible = useLayerManagerStore.use.isDataLayerVisible();
  const waterbodyDefaultView = useWaterStore.use.waterBodyDefaultView();
  const resetGraphStore = useGraphStore.use.resetGraphStore();

  const { selectedIndicator } = useWaterIndicators();

  const selectedMapPoint = useSelectedMapPoint(selectedIndicator?.colormap, {
    min: selectedIndicator?.min_value || -1,
    max: selectedIndicator?.max_value || 1,
  });

  const { waterQualityReamappedResults, waterQualityRemappedResultsLoading } = useGetWaterQualityRemappedResults();

  const { handleWaterBodyPointClick } = useWaterPointHistory();

  const takeScreenshotFlag = useReportsStore.use.takeScreenshotFlag();

  const filteredWaterQualityReamappedResults = waterbodyIdQuery
    ? waterQualityReamappedResults.filter(({ value }) => value === Number(waterbodyIdQuery))
    : waterQualityReamappedResults;

  const shareModalLinks = filteredWaterQualityReamappedResults.map(({ url }) => url);

  useEffect(() => {
    if (!isDataLayerVisible) {
      resetGraphStore();
    }
  }, [isDataLayerVisible]); // eslint-disable-line react-hooks/exhaustive-deps

  if (waterQualityRemappedResultsLoading) {
    return <Loading />;
  }

  return (
    <>
      <Layer visible={isDataLayerVisible} name={'dataLayer'} zIndex={490}>
        {filteredWaterQualityReamappedResults.map(({ id, url }) => {
          return url && selectedIndicator ? (
            <WaterQualityDataLayer
              key={id}
              url={url}
              colormap={selectedIndicator.colormap}
              range={[selectedIndicator.min_value, selectedIndicator.max_value]}
            />
          ) : null;
        })}
        {filteredWaterQualityReamappedResults && !takeScreenshotFlag && (
          <GenericMapTooltip
            results={filteredWaterQualityReamappedResults}
            defaultView={waterbodyDefaultView}
            onPointClick={handleWaterBodyPointClick}
            enabled={isDataLayerVisible}
            builder={buildWaterBodyTooltipStringHelper()}
          />
        )}
      </Layer>
      {selectedMapPoint && isDataLayerVisible && !takeScreenshotFlag && (
        <Marker center={selectedMapPoint.center} color={selectedMapPoint.color} />
      )}
      <ShareModal links={shareModalLinks} activeTab={WaterActiveTab.QUALITY} />
    </>
  );
};
