import chroma from 'chroma-js';

import { RESEARCH_ML_STORAGE_URL } from 'common/constants/services';
import { type LeafletBounds } from 'common/types/mapData';
import { Loading } from 'ui/molecules/loading';
import { DataLayer } from 'components/map';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { titilerInfoService } from 'services/titiler/titilerInfoService';
import { getBoundsFromCogInfo } from 'services/titiler/utils';
import { type MoistureIndicator, type MoistureResult } from 'domain/water/types';
import { useMoistureLayersVisibility } from './useMoistureLayersVisibility';

export const MoistureMapMultiDataLayer = ({
  result,
  indicator,
}: {
  result: MoistureResult;
  indicator: MoistureIndicator;
}) => {
  const { classesVisibility } = useMoistureLayersVisibility();

  const colormapArray = [
    [
      [indicator.min_value, indicator.max_value],
      [...chroma(result.color).rgb(), 255],
    ],
  ];

  const titilerUrl = titilerApiAdapter.buildMonocolorCogUrl(`${RESEARCH_ML_STORAGE_URL}${result.url}`, {
    expression: 'b1',
    colormap: JSON.stringify(colormapArray),
  });

  const { info, isInfoLoading } = titilerInfoService.useGetInfo(result.url);
  const bounds = getBoundsFromCogInfo(info) as LeafletBounds | undefined;

  if (isInfoLoading) {
    return <Loading />;
  }

  if (!titilerUrl || !bounds) {
    return null;
  }

  return (
    <DataLayer
      isVisible={!!classesVisibility[result.name]}
      layerName={'moistureMultiLayer'}
      zIndex={432}
      cogUrl={titilerUrl}
      bounds={bounds}
      opacity={indicator.opacity}
    />
  );
};
