import styled from 'styled-components';

import { SectionLayout } from './SectionLayout';

export const PixelStatisticsSection = () => {
  const samData = [
    { id: 1, name: 'SAM - Mineral X', value: 0.2345, color: '#5AE2A9', secondColor: null },
    { id: 2, name: 'SAM - Mineral X', value: 0.2345, color: '#06A1A1', secondColor: '#fff' },
    { id: 3, name: 'SAM - Mineral X', value: 0.2345, color: '#B770FF', secondColor: null },
    { id: 4, name: 'SAM - Mineral X', value: 0.2345, color: '#FF3939', secondColor: null },
  ];

  const mtmfData = [
    { id: 1, name: 'MTMF - Mineral X', value: 0.2345, color: '#5AE2A9', secondColor: null },
    { id: 2, name: 'MTMF - Mineral X', value: 0.2345, color: '#06A1A1', secondColor: null },
    { id: 3, name: 'MTMF - Mineral X', value: 0.2345, color: '#B770FF', secondColor: null },
    { id: 4, name: 'MTMF - Mineral X', value: 0.2345, color: '#FF3939', secondColor: '#fff' },
  ];

  const indicesData = [
    { id: 1, name: 'Index 1', value: 0.2345 },
    { id: 2, name: 'Index 2', value: 0.2345 },
    { id: 3, name: 'Index 3', value: 0.2345 },
  ];

  return (
    <section>
      <SectionLayout title="pixel statistics" description="lorem ipsum dolor sit">
        <ClassifierStatsList>
          {samData.map(({ id, name, value, color, secondColor }) => (
            <li key={id}>
              <StatisticDot color={color} secondColor={secondColor || undefined} />
              {name}: {value}
            </li>
          ))}
        </ClassifierStatsList>
        <ClassifierStatsList>
          {mtmfData.map(({ id, name, value, color, secondColor }) => (
            <li key={id}>
              <StatisticDot color={color} secondColor={secondColor || undefined} />
              {name}: {value}
            </li>
          ))}
        </ClassifierStatsList>
        <IndicesList>
          {indicesData.map(({ id, name, value }) => (
            <li key={id}>
              {name} : {value}
            </li>
          ))}
        </IndicesList>
      </SectionLayout>
    </section>
  );
};

const ClassifierStatsList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 15px 0 35px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  font-family: 'Barlow', sans-serif;
  font-size: 12px;
  font-weight: 400;

  li {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

const StatisticDot = styled.div<{ color: string; secondColor?: string }>`
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background: ${({ color }) => color};
  border: 2px solid ${({ secondColor }) => secondColor || 'transparent'};
`;

const IndicesList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 15px 0 35px;
  font-family: 'Barlow', sans-serif;
  font-size: 12px;
  line-height: 2;
  font-weight: 400;
`;
