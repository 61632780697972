/* eslint-disable @typescript-eslint/no-unused-vars */
import { type ReactNode } from 'react';

import { calculatePolygonCenter, getDisplayableMapPoint } from 'common/utils/map';
import { AreaInfoPanel } from 'ui/organisms/areaInfoPanel/AreaInfoPanel';
import { AreaInfoTitle, AreaInfoTitleWrapper } from 'ui/organisms/areaInfoPanel/components';
import { type AreaOfInterestDetails } from 'domain/areas/types';
import { EnvironmentsIconButtons } from './EnvironmentsIconButtons';

interface GeneralInfoPanelProps {
  areaDetails: AreaOfInterestDetails;
  extraContent: ReactNode;
  children: ReactNode;
}

export const GeneralInfoPanel = ({ areaDetails, extraContent, children }: GeneralInfoPanelProps) => {
  const lngLat = calculatePolygonCenter(areaDetails.geometry.coordinates[0]);
  const areaLocation = getDisplayableMapPoint(lngLat);

  const onDescriptionSubmitHandler = (value: string) => {
    console.log(value);
  };

  return (
    <AreaInfoPanel
      header={
        <AreaInfoTitleWrapper>
          <AreaInfoTitle>{areaDetails.properties.display_name}</AreaInfoTitle>
          <EnvironmentsIconButtons
            environments={areaDetails.properties.environments || []}
            deposit={areaDetails.properties.deposits[0]}
            areaType={areaDetails.properties.type}
          />
        </AreaInfoTitleWrapper>
      }
      location={areaLocation}
      description={areaDetails.properties.description || ''}
      middleColumn={
        <div>
          <div>Date Added: {areaDetails.properties.created_date.slice(0, 10)}</div>
          <br />
          {extraContent}
        </div>
      }
      onDescriptionSubmit={onDescriptionSubmitHandler}
    >
      {children}
    </AreaInfoPanel>
  );
};
