import styled, { css } from 'styled-components';

import { Slider, Container, LabeledCheckbox, InfoPoint } from 'ui';
import { LegendItemWraper, IndentedContentWraper } from 'components/layerManager';
import type { ProviderType, BandCombosData } from 'domain/composites/types';
import { ReactComponent as ColorsIcon } from './colors-icon.svg';
import { TooltipContent } from './TooltipContent';
import { BandCombosSelect } from './BandCombosSelect';

interface Props {
  name: ProviderType;
  title: string;
  onOptionSelect: (option: ProviderType) => void;
  isSelected: boolean;
  bandCombosData: BandCombosData[];
  opacity: number;
  onOpacityChange: (name: ProviderType, value: number | number[]) => void;
}

export const ProviderField = ({
  title,
  name,
  onOptionSelect,
  isSelected,
  bandCombosData,
  opacity,
  onOpacityChange,
}: Props) => {
  const handleCheckboxChange = () => {
    onOptionSelect(name);
  };

  const handleOpacityChange = (value: number | number[]) => {
    onOpacityChange(name, value);
  };

  return (
    <Wrapper>
      <LabeledCheckbox variant="WIDE" onChange={handleCheckboxChange} checked={isSelected} radio>
        <IndentedContentWraper>
          <LabelWrapper>
            <ColorsIcon />
            {title}
          </LabelWrapper>
        </IndentedContentWraper>
      </LabeledCheckbox>
      <IndentedContentWraper>
        <Container variant="VERTICAL_STRETCH" gap={4} mb={4}>
          <ControlsDisablingWrapper disabled={!isSelected}>
            <SelectWrapper isSelected={isSelected}>
              band<br></br>combo
              <BandCombosSelect {...{ isSelected, bandCombosData }} />
              <InfoPoint
                name="band combo"
                tooltipContent={
                  <TooltipContent
                    data={{ title: `Band Combinations available from ${title}`, options: bandCombosData }}
                  />
                }
                color="midGray"
              />
            </SelectWrapper>
          </ControlsDisablingWrapper>
          <ControlsDisablingWrapper disabled={!isSelected}>
            <LabeledSliderWrapper>
              opacity
              <Slider
                ariaLabel="Opacity slider"
                marks={{
                  '0': '',
                  '1': '',
                }}
                value={opacity}
                onChange={handleOpacityChange}
                defaultValue={1}
                min={0}
                max={1}
                step={0.01}
              />
            </LabeledSliderWrapper>
          </ControlsDisablingWrapper>
        </Container>
      </IndentedContentWraper>
    </Wrapper>
  );
};

const Wrapper = styled(LegendItemWraper)`
  padding: 10px 0 4px;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const LabelWrapper = styled.span`
  font-size: 12px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
`;

const SelectWrapper = styled.div<{ isSelected?: boolean }>`
  width: 100%;
  font-weight: 400;
  line-height: 1.1;
  display: flex;
  align-items: center;
  justify-content: stretch;
  gap: 10px;
  z-index: ${({ isSelected }) => (isSelected ? 1001 : 1000)};
  position: relative;
`;

const LabeledSliderWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
`;

const ControlsDisablingWrapper = styled.div<{ disabled: boolean }>`
  position: relative;
  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.6;
      pointer-events: none;
    `};
`;
