import { useFeatureFlag } from 'configcat-react';

export const useOverridableFeatureFlag = (key: string, defaultValue: boolean, override: string) => {
  const overrideValue = process.env[override];

  if (!(override in process.env)) {
    console.error(`Missing ${override} in .env file`);
  }

  const { value } = useFeatureFlag(key, defaultValue);
  return value || overrideValue === 'true';
};
