import { useRef, type ReactNode, useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import styled from 'styled-components';

import { IconButton, Chevron } from 'ui/atoms';

interface HorizontalSwiperProps {
  children: ReactNode;
  width: number;
  onScrollChangeVisibility?: (isVisible: boolean) => void;
}

export interface HorizontalSwiperRef {
  scrollExist: boolean;
}

const scrollState = { top: 0, left: 0, x: 0, y: 0 };

export const HorizontalSwiper = forwardRef<HorizontalSwiperRef, HorizontalSwiperProps>(
  ({ children, width, onScrollChangeVisibility }, ref) => {
    const scrolledRef = useRef<HTMLDivElement | null>(null);
    const [scrollPosition, setScrollPosition] = useState(0);
    const [scrollExist, setScrollExist] = useState(false);
    const [isGrabbing, setIsGrabbing] = useState(false);

    useImperativeHandle(
      ref,
      () => {
        return {
          scrollExist: scrollExist,
        } satisfies HorizontalSwiperRef;
      },
      [scrollExist],
    );

    const scrollOn = (scroll: number) => scrolledRef.current && (scrolledRef.current.scrollLeft += scroll);
    const scrollHandler = () => scrolledRef.current && setScrollPosition(scrolledRef.current.scrollLeft);
    const checkScrollable = () =>
      scrolledRef.current && setScrollExist(scrolledRef.current.offsetWidth < scrolledRef.current.scrollWidth);

    const scrollHorizontally = (event: Event) => {
      const { deltaY } = event as WheelEvent;
      if (!scrolledRef.current || !deltaY) return;
      scrolledRef.current.scrollLeft += deltaY;
    };

    const mouseMoveHandler = (event: Event) => {
      const { clientX, clientY } = event as WheelEvent;
      if (!scrolledRef.current) return;
      scrolledRef.current.scrollTop = scrollState.top - (clientY - scrollState.y);
      scrolledRef.current.scrollLeft = scrollState.left - (clientX - scrollState.x);
    };

    const endScrollHandler = () => {
      if (!scrolledRef.current) return;

      const wasXscroll = scrolledRef.current.scrollLeft - scrollState.left !== 0;
      const wasYscroll = scrolledRef.current.scrollTop - scrollState.top !== 0;
      if (wasYscroll || wasXscroll) {
        setTimeout(() => scrolledRef.current?.removeEventListener('click', clickPreventer), 200);
      } else {
        scrolledRef.current.removeEventListener('click', clickPreventer);
      }

      scrolledRef.current.removeEventListener('mousemove', mouseMoveHandler);
      scrolledRef.current.removeEventListener('mouseup', endScrollHandler);
      scrolledRef.current.removeEventListener('mouseleave', endScrollHandler);
      setIsGrabbing(false);
    };

    const mouseDownHandler = (event: Event) => {
      const { clientX, clientY } = event as WheelEvent;
      if (!scrolledRef.current) return;

      Object.assign(scrollState, {
        top: scrolledRef.current.scrollTop,
        left: scrolledRef.current.scrollLeft,
        x: clientX,
        y: clientY,
      });

      setIsGrabbing(true);
      scrolledRef.current.addEventListener('mousemove', mouseMoveHandler);
      scrolledRef.current.addEventListener('mouseup', endScrollHandler);
      scrolledRef.current.addEventListener('click', clickPreventer);
      scrolledRef.current.addEventListener('mouseleave', endScrollHandler);
    };

    const clickPreventer = (event: Event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    useEffect(() => {
      const scrolledRefElement = scrolledRef.current;
      const mutationObserver = new MutationObserver(checkScrollable);

      if (scrolledRefElement) {
        checkScrollable();
        scrolledRefElement.addEventListener('scroll', scrollHandler);
        scrolledRefElement.addEventListener('mousewheel', scrollHorizontally, false);
        scrolledRefElement.addEventListener('mousedown', mouseDownHandler);

        mutationObserver.observe(scrolledRefElement, { childList: true, subtree: true });
      }

      return () => {
        scrolledRefElement?.removeEventListener('scroll', scrollHandler);
        scrolledRefElement?.removeEventListener('mousewheel', scrollHorizontally, false);
        scrolledRefElement?.removeEventListener('mousedown', mouseDownHandler);

        mutationObserver.disconnect();
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
      onScrollChangeVisibility && onScrollChangeVisibility(scrollExist);
    }, [scrollExist, onScrollChangeVisibility]);

    return (
      <Wrapper>
        {scrollExist && (
          <>
            <ButtonWrapper>
              <IconButton
                onClick={() => scrollOn(-150)}
                icon={<Chevron />}
                disabled={scrollPosition === 0}
                variant="NO_BORDER"
              />
            </ButtonWrapper>
            <ButtonWrapper $isRight>
              <IconButton
                onClick={() => scrollOn(150)}
                icon={<Chevron />}
                disabled={
                  scrollPosition + (scrolledRef.current?.clientWidth || 0) >= (scrolledRef.current?.scrollWidth || 0)
                }
                variant="NO_BORDER"
              />
            </ButtonWrapper>
          </>
        )}
        <StyledScrolledWrapper $width={width} ref={scrolledRef} $isGrabbing={isGrabbing}>
          {children}
        </StyledScrolledWrapper>
      </Wrapper>
    );
  },
);

const Wrapper = styled.div`
  position: relative;
`;

const StyledScrolledWrapper = styled.div<{ $width: number; $isGrabbing: boolean }>`
  overflow-x: auto;
  max-width: ${({ $width }) => $width}px;
  width: 100%;
  padding: 0 30px 10px;
  position: relative;
  cursor: ${({ $isGrabbing }) => ($isGrabbing ? 'grabbing' : 'grab')};

  &::-webkit-scrollbar {
    height: 4px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: ${({ theme }) => theme.color.chicago};
    border-radius: 3px;
  }
  &::-webkit-scrollbar-button {
    width: 30px;
  }
`;

const ButtonWrapper = styled.div<{ $isRight?: boolean }>`
  position: absolute;

  left: ${({ $isRight }) => ($isRight ? 'auto' : 0)};
  right: ${({ $isRight }) => (!$isRight ? 'auto' : 0)};

  z-index: 2;
  top: 50%;
  transform: translate(0, -50%);

  & svg {
    transform: rotate(${({ $isRight }) => ($isRight ? '-' : '')}90deg);
  }
  & svg path {
    stroke-width: 4px;
  }
`;
