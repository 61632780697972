import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { titilerInfoService } from 'services/titiler/titilerInfoService';
import { getAzureBlobStorageUrl, getBoundsFromCogInfo } from 'services/titiler/utils';
import { Loading } from 'ui/molecules/loading';
import { DataLayer } from 'components/map';
import { VEGETATION_COMPARISON_COLORMAP } from 'domain/greenery/colors';
import { useGetVegetationComparatorCogUseCase } from './getVegetationComparatorCogUseCase';

export const VegetationMapContentComparator = ({ range }: { range: number[] }) => {
  const { comparatorCogUrl, isComparatorCogLoading } = useGetVegetationComparatorCogUseCase();

  if (isComparatorCogLoading) {
    return <Loading />;
  }

  return comparatorCogUrl ? <VegetationComparatorLayer comparatorUrl={comparatorCogUrl} range={range} /> : null;
};

interface VegetationComparatorLayerProps {
  comparatorUrl: string;
  range: number[];
}

const VegetationComparatorLayer = ({ comparatorUrl, range }: VegetationComparatorLayerProps) => {
  const fileUrl = getAzureBlobStorageUrl(comparatorUrl);

  const cogUrl = titilerApiAdapter.buildDiscreteColorScaleFloatCogUrl(fileUrl, {
    range,
    colormapObject: VEGETATION_COMPARISON_COLORMAP,
    noDataValue: 0,
  });

  const { info } = titilerInfoService.useGetInfo(comparatorUrl);

  const bounds = getBoundsFromCogInfo(info);

  if (cogUrl && bounds) {
    return (
      <DataLayer isVisible={true} layerName={'comparisonLayer'} zIndex={490} cogUrl={cogUrl} bounds={bounds} fixed />
    );
  }

  return null;
};
