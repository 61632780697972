import { mapIndicatorsToOptions } from 'common/utils';
import { areExtendedIndicatorsValid } from 'common/typeguards';
import { withIndicatorConfig, withIndicatorLabels } from 'common/decorators';
import { useQueryParameter } from 'common/navigation/hooks';
import { GREENERY_INDICATOR_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { VEGETATION_INDICATORS_CONFIGURATION } from 'domain/greenery/configs';

export const useVegetationIndicators = () => {
  const { greeneryIndicators, greeneryIndicatorsLoading } = greeneryRepository.useFetchGreeneryIndicators();

  const indicatorsWithLabels = withIndicatorLabels(greeneryIndicators, false);
  const indicatorsWithConfig: unknown = withIndicatorConfig(indicatorsWithLabels, VEGETATION_INDICATORS_CONFIGURATION);
  const extendedIndicators = areExtendedIndicatorsValid(indicatorsWithConfig) ? indicatorsWithConfig : [];

  const selectedIndicatorId = useQueryParameter(GREENERY_INDICATOR_QUERY_PARAMETER);
  const selectedIndicator = extendedIndicators.find(({ id }) => selectedIndicatorId === String(id));

  const indicatorOptions = mapIndicatorsToOptions(extendedIndicators);

  return {
    indicators: extendedIndicators,
    selectedIndicator,
    indicatorOptions,
    areIndicatorsLoading: greeneryIndicatorsLoading,
  };
};
