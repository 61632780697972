export enum WarningErrorType {
  //aoi
  AOI_LIST = 'aoiList',
  SATELLITE = 'satellite',

  //mining area
  EXTRACTION_RESULTS = 'extractionResults',
  EXTRACTION_POLYGONS = 'extractionPolygons',

  //water
  WATERBODIES = 'waterBodies',
  WATER_DATES = 'waterDates',

  WATER_QUALITY_INDICATORS = 'waterQualityIndicators',
  WATER_QUALITY_RESULTS = 'waterQualityResults',
  WATER_QUALITY_COGS = 'waterQualityCogs',

  WATER_AREA_RESULTS = 'waterAreaResults',
  WATER_AREA_COGS = 'waterAreaCogs',
  WATER_AREA_COMPARATOR_COGS = 'waterAreaComparatorCogs',

  WATER_POINT_HISTORY = 'waterPointHistory',
  WEATHER_DATA = 'weatherData',

  //landcover
  LAND_COVER_CLASSES = 'landCoverClasses',
  LAND_COVER_DATES = 'landCoverDates',

  DAILY_SEGMENTATION_COG = 'dailySegmentationCog',
  DAILY_SEGMENTATION_COMPARATOR_COG = 'dailySegmentationComparatorCog',

  QUARTERLY_SEGMENTATION_RESULTS = 'quarterlySegmentationResults',
  QUARTERLY_SEGMENTATION_COG = 'quarterlySegmentationCog',
  QUARTERLY_SEGMENTATION_COMPARATOR_COG = 'quarterlySegmentationComparatorCog',

  VEGETATION_INDICATORS = 'vegetationIndicators',
  VEGETATION_RESULTS = 'vegetationResults',
  VEGETATION_COMPARATOR_COG = 'vegetationComparatorCog',
  HOTSPOT_TYPES = 'hotspotTypes',

  VCI_DATA = `vegetationData`,

  //displacements
  DISPLACEMENTS_RESULTS = 'displacementResults',
  DISPLACEMENTS_KMZ_RESULTS = 'displacementKmzResults',
  DISPLACEMENTS_COG = 'displacementCog',
}
