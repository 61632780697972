import { MapValueClick } from 'components/map/MapValueClick';
import { ClustersMapLayer } from './ClustersMapLayer';
import { SelectedClustersMapLayer } from './SelectedClustersMapLayer';
import { useClusters } from './useClusters';
import { useExplorationLayerManagerStore } from '../explorationLayerManagerStore';

export const ClustersMapContent = () => {
  const { selectedClustersSupplier, currentClustersResult } = useClusters();

  const isClustersVisible = useExplorationLayerManagerStore.use.isClustersVisible();
  const isClustersSelectionMode = useExplorationLayerManagerStore.use.isClustersSelectionMode();
  const selectedClustersSaved = useExplorationLayerManagerStore.use.selectedClustersSaved();
  const selectedClustersInProgress = useExplorationLayerManagerStore.use.selectedClustersInProgress();
  const toggleSelectedCluster = useExplorationLayerManagerStore.use.toggleSelectedCluster();

  if (!selectedClustersSupplier || !currentClustersResult) {
    return null;
  }

  const selectedClusters = isClustersSelectionMode ? selectedClustersInProgress : selectedClustersSaved;

  return (
    <>
      {(!selectedClustersSaved.length || isClustersSelectionMode) && (
        <ClustersMapLayer
          url={currentClustersResult.url}
          amount={currentClustersResult.amount}
          opacity={isClustersSelectionMode ? selectedClustersSupplier.opacity / 4 : selectedClustersSupplier.opacity}
          isVisible={isClustersVisible}
        />
      )}
      {!!selectedClusters.length && (
        <SelectedClustersMapLayer
          clusters={selectedClusters}
          url={currentClustersResult.url}
          amount={currentClustersResult.amount}
          opacity={selectedClustersSupplier.opacity}
          isVisible={isClustersVisible}
        />
      )}
      {isClustersSelectionMode && <MapValueClick url={currentClustersResult.url} handleClick={toggleSelectedCluster} />}
    </>
  );
};
