import { useSortedLayersData } from 'components/layerManager/useSortedLayersData';
import { type SurfaceMineralsDisplayed } from 'domain/exploration/types';
import { MINERALIZATION_SLIDER_CONFIG } from 'domain/exploration/configs';
import { useMtmfLayersVisibility } from './useSurfaceLayersVisibility';
import { MineralsPlainMapLayer, MtmfGradientMapLayer } from './MineralsMapLayer';
import { useExplorationLayerManagerStore } from '../explorationLayerManagerStore';

interface MtmfMineralsMapLayersProps {
  areaId: number;
  minerals: SurfaceMineralsDisplayed[];
  indexOffset?: number;
}

export const MtmfMineralsMapContent = ({ areaId, minerals, indexOffset = 0 }: MtmfMineralsMapLayersProps) => {
  const mtmfLayersOrderRecord = useExplorationLayerManagerStore.use.mtmfLayersOrderRecord();
  const sortedMinerals = useSortedLayersData(mtmfLayersOrderRecord, minerals, 'mineral_id');

  const isGradientModeOn = useExplorationLayerManagerStore.use.gradientModeState()[2];
  const { classesVisibility: layersVisibility } = useMtmfLayersVisibility();

  const gradientLayer = sortedMinerals.find(({ name }) => !!layersVisibility[name]);

  return (
    <>
      {isGradientModeOn && gradientLayer ? (
        <MtmfGradientMapLayer
          areaId={areaId}
          index={indexOffset}
          name={gradientLayer.name}
          url={gradientLayer.url}
          isVisible={layersVisibility[gradientLayer.name]}
          layerRange={MINERALIZATION_SLIDER_CONFIG.mtmf.staticRange}
        />
      ) : (
        <>
          {sortedMinerals.map(({ id, name, url, color }, index) => (
            <MineralsPlainMapLayer
              key={id}
              index={index + indexOffset}
              {...{ areaId, url, color }}
              name={name}
              classifierId={2}
              isVisible={layersVisibility[name] || false}
            />
          ))}
        </>
      )}
    </>
  );
};
