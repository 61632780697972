import styled from 'styled-components';

export const ScrollbarWrapper = styled.div<{ offsetY?: number }>`
  height: ${({ offsetY }) => (offsetY ? `calc(100% - ${offsetY}px)` : '100%')};
  overflow-y: auto;
  overflow-x: hidden;

  scrollbar-width: thin;
  scrollbar-color: ${({ theme }) => theme.color.midGray} ${({ theme }) => theme.color.selectGray};

  ::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-track {
    background-color: ${({ theme }) => theme.color.selectGray};
  }

  ::-webkit-scrollbar-thumb {
    background-color: ${({ theme }) => theme.color.midGray};
    border-radius: 8px;
    width: 4px;
    height: 4px;
  }

  ::-webkit-scrollbar-corner {
    background: rgba(0, 0, 0, 0);
  }
`;
