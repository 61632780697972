import { DataLayer, Layer, Marker } from 'components/map';
import { useLayerManagerStore } from 'components/layerManager/layerManagerStore';
import { GenericMapTooltip } from 'components/map/tooltip/GenericMapTooltip';
import { useSelectedMapPoint } from 'components/map/useSelectedMapPoint';
import { useGetCogWithBoundsGeneric } from 'services/titiler/hooks';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { getBareCogUrl } from 'services/titiler/utils';
import { type TooltipData } from 'common/types/mapData';
import { buildSingleViewTooltipStringHelper } from 'common/utils/map';
import { getCelciusFromKelvin } from 'common/utils';
import { useCurrentCog } from 'views/temperature/useCurrentCog';
import { TEMPERATURE_COLORMAP } from 'domain/temperature/colors';
import { useTemperaturePointHistory } from './useTemperaturePointHistory';

export const TemperatureMapContent = () => {
  const isDataLayerVisible = useLayerManagerStore.use.isDataLayerVisible();
  const dataLayerOpacity = useLayerManagerStore.use.dataLayerOpacity();

  const { fileUrl, range } = useCurrentCog();

  const { handleTemperaturePointClick } = useTemperaturePointHistory();

  const selectedMapPoint = useSelectedMapPoint(TEMPERATURE_COLORMAP, {
    min: -1,
    max: 1,
  });

  const buildTemperatureTooltipStringHelper =
    (stats: boolean = false) =>
    (tooltip: TooltipData) => {
      return `
    <h4>Coordinates:</h4>
    <p>${tooltip.lat.toFixed(6)}</p>
    <p>${tooltip.lng.toFixed(6)}</p>
    <h4>Temperature:</h4>
    <p>${typeof tooltip.value === 'number' ? `${getCelciusFromKelvin(tooltip.value).toFixed(3)}°C` : '-'}</p>
    ${stats ? buildSingleViewTooltipStringHelper(tooltip) : ''}
    `;
    };

  const { cogUrl, cogBounds } = useGetCogWithBoundsGeneric(
    titilerApiAdapter.buildDiscreteColorScaleFloatCogUrl,
    fileUrl,
    {
      range,
      colormapObject: TEMPERATURE_COLORMAP,
      noDataValue: 'nan',
    },
  );

  return (
    <Layer visible={isDataLayerVisible} name={'dataLayer'} zIndex={490}>
      <DataLayer
        isVisible={true}
        layerName={'temperatureLayer'}
        opacity={dataLayerOpacity}
        zIndex={490}
        cogUrl={cogUrl}
        bounds={cogBounds}
        fixed
      />
      {fileUrl && (
        <GenericMapTooltip
          results={[{ id: 1, url: `${getBareCogUrl(fileUrl)}`, label: 'Temperature', value: 100 }]}
          defaultView={undefined}
          onPointClick={handleTemperaturePointClick}
          enabled={isDataLayerVisible}
          builder={buildTemperatureTooltipStringHelper()}
        />
      )}
      {selectedMapPoint && isDataLayerVisible && (
        <Marker center={selectedMapPoint.center} color={selectedMapPoint.color} />
      )}
    </Layer>
  );
};
