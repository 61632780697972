import { useEffect } from 'react';
import { AxiosError } from 'axios';

import { subscriptionRepository } from 'infrastructure/subscription/subscriptionRepository';

export const useGetActiveSubscription = (setErrorMessage?: (message: string | null) => void) => {
  const { activeSubscriptionData, isActiveSubscriptionLoading, activeSubscriptionError } =
    subscriptionRepository.useGetStatus();

  const subscriptionStatusGetErrorCode =
    activeSubscriptionError instanceof AxiosError &&
    activeSubscriptionError.response?.status &&
    activeSubscriptionError.response.status !== 404
      ? activeSubscriptionError.response.status
      : null;

  useEffect(() => {
    if (activeSubscriptionError instanceof AxiosError && activeSubscriptionError.response?.status === 404) {
      setErrorMessage && setErrorMessage("you don't have a subscription");
    }
  }, [activeSubscriptionError]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (activeSubscriptionData && setErrorMessage) {
      if (
        activeSubscriptionData.status !== 'active' &&
        activeSubscriptionData.status !== 'trialing' &&
        activeSubscriptionData.status !== 'canceled'
      ) {
        const status = activeSubscriptionData.status === 'past_due' ? 'payment failed' : activeSubscriptionData.status;
        setErrorMessage(`Subscription status: ${status}`);
        return;
      }

      setErrorMessage(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeSubscriptionData]);

  return { activeSubscriptionData, isActiveSubscriptionLoading, subscriptionStatusGetErrorCode };
};
