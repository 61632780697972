export const AREAS_LIST_QUERY_KEY = 'areas_list';
export const AREA_DETAILS_QUERY_KEY = 'area_details';

export const AREA_INSERT_API_PREFIX = process.env.REACT_APP_AOI_UPLOADER_URL || '/aoi_uploader';
export const AREA_INSERT_NEW_MUTATION_KEY = 'create_area_of_interest';

export const SATELLITE_IMAGE_QUERY_KEY = 'satellite_image';
export const INFRASTRUCTURE_SHAPES_QUERY_KEY = 'infrastructure_shapes';
export const INFRASTRUCTURE_SHAPE_QUERY_KEY = 'infrastructure_shape';
export const TOPOGRAPHY_LAYERS_QUERY_KEY = 'topography_layers';

export const AREA_REQUEST_PAYLOAD_LOCAL_STORAGE_KEY = 'RSOM_areaRequestPayload';
