export const GREENERY_INDICATORS_QUERY_KEY = 'greenery_indicators';
export const GREENERY_OBJECTS_QUERY_KEY = 'greenery_objects';
export const GREENERY_VHR_OBJECTS_QUERY_KEY = 'greenery_vhr_objects';
export const GREENERY_DATES_QUERY_KEY = 'greenery_dates';
export const GREENERY_VEGETATION_COG_QUERY_KEY = 'greenery_vegetation_cog';
export const GREENERY_VEGETATION_COMPARATOR_COG_QUERY_KEY = 'greenery_vegetation_comparator_cog';
export const GREENERY_VEGETATION_HOTSPOTS_QUERY_KEY = 'greenery_hotspots';
export const GREENERY_VEGETATION_HOTSPOTS_COG_QUERY_KEY = 'greenery_hotspots_cog';
export const GREENERY_SEGMENTATION_RESULTS_QUERY_KEY = 'daily_segmentation_results';
export const GREENERY_SEGMENTATION_RESULTS_COG_QUERY_KEY = 'daily_segmentation_results_cog';
export const GREENERY_SEGMENTATION_COMPARATOR_COG_QUERY_KEY = 'daily_segmentation_comparator_cog';
export const GREENERY_QUARTERLY_RESULTS_QUERY_KEY = 'greenery_quarterly_results';
export const GREENERY_QUARTERLY_RESULTS_COG_QUERY_KEY = 'greenery_quarterly_result_cog';
export const GREENERY_QUARTERLY_COMPARATOR_COG_QUERY_KEY = 'greenery_quartertly_comparator_cog';
export const GREENERY_VEGETATION_RESULTS_QUERY_KEY = 'greenery_vegetation_results';
export const GREENERY_VHR_SEGMENTATION_COG_URL_QUERY_KEY = 'greenery_vhr_cog';
export const GREENERY_VCI_RESULTS_QUERY_KEY = 'greenery_vci_results';
export const GREENERY_INDICATOR_STATS_QUERY_KEY = 'greenery_indicator_stats';
export const GREENERY_AREAS_QUERY_KEY = 'greenery_areas';

export const GREENERY_INITIAL_DATA_STALE_TIME = 1000 * 60 * 60;
export const GREENERY_INITIAL_DATA_CACHE_TIME = 1000 * 60 * 75;
