import {
  COMMON_QUERY_PARAMETERS,
  GREENERY_CLASSES_QUERY_PARAMETER,
  GREENERY_INDICATOR_QUERY_PARAMETER,
  GREENERY_OBJECT_QUERY_PARAMETER,
  SECOND_DATE_QUERY_PARAMETER,
  VIEW_MODE_QUERY_PARAMETER,
} from 'common/navigation/queryParams';
import { ROUTES } from 'common/navigation/routes';
import { GREENERY_PATH_NAME } from 'domain/greenery/constants';
import { GreeneryActiveTab } from 'domain/greenery/enums';

export const VEGETATION_COMPARISON_COLORSCALE_RANGE_NAMES = ['decrease', 'no change', 'increase'];

export const SEGMENTATION_COMPARISON_COLORSCALE_RANGE_NAMES = ['loss of surface', 'no change', 'gain of surface'];

export const GREENERY_VIEW_CONFIG = {
  name: GREENERY_PATH_NAME,
  path: ROUTES.greenery,
  params: [...COMMON_QUERY_PARAMETERS],
  tabs: {
    [GreeneryActiveTab.SEGMENTATION]: {
      path: ROUTES.landCover,
      params: [
        VIEW_MODE_QUERY_PARAMETER,
        SECOND_DATE_QUERY_PARAMETER,
        GREENERY_OBJECT_QUERY_PARAMETER,
        GREENERY_CLASSES_QUERY_PARAMETER,
      ],
    },
    [GreeneryActiveTab.QUARTERLY]: {
      path: ROUTES.quarterlyAnalysis,
      params: [VIEW_MODE_QUERY_PARAMETER, SECOND_DATE_QUERY_PARAMETER, GREENERY_OBJECT_QUERY_PARAMETER],
    },
    [GreeneryActiveTab.VEGETATION]: {
      path: ROUTES.vegetation,
      params: [
        GREENERY_INDICATOR_QUERY_PARAMETER,
        VIEW_MODE_QUERY_PARAMETER,
        SECOND_DATE_QUERY_PARAMETER,
        GREENERY_OBJECT_QUERY_PARAMETER,
        GREENERY_CLASSES_QUERY_PARAMETER,
      ],
    },
    [GreeneryActiveTab.VHR]: {
      path: ROUTES.vhrSegmentation,
      params: [VIEW_MODE_QUERY_PARAMETER],
    },
    [GreeneryActiveTab.VCI]: {
      path: ROUTES.vci,
      params: [],
    },
  },
};

export const GREENERY_TAB_SWITCHER_BUTTONS = [
  {
    id: 1,
    label: 'segmentation',
    value: GreeneryActiveTab.SEGMENTATION,
  },
  {
    id: 2,
    label: 'quarterly segmentation',
    value: GreeneryActiveTab.QUARTERLY,
  },
  {
    id: 3,
    label: 'vegetation',
    value: GreeneryActiveTab.VEGETATION,
  },
  {
    id: 4,
    label: 'VHR Segmentation',
    value: GreeneryActiveTab.VHR,
  },
  {
    id: 5,
    label: 'Vegetation Condition Index',
    value: GreeneryActiveTab.VCI,
  },
];

export const FOLIAGE_CONDITION_DESCRIPTION =
  'This view enables to visualize vegetation indices for land cover classes associated with the area of interest. Use the first dropdown list to select the specific index. Use the layers manager to select specific classes. Use the timeline below to browse available dates. You can choose a comparison option from the second dropdown list to see the comparison view. Missing areas in visualization might be caused by clouds present during data collection.';
export const LAND_COVER_DESCRIPTION =
  'This view represents land cover classes associated with the area of interest. Use the layers manager to select specific classes. Missing areas in visualization might be caused by clouds present during data collection.';
export const VCI_DESCRIPTION =
  'The Vegetation Condition Index (VCI) compares the current NDVI to range of values observed in the same period in previous years. The VCI is expressed in % and gives an idea where the observed value is situated between the extreme values (minimum and maximum) in previous years. Values closer to 0 indicate unfavorable conditions, while 100 indicate optimal.';

const NDVI_DESCRIPTION =
  'The NDVI allows to differentiate between land cover classes, when all land cover classes selected. It allows to determine overall condition or density of vegetation when used for one selected vegetation class. The NDVI values range from -1 to +1. Within one class, healthier and/or denser vegetation will have a higher NDVI values. Low or negative values indicate low vegetation density and/or stressed vegetation.';
const NDII_DESCRIPTION =
  'The NDII allows to differentiate water content in plant canopies or soil, when used for one selected vegetation class. The NDII values range from -1 to +1, where higher positive values indicate higher water content in vegetation and/or soil. ';
const NDRE_DESCRIPTION =
  'The NDRE allows to determine overall condition or density of vegetation when used for one selected vegetation class. It is particularly sensitive to chlorophyll absorption in plants. The NDRE values range from -1 to +1. High positive values indicate healthier and/or denser vegetation. Low or negative values indicate low vegetation density and/or stressed vegetation.';

export const VEGETATION_VIEW_SPECIFIC_DISABLED_LAYERS = [
  'background',
  'buildup',
  'snow_and_ice',
  'water_bodies',
  'permanent_water_bodies',
];

export const GREENERY_INITIALLY_AVAILABLE_LAYERS: string[] = ['tree_cover', 'grass', 'cropland'];

export const VHR_INITIALY_AVAILABLE_LAYERS: string[] = ['trees'];

export const VCI_LIMITS = {
  min: 0,
  max: 100,
};

export const VEGETATION_INDICATORS_CONFIGURATION: {
  [name: string]: { unit: string; precision: number; description: string };
} = {
  NDVI: { unit: '', precision: 2, description: NDVI_DESCRIPTION },
  NDII: { unit: '', precision: 2, description: NDII_DESCRIPTION },
  NDRE: { unit: '', precision: 2, description: NDRE_DESCRIPTION },
};

export const BIOMASS_COLORSCALE_LABELS = ['0', '250'];
export const BIOMASS_OPACITY = 0.8;
export const BIOMASS_COLORSCALE_UNIT = 'Mg/ha';
