import { useHandleAreaViewOptionChangeUseCase } from './handleAreaViewOptionChangeUseCase';
import { useHandleAreaViewOptionResetUseCase } from './handleAreaViewOptionResetUseCase';
import { useHandleWaterbodyChangeUseCase } from './handleWaterbodyChangeUseCase';
import { useHandleWaterbodyResetUseCase } from './handleWaterbodyResetUseCase';

export const useWaterUseCases = () => {
  const { changeViewOption } = useHandleAreaViewOptionChangeUseCase();
  const { resetViewOption } = useHandleAreaViewOptionResetUseCase();
  const { changeWaterbody } = useHandleWaterbodyChangeUseCase();
  const { resetWaterbody } = useHandleWaterbodyResetUseCase();

  return {
    changeViewOption,
    resetViewOption,
    changeWaterbody,
    resetWaterbody,
  };
};
