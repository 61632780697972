import { type LayerRange } from 'common/types';
import { buildGradientColormapArray } from 'common/utils/color';
import { useLayerManagerStore } from 'components';
import { ExplorationMapLayer } from 'views/exploration/ExplorationMapLayer';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { WithCogBandRange } from 'services/titiler/WithCogBandRange';
import { SPECTRAL_COLORMAP } from 'domain/exploration/colors';
import { LINEAMENT_DENSITY_LAYER_NAME, LINEAMENT_DENSITY_SLIDER_CONFIG } from 'domain/exploration/configs';
import { useLineamentDensity } from './useLineamentDensity';
import { useExplorationLayerManagerStore } from '../explorationLayerManagerStore';

interface LineamentDensityMapContentProps {
  areaName: string;
}

export const LineamentDensityMapContent = ({ areaName }: LineamentDensityMapContentProps) => {
  const { densityResult } = useLineamentDensity(areaName);
  const layersTresholdsValues = useLayerManagerStore.use.layersTresholdsValues();
  const areLineamentsVisible = useExplorationLayerManagerStore.use.areLineamentsVisible();
  const isLineamentDensityVisible = useExplorationLayerManagerStore.use.isLineamentDensityVisible();
  const threshold = layersTresholdsValues[LINEAMENT_DENSITY_LAYER_NAME];

  if (!densityResult || typeof threshold !== 'number') {
    return null;
  }

  const { url } = densityResult;

  return (
    <WithCogBandRange
      Component={LineamentDensityMapLayer}
      props={{
        index: -10,
        name: LINEAMENT_DENSITY_LAYER_NAME,
        url,
        isVisible: isLineamentDensityVisible && areLineamentsVisible,
        threshold,
      }}
      url={url}
      isFetchingEnabled={isLineamentDensityVisible && areLineamentsVisible}
      precision={LINEAMENT_DENSITY_SLIDER_CONFIG.precision}
    />
  );
};

interface LineamentDensityMapLayerProps {
  layerRange: LayerRange;
  name: string;
  index: number;
  url: string;
  isVisible: boolean;
  threshold: number;
}

const LineamentDensityMapLayer = ({
  layerRange,
  name,
  index,
  url,
  isVisible,
  threshold,
}: LineamentDensityMapLayerProps) => {
  const opacity = useExplorationLayerManagerStore.use.lineamentDensityOpacity();

  const expression = `((b1>=${threshold})&(b1<=${layerRange.max}))*b1`;
  const colormapArray = buildGradientColormapArray(SPECTRAL_COLORMAP, layerRange.min, layerRange.max);

  return (
    <ExplorationMapLayer
      {...{ index, name, url, isVisible, opacity }}
      builder={titilerApiAdapter.buildMonocolorCogUrl}
      params={{ expression, colormap: JSON.stringify(colormapArray) }}
    />
  );
};
