import { DataLayer } from 'components/map';
import { useGetCogWithBoundsGeneric } from 'services/titiler/hooks';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { type ColormapDictionary } from 'common/types';

export const WaterQualityDataLayer = ({
  url,
  colormap,
  range,
}: {
  url: string;
  colormap: ColormapDictionary;
  range: number[];
}) => {
  const { cogUrl, cogBounds } = useGetCogWithBoundsGeneric(titilerApiAdapter.buildDiscreteColorScaleFloatCogUrl, url, {
    range,
    colormapObject: colormap,
    noDataValue: 'nan',
  });

  return (
    <DataLayer isVisible={true} layerName={'vhrSegmentationLayer'} zIndex={490} cogUrl={cogUrl} bounds={cogBounds} />
  );
};
