import { useEffect } from 'react';

import { useAoiNumber } from 'common/navigation/hooks';
import { useExplorationLayerManagerStore } from '../explorationLayerManagerStore';

export const useClearClustersData = () => {
  const areaId = useAoiNumber();

  const clearClustersData = useExplorationLayerManagerStore.use.clearClustersData();

  useEffect(() => clearClustersData(), [areaId, clearClustersData]);
};
