import { Loading } from 'ui/molecules/loading';
import { ShareModal } from 'components';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { SegmentationComparatorDataLayer } from 'views/greenery/segmentation/SegmentationComparatorDataLayer';
import { COMPARISON_OPTION_NAME } from 'common/navigation/constants';
import { GreeneryActiveTab } from 'domain/greenery/enums';

interface SegmentationComparatorDataLayerPreparationProps {
  firstResultId: number;
  secondResultId: number;
  currentObjectId: number;
}

export const SegmentationComparatorDataLayerWrapper = ({
  firstResultId,
  secondResultId,
  currentObjectId,
}: SegmentationComparatorDataLayerPreparationProps) => {
  const { segmentationComparatorCogUrl, isSegmentationComparatorCogLoading } =
    greeneryRepository.useFetchSegmentationComparatorCog(firstResultId, secondResultId, currentObjectId);

  if (isSegmentationComparatorCogLoading) {
    return <Loading />;
  }

  if (!segmentationComparatorCogUrl) {
    return null;
  }

  return (
    <>
      <SegmentationComparatorDataLayer url={segmentationComparatorCogUrl} currentObjectId={currentObjectId} />
      <ShareModal
        links={[segmentationComparatorCogUrl]}
        activeTab={`${GreeneryActiveTab.SEGMENTATION}-${COMPARISON_OPTION_NAME}`}
      />
    </>
  );
};
