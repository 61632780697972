import { useParams } from 'react-router-dom';

import { AppLayerManager } from 'components/layerManager/legacy';
import { LayerManagerControlButton } from 'components/layerManager/LayerManagerControlButton';
import { ShareModalControlButton } from 'ui/organisms/shareModal/ShareModalControlButton';
import { useGetAreReportsActiveUseCase } from 'views/reports/getAreReportsActiveUseCase';
import { AreaDetailsLayout } from 'views/areas/areaDetails/AreaDetailsLayout';
import { GreeneryInfoArea, SegmentationColorScale } from 'views/greenery/components';
import { SegmentationLayerManager } from 'views/greenery/segmentation/SegmentationLayerManager';
import { useGreeneryTimestamps } from 'views/greenery/useGreeneryTimestamps';
import { SegmentationSpecialLayerManager } from 'views/greenery/segmentation/SegmentationSpecialLayerManager';
import { SegmentationViewOptionsSelect } from 'views/greenery/components/SegmentationViewOptionsSelect';
import { SegmentationGreeneryObjectsSelect } from 'views/greenery/segmentation/SegmentationGreeneryObjectsSelect';
import { greeneryRepository } from 'infrastructure/greenery/greeneryRepository';
import { VIEW_MODE_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { useQueryParameter } from 'common/navigation/hooks';
import { COMPARISON_OPTION_NAME } from 'common/navigation/constants';
import { LAND_COVER_DESCRIPTION } from 'domain/greenery/configs';
import { SegmentationTimeline } from './SegmentationTimeline';

export const Segmentation = () => {
  const { areaId } = useParams();
  const viewQuery = useQueryParameter(VIEW_MODE_QUERY_PARAMETER);
  const isComparisonActive: boolean = viewQuery === COMPARISON_OPTION_NAME;

  const { timestamps, areTimestampsLoading, validTimestamp, validSecondTimestamp } = useGreeneryTimestamps();

  const { greeneryObjects, greeneryObjectsLoading } = greeneryRepository.useFetchGreeneryObjects();

  const areReportsActive = useGetAreReportsActiveUseCase();

  return (
    <AreaDetailsLayout
      legend={!areReportsActive && <SegmentationColorScale isComparison={isComparisonActive} />}
      toolbarContent={
        <>
          <GreeneryInfoArea>{LAND_COVER_DESCRIPTION}</GreeneryInfoArea>
          {!areReportsActive && (
            <>
              <SegmentationViewOptionsSelect />
              {validTimestamp && (
                <SegmentationGreeneryObjectsSelect
                  isComparisonActive={isComparisonActive}
                  greeneryObjects={greeneryObjects}
                  timestamp={validTimestamp}
                />
              )}
            </>
          )}
        </>
      }
      toolboxContent={
        <>
          {!isComparisonActive && !areReportsActive && <LayerManagerControlButton />}
          {!areReportsActive && <ShareModalControlButton />}
        </>
      }
    >
      <SegmentationTimeline
        {...{
          timestamps,
          areTimestampsLoading,
          validTimestamp,
          validSecondTimestamp,
          isComparisonActive,
          areReportsActive,
        }}
      />
      {!isComparisonActive && !areReportsActive && (
        <AppLayerManager>
          {validTimestamp && (
            <SegmentationLayerManager
              layerObjects={greeneryObjects}
              greeneryObjectsLoading={greeneryObjectsLoading}
              timestamp={validTimestamp}
            />
          )}
          {areaId === '522' && <SegmentationSpecialLayerManager />}
        </AppLayerManager>
      )}
    </AreaDetailsLayout>
  );
};
