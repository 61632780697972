import { mapIndicatorsToOptions } from 'common/utils';
import { useHandleQueryParameter } from 'common/navigation/hooks';
import { SNOW_COVER_INDICATOR_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { waterRepository } from 'infrastructure/water/waterRepository';

export const useSnowCoverIndicators = () => {
  const [indicatorQueryId, handleIndicatorChange, handleIndicatorClear] = useHandleQueryParameter(
    SNOW_COVER_INDICATOR_QUERY_PARAMETER,
  );

  const { indicators, isLoading } = waterRepository.useFetchSnowCoverIndicators();
  const selectedIndicator = indicators.find(({ id }) => String(id) === indicatorQueryId);

  const options = mapIndicatorsToOptions(indicators);
  const selectedOption = options.find(({ value }) => String(value) === indicatorQueryId);

  return {
    indicators: indicators,
    selectedIndicator,
    indicatorsSelectData: {
      options,
      selectedOption,
      initialStateLabel: 'Select Indicator',
      noOptionsLabel: 'No Indicators',
      name: 'snow cover indicator selection',
      title: '',
      isFetching: isLoading,
    },
    handleIndicatorChange,
    handleIndicatorClear,
  };
};
