import { type ReactNode } from 'react';
import styled from 'styled-components';

import { ScrollbarWrapper } from 'ui/atoms/scrollbarWrapper';
import { CreatorColumnWrapper } from 'ui/creator/creatorDataColumn/CreatorColumnWrapper';
import { LabeledCheckbox } from 'ui/molecules/labeledCheckbox';
import { LoadingWave } from 'ui/atoms/loadingWave';
import { type CreatorDataColumnData } from 'ui/creator/types';

interface CreatorSimpleDataColumnProps {
  children?: ReactNode;
  data: CreatorDataColumnData[];
  label?: string;
  isLoading?: boolean;
}

export const CreatorSimpleDataColumn = ({ children, data, label, isLoading }: CreatorSimpleDataColumnProps) => (
  <CreatorColumnWrapper width={240}>
    {isLoading ? (
      <LoadingWave />
    ) : (
      <ScrollbarWrapper>
        {label && <Label>{label}</Label>}
        {data.map(({ id, label, checked, disabled, radio, onChange }) => (
          <CheckboxWrapper key={id}>
            <LabeledCheckbox
              variant="WIDE_FILLED"
              checked={checked}
              disabled={disabled}
              radio={radio}
              onChange={onChange}
            >
              {label}
            </LabeledCheckbox>
          </CheckboxWrapper>
        ))}
        {children}
      </ScrollbarWrapper>
    )}
  </CreatorColumnWrapper>
);

const Label = styled.h4`
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
  text-transform: uppercase;
  padding: 10px 13px 10px 10px;
`;

const CheckboxWrapper = styled.div`
  padding: 10px 13px 10px 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
`;

CreatorSimpleDataColumn.displayName = 'CreatorSimpleDataColumn';
