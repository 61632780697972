import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { type ValueAndLabel } from 'common/types';
import { waterRepository } from 'infrastructure/water/waterRepository';
import { useDeleteQueryParameters, useQueryParameter } from 'common/navigation/hooks';
import { WATERBODY_QUERY_PARAMETER } from 'common/navigation/queryParams';
import { mapWaterBodiesToOptionsHelper } from 'domain/water/helpers';

export const useWaterbodySelectService = (areaId: number | undefined) => {
  const waterbodyIdQuery = useQueryParameter(WATERBODY_QUERY_PARAMETER);
  const location = useLocation();

  const deleteQueryParameters = useDeleteQueryParameters();

  const { waterBodiesList, waterBodiesLoading } = waterRepository.useFetchWaterbodies(areaId);

  const waterBodyOptions = mapWaterBodiesToOptionsHelper(waterBodiesList);
  const selectedWaterbody: ValueAndLabel | undefined = waterBodyOptions.find(
    ({ value }) => String(value) === waterbodyIdQuery,
  );

  useEffect(() => {
    if (!waterBodyOptions || waterbodyIdQuery === 'undefined' || waterbodyIdQuery === 'null') {
      deleteQueryParameters([WATERBODY_QUERY_PARAMETER]);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterBodiesList, location]);

  const { fetchWaterBodyDefaultView } = waterRepository.useFetchWaterBodyDefaultView();

  const getWaterbodyDefultView = () => {
    if (typeof waterbodyIdQuery === 'string') {
      fetchWaterBodyDefaultView(Number(waterbodyIdQuery));
    }
  };

  useEffect(() => {
    getWaterbodyDefultView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waterbodyIdQuery]);

  return {
    waterbodySelectData: {
      options: waterBodyOptions,
      selectedOption: selectedWaterbody,
      initialStateLabel: 'all water bodies',
      noOptionsLabel: 'No Waterbodies',
      title: '',
      name: 'waterbody selection',
      isFetching: waterBodiesLoading,
    },
  };
};
