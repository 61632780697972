import { useSortedLayersData } from 'components/layerManager/useSortedLayersData';
import { WithCogBandRange } from 'services/titiler/WithCogBandRange';
import { type SurfaceMineralsDisplayed } from 'domain/exploration/types';
import { MINERALIZATION_SLIDER_CONFIG } from 'domain/exploration/configs';
import { useSamLayersVisibility } from './useSurfaceLayersVisibility';
import { SamGradientMapLayer, MineralsPlainMapLayer } from './MineralsMapLayer';
import { useExplorationLayerManagerStore } from '../explorationLayerManagerStore';

interface SamMineralsMapLayersProps {
  areaId: number;
  minerals: SurfaceMineralsDisplayed[];
  indexOffset?: number;
}

export const SamMineralsMapContent = ({ areaId, minerals, indexOffset = 0 }: SamMineralsMapLayersProps) => {
  const samLayersOrderRecord = useExplorationLayerManagerStore.use.samLayersOrderRecord();
  const sortedMinerals = useSortedLayersData(samLayersOrderRecord, minerals, 'mineral_id');

  const isGradientModeOn = useExplorationLayerManagerStore.use.gradientModeState()[1];
  const { classesVisibility: layersVisibility } = useSamLayersVisibility();

  const gradientLayer = sortedMinerals.find(({ name }) => !!layersVisibility[name]);

  return (
    <>
      {isGradientModeOn && gradientLayer ? (
        <WithCogBandRange
          Component={SamGradientMapLayer}
          props={{
            areaId,
            index: indexOffset,
            name: gradientLayer.name,
            url: gradientLayer.url,
            isVisible: !!layersVisibility[gradientLayer.name],
          }}
          url={gradientLayer.url}
          isFetchingEnabled={!!layersVisibility[gradientLayer.name]}
          precision={MINERALIZATION_SLIDER_CONFIG.sam.precision}
        />
      ) : (
        <>
          {sortedMinerals.map(({ id, name, url, color }, index) => (
            <MineralsPlainMapLayer
              key={id}
              index={index + indexOffset}
              {...{ areaId, url, color }}
              name={name}
              classifierId={1}
              isVisible={!!layersVisibility[name]}
            />
          ))}
        </>
      )}
    </>
  );
};
