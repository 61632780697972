import styled from 'styled-components';

import { areasRepository } from 'infrastructure/areas/areasRepository';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';

interface AreaCardAvatarProps {
  areaId: number;
}

export const AreaCardAvatar = ({ areaId }: AreaCardAvatarProps) => {
  const { aoiSatelliteUrl } = areasRepository.useFetchCurrentSatelliteImageUrl(areaId);

  const aoiImageUrl =
    aoiSatelliteUrl &&
    titilerApiAdapter.buildGrayscaleTitilerImageUrl(aoiSatelliteUrl, {
      layerNumber: 3,
      maxSize: 512,
      imageHeight: 350,
      imageWidth: 350,
      rescaleFactor: 0.8096,
      colorMap: 'gray',
    });

  if (!aoiImageUrl) {
    return null;
  }

  return (
    <StyledAreaPreview
      src={aoiImageUrl}
      alt="Area preview"
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.remove();
      }}
    />
  );
};

const StyledAreaPreview = styled.img`
  object-fit: cover;
  width: 100%;
  height: 100%;
`;
