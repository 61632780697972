import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

import { ROUTES } from 'common/navigation/routes';
import { useOverridableFeatureFlag } from 'common/hooks/useOverridableFeatureFlag';
import { userRepository } from 'infrastructure/user/userRepository';
import { validateEmail } from 'domain/user/helpers';
import { InputElementError } from 'domain/user/enums';

export const useSignUp = (captchaToken: string | null) => {
  const enableUserRegistration = useOverridableFeatureFlag(
    'enableUserRegistration',
    false,
    'REACT_APP_ENABLE_USER_REGISTRATION',
  );

  const navigate = useNavigate();

  const [inputs, setInputs] = useState({
    username: '',
    email: '',
    company: '',
  });

  const [errors, setErrors] = useState<string[]>([]);
  const [consentChecked, setConsentChecked] = useState(true);

  const { mutate, status, error } = userRepository.useSignUp();

  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    const { email, company } = inputs;

    const emailValid = validateEmail(email);

    if (!emailValid || !company || !captchaToken || !consentChecked) {
      const localErrors = [];

      !emailValid && localErrors.push(InputElementError.INVALID_EMAIL);
      !captchaToken && localErrors.push(InputElementError.NO_CAPTCHA);
      !consentChecked && localErrors.push(InputElementError.CONSENT);

      setErrors(localErrors);
      return;
    }

    if (!enableUserRegistration) {
      setErrors(['Unable to sign up. Please try again later.']);
      return;
    }

    mutate({
      email,
      company,
    });
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputs({
      ...inputs,
      [event.target.name]: event.target.value,
    });
  };

  const onRedirect = () => navigate(ROUTES.login);

  useEffect(() => setErrors([]), [inputs, captchaToken]);

  useEffect(() => {
    setErrors([]);

    if (error) {
      if (error instanceof AxiosError) {
        const responseErrors = error.response?.data.email as string[];

        if (responseErrors?.length > 0) {
          setErrors(responseErrors);
          return;
        }
      }

      setErrors(['Something went wrong. Please try again.']);
    }
  }, [error]);

  return {
    inputs,
    status,
    errors,
    consentChecked,
    setConsentChecked,
    onSubmit,
    onInputChange,
    onRedirect,
  };
};
