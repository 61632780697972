import { useEffect, useState } from 'react';

import { type LeafletBounds } from 'common/types/mapData';
import { getBoundsFromCogInfo } from 'services/titiler/utils';
import { titilerApiAdapter } from 'services/titiler/titilerApiAdapter';
import { titilerInfoService } from 'services/titiler/titilerInfoService';
import * as map from 'components/map';

interface SatelliteImageLayerProps {
  aoiSatelliteUrl: string;
  isGrayscaleSatellite: boolean;
  isVisible: boolean;
  layerName: string;
  zIndex: number;
  isVhrView: boolean;
  colorMap: string;
  colorFormula: string;
}

export const SatelliteImageLayer = ({
  aoiSatelliteUrl,
  isGrayscaleSatellite,
  isVisible,
  layerName,
  zIndex,
  isVhrView,
  colorMap,
  colorFormula,
}: SatelliteImageLayerProps) => {
  const [cogData, setCogData] = useState<{ cog: string | undefined; bounds: LeafletBounds | undefined }>({
    cog: undefined,
    bounds: undefined,
  });

  // TODO: remove when vhr cogs handling on backend will be ready
  const vhrCogUrl =
    '/tms/cog/tiles/WebMercatorQuad/{z}/{x}/{y}@1x?url=%2Fvsiaz%2Fusers%2Fdk%2FSKYWATCH_PL_PS_20170528T0849_ALL_Tile_0_0_ad19.tif&rescale=0%2C0.4&bidx=3&bidx=2&bidx=1';
  // TODO

  const grayscaleSatelliteCogUrl = titilerApiAdapter.buildGrayscaleSatelliteImageUrl(aoiSatelliteUrl, {
    rescaleFactor: '0,8192',
    fileType: 'png',
    colorMap,
  });

  const satelliteCogUrl = titilerApiAdapter.buildSatelliteImageUrl(aoiSatelliteUrl, {
    rescaleFactor: '0,8192',
    fileType: 'png',
    colorFormula,
  });

  const { info: satelliteCogInfo } = titilerInfoService.useGetInfo(aoiSatelliteUrl);

  const satelliteBounds = getBoundsFromCogInfo(satelliteCogInfo);

  useEffect(() => {
    switch (true) {
      case !isGrayscaleSatellite && isVhrView:
        setCogData({ cog: vhrCogUrl, bounds: undefined });
        break;
      case isGrayscaleSatellite && !!aoiSatelliteUrl:
        setCogData({ cog: grayscaleSatelliteCogUrl, bounds: satelliteBounds });
        break;
      default:
        setCogData({ cog: satelliteCogUrl, bounds: satelliteBounds });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [satelliteCogUrl, isGrayscaleSatellite, colorMap]);

  return (
    <>
      {cogData && (satelliteBounds || isVhrView) ? (
        <map.DataLayer
          isVisible={isVisible}
          layerName={layerName}
          zIndex={zIndex}
          bounds={satelliteBounds}
          cogUrl={cogData.cog}
          fixed={isVhrView}
        />
      ) : null}
    </>
  );
};
